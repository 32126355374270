import React, { useState, useEffect, useContext } from 'react';
import { useLocation, Outlet } from 'react-router-dom';
import { useIntl } from 'react-intl';

import CollectionsProvider from '../context/Collections/CollectionsProvider';
import TemplatesProvider from '../context/Templates/TemplatesProvider';

import TiersContext from '../context/Tiers/TiersContext';

import { PrivateLayout } from '../components/PrivateLayout';
import StampSelect from '../components/StampSelect';
import NoAccessRedirect from '../components/NoAccessRedirect';
import ConfirmModal from '../components/ConfirmModal';
import Loader from '../components/Loader';

import { statusContext, statusPayment } from '../constants';
import { APP_ROUTES } from '../constants';

export const StampPage = () => {
  const [showSelect, setShowSelect] = useState(true);
  const [paymentModal, setPaymentModal] = useState(false);

  const intl = useIntl();
  const tiersContext = useContext(TiersContext);
  const location = useLocation();

  useEffect(() => {
    if (
      tiersContext.paymentData.lastOperationStatus === statusPayment.PAID &&
      tiersContext.paymentData.lastTransactionStatus === statusContext.PENDING &&
      (tiersContext.userTier.documentStamp?.enabled ||
        tiersContext.userTier.collectionStamp?.enabled)
    ) {
      setPaymentModal(true);
    }
  }, [tiersContext.paymentData.lastOperationStatus]);

  useEffect(() => {
    if (
      location.pathname === APP_ROUTES.STAMP.FREE ||
      location.pathname === APP_ROUTES.STAMP.TEMPLATE
    ) {
      setShowSelect(false);
    } else {
      setShowSelect(true);
    }
  }, [location]);

  document.title = intl.formatMessage({ id: 'app.page.stamp' });

  return (
    <CollectionsProvider>
      <TemplatesProvider>
          <PrivateLayout
            title={!showSelect && intl.formatMessage({ id: 'app.stamp.title' })}
            subtitle={intl.formatMessage({ id: 'app.stamp.subtitle' })}
          >
            <ConfirmModal
              open={paymentModal}
              modalText={intl.formatMessage({
                id: 'app.my-account.plans.processing-modal-paid',
              })}
              confirmButtonText={intl.formatMessage({
                id: 'app.my-account.plans.processing-modal-confirm-button',
              })}
              confirmAction={() => {
                setPaymentModal(false);
              }}
            />
            {tiersContext.userTier.documentStamp?.enabled &&
              tiersContext.userTier.collectionStamp?.enabled && (
                <>
                  {showSelect && !tiersContext.loading && (
                    <StampSelect locations={APP_ROUTES.STAMP} />
                  )}
                  <Outlet />
                </>
              )}
            {(tiersContext.userTier.documentStamp?.enabled === false ||
              tiersContext.userTier.collectionStamp?.enabled === false) && (
              <NoAccessRedirect
                title={intl.formatMessage({ id: 'app.noaccess.title' })}
                text={intl.formatMessage({
                  id: 'app.noaccess.no-stamps-left',
                })}
              />
            )}
            {tiersContext.loading && <Loader margin={'12rem'} />}
          </PrivateLayout>
      </TemplatesProvider>
    </CollectionsProvider>
  );
};
