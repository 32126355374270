import { AppRouter } from './routers';
import { ThemeProvider } from '@mui/material/styles';
import { useIntl } from 'react-intl';

import GlobalAlert from './components/GlobalAlert';
import DisconnectModal from './components/DisconnectModal';

import AlertsProvider from './context/Alerts/AlertsProvider';

import theme from './theme';

export const App = () => {
  const intl = useIntl();

  return (
    <ThemeProvider theme={theme}>
      <AlertsProvider>
        <AppRouter />
        <GlobalAlert />
        <DisconnectModal
          alertText={intl.formatMessage({
            id: 'app.alerts.disconnect-modal-text1',
          })}
          buttonText={intl.formatMessage({
            id: 'app.alerts.disconnect-modal-button',
          })}
          buttonAction={() => window.location.reload()}
        />
      </AlertsProvider>
    </ThemeProvider>
  );
};
