import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Snackbar,
  Alert,
  AlertTitle,
  Grid,
  Button,
  Typography,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { useIntl } from 'react-intl';
import { styled } from '@mui/material/styles';
import TiersContext from '../context/Tiers/TiersContext';

const CustomPlanAlert = styled(Alert)({
  background: '#FFFFFF',
  boxShadow: '0px 0px 13px rgba(0, 0, 0, 0.3)',
  borderRadius: '0.5rem',
  '.MuiAlert-icon': {
    display: 'flex',
    alignItems: 'center',

    '& svg': {
      padding: '0.6rem',
      borderRadius: '8px',
      background: '#EFF3FF',
    },
  },
});

const ButtonGrid = styled(Grid)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  '@media (max-width: 900px)': {
    justifyContent: 'center',
    margin: '1rem 0rem',
  },
});

const CustomButton = styled(Button)({
  padding: '0.7rem 4rem',
  marginLeft: '4rem',
  textTransform: 'none',
});

export default function PlanAlert(props) {
  const tiersContext = useContext(TiersContext);
  const intl = useIntl();
  const navigate = useNavigate();

  function textSwitch(remainingItems) {
    switch (remainingItems) {
      case 1:
        return 'app.my-account.plans.alert-text-1-left';
      case 0:
        return 'app.my-account.plans.alert-text-0-left';
      default:
        return 'app.my-account.plans.alert-text';
    }
  }

  return (
    <CustomPlanAlert severity="info">
      <Grid container>
        <Grid item md={8} xs={12}>
          <AlertTitle
            color="primary"
            children={intl.formatMessage({
              id: 'app.my-account.plans.alert-title',
            })}
          />
          <Typography
            variant="body2"
            children={intl.formatMessage(
              { id: textSwitch(tiersContext.userTier.documentStamp?.remaining_items) },
              { availableStamps: tiersContext.userTier.documentStamp?.remaining_items }
            )}
          />
        </Grid>
        <ButtonGrid item md={4} xs={12}>
          <CustomButton
            variant="contained"
            onClick={() => navigate('/my-account/plans')}
            children={
              <Typography
                sx={{ whiteSpace: 'nowrap' }}
                variant="body2"
                children={intl.formatMessage({
                  id: 'app.my-account.plans.alert-button',
                })}
              />
            }
          />
        </ButtonGrid>
      </Grid>
    </CustomPlanAlert>
  );
}
