import React, { useContext, useEffect, useState } from 'react';
import { Element, animateScroll } from 'react-scroll';
import useCollection from '../hooks/useCollection';
import { useNavigate, useParams } from 'react-router-dom';
import ChipGrid from './ChipGrid';
import InlineAlert from './InlineAlert';
import PlanAlert from './PlanAlert';
import ConfirmModal from './ConfirmModal';
import NoAccessRedirect from './NoAccessRedirect';
import Loader from './Loader';
import StampDocumentCard from './StampDocumentCard';
import AddExistingDocument from './stamp/AddExistingDocument';
import { useCallbackPrompt } from '../hooks/useCallbackPrompt';

import { v4 as uuidv4 } from 'uuid';

import {
  FIELD_TYPE_FILE,
  FIELD_TYPE_TEXT,
  STAMP_MODE_FREE,
  STAMP_MODE_TEMPLATE,
  STAMP_MODE_EDIT_DRAFT,
  COLLECTION_STATUS_PENDING,
  COLLECTION_STATUS_PROCESSING,
  COLLECTION_STATUS_DRAFT,
  COLLECTION_STATUS_STAMPED,
  TEMPLATE_STATUS_ACTIVE,
  REQUEST_SUCCESS,
  REQUEST_ERROR,
  DELETE_SUCCESS,
  ADD_EXISTING_MODES,
} from '../constants';

import CollectionsContext from '../context/Collections/CollectionsContext';
import TemplatesContext from '../context/Templates/TemplatesContext';
import TiersContext from '../context/Tiers/TiersContext';
import NotificationsContext from '../context/Notifications/NotificationsContext';

import { useIntl } from 'react-intl';

import { config } from '../config';

import {
  TextField,
  Box,
  Button,
  Stack,
  Grid,
  CircularProgress,
  Fade,
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem,
  InputLabel,
  Typography,
  useMediaQuery,
} from '@mui/material';

import CustomButton from './CustomButton';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import { ReactComponent as AddDocumentIcon } from '../assets/AddDocumentIcon.svg';

import { styled } from '@mui/material/styles';

const TextFieldContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const TemplateLabelContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '30rem',
  margin: '1rem 0rem',
  '@media (max-width: 600px)': {
    width: '100%',
  },
});

const CustomTextField = styled(TextField)({
  '@media (min-width: 600px)': {
    minWidth: '30rem',
  },
});

const FieldButton = styled(Button)(({ theme }) => ({
  minWidth: '24rem',
  minHeight: '4rem',
  backgroundColor: '#FFFFFF',
  margin: '0.5rem 0.5rem',
  padding: '0.8rem 0rem',
  boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.2)',
  '@media (max-width: 600px)': {
    minWidth: '90vw',
  },
  '& p': {
    color: theme.palette.primary.main,
  },
  '&:hover': {
    backgroundColor: '#f0f0f0',
  },
  '.MuiButton-startIcon': {
    '& svg': {
      fontSize: '32px',
      color: 'rgba(125,80,255,1)',
    },
  },
}));

const StampDialog = styled(Dialog)({
  '.MuiDialog-paper': {
    borderRadius: '18px',
  },
});

export default function Stamp(props) {
  const [selectedTemplateId, setSelectedTemplateId] = useState();
  const [request, setRequest] = useState(0);
  const [showSkipModal, setShowSkipModal] = useState(false);
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);

  const [addExistingModal, setAddExistingModal] = useState(false);
  const [addExistingType, setAddExistingType] = useState(undefined);
  const [deleteId, setDeleteId] = useState();

  const templatesContext = useContext(TemplatesContext);
  const collectionsContext = useContext(CollectionsContext);
  const tiersContext = useContext(TiersContext);
  const notificationsContext = useContext(NotificationsContext);

  const intl = useIntl();
  const navigate = useNavigate();
  const { id } = useParams();
  const mobile = useMediaQuery('(max-width: 600px)');

  const delay = 3;

  const {
    handleInitInputFields,
    handleSelectTemplateId,
    handleChangeCollectionName,
    handleAddFields,
    addExistingDocument,
    handleChangeInput,
    handleRemoveFields,
    handleSubmit,
    validate,
    handleDeleteDraft,
    setName,
    setInputFieldIndex,
    inputFields,
    inputFieldIndex,
    templateId,
    name,
    titleError,
    redirect,
    isChanged,
  } = useCollection();

  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(isChanged);

  const getUploadProgress = (progressInfo) => {
    setUploadInProgress(progressInfo?.status.loading);
  };

  const onChange = (id, event) => {
    const newInputFields2 = inputFields.map((i) => {
      if (id === i.id) {
        i[event.name] = event.uploadedFileInfo;
      }
      return i;
    });
  };

  async function getTemplateFields(tempId) {
    if (
      collectionsContext.status === REQUEST_SUCCESS ||
      collectionsContext.status === DELETE_SUCCESS
    ) {
      return;
    } else if (tempId !== config.templateDefaultId) {
      await templatesContext.getTemplateById(tempId);
    }
  }

  async function getCollectionFields(id) {
    await collectionsContext.getCollectionById(id);
  }

  const handleChangeTemplate = (event) => {
    setSelectedTemplateId(event.target.value);
    handleSelectTemplateId(event.target.value);
    setName('');
  };

  const handleFinish = () => {
    navigate('/my-documents');
  };

  const handleGoCollectionDetail = () => {
    if (
      collectionsContext.postedCollection &&
      collectionsContext.loading === false
    ) {
      !collectionsContext.errors &&
        navigate(`/collections/` + collectionsContext.postedCollection.id);
    }
  };

  const handleSkipPreload = (event, reason) => {
    if (reason && reason == 'backdropClick') {
      return;
    }
    notificationsContext.setPulled(false);
    notificationsContext.getNotifications(0, 1000, true, null, null, false);
    handleFinish();
  };

  const handleOpenAddExistingModal = (type) => {
    setAddExistingType(type);
    setAddExistingModal(true);
  };

  const handleCloseAddExistingModal = () => {
    setInputFieldIndex(undefined);
    setAddExistingModal(false);
  };

  const handleAddDocument = (type, templateDocumentId) => {
    handleAddFields(type, templateDocumentId);
    if (inputFields?.length > 0) {
      animateScroll.scrollToBottom();
    }
  };

  const handleAddExistingDocument = (document, index, type) => {
    addExistingDocument(document, index, type);
    if (inputFields?.length > 0 && type === ADD_EXISTING_MODES.DOCUMENT) {
      animateScroll.scrollToBottom();
    }
    setAddExistingModal(false);
  };

  const handleOpenConfirmModal = () => {
    setConfirmModal(true);
  };

  const handleCloseConfirmModal = () => {
    setConfirmModal(false);
  };

  const handleValidate = (status) => {
    if (validate(status)) {
      handleOpenConfirmModal();
    }
  };

  const handleDeleteCollection = async (id) => {
    await handleDeleteDraft(id);
    handleCloseConfirmModal();
  };

  async function handleSubmitCollection(status, id) {
    await handleSubmit(status, id);
    setRequest(request + 1);
  }

  const executeTimeout = async (collection) => {
    const timeout = setTimeout(async () => {
      collection && (await collectionsContext.getCollectionById(collection.id));
      setRequest(request + 1);
    }, delay * 1000);
    return () => clearTimeout(timeout);
  };

  useEffect(() => {
    if (props.stampMode === STAMP_MODE_FREE) {
      handleSelectTemplateId(config.templateDefaultId);
      setSelectedTemplateId(templateId);
    }
    if (props.stampMode === STAMP_MODE_EDIT_DRAFT) {
      if (request === 0) {
        getCollectionFields(id);
      }
    }
    return;
  }, []);

  useEffect(() => {
    if (request > 0) {
      if (!collectionsContext.errors && collectionsContext.postedCollection) {
        if (
          collectionsContext.postedCollection.status === COLLECTION_STATUS_DRAFT
        ) {
          handleFinish();
        } else if (
          collectionsContext.postedCollection.status ===
            COLLECTION_STATUS_PENDING ||
          collectionsContext.postedCollection.status ===
            COLLECTION_STATUS_PROCESSING
        ) {
          setShowSkipModal(true);
          executeTimeout(collectionsContext.postedCollection);
          if (
            collectionsContext.collection?.status === COLLECTION_STATUS_STAMPED
          ) {
            handleGoCollectionDetail();
          }
        }
      } else {
        setRequest(0);
      }
    }
  }, [request, collectionsContext.status]);

  useEffect(() => {
    if (
      redirect &&
      (collectionsContext.status === REQUEST_SUCCESS ||
        collectionsContext.status === DELETE_SUCCESS)
    ) {
      handleFinish();
    } else if (collectionsContext.status === REQUEST_ERROR) {
      handleCloseConfirmModal();
    }
  }, [redirect, collectionsContext.status]);

  useEffect(() => {
    if (
      props.stampMode === STAMP_MODE_TEMPLATE ||
      props.stampMode === STAMP_MODE_EDIT_DRAFT
    ) {
      templatesContext.getTemplatesList();
      if (templateId) {
        getTemplateFields(templateId);
      }
    }
  }, [templateId]);

  useEffect(() => {
    if (
      props.stampMode === STAMP_MODE_TEMPLATE &&
      templatesContext.editTemplate
    ) {
      const templateDocuments =
        templatesContext.editTemplate.template_documents.map((t) => {
          t['error'] = { title: false, description: false };
          t['document_type'] = t.field_type;
          t['template_document_id'] = t.id;
          if (t.document_type === FIELD_TYPE_TEXT) {
            t['text_value'] = '';
          } else if (t.document_type === FIELD_TYPE_FILE) {
            t['uploader_1'] = [];
          }
          return t;
        });
      handleInitInputFields(templateDocuments);
    } else if (
      props.stampMode === STAMP_MODE_EDIT_DRAFT &&
      collectionsContext.collection
    ) {
      const draftDocuments = collectionsContext.collection.documents.map(
        (d) => {
          d['error'] = { title: false, description: false };
          if (
            collectionsContext.collection.template_id !==
              config.templateDefaultId &&
            d.template_document_id
          ) {
            d['validations'] =
              collectionsContext.collection.template.template_documents.filter(
                (td) => td.id === d.template_document_id
              )[0].validations;
          }

          if (d.document_type === FIELD_TYPE_FILE) {
            if (d.file_hash) {
              let uploaderDocument = {
                publicFileURI: d.file,
                checksum: d.file_hash,
                originalName: d.file_name,
                fileId: d.file_id,
              };
              d['restoredFile'] = uploaderDocument;
              d['uploader_1'] = [uploaderDocument];
            } else {
              d['uploader_1'] = [];
            }
          }
          return d;
        }
      );
      setName(collectionsContext.collection.name);
      handleSelectTemplateId(collectionsContext.collection.template_id);
      setSelectedTemplateId(templateId);
      handleInitInputFields(draftDocuments);
    } else if (props.stampMode === STAMP_MODE_FREE) {
      handleInitInputFields([
        {
          id: uuidv4(),
          title: '',
          description: '',
          uploader_1: [],
          document_type: FIELD_TYPE_FILE,
          template_document_id: null,
          error: { title: false, description: false },
        },
      ]);
    } else {
      return;
    }
  }, [
    templatesContext.editTemplate,
    collectionsContext.collection,
    selectedTemplateId,
  ]);

  return (
    <>
      <ConfirmModal
        open={confirmModal}
        onClose={handleCloseConfirmModal}
        modalTitle={intl.formatMessage({ id: 'app.stamp.draft-modal-title' })}
        modalText={
          deleteId
            ? intl.formatMessage({
                id: 'app.stamp.delete-draft-confirm',
              })
            : intl.formatMessage({
                id: 'app.stamp.draft-confirm',
              })
        }
        confirmAction={() => {
          handleCloseConfirmModal();
          if (deleteId) {
            handleDeleteCollection(id);
          } else {
            handleSubmitCollection(COLLECTION_STATUS_DRAFT, id);
          }
          handleCloseConfirmModal();
        }}
        cancelAction={() => {
          handleCloseConfirmModal();
        }}
      />
      <ConfirmModal
        open={showPrompt}
        modalTitle={intl.formatMessage({
          id: 'app.alerts.not-saved-changes.modal-title',
        })}
        warningTitle={intl.formatMessage({
          id: 'app.confirm-modal.warning-title',
        })}
        warningText={intl.formatMessage({
          id: 'app.alerts.not-saved-changes.text',
        })}
        confirmAction={confirmNavigation}
        cancelAction={cancelNavigation}
      />
      <AddExistingDocument
        open={addExistingModal}
        inputFieldIndex={inputFieldIndex}
        addExistingType={addExistingType}
        handleCloseAddExistingModal={handleCloseAddExistingModal}
        handleAddExistingDocument={handleAddExistingDocument}
      />
      <Box
        component="form"
        onSubmit={handleSubmit}
        noValidate
        sx={{
          filter:
            (props.stampMode === STAMP_MODE_TEMPLATE &&
              !tiersContext.userTier.templateSupport?.enabled) ||
            !tiersContext.userTier.documentStamp?.enabled ||
            !tiersContext.userTier.collectionStamp?.enabled
              ? 'blur(3px)'
              : null,
        }}
      >
        <Fade timeout={250} in={true}>
          <Stack spacing={2}>
            {(props.stampMode === STAMP_MODE_TEMPLATE ||
              props.stampMode === STAMP_MODE_EDIT_DRAFT) && (
              <>
                <TextFieldContainer>
                  <TemplateLabelContainer>
                    <div style={{ display: 'flex' }}>
                      {collectionsContext.collection?.template_id !==
                        config.templateDefaultId && (
                        <InputLabel id="templates-label" sx={{ mb: 0 }}>
                          {intl.formatMessage({
                            id:
                              props.stampMode === STAMP_MODE_EDIT_DRAFT
                                ? 'app.stamp.edit-label'
                                : 'app.stamp.select-label',
                          })}
                        </InputLabel>
                      )}
                      {templatesContext.loading && (
                        <CircularProgress
                          sx={{ margin: '0rem 1rem' }}
                          color="inherit"
                          size={'1.5rem'}
                        />
                      )}
                    </div>
                    {props.stampMode === STAMP_MODE_EDIT_DRAFT &&
                      collectionsContext.collection &&
                      collectionsContext.collection.template_id !==
                        config.templateDefaultId && (
                        <ChipGrid
                          status={TEMPLATE_STATUS_ACTIVE}
                          showIcon={true}
                          templateName={
                            collectionsContext.collection.template?.title
                          }
                        />
                      )}
                  </TemplateLabelContainer>
                  {props.stampMode === STAMP_MODE_TEMPLATE &&
                    templatesContext.templates?.length !== 0 && (
                      <CustomTextField
                        select
                        fullWidth={mobile}
                        variant="outlined"
                        disabled={
                          templatesContext.loading ||
                          !tiersContext.userTier.templateSupport?.enabled
                        }
                        label={intl.formatMessage({
                          id: 'app.stamp.select-placeholder',
                        })}
                        value={selectedTemplateId}
                        onChange={handleChangeTemplate}
                        children={templatesContext.templates?.map(
                          (template, index) => (
                            <MenuItem divider key={index} value={template.id}>
                              {template.title}
                            </MenuItem>
                          )
                        )}
                      />
                    )}
                  {props.stampMode === STAMP_MODE_TEMPLATE &&
                    templatesContext.templates?.length === 0 && (
                      <InlineAlert
                        title={intl.formatMessage({
                          id: 'app.alerts.inline-info-title',
                        })}
                        severity="info"
                        alertText={intl.formatMessage({
                          id: 'app.alerts.inline-info-novalidtemplate',
                        })}
                      />
                    )}
                </TextFieldContainer>
              </>
            )}
            {inputFields?.length > 1 && (
              <TextFieldContainer>
                <CustomTextField
                  fullWidth={mobile}
                  label={intl.formatMessage({
                    id: 'app.stamp.collection-name',
                  })}
                  variant="outlined"
                  value={name}
                  onChange={handleChangeCollectionName}
                  error={titleError}
                  helperText={
                    titleError &&
                    intl.formatMessage({
                      id: 'app.stamp.collection-name-error',
                    })
                  }
                  inputProps={{ maxLength: config.maxLengthTitle }}
                />
                <Element name={`collectionName`} />
              </TextFieldContainer>
            )}
            {templatesContext.loading === false && request === 0 ? (
              inputFields?.map((inputField, index) => (
                <Element key={index} name={`documentCard-${inputField.id}`}>
                  <StampDocumentCard
                    key={index}
                    index={index}
                    handleChangeInput={handleChangeInput}
                    handleRemoveFields={handleRemoveFields}
                    handleOpenAddExistingModal={handleOpenAddExistingModal}
                    getUploadProgress={getUploadProgress}
                    setInputFieldIndex={setInputFieldIndex}
                    onChange={onChange}
                    inputField={inputField}
                    documentType={inputField.document_type}
                    deletable={inputField.validations ? false : true}
                    stampMode={props.stampMode}
                  />
                </Element>
              ))
            ) : (
              <Loader margin={'2rem'} />
            )}
            {inputFields?.length === 0 &&
            props.stampMode === STAMP_MODE_FREE ? (
              <Grid container direction={'row'} justifyContent={'center'}>
                <Grid
                  item
                  md={12}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '2rem 0rem',
                  }}
                >
                  <Typography
                    variant="h5"
                    textAlign={'center'}
                    children={intl.formatMessage({
                      id: 'app.stamp.no-documents-text',
                    })}
                  />
                </Grid>
              </Grid>
            ) : null}
          </Stack>
        </Fade>
        {(templatesContext.editTemplate?.allow_add_documents ||
          props.stampMode === STAMP_MODE_FREE ||
          collectionsContext.collection?.template.allow_add_documents) && (
          <Fade timeout={250} in={true}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{ mt: 2, mb: 2 }}
            >
              <FieldButton
                variant="contained"
                onClick={() => {
                  handleAddDocument(FIELD_TYPE_FILE, null);
                }}
                startIcon={<AddCircleIcon />}
                children={
                  <Typography
                    variant="body2"
                    fontWeight={500}
                    children={intl.formatMessage({
                      id: 'app.stamp.add-file-button',
                    })}
                  />
                }
              />
              <FieldButton
                variant="contained"
                onClick={() => {
                  handleAddDocument(FIELD_TYPE_TEXT, null);
                }}
                startIcon={<AddCircleIcon />}
                children={
                  <Typography
                    variant="body2"
                    fontWeight={500}
                    children={intl.formatMessage({
                      id: 'app.stamp.add-text-button',
                    })}
                  />
                }
              />
              <FieldButton
                variant="contained"
                onClick={() => {
                  handleOpenAddExistingModal(ADD_EXISTING_MODES.DOCUMENT);
                }}
                startIcon={<AddDocumentIcon />}
                children={
                  <Typography
                    variant="body2"
                    fontWeight={500}
                    children={intl.formatMessage({
                      id: 'app.stamp.add-doc-button',
                    })}
                  />
                }
              />
            </Grid>
          </Fade>
        )}
        {templatesContext.loading === false &&
        collectionsContext.loading === false ? (
          <Fade timeout={250} in={true}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={0}
              sx={{
                mt: 3,
                mb: 3,
                '& button': {
                  margin: '0.5rem',
                  width: '16rem',
                },
              }}
            >
              {tiersContext.userTier.documentStamp?.remaining_items &&
                inputFields.length >
                  tiersContext.userTier.documentStamp.remaining_items && (
                  <Grid item sx={{ margin: '1rem 0rem' }}>
                    <PlanAlert />
                  </Grid>
                )}
              <Grid
                item
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <CustomButton
                  variant="contained"
                  onClick={() => {
                    if (validate(COLLECTION_STATUS_PENDING)) {
                      handleSubmitCollection(COLLECTION_STATUS_PENDING, id);
                    }
                  }}
                  disabled={
                    uploadInProgress ||
                    inputFields?.length === 0 ||
                    (tiersContext.userTier.documentStamp?.remaining_items &&
                      inputFields.length >
                        tiersContext.userTier.documentStamp.remaining_items)
                  }
                  children={intl.formatMessage({
                    id: 'app.stamp.stamp-button',
                  })}
                />
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    handleValidate(COLLECTION_STATUS_DRAFT);
                  }}
                  disabled={uploadInProgress || inputFields?.length === 0}
                  children={intl.formatMessage({
                    id: 'app.stamp.draft-button',
                  })}
                />
                {props.stampMode === STAMP_MODE_EDIT_DRAFT && (
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                      setDeleteId(id);
                      handleOpenConfirmModal();
                    }}
                    disabled={uploadInProgress || inputFields?.length === 0}
                    children={intl.formatMessage({
                      id: 'app.stamp.delete-button',
                    })}
                  />
                )}
              </Grid>
            </Grid>
          </Fade>
        ) : null}
      </Box>
      <StampDialog
        open={showSkipModal}
        maxWidth="md"
        onClose={() => {
          collectionsContext.status !== REQUEST_ERROR && handleSkipPreload();
        }}
        disableEscapeKeyDown={true}
      >
        <DialogTitle>
          {intl.formatMessage({ id: 'app.stamp.stamp-modal-status' })}
        </DialogTitle>
        <DialogContent>
          <Stack justifyContent="center" alignItems="center">
            <CircularProgress color="inherit" style={{ margin: '2rem 0rem' }} />
            <Grid container>
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent="center"
                height="3rem"
              >
                {request > 1 && (
                  <CustomButton
                    variant="contained"
                    onClick={() => {
                      handleSkipPreload();
                    }}
                    children={intl.formatMessage({
                      id: 'app.stamp.upload-skip',
                    })}
                  />
                )}
              </Grid>
            </Grid>
          </Stack>
        </DialogContent>
      </StampDialog>
      {(props.stampMode === STAMP_MODE_TEMPLATE &&
        tiersContext.userTier.templateSupport?.enabled === false) ||
      (tiersContext.userTier.documentStamp?.enabled === false &&
        tiersContext.userTier.collectionStamp?.enabled === false) ? (
        <NoAccessRedirect
          title={intl.formatMessage({ id: 'app.noaccess.title' })}
          text={intl.formatMessage({
            id: 'app.noaccess.no-stamps-left',
          })}
        />
      ) : null}
    </>
  );
}
