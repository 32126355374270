import React, { useReducer, useContext } from 'react';

import { STATUS_OK } from '../../constants';

import UserContext from './UserContext';
import UserReducer from './UserReducer';
import AlertsContext from '../Alerts/AlertsContext';

import {
  REGISTER_FAILURE,
  REGISTER_SUCCESS,
  REGISTER_REQUEST,
  ACTIVATE_FAILURE,
  ACTIVATE_SUCCESS,
  PASSWORD_REQUEST,
  PASSWORD_SUCCESS,
  PASSWORD_FAILURE,
} from './UserTypes';

import { registerUser, activateUser, changePassword } from '../../api/user';

import { useIntl } from 'react-intl';

const UserProvider = (props) => {
  const initialState = {
    registerRequested: false,
    registerFailed: false,
    registerSuccess: false,
    activateFailed: false,
    activateSuccess: true,
    passwordRequested: false,
    passwordFailed: false,
    passwordSuccess: false,
    errors: undefined,
  };

  const [state, dispatch] = useReducer(UserReducer, initialState);
  const intl = useIntl();
  const alertsContext = useContext(AlertsContext);

  const register = async (user) => {
    try {
      dispatch({ type: REGISTER_REQUEST });
      const response = await registerUser(user);
      if (response.status === STATUS_OK) {
        dispatch({ type: REGISTER_SUCCESS });
      } else {
        dispatch({ type: REGISTER_FAILURE, payload: response.data.errors });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: REGISTER_FAILURE,
        payload: [intl.formatMessage({ id: 'app.userprovider.error' })],
      });
    }
  };

  const activate = async (hash) => {
    try {
      const response = await activateUser(hash);
      if (response.status === STATUS_OK) {
        dispatch({ type: ACTIVATE_SUCCESS });
      } else {
        dispatch({ type: ACTIVATE_FAILURE, payload: response.data.errors });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: ACTIVATE_FAILURE,
        payload: [intl.formatMessage({ id: 'app.userprovider.error' })],
      });
    }
  };

  const changePasswordManually = async (oldPassword, newPassword) => {
    try {
      dispatch({ type: PASSWORD_REQUEST });
      const response = await changePassword({
        old_password: oldPassword,
        password: newPassword,
      });
      if (response.status === STATUS_OK) {
        dispatch({ type: PASSWORD_SUCCESS });
        alertsContext.createSuccessAlert(
          intl.formatMessage({ id: 'app.my-account.security.success' })
        );
      } else {
        dispatch({ type: PASSWORD_FAILURE, payload: response.data.errors });
        alertsContext.createErrorAlert(response.data.errors);
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: PASSWORD_FAILURE,
        payload: [intl.formatMessage({ id: 'app.userprovider.error' })],
      });
      alertsContext.createErrorAlert(
        intl.formatMessage({ id: 'app.userprovider.error' })
      );
    }
  };

  return (
    <UserContext.Provider
      value={{
        registerRequested: state.registerRequested,
        registerFailed: state.registerFailed,
        registerSuccess: state.registerSuccess,
        activateFailed: state.activateFailed,
        activateSuccess: state.activateSuccess,
        passwordRequested: state.passwordRequested,
        passwordFailed: state.passwordFailed,
        passwordSuccess: state.passwordSuccess,
        errors: state.errors,
        register,
        activate,
        changePasswordManually,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserProvider;
