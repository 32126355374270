import React, { useEffect, useContext } from 'react';
import SideMenu from '../components/SideMenu';
import TopDrawerMenu from './TopDrawerMenu';
import PlanAlert from '../components/PlanAlert';

// import { useSnackbar } from 'notistack';
import NotificationsMenu from './notifications/NotificationsMenu';
// import { Alert } from '@mui/material';

import validitaLogo from '../assets/ValiditaLogoClear.svg';

import {
  Grid,
  Paper,
  Container,
  Typography,
  useMediaQuery,
} from '@mui/material';

import { styled } from '@mui/material/styles';

import TiersContext from '../context/Tiers/TiersContext';
import NotificationsContext from '../context/Notifications/NotificationsContext';

export const Header = styled(Paper)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  background: '#FFFFFF',
  boxShadow: '0px 4px 35px rgba(166, 138, 205, 0.2)',
  borderRadius: '0px 0px 15px 15px',
  padding: '0.3rem',
  position: 'fixed',
  minHeight: '64px',
  width: '100%',
  zIndex: '2',
});

const SideMenuContainer = styled('div')({
  background: '#FFFFFF',
  boxShadow: '0px 4px 35px rgba(109, 102, 119, 0.2)',
  maxWidth: '232px',
  minHeight: '100vh',
  height: '100%',
});

const LogoContainer = styled('div')({
  display: 'flex',
  border: '1px solid rgba(91, 91, 91, 0.1)',
  padding: '0.7rem',
  borderRadius: '0.5rem',
  width: 'auto',
  height: '100%',
  marginLeft: '0.4rem',
  '& img': {
    width: '6rem',
  },
});

const TitleContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  height: '5rem',
  width: '100%',
  '@media (max-width: 600px)': {
    justifyContent: 'center',
  },
});

// const CustomPlanAlert = styled(Alert)({
//   background: '#FFFFFF',
//   boxShadow: '0px 0px 13px rgba(0, 0, 0, 0.3)',
//   borderRadius: '0.5rem',
//   '.MuiAlert-icon': {
//     display: 'flex',
//     alignItems: 'center',
//     '& svg': {
//       padding: '0.6rem',
//       borderRadius: '8px',
//       background: '#EFF3FF',
//     },
//   },
// });

export const PrivateLayout = (props) => {
  const tiersContext = useContext(TiersContext);
  const notificationsContext = useContext(NotificationsContext);

  const mobile = useMediaQuery('(max-width: 900px)');

  const SEARCH_LIMIT = 9;

  useEffect(() => {
    async function defaultFetch() {
      await tiersContext.getTierState();
      await tiersContext.getLastPaymentStatus();
      if (!notificationsContext.pulled) {
        notificationsContext.getNotifications(
          0,
          SEARCH_LIMIT,
          true,
          null,
          null,
          false
        );
      }
    }
    defaultFetch();
  }, []);

  // useEffect(() => {
  //   async function notificationsCall() {
  //     if (!notificationsContext.pulled && notificationsContext.notifications) {
  //       notificationsContext.notifications?.forEach((notification) => {
  //         const queuedSnack = enqueueSnackbar(notification.message.es, {
  //           content: (
  //             <CustomPlanAlert severity="info">
  //               <NotificationAlert
  //                 id={notification.id}
  //                 title={notification.title.es}
  //                 message={notification.message.es}
  //                 code={notification.code}
  //                 handleCloseSnackbar={() => {
  //                   closeSnackbar(queuedSnack);
  //                 }}
  //               />
  //             </CustomPlanAlert>
  //           ),
  //         });
  //       });
  //     }
  //   }
  //   notificationsCall();
  // }, [notificationsContext.notifications, notificationsContext.pulled]);

  return (
    <>
      <Header>
        <LogoContainer>
          <img src={validitaLogo} alt="Validita Logo" />
        </LogoContainer>
        <div>
          <NotificationsMenu searchLimit={SEARCH_LIMIT} />
          {mobile && <TopDrawerMenu />}
        </div>
      </Header>
      <Grid container>
        {!mobile && (
          <Grid item xs={1.5}>
            <SideMenuContainer>
              <SideMenu />
            </SideMenuContainer>
          </Grid>
        )}
        <Grid item xs={mobile ? 12 : 10.5} sx={{ marginTop: '6rem' }}>
          <Container maxWidth="xl">
            {props.title && (
              <TitleContainer>
                <Typography
                  variant="h5"
                  color="primary"
                  fontWeight={'500'}
                  children={props.title}
                />
              </TitleContainer>
            )}
            {props.children}
          </Container>
        </Grid>
      </Grid>
    </>
  );
};
