import { createTheme } from '@mui/material/styles';

export default createTheme({
  typography: {
    fontFamily: `"Rubik", "Ubuntu", "Roboto", "Helvetica", "Arial", sans-serif`,
  },
  palette: {
    primary: {
      main: '#3969E8',
    },
    secondary: {
      main: '#FF6969',
    },
  },
  button: {
    active:
      'linear-gradient(230deg, rgba(57,105,232,1) 0%, rgba(125,80,255,1) 100%)',
    hover:
      'linear-gradient(79deg, rgba(84,47,190,1) 0%, rgba(68,25,186,1) 100%)',
    disabled: '#A4A7C1',
  },
});
