import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { App } from './App';
import { BrowserRouter } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import Spanish from '../src/languages/es.json';

ReactDOM.render(
  <React.StrictMode>
    <IntlProvider locale={navigator.language} messages={Spanish}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </IntlProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
