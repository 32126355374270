import React from 'react';

import { Grid, Button } from '@mui/material';
import CustomButton from '../CustomButton';

import { styled } from '@mui/material/styles';

const ButtonGrid = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  padding: '1rem',
  alignItems: 'center',
  '& button': {
    // margin: '0.3rem 0rem',
    width: '12rem',
    '@media (max-width: 900px)': {
      height: '4vh',
      width: '8rem',
    },
  },
});

export default function BottomButtons({
  disabled,
  confirmText,
  confirmAction,
  cancelText,
  cancelAction,
}) {
  return (
    <ButtonGrid item>
      {confirmAction && (
        <CustomButton
          disabled={disabled}
          variant="contained"
          children={confirmText}
          onClick={confirmAction}
        />
      )}
      {cancelAction && (
        <Button
          variant="contained"
          color="secondary"
          sx={{ color: '#ffffff', marginTop: '0.8vh' }}
          children={cancelText}
          onClick={cancelAction}
        />
      )}
    </ButtonGrid>
  );
}
