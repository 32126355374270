import { useReducer, useContext } from 'react';

import CollectionsReducer from './CollectionsReducer';
import CollectionsContext from './CollectionsContext';
import AlertsContext from '../Alerts/AlertsContext';

import {
  REQUEST_SUCCESS,
  DELETE_SUCCESS,
  REQUEST_ERROR,
  COLLECTION_STATUS_DRAFT,
  COLLECTION_STATUS_PENDING,
  COLLECTION_STATUS_PROCESSING,
} from '../../constants';

import {
  STATUS_ERROR,
  STATUS_DISCONNECT_ERROR,
  GET_COLLECTIONS,
  GET_MORE_COLLECTIONS,
  GET_ID_COLLECTIONS,
  POST_COLLECTIONS,
  REVOKE_DOCUMENT,
  DELETE_DRAFT,
  AUTOUPDATE_COLLECTIONS,
  FINISH_AUTOUPDATE,
  LOADING_COLLECTIONS,
  ERROR_COLLECTIONS,
} from './CollectionsTypes';

import {
  getCollectionList as apiGetCollectionList,
  createCollection,
  getCollectionById as apiGetCollectionById,
  getCollectionByUrl as apiGetCollectionByUrl,
  deleteDraftCollection,
  patchRevokeDocument,
} from '../../api/collection';

import { STATUS_OK } from '../../constants';

import { config } from '../../config';

import {
  sanitizeSpecialCharacters,
  renameFilesDuplicatesZip,
} from '../../helpers';
import fileDownload from 'js-file-download';
import { getFile } from '../../services/fileUpload';

import { useIntl } from 'react-intl';

import { FIELD_TYPE_FILE } from '../../constants';

const AdmZip = require('adm-zip');
const arrayBufferToBuffer = require('arraybuffer-to-buffer');

const CollectionsProvider = (props) => {
  const initialState = {
    collections: undefined,
    collection: undefined,
    postedCollection: undefined,
    revokedDocument: undefined,
    isAutoUpdating: true,
    status: undefined,
    loading: false,
    errors: undefined,
  };

  const [state, dispatch] = useReducer(CollectionsReducer, initialState);

  const intl = useIntl();
  const alertsContext = useContext(AlertsContext);

  const getCollections = async (
    offset = 0,
    limit = 1000,
    sortDesc = true,
    filterText = '',
    filterTemplateId = '',
    filterStatus = '',
    infiniteScroll = false
  ) => {
    !infiniteScroll && dispatch({ type: LOADING_COLLECTIONS });
    try {
      const response = await apiGetCollectionList(
        offset,
        limit,
        sortDesc,
        filterText,
        filterTemplateId,
        filterStatus
      );
      if (response.status === STATUS_OK) {
        dispatch({
          type: infiniteScroll ? GET_MORE_COLLECTIONS : GET_COLLECTIONS,
          payload: response.data.rows,
        });
        return response.data.rows;
      } else {
        dispatch({
          type: ERROR_COLLECTIONS,
          payload: response.data.errors,
          status: REQUEST_ERROR,
        });
        alertsContext.createErrorAlert(response.data.errors);
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: ERROR_COLLECTIONS,
        payload: error,
      });
    }
  };

  const getCollectionById = async (id) => {
    dispatch({ type: LOADING_COLLECTIONS });
    try {
      const response = await apiGetCollectionById(id);
      if (response.status === STATUS_OK) {
        dispatch({ type: GET_ID_COLLECTIONS, payload: response.data });
      } else {
        dispatch({
          type: ERROR_COLLECTIONS,
          payload: response.data.errors,
          status: REQUEST_ERROR,
        });
        alertsContext.createErrorAlert(response.data.errors);
      }
    } catch (error) {
      dispatch({
        type: ERROR_COLLECTIONS,
        payload: error,
      });
      alertsContext.createErrorAlert(
        intl.formatMessage({ id: 'app.collectionprovider.alert-error-api' })
      );
    }
  };

  const getCollectionByUrl = async (hash) => {
    dispatch({ type: LOADING_COLLECTIONS });
    try {
      const response = await apiGetCollectionByUrl(hash);
      if (response.status === STATUS_OK) {
        dispatch({ type: GET_ID_COLLECTIONS, payload: response.data });
      } else {
        dispatch({
          type: ERROR_COLLECTIONS,
          payload: response.data.errors,
          status: REQUEST_ERROR,
        });
        alertsContext.createErrorAlert(
          intl.formatMessage({ id: 'app.collectionprovider.alert-error-api' })
        );
      }
    } catch (error) {
      dispatch({
        type: ERROR_COLLECTIONS,
        payload: error,
      });
      alertsContext.createErrorAlert(
        intl.formatMessage({ id: 'app.collectionprovider.alert-error-api' })
      );
    }
  };

  const postCollectionMessage = async ({ status, message }) => {
    try {
      dispatch({
        type: STATUS_ERROR,
        payload: [],
        status: status,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const postCollection = async (
    name,
    templateId,
    colStatus,
    parentId,
    documents
  ) => {
    dispatch({ type: LOADING_COLLECTIONS });
    try {
      await createCollection({
        name: name,
        template_id: templateId,
        status: colStatus,
        collection_parent_id: parentId,
        documents: documents,
      })
        .then((response) => {
          if (response.status === STATUS_OK) {
            dispatch({
              type: POST_COLLECTIONS,
              payload: response.data,
              status: REQUEST_SUCCESS,
            });
            if (colStatus === COLLECTION_STATUS_DRAFT) {
              alertsContext.createSuccessAlert(
                intl.formatMessage({
                  id: 'app.collectionprovider.alert-success-create',
                })
              );
            }
          } else {
            dispatch({
              type: STATUS_ERROR,
              status: REQUEST_ERROR,
            });
            alertsContext.createErrorAlert(response.data.errors);
          }
        })
        .catch((error) => {
          dispatch({
            type: STATUS_DISCONNECT_ERROR,
            status: REQUEST_ERROR,
            errors: true,
          });
          alertsContext.createErrorAlert(error.data);
        });
    } catch (error) {
      dispatch({
        type: STATUS_DISCONNECT_ERROR,
        status: REQUEST_ERROR,
        errors: true,
      });
      alertsContext.createErrorAlert(
        intl.formatMessage({ id: 'app.collectionprovider.alert-error-create2' })
      );
    }
  };

  const revokeDocument = async (id) => {
    dispatch({ type: LOADING_COLLECTIONS });
    try {
      const response = await patchRevokeDocument(id);
      if (response.status === STATUS_OK) {
        dispatch({
          type: REVOKE_DOCUMENT,
          payload: response.data,
          status: DELETE_SUCCESS,
        });
        alertsContext.createSuccessAlert(
          intl.formatMessage({ id: 'app.alerts.revoked-success' })
        );
      } else {
        dispatch({
          type: STATUS_ERROR,
          status: REQUEST_ERROR,
        });
        alertsContext.createErrorAlert(response.data.errors);
      }
    } catch (error) {
      dispatch({
        type: STATUS_DISCONNECT_ERROR,
        status: REQUEST_ERROR,
        errors: true,
      });
      alertsContext.createErrorAlert(
        intl.formatMessage({ id: 'app.collectionprovider.alert-error-api' })
      );
    }
  };

  const deleteDraft = async (id) => {
    dispatch({ type: LOADING_COLLECTIONS });
    try {
      await deleteDraftCollection(id)
        .then((response) => {
          if (response.status === STATUS_OK) {
            dispatch({
              type: DELETE_DRAFT,
              status: DELETE_SUCCESS,
            });
            alertsContext.createSuccessAlert(response.data);
          } else {
            dispatch({
              type: STATUS_ERROR,
              status: REQUEST_ERROR,
            });
            alertsContext.createErrorAlert(response.data.errors);
          }
        })
        .catch((error) => {
          dispatch({
            type: STATUS_DISCONNECT_ERROR,
            status: REQUEST_ERROR,
            errors: true,
          });
          alertsContext.createErrorAlert(
            intl.formatMessage({
              id: 'app.collectionprovider.alert-error-delete2',
            })
          );
        });
    } catch (error) {
      dispatch({
        type: STATUS_DISCONNECT_ERROR,
        status: REQUEST_ERROR,
        errors: true,
      });
      alertsContext.createErrorAlert(
        intl.formatMessage({ id: 'app.collectionprovider.alert-error-api' })
      );
    }
  };

  const autoUpdateCollectionsStatus = async () => {
    if (config.stampAutoUpdateDelayMs) {
      const collectionsList = [...state.collections];
      const pendingCollections = collectionsList.filter((collection) => {
        return (
          collection.status === COLLECTION_STATUS_PENDING ||
          collection.status === COLLECTION_STATUS_PROCESSING
        );
      });
      if (pendingCollections.length !== 0) {
        for (const collection of pendingCollections) {
          const updatedCol = await apiGetCollectionById(collection.id);
          collectionsList.forEach((collection, index) => {
            if (
              collection.id === updatedCol.data.id &&
              collection.status !== updatedCol.data.status
            ) {
              collectionsList[index] = updatedCol.data;
            }
          });
        }
        dispatch({ type: AUTOUPDATE_COLLECTIONS, payload: collectionsList });
      } else {
        dispatch({ type: FINISH_AUTOUPDATE });
      }
    }
  };

  const generateZip = async (collection) => {
    let zip = new AdmZip();
    if (collection.documents) {
      const documentsFile = collection.documents.filter(
        (document) => document.document_type === FIELD_TYPE_FILE
      );
      await Promise.all(
        documentsFile.map(async (document, index) => {
          let response = await getFile(document.file);
          let s3Document = {
            filename: document.file_name,
            url: response.data.url,
          };
          return s3Document;
        })
      )
        .then(async (s3Urls) => {
          if (s3Urls.length > 0) {
            const s3UrlsRenamed = renameFilesDuplicatesZip(s3Urls);
            await Promise.all(
              s3UrlsRenamed.map(async (document) => {
                let response = await getFile(document.url, {
                  responseType: 'arraybuffer',
                });
                let file = {
                  filename: document.filename,
                  data: response.data,
                };
                zip.addFile(file.filename, arrayBufferToBuffer(file.data));
              })
            )
              .then(() => {
                const zipFile = zip.toBuffer();
                const filenameZip =
                  collection.documents.length > 1
                    ? sanitizeSpecialCharacters(`${collection.name}.zip`)
                    : sanitizeSpecialCharacters(
                        `${collection.documents[0].title}.zip`
                      );
                fileDownload(zipFile, filenameZip);
              })
              .catch((error) => console.log(error.message));
          } else {
            console.log(
              intl.formatMessage({
                id: 'app.collectionprovider.zip-nos3-error',
              })
            );
          }
        })
        .catch((error) => console.log(error.message));
    } else {
      console.log(
        intl.formatMessage({ id: 'app.collectionprovider.zip-invalid-error' })
      );
    }
  };

  return (
    <CollectionsContext.Provider
      value={{
        collection: state.collection,
        postedCollection: state.postedCollection,
        collections: state.collections,
        revokedDocument: state.revokedDocument,
        isAutoUpdating: state.isAutoUpdating,
        status: state.status,
        errors: state.errors,
        loading: state.loading,
        getCollections,
        getCollectionById,
        getCollectionByUrl,
        postCollection,
        revokeDocument,
        deleteDraft,
        postCollectionMessage,
        autoUpdateCollectionsStatus,
        generateZip,
      }}
    >
      {props.children}
    </CollectionsContext.Provider>
  );
};

export default CollectionsProvider;
