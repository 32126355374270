export const NAME = 'NAME';
export const LAST_NAME = 'LAST_NAME';
export const EMAIL = 'EMAIL';
export const PHONE = 'PHONE';
export const COUNTRY = 'COUNTRY';
export const BIRTHDAY = 'BIRTHDAY';
export const AVATAR = 'AVATAR';
export const FETCH = 'FETCH';

export default function myDataReducer(state, action) {
  const { type, value } = action;

  switch (type) {
    case NAME: {
      return {
        ...state,
        name: value,
      };
    }
    case LAST_NAME: {
      return {
        ...state,
        lastName: value,
      };
    }
    case EMAIL: {
      return {
        ...state,
        email: value,
      };
    }
    case PHONE: {
      return {
        ...state,
        phone: value,
      };
    }
    case COUNTRY: {
      return {
        ...state,
        country: value,
      };
    }
    case BIRTHDAY: {
      return {
        ...state,
        birthday: value,
      };
    }
    case AVATAR: {
      return {
        ...state,
        avatar: value,
      };
    }
    case FETCH: {
      return {
        name: value.name,
        lastName: value.lastName,
        email: value.email,
        phone: value.phone,
        country: value.country,
        birthday: value.birthday,
        avatar: value.avatar,
      };
    }
    default: {
      return state;
    }
  }
}
