import React from 'react';

import { IconButton, Grid } from '@mui/material';

import CircleIcon from '@mui/icons-material/Circle';

import { styled } from '@mui/material/styles';

const ColorPickerGrid = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingTop: '1rem',
});

const PickerButtonsGrid = styled(Grid)({});

const PickerButton = styled(IconButton)((props) => ({
  outline: props.selected && `3px solid ${props.outlinecolor}`,
  padding: '0px',
  margin: '0rem 0.3rem',
}));

const ColorIcon = styled(CircleIcon)((props) => ({
  color: props.color,
}));

export default function ColorPickerButtons(props) {
  return (
    <ColorPickerGrid item md={6} xs={12}>
      <PickerButtonsGrid container columns={!props.mobile ? 4 : 8} spacing={0}>
        {props.colors.map((color, index) => {
          return (
            <Grid
              item
              xs={1}
              key={index}
              display="flex"
              justifyContent="center"
            >
              <PickerButton
                disabled={props.disabled}
                selected={props.selectedColor === color}
                outlinecolor={color}
                disableFocusRipple
                onClick={() => {
                  props.handleChangeBrandColor(color.toUpperCase());
                }}
                children={<ColorIcon color={color} fontSize="large" />}
              />
            </Grid>
          );
        })}
      </PickerButtonsGrid>
    </ColorPickerGrid>
  );
}
