import React, { useState, useEffect, useContext } from 'react';
import { useIntl } from 'react-intl';
import useDocument from '../../hooks/useDocument';
import dayjs from 'dayjs';

import DocumentPreview from './DocumentPreview';
import { ValidatedFile } from '../ValidatedFile';
import CustomButton from '../CustomButton';
import ChipGrid from '../ChipGrid';
import ShareButton from '../ShareButton';
import ConfirmModal from '../ConfirmModal';
import { ReactComponent as ShareIcon } from '../../assets/share.svg';

import FileCopyIcon from '@mui/icons-material/FileCopy';
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';
import RadioButtonCheckedOutlinedIcon from '@mui/icons-material/RadioButtonCheckedOutlined';
// import BlockIcon from '@mui/icons-material/Block';
import { ReactComponent as AlgorandIcon } from '../../assets/AlgorandIcon.svg';
import { ReactComponent as DownloadFileIcon } from '../../assets/DownloadFileIcon.svg';

import {
  Divider,
  Typography,
  Grid,
  Button,
  Checkbox,
  Tooltip,
  // IconButton,
  TextField,
  useMediaQuery,
} from '@mui/material';

import { styled } from '@mui/material/styles';

import { fileTypeName } from '../../helpers';
import {
  ADD_EXISTING_MODES,
  COLLECTION_STATUS_STAMPED,
  FIELD_TYPE_FILE,
  FIELD_TYPE_TEXT,
} from '../../constants';

import CollectionsContext from '../../context/Collections/CollectionsContext';

const DetailsGrid = styled(Grid)(({ grayed = false }) => ({
  opacity: grayed ? '0.3' : undefined,
  pointerEvents: grayed ? 'none' : undefined,
}));

const DocumentTitle = styled(Typography)((color) => ({
  fontSize: '0.9rem',
  color: color ? null : '#3969E8',
  wordWrap: 'break-word',
}));

const DocumentLabel = styled(Typography)({
  color: '#999',
  fontSize: '0.8rem',
});

const DocumentText = styled(Typography)({
  fontSize: '0.9rem',
  color: 'rgba(0, 0, 0, 0.87)',
  wordBreak: 'break-all',
});

const ButtonsGrid = styled(Grid)({
  '& button, a': {
    margin: '0rem 0.2rem',
  },
});

const DownloadButton = styled(CustomButton)({
  width: '100%',
  height: '36px',
  '@media (max-width: 900px)': {
    width: '13rem',
  },
});

const AlgorandButton = styled(Button)({
  maxWidth: '46px',
  minWidth: '46px',
  height: '36px',
  borderColor: '#3969E8',
});

export default function DocumentDetails({
  addExistingType,
  selectedDocument,
  gridDisplay,
  expanded,
  document,
  isShareable = true,
  find = false,
  customColor,
  handleOpenPreview,
  handleRefresh,
  setSelectedDocument,
  textValidated,
  textNoValidated,
}) {
  const [selected, setSelected] = useState(false);
  const [grayed, setGrayed] = useState(false);
  const [revokeModal, setRevokeModal] = useState(false);

  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [textProp1, setTextProp1] = useState('');

  const intl = useIntl();
  const doc = useDocument();
  const mobile = useMediaQuery('(max-width: 900px)');
  const revoke = useContext(CollectionsContext)?.revokeDocument;

  useEffect(() => {
    if (document.document_type === FIELD_TYPE_FILE) {
      doc.fetchDownloadUrl(document.file);
    }
  }, [document]);

  useEffect(() => {
    setGrayed(false);
    if (selectedDocument) {
      if (selectedDocument.id !== document.id) {
        setGrayed(true);
        setSelected(false);
      } else {
        setSelected(true);
      }
    }
  }, [selectedDocument, selected]);

  function handleSelectDocument(e, document) {
    setSelected(e.target.checked);
    setSelectedDocument(e.target.checked ? document : undefined);
  }

  function handleOpenRevokeModal() {
    setRevokeModal(true);
  }

  function handleCloseRevokeModal() {
    setRevokeModal(false);
  }

  const onValidationChange = async (statusInfo) => {
    if (!uploadInProgress) {
      if (statusInfo.uploadedFileInfo[0]?.checksum === document.file_hash) {
        setTextProp1(textValidated);
      } else {
        setTextProp1(textNoValidated);
      }
    }
    if (statusInfo.reset) {
      setTextProp1('');
    }
  };

  const getUploadProgress = (progressInfo) => {
    setUploadInProgress(progressInfo?.status.loading);
    if (uploadInProgress) {
      setTextProp1('');
    }
  };

  const StatusChip = () => {
    if (gridDisplay) {
      return (
        <Grid
          item
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          mr={1}
        >
          {document.source_document_id ? (
            <Tooltip
              title={intl.formatMessage({
                id: 'app.mydocuments.reused-document',
              })}
              placement="bottom"
              arrow
              enterDelay={600}
            >
              <FileCopyIcon sx={{ margin: '0rem 0.5rem' }} color="primary" />
            </Tooltip>
          ) : // document.status === COLLECTION_STATUS_STAMPED &&
          // !document.revoked_at &&
          // !addExistingDocument && (
          //   <Tooltip
          //     title={intl.formatMessage({
          //       id: 'app.mydocuments.revoke-document',
          //     })}
          //     placement="bottom"
          //     arrow
          //     enterDelay={600}
          //   >
          //     <IconButton
          //       sx={{ position: 'absolute', right: 0 }}
          //       size="small"
          //       onClick={handleOpenRevokeModal}
          //       children={<BlockIcon fontSize="small" />}
          //     />
          //   </Tooltip>
          // )
          null}
          <ChipGrid
            status={document.status}
            revoked={document.revoked_at}
            showIcon={true}
          />
        </Grid>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <ConfirmModal
        open={revokeModal}
        onClose={handleCloseRevokeModal}
        modalTitle={intl.formatMessage({
          id: 'app.mydocuments.confirm-revoke-title',
        })}
        modalText={intl.formatMessage({
          id: 'app.mydocuments.confirm-revoke-text',
        })}
        warningTitle={intl.formatMessage({
          id: 'app.confirm-modal.warning-title',
        })}
        warningText={intl.formatMessage({
          id: 'app.mydocuments.confirm-revoke-warning-text',
        })}
        confirmAction={() => {
          revoke(document.id);
          handleRefresh();
        }}
        cancelAction={handleCloseRevokeModal}
      />
      {gridDisplay && <Divider />}
      <DetailsGrid
        selected={selected}
        grayed={grayed}
        container
        display="flex"
        flexDirection={mobile && 'column'}
        p={gridDisplay && 2}
      >
        <Grid
          item
          xs={12}
          md={3}
          p={1}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <DocumentPreview
            document={document}
            expanded={expanded}
            handleOpenPreview={handleOpenPreview}
          />
        </Grid>
        <Grid item md={9} xs={12} pl={!mobile && 2}>
          <Grid
            item
            xs={12}
            display="flex"
            flexDirection={!mobile ? 'row' : 'column'}
          >
            <Grid item md={7} xs={12} display="flex" flexDirection="column">
              <Grid item mb={2}>
                <DocumentLabel
                  children={intl.formatMessage({
                    id: 'app.mydocuments.doc-title',
                  })}
                />
                <DocumentTitle
                  color="primary"
                  children={
                    !document.title
                      ? intl.formatMessage(
                          { id: 'app.mydocuments.no-name' },
                          {
                            type: intl.formatMessage({
                              id: fileTypeName(document.document_type),
                            }),
                          }
                        )
                      : document.title
                  }
                />
              </Grid>
              <Grid item mb={2}>
                <DocumentLabel
                  children={intl.formatMessage({
                    id: 'app.mydocuments.doc-date',
                  })}
                />
                <DocumentText
                  children={dayjs(document.created_at).format('DD/MM/YYYY')}
                />
              </Grid>
              <Grid item>
                <DocumentLabel
                  children={intl.formatMessage({
                    id: 'app.mydocuments.doc-author',
                  })}
                />
                <DocumentText
                  children={
                    document.user.first_name + ' ' + document.user.last_name
                  }
                />
              </Grid>
            </Grid>
            <Grid item md={5} xs={12} display="flex" flexDirection="column">
              <ButtonsGrid
                item
                display="flex"
                justifyContent={!mobile ? 'flex-end' : 'center'}
              >
                <StatusChip />
                {!gridDisplay && (
                  <AlgorandButton
                    variant="outlined"
                    type="submit"
                    children={<AlgorandIcon alt="Algorant" />}
                    href={document.tx_url}
                    target={'_blank'}
                    backgroundColor={customColor}
                  />
                )}
                {isShareable &&
                document.status === COLLECTION_STATUS_STAMPED &&
                !addExistingType &&
                !document.revoked_at ? (
                  <ShareButton
                    shareItem={document}
                    buttonIcon={<ShareIcon />}
                    buttonStyle={{
                      maxWidth: '46px',
                      minWidth: '46px',
                      height: '36px',
                    }}
                  />
                ) : null}
                {addExistingType === ADD_EXISTING_MODES.DOCUMENT &&
                  !document.source_document_id &&
                  !document.revoked_at &&
                  document.status === COLLECTION_STATUS_STAMPED && (
                    <Checkbox
                      disableFocusRipple
                      size="medium"
                      checked={selected}
                      disabled={grayed}
                      icon={<RadioButtonUncheckedOutlinedIcon />}
                      checkedIcon={<RadioButtonCheckedOutlinedIcon />}
                      onChange={(e) => {
                        handleSelectDocument(e, document);
                      }}
                    />
                  )}
                {document.document_type === FIELD_TYPE_FILE &&
                !gridDisplay &&
                !document.revoked_at ? (
                  <DownloadButton
                    variant="contained"
                    type="submit"
                    startIcon={
                      <DownloadFileIcon alt="Download" fill={'#FFFFFF'} />
                    }
                    children={
                      <Typography
                        variant="body2"
                        textTransform="none"
                        children="Descargar"
                      />
                    }
                    href={doc.downloadUrl}
                    download
                  />
                ) : null}
              </ButtonsGrid>
              <Grid item display="flex" alignItems="center" mt={2}>
                {document.document_type === FIELD_TYPE_FILE && !find && (
                  <ValidatedFile
                    textProp0={intl.formatMessage({ id: 'app.search.default' })}
                    textProp1={textProp1}
                    textValidated={textValidated}
                    textNoValidated={textNoValidated}
                    onChange={onValidationChange}
                    getUploadProgress={getUploadProgress}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          {document.document_type === FIELD_TYPE_TEXT && (
            <Grid item xs={12} mt={2}>
              <DocumentLabel
                children={intl.formatMessage({
                  id: 'app.mydocuments.doc-text',
                })}
              />
              <TextField
                InputProps={{
                  readOnly: true,
                  sx: {
                    fontSize: '0.9rem',
                    color: 'rgba(0, 0, 0, 0.87)',
                    wordBreak: 'break-all',
                  },
                }}
                multiline
                rows={3}
                fullWidth
                value={document.text_value}
              />
            </Grid>
          )}
          {document.description && (
            <Grid item mt={2}>
              <DocumentLabel
                children={intl.formatMessage({
                  id: 'app.mydocuments.doc-desc',
                })}
              />
              <DocumentText children={document.description} />
            </Grid>
          )}
        </Grid>
      </DetailsGrid>
    </>
  );
}
