import React, { useState, useEffect } from 'react';
import { Paper } from '@mui/material';

import DocumentDetails from '../my-documents/DocumentDetails';

import { styled } from '@mui/material/styles';

// import SharedContext from '../context/Shared/SharedContext';
// import AlertsContext from '../../context/Alerts/AlertsContext';
import useDocument from '../../hooks/useDocument';

// import { useIntl } from 'react-intl';

import { FIELD_TYPE_FILE } from '../../constants';

const DocumentPaper = styled(Paper)({
  padding: '1rem',
  borderRadius: '15px',
  boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
  marginBottom: '1rem',
});

export default function DocumentCard({
  document,
  isShareable,
  find,
  expanded,
  validateDialog = true,
  textValidated,
  textNoValidated,
  customColor,
  handleOpenPreview,
}) {
  // const [textProp1, setTextProp1] = useState('');
  // const [open, setOpen] = useState(false);
  // const [uploadInProgress, setUploadInProgress] = useState(false);

  // const sharedContext = useContext(SharedContext);
  // const alertsContext = useContext(AlertsContext);

  // const intl = useIntl();
  const doc = useDocument();

  useEffect(() => {
    if (document.document_type === FIELD_TYPE_FILE) {
      doc.fetchDownloadUrl(document.file);
    }
  }, [document]);

  // const getUploadProgress = (progressInfo) => {
  //   setUploadInProgress(progressInfo?.status.loading);
  //   if (uploadInProgress) {
  //     setTextProp1('');
  //   }
  // };

  // function paddingQuery(query) {
  //   if (query) {
  //     return '6px';
  //   }
  //   return '24px';
  // }

  // const onChange = async (statusInfo) => {
  //   // @TODO file_id when column change name as requested
  //   if (!uploadInProgress) {
  //     if (statusInfo.uploadedFileInfo[0]?.checksum === document.file_hash) {
  //       setTextProp1(textValidated);
  //     } else {
  //       setTextProp1(textNoValidated);
  //     }
  //   }
  //   if (statusInfo.reset) {
  //     setTextProp1('');
  //   }
  // };

  // const handleClose = (event, reason) => {
  //   if (reason === 'clickaway') {
  //     return;
  //   }
  //   setOpen(false);
  // };

  // const handleShareDocument = async (e, id) => {
  //   e.preventDefault();
  //   await sharedContext.postShared(id, 'Documents');
  //   setOpen(true);
  // };

  // const textProp0 = intl.formatMessage({ id: 'app.search.default' });

  return (
    <DocumentPaper id={'document-' + document.id} key={document.id}>
      <DocumentDetails
        document={document}
        isShareable={isShareable}
        find={find}
        expanded={expanded}
        customColor={customColor}
        handleOpenPreview={handleOpenPreview}
        textValidated={textValidated}
        textNoValidated={textNoValidated}
      />
    </DocumentPaper>
  );
}
