import QRCode from "qrcode.react";

const BASE_URL_FRONT = process.env.REACT_APP_FRONT_BASE_URL;

export const SharedQR = ({ id, link, srcLogo }) => {
    const logoDefault = `${BASE_URL_FRONT}/${srcLogo}`;
    return (                
        <div style={{display:"none"}}>
            <QRCode 
                id={id}
                value={link} 
                size={480}
                bgColor={"#ffffff"}
                fgColor={"#000000"}
                level={"Q"}
                includeMargin={true}
                renderAs={"canvas"}                
                imageSettings={{
                    src: logoDefault,
                    height: 115,
                    width: 115,
                    excavate: false,                    
                }}
            /> 
        </div>
    );
}