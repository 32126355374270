import React from 'react';
import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

const StyledButton = styled(Button)(({ theme, backgroundcolor, children }) => ({
  background: backgroundcolor ? backgroundcolor : theme.button.active,
  color: '#FFF',
  transition: 'background 0.4s',
  whiteSpace: 'nowrap',
  '& span:first-of-type': {
    margin: !children && '0rem',
  },
  '&:hover': {
    background: backgroundcolor ? backgroundcolor - 20 : theme.button.hover,
  },
  '&:disabled': {
    background: theme.button.disabled,
    color: '#FFF',
  },
}));

export default function CustomButton(props) {
  return (
    <StyledButton
      {...props}
      children={props.children}
      backgroundcolor={props.backgroundcolor}
    />
  );
}
