import React from "react";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

import IconButton from "@mui/material/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

const ProgressBar = (props) => {
  const { progressInfo, loading, error } = props;

  return progressInfo.map((p, i) => (
    <div key={i}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%" }}>
          <LinearProgress
            variant="determinate"
            value={p.percentage}
            sx={{
              maxWidth: "100%",
              height: "0.3rem",
              borderRadius: "0.3rem",
            }}
            color={!error ? (loading ? "primary" : "success") : "error"}
          />
        </Box>
      </Box>
    </div>
  ));
};

const DownloadButton = (props) => {
  const { restoredFile } = props;

  return (
    <IconButton
      href={restoredFile && restoredFile.publicFileURI}
      LinkComponent={"a"}
      // size="xs"
      sx={{
        marginRight: "0.5rem",
        marginLeft: "0px",
      }}
      edge="start"
    >
      <FontAwesomeIcon icon={faDownload} size="xs" />
    </IconButton>
  );
};

const InputFilename = (props) => {
  const {
    selectedFileInfo,
    restoredFile,
    restored,
    loading,
    success,
    error,
    errorMessage,
  } = props;

  return (
    <span style={{ fontFamily: "roboto", fontSize: "0.9rem" }}>
      {loading || success
        ? selectedFileInfo.name
        : restored
        ? restoredFile && restoredFile.originalName
        : error
        ? errorMessage
        : ""}
    </span>
  );
};

export { ProgressBar, DownloadButton, InputFilename };
