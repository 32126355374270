import React, { useState, useEffect, useContext } from 'react';

import {
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
  Link,
  Divider,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from '@mui/material';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

import { styled } from '@mui/material/styles';
import { useIntl } from 'react-intl';
import {
  FREE_PLAN_ID,
  PLAN_ONE_PAY,
  PLAN_SUBSCRIPTION,
  statusContext,
  statusPayment,
} from '../../constants';

import CustomButton from '../CustomButton';

import TiersContext from '../../context/Tiers/TiersContext';

const PlanCardGrid = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '0.5rem',
});

const PlanCustomCard = styled(Card)((props) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: '14px',
  width: '100%',
  minHeight: '38rem',
  border: props.selected && '2px solid #4BCDB6',
  '@media (max-width: 1200px)': {
    width: '20rem',
  },
  '@media (max-width: 900px)': {
    minHeight: '0',
  },
}));

const PlanTitleContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '1rem',
  height: '10%',
});

const PlanTypeText = styled(Typography)({
  fontWeight: '400',
  color: '#A4A7C1',
});

const PlanDescriptionContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  padding: '0.6rem',
  p: {
    alignSelf: 'center',
  },
});

const PlanDescription = styled('div')((props) => ({
  display: 'flex',
  flexDirection: 'row',
  margin: '0.5rem 0rem',
  '& svg': {
    marginRight: '0.4rem',
    color: props.benefit ? '#4BCDB6' : '#FF794F',
  },
}));

const CustomCardActions = styled(CardActions)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '20%',
});

const CustomDivider = styled(Divider)({
  width: '75%',
});

const PricesContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'space-around',
});

const PriceBlock = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

export default function TierCard({
  tier,
  customPlan,
  priceData,
  setPlanNumber,
  handleOpenPaymentModal,
  handleOpenCancelModal,
  handleOpenConfirmModal,
}) {
  const [selected, setSelected] = useState(false);

  const intl = useIntl();
  const tiersContext = useContext(TiersContext);

  useEffect(() => {
    if (tiersContext.userTier.tierId === tier.id) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [tier.id, tiersContext.userTier.tierId]);

  const PlanFeature = (props) => {
    return (
      <PlanDescription benefit={props.benefit}>
        {props.benefit ? (
          <CheckCircleOutlineIcon fontSize="small" />
        ) : (
          <CancelOutlinedIcon fontSize="small" />
        )}
        <Typography variant="body2" children={props.featureDesc} />
      </PlanDescription>
    );
  };

  const IconDisplay = () => {
    if (!tiersContext.loadingAutoRenewal) {
      if (tiersContext.autoRenewal) {
        return <CheckIcon htmlColor="#6CD9B0" />;
      } else if (!tiersContext.autoRenewal) {
        return <CloseIcon htmlColor="#FF4141" />;
      }
    } else {
      return (
        <CircularProgress
          size={'1rem'}
          thickness={6}
          sx={{ color: '#7D50FF' }}
        />
      );
    }
  };

  function handleChangeCheckAutoRenewal(e) {
    tiersContext.changeAutoRenewal({
      auto_renewal: e.target.checked,
    });
  }

  return (
    <>
      <PlanCardGrid item lg={3} md={6} xs={12}>
        <PlanCustomCard selected={selected} raised customplan={customPlan}>
          {selected && (
            <CheckCircleOutlineIcon
              sx={{ position: 'absolute', left: 5, top: 5 }}
              htmlColor="#4BCDB6"
            />
          )}
          <PlanTitleContainer>
            <PlanTypeText
              variant="body2"
              children={
                customPlan
                  ? intl.formatMessage({
                      id: 'app.my-account.plans.card.ons',
                    })
                  : intl.formatMessage({
                      id: 'app.my-account.plans.card.plan',
                    })
              }
            />
            <Typography variant="h5" align="center" children={tier.name} />
          </PlanTitleContainer>
          <CustomDivider />
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              height: '70%',
              ':last-child': {
                padding: '0',
              },
            }}
          >
            <PlanDescriptionContainer>
              <PlanTypeText
                variant="caption"
                textAlign="center"
                children={intl.formatMessage({
                  id: 'app.my-account.plans.card.details',
                })}
              />
              {tier.tier_detail_json.features?.map((feature, index) => {
                return (
                  <PlanFeature
                    key={index}
                    benefit={feature.benefit}
                    featureDesc={feature.feature_desc}
                  />
                );
              })}
            </PlanDescriptionContainer>
          </CardContent>
          {priceData?.[PLAN_ONE_PAY] !== (0 || undefined) && (
            <>
              <PricesContainer>
                <PriceBlock>
                  <Typography
                    variant="caption"
                    children={intl.formatMessage({
                      id: 'app.my-account.plans.monthly-plan-desc',
                    })}
                  />
                  <Typography
                    variant="h6"
                    color="#7D50FF"
                    children={'US$ ' + priceData?.[PLAN_SUBSCRIPTION]}
                  />
                </PriceBlock>
                <PriceBlock>
                  <Typography
                    variant="caption"
                    children={intl.formatMessage({
                      id: 'app.my-account.plans.yearly-plan-desc',
                    })}
                  />
                  <Typography
                    variant="h6"
                    color="#7D50FF"
                    children={'US$ ' + priceData?.[PLAN_ONE_PAY]}
                  />
                </PriceBlock>
              </PricesContainer>
              <CustomDivider />
            </>
          )}
          <CustomCardActions>
            {selected && tier.id !== FREE_PLAN_ID ? (
              <Grid container>
                <Grid
                  item
                  xs={9}
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        sx={{
                          padding: '0',
                          mr: '0.3rem',
                          '&.Mui-checked': {
                            color: '#7D50FF',
                          },
                        }}
                        onChange={handleChangeCheckAutoRenewal}
                        checked={tiersContext.autoRenewal}
                      />
                    }
                    label={
                      <Typography
                        variant="caption"
                        align="center"
                        noWrap
                        children={intl.formatMessage({
                          id: 'app.my-account.plans.autorenewal',
                        })}
                      />
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={3}
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  {IconDisplay()}
                </Grid>
                {!customPlan && tier.id !== FREE_PLAN_ID && (
                  <Grid
                    item
                    xs={12}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Link
                      sx={{ cursor: 'pointer', textDecoration: 'none' }}
                      onClick={() => {
                        handleOpenCancelModal();
                      }}
                      children={
                        <Typography
                          variant="caption"
                          color={'#FF794F'}
                          children={intl.formatMessage({
                            id: 'app.my-account.plans.link1',
                          })}
                        />
                      }
                    />
                  </Grid>
                )}
              </Grid>
            ) : (
              tier.id !== FREE_PLAN_ID && (
                <CustomButton
                  variant="contained"
                  disabled={
                    tiersContext.userTier.paymentType === PLAN_ONE_PAY ||
                    (tiersContext.paymentData.lastOperationStatus === statusPayment.PAID &&
                      tiersContext.paymentData.lastTransactionStatus ===
                        statusContext.PENDING)
                  }
                  children={
                    customPlan
                      ? intl.formatMessage({
                          id: 'app.my-account.plans.button3',
                        })
                      : !selected
                      ? intl.formatMessage({
                          id: 'app.my-account.plans.button2',
                        })
                      : intl.formatMessage({
                          id: 'app.my-account.plans.button1',
                        })
                  }
                  onClick={() => {
                    if (tier.id === FREE_PLAN_ID) {
                      setPlanNumber(FREE_PLAN_ID);
                      handleOpenConfirmModal(tier.id);
                    } else {
                      handleOpenPaymentModal(tier.id);
                    }
                  }}
                />
              )
            )}
          </CustomCardActions>
        </PlanCustomCard>
      </PlanCardGrid>
    </>
  );
}
