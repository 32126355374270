import { useReducer } from 'react';

import AlertsContext from './AlertsContext';
import AlertsReducer from './AlertsReducer';

import {
  ALERT_ERROR,
  ALERT_WARNING,
  ALERT_INFO,
  ALERT_SUCCESS,
  ALERT_NOTIFICATION,
} from './AlertsTypes';

const AlertsProvider = (props) => {
  const initialState = {
    status: false,
    message: undefined,
    type: 'error',
  };

  const [state, dispatch] = useReducer(AlertsReducer, initialState);

  const createErrorAlert = (message) => {
    dispatch({ status: true, type: ALERT_ERROR, message: message });
  };

  const createWarningAlert = (message) => {
    dispatch({ status: true, type: ALERT_WARNING, message: message });
  };

  const createInfoAlert = (message) => {
    dispatch({ status: true, type: ALERT_INFO, message: message });
  };

  const createSuccessAlert = (message) => {
    dispatch({ status: true, type: ALERT_SUCCESS, message: message });
  };

  const closeAlert = () => {
    dispatch({ status: false, type: state.type, message: state.message });
  };

  // create new alert snackbar que salgan como plan alert
  const createNotificationAlert = (message) => {
    dispatch({ status: true, type: ALERT_NOTIFICATION, message: message });
  };


  return (
    <AlertsContext.Provider
      value={{
        status: state.status,
        message: state.message,
        type: state.type,
        createErrorAlert,
        createWarningAlert,
        createInfoAlert,
        createSuccessAlert,
        closeAlert,
        createNotificationAlert,
      }}
    >
      {props.children}
    </AlertsContext.Provider>
  );
};

export default AlertsProvider;
