import React, { useReducer, useContext } from 'react';

import DocumentsContext from './DocumentsContext';
import DocumentsReducer from './DocumentsReducer';

import AlertsContext from '../Alerts/AlertsContext';

import {
  GET_ID_DOCUMENTS,
  LOADING_DOCUMENTS,
  ERROR_DOCUMENTS,
} from './DocumentsTypes';

import {
  getDocumentById as apiGetDocumentById,
  getDocumentByUrl as apiGetDocumentByUrl,
} from '../../api/document';
import { STATUS_OK } from '../../constants';

import { useIntl } from 'react-intl';

const DocumentsProvider = (props) => {
  const initialState = {
    document: undefined,
    loading: false,
    errors: undefined,
  };

  const [state, dispatch] = useReducer(DocumentsReducer, initialState);

  const intl = useIntl();
  const alertsContext = useContext(AlertsContext);

  const getDocumentById = async (id) => {
    dispatch({ type: LOADING_DOCUMENTS });
    try {
      const response = await apiGetDocumentById(id);
      if (response.status === STATUS_OK) {
        dispatch({ type: GET_ID_DOCUMENTS, payload: response.data });
      } else {
        dispatch({ type: ERROR_DOCUMENTS, payload: response.data.errors });
        alertsContext.createErrorAlert(
          intl.formatMessage({ id: 'app.collectionprovider.alert-error-api' })
        );
      }
    } catch (error) {
      dispatch({
        type: ERROR_DOCUMENTS,
        payload: [intl.formatMessage({ id: 'app.documentsprovider.error' })],
      });
      console.error(error);
    }
  };

  const getDocumentByUrl = async (hash) => {
    dispatch({ type: LOADING_DOCUMENTS });
    try {
      const response = await apiGetDocumentByUrl(hash);
      if (response.status === STATUS_OK) {
        dispatch({ type: GET_ID_DOCUMENTS, payload: response.data });
      } else {
        dispatch({ type: ERROR_DOCUMENTS, payload: response.data.errors });
        alertsContext.createErrorAlert(
          intl.formatMessage({ id: 'app.collectionprovider.alert-error-api' })
        );
      }
    } catch (error) {
      dispatch({
        type: ERROR_DOCUMENTS,
        payload: [
          intl.formatMessage({ id: 'app.collectionprovider.alert-error-api' }),
        ],
      });
      console.error(error);
    }
  };

  return (
    <DocumentsContext.Provider
      value={{
        document: state.document,
        loading: state.loading,
        errors: state.errors,
        getDocumentById,
        getDocumentByUrl,
      }}
    >
      {props.children}
    </DocumentsContext.Provider>
  );
};

export default DocumentsProvider;
