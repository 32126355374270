import { useState, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';

import CollectionsContext from '../context/Collections/CollectionsContext';
import AlertsContext from '../context/Alerts/AlertsContext';

import {
  FIELD_TYPE_TEXT,
  FIELD_TYPE_FILE,
  COLLECTION_STATUS_DRAFT,
  COLLECTION_STATUS_PENDING,
  COLLECTION_STATUS_PROCESSING,
  REQUEST_SUCCESS,
  ADD_EXISTING_MODES,
} from '../constants';

import { config } from '../config';

import { useIntl } from 'react-intl';

import { scroller } from 'react-scroll';

const useCollection = () => {
  const collectionsContext = useContext(CollectionsContext);
  const alertsContext = useContext(AlertsContext);

  const intl = useIntl();

  const [name, setName] = useState('');
  const [templateId, setTemplateId] = useState();
  const [inputFieldIndex, setInputFieldIndex] = useState(undefined);

  const [inputFields, setInputFields] = useState([]);

  const [titleError, setTitleError] = useState(undefined);

  const [redirect, setRedirect] = useState(false);

  const [isChanged, setIsChanged] = useState(false);

  const handleSelectTemplateId = (id) => {
    setTemplateId(id);
  };

  const handleInitInputFields = (inputFields) => {
    setInputFields(inputFields);
  };

  const handleChangeCollectionName = (event) => {
    setName(event.target.value);
    setIsChanged(true);
  };

  const handleAddFields = (documentType, templateDocumentId) => {
    const fileField = {
      id: uuidv4(),
      title: '',
      uploader_1: [],
      description: '',
      document_type: FIELD_TYPE_FILE,
      template_document_id: templateDocumentId,
      error: { title: false, content: false, description: false },
    };

    const textField = {
      id: uuidv4(),
      title: '',
      text_value: '',
      description: '',
      document_type: FIELD_TYPE_TEXT,
      template_document_id: templateDocumentId,
      error: { title: false, content: false },
    };

    setInputFields([
      ...inputFields,
      documentType === FIELD_TYPE_FILE ? fileField : textField,
    ]);
  };

  const addExistingDocument = (document, index, type) => {
    const uploaderDocument = {
      checksum: document.file_hash,
      fileId: document.file_id,
      originalName: document.file_name,
      publicFileURI: document.file,
    };

    if (type === ADD_EXISTING_MODES.DOCUMENT) {
      const existingField = {
        id: uuidv4(),
        document_type: document.document_type,
        title: document.title,
        restoredFile:
          document.document_type === FIELD_TYPE_FILE ? uploaderDocument : null,
        uploader_1:
          document.document_type === FIELD_TYPE_FILE ? [uploaderDocument] : [],
        text_value:
          document.document_type === FIELD_TYPE_TEXT && document.text_value,
        description: document.description,
        template_document_id: document.template_document_id,
        source_document_id: document.id,
        error: { title: false, content: false, description: false },
      };

      setInputFields([...inputFields, existingField]);
    } else if (type === ADD_EXISTING_MODES.FILE) {
      const newFields = inputFields;
      newFields[index].restoredFile = uploaderDocument;
      newFields[index].uploader_1 = [uploaderDocument];
      setInputFields(newFields);
    }
  };

  const handleChangeInput = (id, event, replace) => {
    const newInputFields = inputFields.map((field) => {
      if (id === field.id) {
        if (event) {
          field[event.target.name] = !replace ? event.target.value : replace;
        } else {
          field.title = replace;
        }
      }
      return field;
    });
    setInputFields(newInputFields);
    if (
      inputFields.length === 1 &&
      !inputFields[0]?.title &&
      (inputFields[0].uploader_1?.length === 0 || !inputFields[0]?.text_value)
    ) {
      setIsChanged(false);
    } else {
      setIsChanged(true);
    }
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
    if (inputFields.length === 0) {
      setIsChanged(false);
    } else {
      setIsChanged(true);
    }
  };

  const handleClose = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    collectionsContext.postCollectionMessage({ status: false, message: '' });
  };

  const validate = (status) => {
    let isValid = true;

    if (inputFields.length > 1) {
      if (name.trim().length === 0) {
        isValid = false;
        setTitleError(true);
        setRedirect(false);
      } else {
        setTitleError(false);
      }
    } else if (
      inputFields.length === 1 &&
      templateId == config.templateDefaultId
    ) {
      if (inputFields[0].title.length === 0) {
        inputFields[0].error.title = true;
        isValid = false;
        setRedirect(false);
        alertsContext.createErrorAlert(
          intl.formatMessage({ id: 'app.stamp.error-insert-title-alert' })
        );
      } else {
        inputFields[0].error.title = false;
      }
    }

    const newInputFields = inputFields.map((inputField) => {
      if (
        (status === COLLECTION_STATUS_PENDING ||
          status === COLLECTION_STATUS_PROCESSING) &&
        (templateId == config.templateDefaultId ||
          inputField.validations?.required === true)
      ) {
        if (inputField.title?.trim().length === 0) {
          inputField.error.title = true;
          isValid = false;
          setRedirect(false);
        } else {
          inputField.error.title = false;
        }
        if (inputField.document_type === FIELD_TYPE_FILE) {
          if (
            inputField.uploader_1.length === 0 ||
            inputField.uploader_1[0].checksum === ''
          ) {
            inputField.error.content = true;
            isValid = false;
            setRedirect(false);
          } else if (
            inputField.restoredFile &&
            inputField.restoredFile.publicFileURI.length === 0
          ) {
            inputField.error.content = true;
            isValid = false;
            setRedirect(false);
          } else {
            inputField.error.content = false;
          }
        }
        if (inputField.document_type === FIELD_TYPE_TEXT) {
          if (
            !inputField.text_value ||
            inputField.text_value.trim().length === 0
          ) {
            inputField.error.content = true;
            isValid = false;
            setRedirect(false);
          } else {
            inputField.error.content = false;
          }
        }
      } else if (inputFields.length > 1) {
        inputField.error.title = false;
        inputField.error.content = false;
      }
      return inputField;
    });

    if (isValid) {
      setInputFields(newInputFields);
    }

    if (!isValid && name.trim().length === 0) {
      scroller.scrollTo('collectionName');
    } else {
      let firstInputError = inputFields.filter(
        (inputField) => inputField.error.title
      )[0];
      if (firstInputError) {
        scroller.scrollTo(`documentCard-${firstInputError.id}`);
      }
      let firstFileError = inputFields.filter(
        (inputField) => inputField.error.content
      )[0];
      if (firstFileError) {
        scroller.scrollTo(`documentCard-${firstFileError.id}`);
      }
    }

    setInputFields(newInputFields);
    return isValid;
  };

  const handleSubmit = async (status, parentId) => {
    setIsChanged(false);
    let documents = [];
    await inputFields.forEach((inputField) => {
      let document;
      if (
        inputField.validations?.required === true ||
        status === COLLECTION_STATUS_DRAFT ||
        ((inputField.validations?.required === false ||
          !inputField.validations) &&
          (inputField.text_value ||
            (inputField.uploader_1 && inputField.uploader_1.length !== 0))) ||
        templateId === config.templateDefaultId
      ) {
        if (inputField.source_document_id) {
          document = {
            source_document_id: inputField.source_document_id,
          };
        } else {
          if (inputField.document_type === FIELD_TYPE_FILE) {
            document = {
              title: inputField.title,
              description: inputField.description,
              file:
                inputField.uploader_1?.length !== 0
                  ? inputField.uploader_1[0]?.publicFileURI
                  : '',
              file_hash:
                inputField.uploader_1?.length !== 0
                  ? inputField.uploader_1[0]?.checksum
                  : '',
              file_name:
                inputField.uploader_1?.length !== 0
                  ? inputField.uploader_1[0]?.originalName
                  : '',
              file_id:
                inputField.uploader_1?.length !== 0
                  ? inputField.uploader_1[0]?.fileId
                  : '',
              template_document_id: inputField.template_document_id,
              document_type: inputField.document_type,
            };
          } else if (inputField.document_type === FIELD_TYPE_TEXT) {
            document = {
              title: inputField.title,
              description: inputField.description,
              template_document_id: inputField.template_document_id,
              document_type: inputField.document_type,
              text_value:
                !inputField.text_value ||
                (inputField.text_value &&
                  inputField.text_value.trim().length === 0)
                  ? ''
                  : inputField.text_value,
            };
          }
        }
        documents.push(document);
      }
    });
    if (validate(status)) {
      await collectionsContext.postCollection(
        name,
        templateId,
        status,
        parentId,
        documents
      );
      if (collectionsContext.status === REQUEST_SUCCESS) {
        setRedirect(true);
      }
    } else {
      alertsContext.createErrorAlert(
        intl.formatMessage({ id: 'app.stamp.error-alert-checkfields' })
      );
      return null;
    }
  };

  const handleDeleteDraft = async (id) => {
    collectionsContext.deleteDraft(id);
    setRedirect(true);
  };

  return {
    handleInitInputFields,
    handleSelectTemplateId,
    handleChangeCollectionName,
    handleAddFields,
    addExistingDocument,
    handleChangeInput,
    handleRemoveFields,
    handleClose,
    handleSubmit,
    handleDeleteDraft,
    validate,
    setName,
    setInputFieldIndex,
    setRedirect,
    setTitleError,
    setIsChanged,
    inputFields,
    inputFieldIndex,
    name,
    templateId,
    titleError,
    redirect,
    isChanged,
  };
};

export default useCollection;
