import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import CollectionShared from '../components/kontroli/CollectionShared';
import HeaderIdenteco from '../components/HeaderIdenteco';
import Loader from '../components/Loader';
import { DocumentBadges } from '../components/DocumentBadges';
import { imageDataParser } from '../helpers';

import { AccordionContainer, CustomAccordion } from './CollectionPage';

import CollectionsContext from '../context/Collections/CollectionsContext';

import {
  Box,
  Container,
  Stack,
  Grid,
  AccordionSummary,
  AccordionDetails,
  Typography,
  useMediaQuery,
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { styled, ThemeProvider, createTheme } from '@mui/material/styles';

import { useIntl } from 'react-intl';

const CustomAccordionContainer = styled(AccordionContainer)({
  margin: '1rem',
});

const CustomGrid = styled(Grid)({
  paddingLeft: '1rem',
  paddingTop: '1rem',
});

export const CollectionSharedPage = () => {
  const [customColor, setCustomColor] = useState();
  const [logoUrl, setLogoUrl] = useState();
  const [brandName, setBrandName] = useState();

  const intl = useIntl();
  const { hash } = useParams();
  const mobile = useMediaQuery('(max-width: 900px)');

  const collectionsContext = useContext(CollectionsContext);

  useEffect(() => {
    async function defaultFetch() {
      await collectionsContext.getCollectionByUrl(hash);
    }
    defaultFetch();
  }, []);

  useEffect(() => {
    if (
      collectionsContext.collection &&
      collectionsContext.collection.user.user_settings
    ) {
      if (collectionsContext.collection.user.user_settings.whiteline_active) {
        setCustomColor(collectionsContext.collection.user.user_settings.color);
        setBrandName(collectionsContext.collection.user.user_settings.name);
        const logoThumbURL = imageDataParser(
          collectionsContext.collection?.user.user_settings,
          'logo_thumb_meta'
        );
        setLogoUrl(logoThumbURL);
      }
    }
  }, [collectionsContext.collection]);

  document.title = intl.formatMessage({ id: 'app.page.collection-shared' });

  const CustomTheme = createTheme({
    typography: {
      fontFamily: `"Rubik", "Ubuntu", "Roboto", "Helvetica", "Arial", sans-serif`,
    },
    palette: {
      primary: {
        main: customColor ? customColor : '#3969E8',
      },
      secondary: {
        main: '#FF6969',
      },
    },
  });

  return (
    <ThemeProvider theme={CustomTheme}>
      {!collectionsContext.loading ? (
        <>
          <HeaderIdenteco
            title={intl.formatMessage({ id: 'app.collection.shared.title' })}
            customColor={customColor}
            logoUrl={logoUrl}
            brandName={brandName}
          />
          <Grid container>
            <Grid container mt={10}>
              {!mobile && (
                <CustomGrid item xs={2.5}>
                  <DocumentBadges label={true} />
                </CustomGrid>
              )}
              <Grid item md={9.5} xs={12}>
                {mobile && (
                  <CustomAccordionContainer>
                    <CustomAccordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>
                          {intl.formatMessage({ id: 'app.collection.header' })}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <DocumentBadges />
                      </AccordionDetails>
                    </CustomAccordion>
                  </CustomAccordionContainer>
                )}
                <Container>
                  <Box component="form" noValidate>
                    <Stack spacing={1}>
                      <CollectionShared customColor={customColor} />
                    </Stack>
                  </Box>
                </Container>
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <Loader margin={'8rem'} />
      )}
    </ThemeProvider>
  );
};
