export const TEXT = 'TEXT';
export const SORT = 'SORT';
export const TEMPLATE = 'TEMPLATE';
export const STATUS = 'STATUS';
export const GROUP = 'GROUP';
export const REFRESH = 'REFRESH';

export default function filtersReducer(state, action) {
  const { type, value } = action;

  switch (type) {
    case TEXT: {
      return {
        ...state,
        filterText: value,
      };
    }
    case SORT: {
      return {
        ...state,
        sortDesc: value,
      };
    }
    case TEMPLATE: {
      return {
        ...state,
        filterTemplateId: value,
      };
    }
    case STATUS: {
      return {
        ...state,
        filterStatus: value,
      };
    }
    case GROUP: {
      return {
        ...state,
        groupByCollections: value,
      };
    }
    case REFRESH: {
      return {
        ...state,
        refreshCount: state.refreshCount + 1,
      };
    }
    default: {
      return state;
    }
  }
}
