import React from 'react';
import { useIntl } from 'react-intl';

import {
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from '@mui/material';
import CustomButton from '../CustomButton';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

import { styled } from '@mui/material/styles';
import {
  GLOBAL_CUSTOM_PLAN_ID,
  PLAN_ONE_PAY,
  PLAN_SUBSCRIPTION,
} from '../../constants';

const PaymentCardGrid = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: '1rem 0.5rem',
});

const PaymentCustomCard = styled(Card)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: '16px',
  width: '20rem',
  minHeight: '21rem',
  '@media (max-width: 900px)': {
    width: '100%',
    minHeight: '0',
  },
});

const PlanLengthContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '0.5rem',
});

const PlanLength = styled(Typography)({
  fontWeight: '500',
  color: '#3969E8',
});

const PlanDescriptionContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
});

const PlanDescription = styled('div')((props) => ({
  display: 'flex',
  flexDirection: 'row',
  margin: '0.3rem 0rem',
  '& svg': {
    marginRight: '1rem',
    color: props.benefit ? '#4BCDB6' : '#FF794F',
  },
}));

const CustomCardActions = styled(CardActions)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '80%',
  '& button': {
    margin: '1rem 0rem',
    maxWidth: '13rem',
    backgroundColor: '#3969E8',
  },
});

const PriceHeaderText = styled(Typography)({
  fontWeight: '400',
  color: '#A4A7C1',
});

const Price = styled(Typography)({
  color: '#7D50FF',
  borderRadius: '0.75rem',
});

export default function PaymentCard({
  planLength,
  planType,
  buttonText,
  priceHeaderText,
  planNumber,
  type,
  activePaymentType,
  cardData,
  handleOpenConfirmModal,
}) {
  const intl = useIntl();

  const PaymentFeature = (props) => {
    return (
      <PlanDescription benefit={props.benefit}>
        {props.benefit ? (
          <CheckCircleIcon fontSize="small" />
        ) : (
          <CancelIcon fontSize="small" />
        )}
        <Typography variant="body2" children={props.featureDesc} />
      </PlanDescription>
    );
  };

  return (
    cardData && (
      <PaymentCardGrid item>
        <PaymentCustomCard raised>
          <CardContent>
            <PlanLengthContainer>
              <PlanLength variant="body1" children={planLength} />
              <Typography
                variant="h5"
                align="center"
                children={planType}
              />
            </PlanLengthContainer>
            <PlanDescriptionContainer>
              {cardData.features ? (
                cardData.features.map((feature, index) => (
                  <PaymentFeature
                    key={index}
                    benefit={feature.benefit}
                    featureDesc={feature.feature_desc}
                  />
                ))
              ) : (
                <PaymentFeature
                  benefit
                  featureDesc={intl.formatMessage({
                    id: `app.my-account.plans.payment-card.feature-${
                      type === PLAN_SUBSCRIPTION ? '1' : '2'
                    }`,
                  })}
                />
              )}
            </PlanDescriptionContainer>
          </CardContent>
          <CustomCardActions>
            {cardData.priceData && (
              <>
                <PriceHeaderText
                  variant="caption"
                  children={priceHeaderText}
                />
                <Price
                  variant="h6"
                  children={'US$ ' + cardData.priceData.price}
                />
              </>
            )}
            <CustomButton
              variant="contained"
              fullWidth
              children={buttonText}
              disabled={
                type === PLAN_SUBSCRIPTION &&
                activePaymentType === PLAN_ONE_PAY
              }
              onClick={() => {
                handleOpenConfirmModal(
                  planNumber === GLOBAL_CUSTOM_PLAN_ID
                    ? 0
                    : cardData.priceData.id
                );
              }}
            />
          </CustomCardActions>
        </PaymentCustomCard>
      </PaymentCardGrid>
    )
  );
}
