import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import AuthContext from '../context/Auth/AuthContext';
// import { SnackbarProvider } from 'notistack';
import NotificationsProvider from '../context/Notifications/NotificationsProvider';

const PrivateRoute = () => {
  const authContext = useContext(AuthContext);

  return authContext.loggedIn ? (
    <NotificationsProvider>
      {/* <SnackbarProvider autoHideDuration={null}> */}
      <Outlet />
      {/* </SnackbarProvider> */}
    </NotificationsProvider>
  ) : (
    <Navigate to={'/login'} />
  );
};

export default PrivateRoute;
