import {
  GET_USER,
  GET_SETTINGS,
  EDIT_USER,
  EDIT_SETTINGS,
  LOADING_SETTINGS,
  SETTINGS_ERROR,
} from './SettingsTypes';

export default function SettingsReducer(state, action) {
  const { payload, type } = action;

  switch (type) {
    case GET_USER:
      return {
        user: payload,
        loading: false,
        error: false,
      };
    case GET_SETTINGS:
      return {
        settings: payload,
        loading: false,
        error: false,
      };
    case EDIT_USER:
      return {
        ...state,
        user: payload,
        loading: false,
        error: false,
      };
    case EDIT_SETTINGS:
      return {
        ...state,
        settings: payload,
        loading: false,
        error: false,
      };
    case LOADING_SETTINGS:
      return {
        ...state,
        user: undefined,
        settings: undefined,
        loading: true,
        error: false,
      };
    case SETTINGS_ERROR:
      return {
        ...state,
        user: undefined,
        settings: undefined,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
}
