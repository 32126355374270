import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

function Loader(props) {
  return (
    <Box sx={{ width: '100%', textAlign: 'center', marginTop: props.margin }}>
      <CircularProgress size={props.size} thickness={props.thickness} />
    </Box>
  );
}

export default Loader;
