import { useState, useContext, useEffect } from 'react';
import { Typography, Paper, Fade, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import CollectionsContext from '../context/Collections/CollectionsContext';
import { makeStyles } from '@mui/styles';
import DocumentCard from '../components/kontroli/DocumentCard';
import InlineAlert from './InlineAlert';
import Loader from './Loader';
import FilePreviewer from './FilePreviewer';

import SharedProvider from '../context/Shared/SharedProvider';
import ShareCollectionsButton from '../components/kontroli/ShareCollectionsButton';
import { BackButton } from '../components/BackButton';

import { useIntl } from 'react-intl';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  padding: theme.spacing(2),
  paddingLeft: theme.spacing(16),
  boxShadow: 'none',
  backgroundColor: '#FCFCFC',
  '@media (max-width: 600px)': {
    padding: 0,
  },
}));

const useStyles = makeStyles({
  title: {
    color: '#3969E8',
  },
  titleBlock: {
    float: 'left',
    '@media (max-width: 900px)': {
      display: 'flex',
      padding: '1rem 0rem',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
    },
  },
  contentBlock: {
    paddingTop: '20px',
    clear: 'both',
  },
  shareBlock: {
    float: 'right',
    paddingTop: '10px',
    '@media (max-width: 900px)': {
      display: 'flex',
      padding: '1rem 0rem',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
    },
    '@media (max-width: 600px)': {
      display: 'none',
    },
  },
  shareBlockBottom: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '10px',
    '& button': {
      margin: '1rem',
    },
    '@media (min-width: 600px)': {
      display: 'none',
    },
  },
});

export const Collection = ({ collectionId }) => {
  const classes = useStyles();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewDocument, setPreviewDocument] = useState(undefined);
  const collectionsContext = useContext(CollectionsContext);

  const intl = useIntl();

  function handleOpenPreview(document) {
    setPreviewDocument(document);
    setPreviewOpen(true);
  }

  function handleClosePreview() {
    setPreviewOpen(false);
  }

  useEffect(async () => {
    await collectionsContext.getCollectionById(collectionId);
  }, []);

  if (collectionsContext.loading) {
    return <Loader margin={'6rem'} />;
  }

  return (
    <>
      <FilePreviewer
      open={previewOpen}
      onClose={handleClosePreview}
      document={previewDocument}
      />
      <Fade timeout={1000} in={true}>
        <Grid>
          <SharedProvider>
            {collectionsContext.errors && (
              <InlineAlert
                title={intl.formatMessage({
                  id: 'app.alerts.inline-error-title',
                })}
                severity="error"
                alertText={collectionsContext.errors}
              />
            )}
            {collectionsContext.collection && (
              <>
                <Item>
                  <div className={classes.titleBlock}>
                    <div>
                      <Typography variant="subtitle1" component="h5">
                        {intl.formatMessage({ id: 'app.collection.name' })}
                      </Typography>
                      <Typography
                        variant="subtitle"
                        component="h2"
                        className={classes.title}
                      >
                        {collectionsContext.collection.name}
                      </Typography>
                    </div>
                  </div>
                  <div className={classes.shareBlock}>
                    <SharedProvider>
                      <ShareCollectionsButton collectionId={collectionId} />
                    </SharedProvider>
                  </div>
                </Item>
                <div className={classes.contentBlock}>
                  {collectionsContext.collection.documents.map((document) => (
                    <DocumentCard
                      document={document}
                      validateDialog={false}
                      isShareable={true}
                      handleOpenPreview={handleOpenPreview}
                      textValidated={{
                        title: intl.formatMessage({
                          id: 'app.search.validate',
                        }),
                      }}
                      textNoValidated={{
                        title: intl.formatMessage({
                          id: 'app.search.novalidate',
                        }),
                        desc: intl.formatMessage({
                          id: 'app.search.novalidate-desc',
                        }),
                      }}
                    />
                  ))}
                </div>
                <BackButton />
              </>
            )}
            <div className={classes.shareBlockBottom}>
              <ShareCollectionsButton collectionId={collectionId} />
            </div>
          </SharedProvider>
        </Grid>
      </Fade>
    </>
  );
};
