import React, {
  useEffect,
  useState,
  useContext,
  useMemo,
  useReducer,
} from 'react';
import filtersReducer, { TEXT, GROUP, REFRESH } from '../../reducers/my-documents/FiltersReducer';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import InfiniteScroll from 'react-infinite-scroll-component';
import { debounce } from 'lodash';
import { Box, Stack, Fade, Alert } from '@mui/material';

import TemplatesContext from '../../context/Templates/TemplatesContext';
import CollectionsContext from '../../context/Collections/CollectionsContext';

import FilePreviewer from '../FilePreviewer';
import CollectionsGridFilters from './CollectionsGridFilters';
import CollectionAccordion from './CollectionAccordion';
import DocumentGridCard from './DocumentGridCard';
import Loader from '../Loader';
import InlineAlert from '../InlineAlert';

import { statusName } from '../../utils/status';
import { fileTypeName } from '../../helpers';

import {
  APP_ROUTES,
  ADD_EXISTING_MODES,
  FIELD_TYPE_TEXT,
} from '../../constants';
import { config } from '../../config';

export default function CollectionsGrid({
  addExistingType,
  selectedDocument,
  setSelectedDocument,
}) {
  const [filters, dispatch] = useReducer(filtersReducer, {
    page: 0,
    sortDesc: true,
    filterText: '',
    filterTemplateId: '',
    filterStatus: '',
    groupByCollections: true,
    refreshCount: 0,
  });

  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewDocument, setPreviewDocument] = useState(undefined);

  const templatesContext = useContext(TemplatesContext);
  const collectionsContext = useContext(CollectionsContext);

  const navigate = useNavigate();
  const intl = useIntl();

  const SEARCH_LIMIT = 10;

  useEffect(() => {
    async function getData() {
      await getCollections(
        page,
        SEARCH_LIMIT,
        filters.sortDesc,
        filters.filterText,
        filters.filterTemplateId,
        filters.filterStatus
      );
      setPage(page + 1);
    }
    getData();
  }, [
    filters.sortDesc,
    filters.filterText,
    filters.filterTemplateId,
    filters.filterStatus,
    filters.refreshCount,
  ]);

  useEffect(() => {
    if (!addExistingType) {
      async function getTemplates() {
        await templatesContext.getTemplatesList();
      }
      getTemplates();
    }

    if (addExistingType === ADD_EXISTING_MODES.FILE) {
      dispatch({ type: GROUP, value: false });
    }
  }, []);

  useEffect(() => {
    if (collectionsContext.collections && collectionsContext.isAutoUpdating) {
      const autoUpdate = setInterval(() => {
        collectionsContext.autoUpdateCollectionsStatus();
      }, config.stampAutoUpdateDelayMs);
      return () => clearInterval(autoUpdate);
    }
  }, [collectionsContext.collections, collectionsContext.isAutoUpdating]);

  function handleEditDraft(id) {
    navigate(`${APP_ROUTES.STAMP.INDEX}/${id}`);
  }

  function resetPage() {
    setPage(0);
    setHasMore(true);
  }

  const handleChangeFilters = (type, value) => {
    type !== GROUP && resetPage();
    dispatch({ type: type, value: value });
  };

  const handleChangeFilterTextDebounced = (e) => {
    resetPage();
    dispatch({ type: TEXT, value: e.target.value });
  };

  function getCollectionNameById(collectionId) {
    const found = collectionsContext.collections.filter(
      (item) => item.id === collectionId
    );
    return found ? found[0].name : '-';
  }

  function handleOpenPreview(document) {
    setPreviewDocument(document);
    setPreviewOpen(true);
  }

  function handleClosePreview() {
    setPreviewOpen(false);
  }

  async function getCollections(
    page,
    limit = SEARCH_LIMIT,
    sortDesc,
    filterText,
    filterTemplateId,
    filterStatus,
    infiniteScroll = false
  ) {
    const collections = await collectionsContext.getCollections(
      page,
      limit,
      sortDesc,
      filterText,
      filterTemplateId,
      filterStatus,
      infiniteScroll
    );
    if (!collectionsContext.errors) {
      if (collections?.length < SEARCH_LIMIT) {
        setHasMore(false);
      }
      return collectionsContext.collections;
    } else {
      setHasMore(false);
    }
  }

  const getMoreCollections = async () => {
    await getCollections(
      page,
      SEARCH_LIMIT,
      filters.sortDesc,
      filters.filterText,
      filters.filterTemplateId,
      filters.filterStatus,
      true
    );
  };

  const debouncedSearch = useMemo(() => {
    return debounce(handleChangeFilterTextDebounced, 350);
  }, []);

  return (
    <>
      <FilePreviewer
        open={previewOpen}
        onClose={handleClosePreview}
        document={previewDocument}
      />
      <Fade timeout={1000} in={true}>
        <Box component="form" noValidate>
          <CollectionsGridFilters
            addExistingType={addExistingType}
            sortDesc={filters.sortDesc}
            filterTemplateId={filters.filterTemplateId}
            filterStatus={filters.filterStatus}
            groupByCollections={filters.groupByCollections}
            debouncedSearch={debouncedSearch}
            handleChangeFilters={handleChangeFilters}
          />
        </Box>
      </Fade>
      {collectionsContext.loading && <Loader />}
      {collectionsContext.errors && (
        <InlineAlert
          title={intl.formatMessage({ id: 'app.alerts.inline-error-title' })}
          severity="error"
          alertText={collectionsContext.errors}
        />
      )}
      {collectionsContext.collections?.length > 0 &&
        !collectionsContext.loading && (
          <>
            <InfiniteScroll
              dataLength={collectionsContext.collections.length}
              next={getMoreCollections}
              hasMore={hasMore}
              loader={<Loader margin={'6rem'} />}
              endMessage={
                <Alert severity="info" sx={{ mb: 2 }}>
                  {intl.formatMessage({ id: 'app.mydocuments.no-records' })}
                </Alert>
              }
              style={{
                overflow: 'hidden',
              }}
              scrollableTarget={addExistingType && 'add-doc-scroll'}
            >
              {!filters.groupByCollections ? (
                <Fade timeout={1000} in={true}>
                  <Box component="form" noValidate>
                    <Stack spacing={2} marginBottom={2}>
                      {collectionsContext.collections.map(
                        ({ documents }, index) => (
                          <React.Fragment key={index}>
                            {documents.map((document, index) => {
                              if (addExistingType === ADD_EXISTING_MODES.FILE) {
                                if (
                                  document.document_type === FIELD_TYPE_TEXT
                                ) {
                                  return null;
                                } else {
                                  return (
                                    <DocumentGridCard
                                      key={index}
                                      addExistingType={addExistingType}
                                      document={document}
                                      fileTypeName={fileTypeName}
                                      getCollectionNameById={
                                        getCollectionNameById
                                      }
                                      statusName={statusName}
                                      selectedDocument={selectedDocument}
                                      setSelectedDocument={setSelectedDocument}
                                    />
                                  );
                                }
                              } else {
                                return (
                                  <DocumentGridCard
                                    key={index}
                                    addExistingType={addExistingType}
                                    document={document}
                                    fileTypeName={fileTypeName}
                                    getCollectionNameById={
                                      getCollectionNameById
                                    }
                                    statusName={statusName}
                                    selectedDocument={selectedDocument}
                                    setSelectedDocument={setSelectedDocument}
                                  />
                                );
                              }
                            })}
                          </React.Fragment>
                        )
                      )}
                    </Stack>
                  </Box>
                </Fade>
              ) : (
                <Fade timeout={1000} in={true}>
                  <Box>
                    {collectionsContext.collections.map(
                      (
                        {
                          id,
                          name,
                          url,
                          url_short,
                          status,
                          created_at,
                          documents,
                        },
                        index
                      ) => (
                        <CollectionAccordion
                          key={index}
                          addExistingType={addExistingType}
                          id={id}
                          name={name}
                          url={url}
                          url_short={url_short}
                          status={status}
                          created_at={created_at}
                          documents={documents}
                          handleEditDraft={handleEditDraft}
                          handleOpenPreview={handleOpenPreview}
                          handleRefresh={() => handleChangeFilters(REFRESH)}
                          selectedDocument={selectedDocument}
                          setSelectedDocument={setSelectedDocument}
                        />
                      )
                    )}
                  </Box>
                </Fade>
              )}
            </InfiniteScroll>
          </>
        )}
      {collectionsContext.collections?.length === 0 &&
        !collectionsContext.errors && (
          <Alert
            severity="info"
            children={intl.formatMessage({ id: 'app.mydocuments.no-search' })}
          />
        )}
    </>
  );
}
