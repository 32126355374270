import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { TextField, Button, Alert, Grid } from '@mui/material';
import CustomButton from './CustomButton';

import { styled } from '@mui/material/styles';

import { useIntl } from 'react-intl';

import useForgotPassword from '../hooks/useForgotPassword';

import InlineAlert from './InlineAlert';
import Loader from './Loader';

const InputsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  margin: '1rem',
});

const ButtonsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: '1rem',
  '& button': {
    width: '10rem',
    margin: '0.5rem 0rem',
  },
});

const SuccessContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

export default function UserRecoverPass() {
  const { hash } = useParams();

  const [newPsw1, setNewPsw1] = useState('');
  const [newPsw2, setNewPsw2] = useState('');
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const navigate = useNavigate();
  const intl = useIntl();

  const forgot = useForgotPassword();

  useEffect(async () => {
    await forgot.getForgot(hash);
  }, []);

  function validatePass() {
    setError(false);
    if (newPsw1 === newPsw2) {
      return true;
    }
    setError(true);
    return false;
  }

  function handleChange(e) {
    switch (e.target.name) {
      case 'newpass1':
        setNewPsw1(e.target.value);
        break;
      case 'newpass2':
        setNewPsw2(e.target.value);
        break;
      default:
        break;
    }
  }

  async function handleSubmit() {
    await forgot.resetPassword(hash, newPsw2);
    setSuccess(forgot.ok);
    return;
  }

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} md={5}>
        {forgot.errors && (
          <InlineAlert
            title={intl.formatMessage({ id: 'app.alerts.inline-error-title' })}
            severity="error"
            alertText={forgot.errors}
          />
        )}
        {forgot.loading && <Loader />}
        {success && !forgot.errors && (
          <SuccessContainer>
            <Alert severity="success" sx={{ mb: 2 }}>
              {intl.formatMessage({ id: 'app.recover.success' })}
            </Alert>
          </SuccessContainer>
        )}
        {!success && !forgot.errors && (
        <InputsContainer>
          <TextField
            margin="normal"
            required
            fullWidth
            name="newpass1"
            label={intl.formatMessage({ id: 'app.recover.newpass1' })}
            type="password"
            id="password"
            autoComplete="newpass"
            onChange={handleChange}
            onBlur={() => {
              newPsw2 && validatePass();
            }}
            value={newPsw1}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="newpass2"
            label={intl.formatMessage({ id: 'app.recover.newpass2' })}
            type="password"
            id="password"
            autoComplete="newpass"
            onChange={handleChange}
            onBlur={validatePass}
            value={newPsw2}
            error={error}
            helperText={
              error && intl.formatMessage({ id: 'app.recover.passerror' })
            }
          />
        </InputsContainer>
        )}
        <ButtonsContainer>
          <CustomButton
            variant="contained"
            size="medium"
            onClick={handleSubmit}
            disabled={
              !newPsw2 || error || forgot.loading || (success && !forgot.errors)
            }
            children={intl.formatMessage({ id: 'app.recover.recover-button' })}
          />
          <Button
            variant="text"
            onClick={() => {
              navigate('/');
            }}
            children={intl.formatMessage({ id: 'app.recover.back-button' })}
          />
        </ButtonsContainer>
      </Grid>
    </Grid>
  );
}
