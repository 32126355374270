import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';

import { Grid, Paper, Typography, IconButton, Checkbox } from '@mui/material';
import { styled } from '@mui/material';

import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';
import RadioButtonCheckedOutlinedIcon from '@mui/icons-material/RadioButtonCheckedOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';

import {
  FIELD_TYPE_FILE,
  COLLECTION_STATUS_STAMPED,
  ADD_EXISTING_MODES,
} from '../../constants';

const DocumentPaper = styled(Paper)(({ grayed = false }) => ({
  padding: '0.5rem 1rem',
  borderRadius: '4px',
  boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.12)',
  opacity: grayed ? '0.3' : undefined,
  pointerEvents: grayed ? 'none' : undefined,
}));

const DocumentSummaryGrid = styled(Grid)({
  padding: '0.5rem 1rem',
  '@media (max-width: 1200px)': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const DocumentTitle = styled(Typography)({
  fontWeight: 'bold',
  color: '333333 !important',
  textTransform: 'uppercase',
  wordBreak: 'break-all',
});

export default function DocumentGridCard({
  addExistingType,
  document,
  fileTypeName,
  getCollectionNameById,
  statusName,
  selectedDocument,
  setSelectedDocument,
}) {
  const [selected, setSelected] = useState(false);
  const [grayed, setGrayed] = useState(false);

  const intl = useIntl();

  useEffect(() => {
    setGrayed(false);
    if (selectedDocument) {
      if (selectedDocument.id !== document.id) {
        setGrayed(true);
        setSelected(false);
      } else {
        setSelected(true);
      }
    }
  }, [selectedDocument, selected]);

  function handleSelectDocument(e, document) {
    setSelected(e.target.checked);
    setSelectedDocument(e.target.checked ? document : undefined);
  }

  return (
    <DocumentPaper key={document.id} grayed={grayed}>
      <DocumentSummaryGrid container alignItems="center">
        <Grid
          item
          xs={12}
          md={addExistingType === ADD_EXISTING_MODES.DOCUMENT ? 2.5 : 3}
        >
          <DocumentTitle>
            {document.title ? (
              document.title
            ) : (
              <div style={{ color: '#3969E8' }}>
                {intl.formatMessage(
                  { id: 'app.mydocuments.no-name' },
                  {
                    type: intl.formatMessage({
                      id: fileTypeName(document.document_type),
                    }),
                  }
                )}
              </div>
            )}
          </DocumentTitle>
        </Grid>
        <Grid
          item
          xs={12}
          md={addExistingType === ADD_EXISTING_MODES.DOCUMENT ? 2.5 : 3}
        >
          <Typography sx={{ wordBreak: 'break-all' }}>
            {getCollectionNameById(document.collection_id)}
          </Typography>
        </Grid>
        <Grid item xs={12} md={1.5}>
          <Typography>
            {document.document_type === FIELD_TYPE_FILE
              ? intl.formatMessage({
                  id: 'app.mydocuments.doc-file-type',
                })
              : intl.formatMessage({
                  id: 'app.mydocuments.doc-text-type',
                })}
          </Typography>
        </Grid>
        <Grid item xs={12} md={1.5}>
          <Typography>{statusName(document.status)}</Typography>
        </Grid>
        <Grid item xs={12} md={2}>
          <Typography>
            {dayjs(document.created_at).format('DD/MM/YYYY')}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={addExistingType === ADD_EXISTING_MODES.DOCUMENT ? 2 : 1}
          display="flex"
          justifyContent="flex-end"
        >
          {addExistingType &&
            !document.source_document_id &&
            document.status === COLLECTION_STATUS_STAMPED && (
              <Checkbox
                disableFocusRipple
                size="medium"
                checked={selected}
                disabled={grayed}
                icon={<RadioButtonUncheckedOutlinedIcon />}
                checkedIcon={<RadioButtonCheckedOutlinedIcon />}
                onChange={(e) => {
                  handleSelectDocument(e, document);
                }}
              />
            )}
          <Link
            to={`/documents/${document.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconButton aria-label="more">
              <VisibilityIcon />
            </IconButton>
          </Link>
        </Grid>
      </DocumentSummaryGrid>
    </DocumentPaper>
  );
}
