import { PublicLayout } from '../components/PublicLayout';
import UserForgotPass from '../components/UserForgotPass';

import { useIntl } from 'react-intl';

import { useMediaQuery } from '@mui/material';

import { ReactComponent as MessageIcon } from '../assets/message.svg';

export const ForgotPassPage = () => {
  const intl = useIntl();
  const mobile = useMediaQuery('(max-width: 900px)');

  document.title = intl.formatMessage({ id: 'app.page.forgot-pass' });

  return (
    <PublicLayout
      iconForm={<MessageIcon width={'32px'} height={'37px'} />}
      welcomeTitle={intl.formatMessage({ id: 'app.layout.forgot' })}
      welcomeText={intl.formatMessage({ id: 'app.layout.forgot-message' })}
      pageTitle={!mobile && intl.formatMessage({ id: 'app.forgotpass.title' })}
    >
      <UserForgotPass />
    </PublicLayout>
  );
};
