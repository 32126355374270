import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { debounce } from 'lodash';

import { Box, Grid, Fade, Typography, Alert } from '@mui/material';
import { styled } from '@mui/material/styles';

import AddIcon from '@mui/icons-material/Add';

import { ReactComponent as NoTemplatesIcon } from '../assets/no-templates.svg';

import { IconSvgContainer } from './IconSvgContainer';
import TemplateCard from './TemplateCard';
import Loader from './Loader';
import ConfirmModal from './ConfirmModal';
import TemplateGridFilters from './TemplateGridFilters';
import CustomButton from './CustomButton';

import TemplatesContext from '../context/Templates/TemplatesContext';

import { TEMPLATE_STATUS_INACTIVE } from '../constants';
import { config } from '../config';

const CreateButtonGrid = styled(Grid)({
  display: 'flex',
  direction: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  '@media (max-width: 900px)': {
    justifyContent: 'center',
  },
});

const NoTemplatesGrid = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '80vh',
});

const CustomGrid = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  '& button': {
    marginTop: '2rem',
  },
  '& p': {
    marginTop: '2rem',
  },
});

const TitleNoTemplates = styled(Typography)({
  fontFamily: 'Ubuntu',
  fontSize: '30px',
  fontWeight: '400',
  lineHeight: '36px',
  letterSpacing: '0px',
  textAlign: 'center',
  color: '#3969E8',
  width: '337px',
});

const SubtitleNoTemplates = styled(Typography)({
  fontFamily: 'Ubuntu',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '18px',
  lineHeight: '25px',
  textAlign: 'center',
  color: 'rgba(0, 0, 0, 0.87)',
  width: '285px',
});

const PATH_NEWTEMPLATE = 'new-template';

export default function TemplatesGrid(props) {
  const [filterTextSearch, setFilterTextSearch] = useState();
  const [orderFilter, setOrderFilter] = useState(true);
  const [statusFilter, setStatusFilter] = useState('');
  const [confirmModal, setConfirmModal] = useState(false);
  const [deleteTemplateId, setDeleteTemplateId] = useState();
  const [noTemplates, setNoTemplates] = useState(false);
  const [defaultLoad, setDefaultLoad] = useState(false);
  const [newPrompt, setNewPrompt] = useState(false);

  const navigate = useNavigate();
  const intl = useIntl();
  const templatesContext = useContext(TemplatesContext);

  async function templatesFetch(sortDesc, filterText, filterStatus) {
    await templatesContext.getTemplates(sortDesc, filterText, filterStatus);
  }

  async function defaultFetch() {
    await templatesFetch(orderFilter, '', '');
  }

  useEffect(() => {
    setDefaultLoad(true);
    defaultFetch();
  }, []);

  useEffect(() => {
    if (templatesContext.templates?.length === 0) {
      setNoTemplates(true);
    }
    if (noTemplates && defaultLoad) {
      setNewPrompt(true);
    }
  }, [templatesContext.templates]);

  useEffect(() => {
    templatesFetch(orderFilter, filterTextSearch, statusFilter);
  }, [orderFilter, filterTextSearch, statusFilter]);

  function handleOpenConfirmModal(id) {
    setDeleteTemplateId(id);
    setConfirmModal(true);
  }

  function handleCloseConfirmModal() {
    setConfirmModal(false);
  }

  function handleTextSearch(e) {
    setDefaultLoad(false);
    setFilterTextSearch(e.target.value);
    if (filterTextSearch === '') {
      templatesFetch(orderFilter, null, statusFilter);
    } else {
      templatesFetch(orderFilter, filterTextSearch, statusFilter);
    }
  }

  function handleOrderFilterChange(e) {
    setDefaultLoad(false);
    setOrderFilter(e.target.value);
  }

  function handleStatusFilterChange(e) {
    setDefaultLoad(false);
    setStatusFilter(e.target.value);
  }

  async function handleDeleteTemplate(id) {
    await templatesContext.deleteTemplate(id);
    await defaultFetch();
    setFilterTextSearch('');
    setOrderFilter(true);
    setStatusFilter('');
    setConfirmModal(false);
  }

  const debouncedSearch = useMemo(() => {
    return debounce(handleTextSearch, 350);
  }, []);

  return (
    <>
      <ConfirmModal
        open={confirmModal}
        onClose={handleCloseConfirmModal}
        modalTitle={intl.formatMessage({
          id: 'app.templates.new.modal-title-delete',
        })}
        modalText={intl.formatMessage({
          id: 'app.templates.delete',
        })}
        confirmAction={() => {
          handleDeleteTemplate(deleteTemplateId);
        }}
        cancelAction={handleCloseConfirmModal}
      />
      <div style={{ filter: props.templateSupport === false && 'blur(3px)' }}>
        <Fade timeout={250} in={true}>
          <Box>
            {!newPrompt && (
              <>
                <Grid container sx={{ mb: 3 }}>
                  <CreateButtonGrid item xs={12}>
                    <CustomButton
                      variant="contained"
                      startIcon={<AddIcon />}
                      children={intl.formatMessage({
                        id: 'app.templates.create-button',
                      })}
                      onClick={() => {
                        navigate(`/${PATH_NEWTEMPLATE}`);
                      }}
                      disabled={
                        templatesContext.loading || !props.templateSupport
                          ? true
                          : false
                      }
                    />
                  </CreateButtonGrid>
                </Grid>
                <TemplateGridFilters
                  templateSupport={props.templateSupport}
                  orderFilter={orderFilter}
                  statusFilter={statusFilter}
                  handleOrderFilterChange={handleOrderFilterChange}
                  handleStatusFilterChange={handleStatusFilterChange}
                  debouncedSearch={debouncedSearch}
                />
              </>
            )}
          </Box>
        </Fade>
        {templatesContext.loading === false ? (
          <Fade timeout={250} in={true}>
            <Box>
              {templatesContext.templates?.map((template, index) => {
                if (
                  template.id !== config.templateDefaultId &&
                  template.status !== TEMPLATE_STATUS_INACTIVE
                ) {
                  return (
                    <TemplateCard
                      key={index}
                      index={index}
                      template={template}
                      id={template.id}
                      title={template.title}
                      stampedCollections={template.stamped_collections}
                      fieldCount={template.field_count}
                      createdAt={template.created_at}
                      updatedAt={template.updated_at}
                      deletedAt={template.deleted_at}
                      status={template.status}
                      handleOpenConfirmModal={handleOpenConfirmModal}
                    />
                  );
                } else {
                  return null;
                }
              })}
              {templatesContext.templates?.length === 0 &&
              filterTextSearch !== '' &&
              !defaultLoad ? (
                <Alert
                  severity="info"
                  children={intl.formatMessage({
                    id: 'app.mydocuments.no-search',
                  })}
                />
              ) : null}
              {newPrompt ? (
                <NoTemplatesGrid container spacing={0}>
                  <CustomGrid item md={12}>
                    <IconSvgContainer
                      backgroundColor="#fff"
                      width={'70px'}
                      height={'70px'}
                      shadow="true"
                      gradient="true"
                    >
                      <NoTemplatesIcon width={'32px'} height={'37px'} />
                    </IconSvgContainer>

                    <TitleNoTemplates
                      children={intl.formatMessage({
                        id: 'app.templates.no-templates-title',
                      })}
                    />
                    <SubtitleNoTemplates
                      marginTop={0}
                      children={intl.formatMessage({
                        id: 'app.templates.no-templates',
                      })}
                    />

                    <CustomButton
                      variant="contained"
                      startIcon={<AddIcon />}
                      children={intl.formatMessage({
                        id: 'app.templates.create-button',
                      })}
                      onClick={() => {
                        navigate(`/${PATH_NEWTEMPLATE}`);
                      }}
                    />
                  </CustomGrid>
                </NoTemplatesGrid>
              ) : null}
            </Box>
          </Fade>
        ) : (
          <Loader margin={'8rem'} />
        )}
      </div>
    </>
  );
}
