import React, { useState, useEffect } from 'react';
import { FIELD_TYPE_FILE, FIELD_TYPE_TEXT } from '../constants';

import { styled } from '@mui/material/styles';

import { useIntl } from 'react-intl';

import {
  Box,
  Button,
  Grid,
  TextField,
  Paper,
  MenuItem,
  InputLabel,
  Typography,
  Modal,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import CustomButton from './CustomButton';

import { config } from '../config';

const ModalBox = styled(Box)({
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
});

const ModalPaper = styled(Paper)({
  display: 'flex',
  flexDirection: 'column',
  width: '35rem',
  borderRadius: '18px',
  '@media (max-width: 600px)': {
    width: '95vw',
  },
});

const TitleContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  borderBottom: '1px solid rgba(33, 33, 33, 0.08)',
  marginBottom: '2rem',
  padding: '2rem',
});

const InputsContainer = styled(Grid)({
  padding: '1rem 5rem',
  '@media (max-width: 600px)': {
    padding: '1rem 2rem',
  },
});

const ButtonsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: ' center',
  '& button': {
    width: '50%',
    margin: '0.5rem',
  },
});

export default function TemplatesNewFieldModal(props) {
  const [fieldType, setFieldType] = useState('');
  const [title, setTitle] = useState('');
  const [required, setRequired] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const [typeError, setTypeError] = useState(false);

  useEffect(() => {
    if (props.editField === true) {
      setFieldType(props.fields[props.editIndex].field_type);
      setTitle(props.fields[props.editIndex].title);
      setRequired(props.fields[props.editIndex].validations.required);
    } else {
      return;
    }
  }, [props.editField]);

  const intl = useIntl();

  function handleFieldTypeChange(event) {
    setFieldType(event.target.value);
  }

  function handleFieldTitleChange(event) {
    setTitle(event.target.value);
  }

  function handleCheckRequired(event) {
    setRequired(event.target.checked);
  }

  function handleCreateNewField() {
    if (fieldType !== '' && title !== '') {
      props.handleCreateField({
        field_type: fieldType,
        title: title,
        validations: { required: required },
        order_index: props.fields.length + 1,
      });
      handleClose();
    } else if (title === '') {
      setTitleError(true);
    } else if (fieldType === '') {
      setTypeError(true);
    }
  }

  function handleEditExistingField() {
    if (fieldType !== '' && title !== '') {
      props.handleEditField(props.editIndex, {
        field_type: fieldType,
        title: title,
        validations: { required: required },
        order_index: props.fields.length + 1,
      });
      handleClose();
    } else if (title === '') {
      setTitleError(true);
    } else if (fieldType === '') {
      setTypeError(true);
    }
  }

  function handleClose() {
    setFieldType('');
    setTitle('');
    setRequired(false);
    props.handleCloseFieldModal();
  }

  const handleEnterKey = (e) => {
    if (e.key === 'Enter') {
      props.editField ? handleEditExistingField() : handleCreateNewField();
    }
  };

  return (
    <>
      <Modal
        open={props.fieldModal}
        onClose={() => {
          handleClose();
        }}
      >
        <ModalBox>
          <ModalPaper>
            <TitleContainer>
              <Typography
                variant="h5"
                children={intl.formatMessage({
                  id:
                    props.editField === true
                      ? 'app.templates.edit.field-modal-title'
                      : 'app.templates.new.field-modal-title',
                })}
              ></Typography>
            </TitleContainer>
            <InputsContainer container spacing={4}>
              <Grid item xs={12}>
                <TextField
                  select
                  variant="outlined"
                  fullWidth
                  onChange={handleFieldTypeChange}
                  value={fieldType}
                  label={intl.formatMessage({
                    id: 'app.templates.new.field-type',
                  })}
                  error={typeError}
                  helperText={
                    typeError &&
                    intl.formatMessage({
                      id: 'app.templates.new.field-type-error',
                    })
                  }
                >
                  <MenuItem value={FIELD_TYPE_TEXT}>
                    {intl.formatMessage({
                      id: 'app.templates.new.field-text',
                    })}
                  </MenuItem>
                  <MenuItem value={FIELD_TYPE_FILE}>
                    {intl.formatMessage({
                      id: 'app.templates.new.field-file',
                    })}
                  </MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <InputLabel sx={{ mb: 1 }}>
                  {intl.formatMessage({
                    id: 'app.templates.new.field-title',
                  })}
                </InputLabel>
                <TextField
                  required={true}
                  fullWidth
                  value={title}
                  onChange={handleFieldTitleChange}
                  onKeyDown={handleEnterKey}
                  error={titleError}
                  helperText={
                    titleError &&
                    intl.formatMessage({
                      id: 'app.templates.new.field-title-error',
                    })
                  }
                  inputProps={{ maxLength: config.maxLengthTitle }}
                ></TextField>
              </Grid>
              <Grid item xs={12}>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox />}
                    label={intl.formatMessage({
                      id: 'app.templates.new.required',
                    })}
                    checked={required}
                    onChange={handleCheckRequired}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <ButtonsContainer>
                  <CustomButton
                    variant="contained"
                    children={intl.formatMessage({
                      id: props.editField
                        ? 'app.templates.edit.edit-button'
                        : 'app.templates.new.add-button',
                    })}
                    onClick={() => {
                      props.editField
                        ? handleEditExistingField()
                        : handleCreateNewField();
                    }}
                  />
                  <Button
                    variant="outlined"
                    children={intl.formatMessage({
                      id: 'app.templates.new.cancel-button',
                    })}
                    onClick={() => {
                      handleClose();
                    }}
                  />
                </ButtonsContainer>
              </Grid>
            </InputsContainer>
          </ModalPaper>
        </ModalBox>
      </Modal>
    </>
  );
}
