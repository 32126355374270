import { get, post } from '../api';
import {
  GET_FILE_URL,
  POST_PUBLIC_FILE_URL,
  POST_PRIVATE_FILE_URL,
  GET_MEDIA_FILE_URL,
  MEDIA_URL,
} from '../utils/url';

export const getFileUploadUrl = () => {
  return get(GET_FILE_URL, false);
};

export const getMediaUploadUrl = () => {
  return get(GET_MEDIA_FILE_URL, false);
};

export const savePublicFile = (data) => {
  return post(POST_PUBLIC_FILE_URL, data, false);
};

export const savePrivateFile = (data) => {
  return post(POST_PRIVATE_FILE_URL, data);
};

export const saveMediaFile = (data) => {
  return post(MEDIA_URL, data);
};
