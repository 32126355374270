import { get, put } from '../api';

import { GET_NOTIFICATIONS, PUT_NOTIFICATION_MARK_AS_READ } from '../utils/url';

export const getNotificationsList = (
  offset = 0,
  limit = 1000,
  sortDesc = true,
  context = '',
  code = '',
  isRead = false
) => {
  const filters =
    '?offset=' +
    offset +
    '&limit=' +
    limit +
    '&sortDesc=' +
    sortDesc +
    (context ? '&context=' + context : '') +
    (code ? '&code=' + code : '') +
    ('&isRead=' + isRead);
  return get(GET_NOTIFICATIONS + filters);
};

export const putMarkNotificationAsRead = (id) => {
  return put(PUT_NOTIFICATION_MARK_AS_READ + id);
};
