import { PublicLayout } from '../components/PublicLayout';
import UserRecoverPass from '../components/UserRecoverPass';

import { useIntl } from 'react-intl';

import { useMediaQuery } from '@mui/material';

import { ReactComponent as UserIcon } from '../assets/user.svg';

export default function RecoverPassPage() {
  const intl = useIntl();
  const mobile = useMediaQuery('(max-width: 900px)');

  document.title = intl.formatMessage({ id: 'app.page.recover-pass' });

  return (
    <PublicLayout
      iconForm={<UserIcon width={'32px'} height={'37px'} />}
      welcomeTitle={intl.formatMessage({ id: 'app.layout.forgot' })}
      welcomeText={intl.formatMessage({ id: 'app.layout.recover-message' })}
      pageTitle={!mobile && intl.formatMessage({ id: 'app.recover.title' })}
    >
      <UserRecoverPass />
    </PublicLayout>
  );
}
