import { post } from "../api";
import {
  AUTH_ACCESS_TOKEN_URL,
} from "../utils/url";

export const accessToken = (email, password) => {
  return post(AUTH_ACCESS_TOKEN_URL, {
    email: email,
    password: password,
  }, false);
}
