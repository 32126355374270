import { useContext, useEffect, useState } from 'react';
import CollectionsContext from '../../context/Collections/CollectionsContext';

import { Grid, Fade } from '@mui/material';
import CustomButton from '../CustomButton';
import { styled } from '@mui/material/styles';

import { ReactComponent as ShareIcon } from '../../assets/share.svg';
import ShareButton from '../ShareButton';
import DownloadIcon from '@mui/icons-material/Download';

import { useIntl } from 'react-intl';

import { COLLECTION_STATUS_STAMPED, FIELD_TYPE_FILE } from '../../constants';

const ButtonsContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  '@media (max-width: 600px)': {
    flexDirection: 'column',
    justifyContent: 'center',
  },
});

export default function ShareCollectionsButton({ customColor, collectionId }) {
  const collectionsContext = useContext(CollectionsContext);

  const [countFiles, setCountFiles] = useState(0);

  useEffect(() => {
    if (collectionsContext.collection) {
      setCountFiles(
        collectionsContext.collection.documents.filter(
          (document) => document.document_type === FIELD_TYPE_FILE
        ).length
      );
    }
  }, [collectionsContext.collection]);

  const handleDownloadCollections = async () => {
    await collectionsContext.generateZip(collectionsContext.collection);
  };

  const intl = useIntl();

  return (
    <Fade timeout={1000} in={true}>
      <Grid>
        {collectionsContext.collection?.status ===
          COLLECTION_STATUS_STAMPED && (
          <ButtonsContainer>
            {countFiles > 0 && (
              <CustomButton
                variant="contained"
                backgroundcolor={customColor}
                sx={{ marginRight: '10px' }}
                startIcon={<DownloadIcon />}
                onClick={() => handleDownloadCollections()}
                children={intl.formatMessage({
                  id: 'app.sharecollectionsbutton.download',
                })}
              />
            )}
            <ShareButton
              customColor={customColor}
              shareItem={collectionsContext.collection}
              buttonIcon={<ShareIcon />}
              buttonText={intl.formatMessage({
                id: 'app.collection.share-button',
              })}
            />
          </ButtonsContainer>
        )}
      </Grid>
    </Fade>
  );
}
