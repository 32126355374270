import React from 'react';

import CollectionsProvider from '../context/Collections/CollectionsProvider';
import TemplatesProvider from '../context/Templates/TemplatesProvider';

import { PrivateLayout } from '../components/PrivateLayout';
import Stamp from '../components/Stamp';

import { useIntl } from 'react-intl';
import { STAMP_MODE_EDIT_DRAFT } from '../constants';

export default function EditDraftStampPage() {
  const intl = useIntl();

  document.title = intl.formatMessage({ id: 'app.page.stamp' });

  return (
    <CollectionsProvider>
      <TemplatesProvider>
        <PrivateLayout
          title={intl.formatMessage({ id: 'app.stamp.title' })}
          subtitle={intl.formatMessage({ id: 'app.stamp.subtitle' })}
        >
          <Stamp stampMode={STAMP_MODE_EDIT_DRAFT} />
        </PrivateLayout>
      </TemplatesProvider>
    </CollectionsProvider>
  );
}
