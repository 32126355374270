import { useState } from 'react';
import { useIntl } from 'react-intl';

import {
  postForgotPassword,
  getForgotPassword,
  postResetPassword,
} from '../api/forgotPassword';

import { STATUS_OK } from '../constants';

const useForgotPassword = () => {
  const intl = useIntl();

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(undefined);
  const [ok, setOk] = useState(false);

  const initState = () => {
    setLoading(true);
    setErrors(undefined);
    setOk(false);
  };

  const forgotPassword = async (email) => {
    initState();
    try {
      const response = await postForgotPassword({ email });
      if (response.status === STATUS_OK) {
        setOk(true);
      } else {
        setErrors(response.data.errors);
      }
    } catch (err) {
      console.log(err);
      setErrors([intl.formatMessage({ id: 'app.api.response-error' })]);
    } finally {
      setLoading(false);
    }
  };

  const getForgot = async (hash) => {
    initState();
    try {
      const response = await getForgotPassword(hash);
      if (response.status === STATUS_OK) {
        setOk(true);
      } else {
        setErrors(response.data.errors);
      }
    } catch (err) {
      console.log(err);
      setErrors([intl.formatMessage({ id: 'app.api.response-error' })]);
    } finally {
      setLoading(false);
    }
  };

  const resetPassword = async (hash, password) => {
    initState();
    try {
      const response = await postResetPassword({ hash, password });
      if (response.status === STATUS_OK) {
        setOk(true);
      } else {
        setErrors(response.data.errors);
      }
    } catch (err) {
      console.log(err);
      setErrors([intl.formatMessage({ id: 'app.api.response-error' })]);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    errors,
    ok,
    forgotPassword,
    getForgot,
    resetPassword,
  };
};

export default useForgotPassword;
