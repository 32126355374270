import { useContext, useState, useEffect} from 'react';

import { Stack, Grid, Fade, Typography } from '@mui/material';

import BlockchainBadge from '../components/BlockchainBadge';

import CollectionsContext from '../context/Collections/CollectionsContext';

import { useIntl } from 'react-intl';

import { FIELD_TYPE_FILE } from '../constants'

import { styled } from '@mui/material/styles';

const DocumentLabel = styled(Typography)({
  color: '#999',
  fontSize: '0.8rem',
});

export const DocumentBadges = (props) => {
  const collectionsContext = useContext(CollectionsContext);
  const intl = useIntl();

  const [countFiles,setCountFiles] = useState(0);

  useEffect(() => {
    if (collectionsContext.collection){
      setCountFiles(collectionsContext.collection.documents.filter((document)=> document.document_type === FIELD_TYPE_FILE).length);
    }
  }, [collectionsContext.collection])
  

  return (
    <Fade timeout={1000} in={true}>
      <Grid item xs={12}>
        {collectionsContext.collection && (
          <Stack spacing={2}>            
            <DocumentLabel>
              {intl.formatMessage({ id: 'app.collection.badges-title' })}
            </DocumentLabel>            
            {
              collectionsContext.collection.documents.map((document, index) => 
                (
                  <BlockchainBadge
                    key={index}
                    document={document}
                    clickeable={true}
                  />                 
                )
              )
            }
          </Stack>
        )}
      </Grid>
    </Fade>
  );
};
