import { useContext, useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { Typography, CircularProgress, Grid } from '@mui/material';
import CustomButton from './CustomButton';

import { styled } from '@mui/material/styles';

import UserContext from '../context/Users/UserContext';

import checked from '../assets/checked.svg';

import InlineAlert from './InlineAlert';

import { useIntl } from 'react-intl';

const ContentGrid = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

export const UserActivate = () => {
  const userContext = useContext(UserContext);

  const { hash } = useParams();

  const navigate = useNavigate();
  const intl = useIntl();

  useEffect(() => {
    userContext.activate(hash);
  }, [hash]);

  return (
    <Grid container justifyContent="center">
      <ContentGrid item xs={12}>
        {userContext.activateSuccess ? (
          <>
            <img src={checked} alt="create" />
            <Typography component="h4" variant="h4" sx={{ mt: 2 }}>
              {intl.formatMessage({ id: 'app.activate.success' })}
            </Typography>
            <CustomButton
              variant="contained"
              onClick={() => navigate(`/login`)}
              sx={{ mt: 3, mb: 2 }}
              children={intl.formatMessage({ id: 'app.activate.login' })}
            />
          </>
        ) : userContext.activateFailed ? (
          <InlineAlert
            title={intl.formatMessage({ id: 'app.alerts.inline-error-title' })}
            severity="error"
            alertText={userContext.errors}
          />
        ) : (
          <CircularProgress />
        )}
      </ContentGrid>
    </Grid>
  );
};
