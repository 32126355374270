import { Chip, Typography, useMediaQuery } from '@mui/material';
import { useIntl } from 'react-intl';
import {
  COLLECTION_STATUS_DRAFT,
  COLLECTION_STATUS_PENDING,
  COLLECTION_STATUS_PROCESSING,
  COLLECTION_STATUS_STAMPED,
  TEMPLATE_STATUS_ACTIVE,
} from '../constants';

import CustomIcon from './CustomIcon';
import { ReactComponent as StampIcon } from '../assets/StampIcon.svg';
import { ReactComponent as DraftIcon } from '../assets/draft.svg';
import TemplateIcon from '../assets/template.svg';
import BlockIcon from '@mui/icons-material/Block';

import ScheduleIcon from '@mui/icons-material/Schedule';

export default function ChipGrid(props) {
  const intl = useIntl();
  const mobile = useMediaQuery('(max-width: 600px)');

  const colorSwitcher = (element, status, revoked) => {
    switch (element) {
      case 'chip':
        if (!revoked) {
          if (
            status === TEMPLATE_STATUS_ACTIVE ||
            status === COLLECTION_STATUS_STAMPED
          ) {
            return '#DEFFF3';
          } else if (status === COLLECTION_STATUS_DRAFT) {
            return '#E1E9FF';
          } else if (
            status === COLLECTION_STATUS_PENDING ||
            status === COLLECTION_STATUS_PROCESSING
          ) {
            return '#FFFCE1';
          }
        } else {
          return '#ffe1e1';
        }

        break;
      case 'text':
        if (!revoked) {
          if (
            status === TEMPLATE_STATUS_ACTIVE ||
            status === COLLECTION_STATUS_STAMPED
          ) {
            return '#2CAB6E';
          } else if (status === COLLECTION_STATUS_DRAFT) {
            return '#3969E8';
          } else if (
            status === COLLECTION_STATUS_PENDING ||
            status === COLLECTION_STATUS_PROCESSING
          ) {
            return '#E89739';
          }
        } else {
          return '#e83939';
        }
        break;
      default:
        break;
    }
  };

  function textDisplay(props) {
    if (props.templateBadge) {
      return intl.formatMessage(
        { id: 'app.templates.card.in-use' },
        { stampedCollections: props.stampedCollections }
      );
    } else if (props.templateName) {
      return props.templateName;
    }

    if (props.revoked) {
      return intl.formatMessage({ id: 'app.mydocuments.revoked' });
    }

    if (!props.stampedCollections && !props.templateName) {
      if (props.status === COLLECTION_STATUS_DRAFT) {
        return intl.formatMessage({ id: 'app.mydocuments.draft-chip' });
      } else if (
        props.status === COLLECTION_STATUS_PENDING ||
        props.status === COLLECTION_STATUS_PROCESSING
      ) {
        return intl.formatMessage({ id: 'app.mydocuments.pending' });
      } else if (props.status === COLLECTION_STATUS_STAMPED) {
        return intl.formatMessage({ id: 'app.mydocuments.stamped' });
      }
    }
  }

  function iconDisplay(status, revoked) {
    if (!revoked) {
      if (status === COLLECTION_STATUS_DRAFT) {
        return <DraftIcon />;
      } else if (
        status === COLLECTION_STATUS_PENDING ||
        status === COLLECTION_STATUS_PROCESSING
      ) {
        return <ScheduleIcon htmlColor="#E89739" fontSize="small" />;
      } else if (status === COLLECTION_STATUS_STAMPED) {
        return <StampIcon fill="#2CAB6E" />;
      } else if (status === TEMPLATE_STATUS_ACTIVE) {
        return <CustomIcon src={TemplateIcon} />;
      }
    } else {
      return <BlockIcon htmlColor="#e83939" />;
    }
  }

  return (
    <Chip
      sx={{
        background: colorSwitcher('chip', props.status, props.revoked),
        minWidth: mobile && props.gridSummary ? 'none' : '8rem',
        display: 'flex',
        justifyContent: 'center',
        '.MuiChip-label': {
          padding: '0rem 0rem 0rem 0.3rem',
          display: mobile && props.gridSummary ? 'none' : 'block',
        },
        '.MuiChip-icon': {
          margin: 0,
          padding: mobile && props.gridSummary ? '0rem 1rem' : '0rem 0.4rem',
        },
      }}
      icon={
        <div>{props.showIcon && iconDisplay(props.status, props.revoked)}</div>
      }
      label={
        <Typography
          sx={{
            color: colorSwitcher('text', props.status, props.revoked),
          }}
          variant="body2"
          children={textDisplay(props)}
        />
      }
    />
  );
}
