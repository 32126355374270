import React from 'react';
import { useIntl } from 'react-intl';

import {
  Paper,
  Grid,
  TextField,
  MenuItem,
  InputAdornment,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import { styled } from '@mui/material/styles';

import { TEMPLATE_STATUS_ACTIVE, TEMPLATE_STATUS_DRAFT } from '../constants';

const SearchPaper = styled(Paper)({
  borderRadius: '18px',
  padding: '2rem 2rem',
  marginBottom: '2rem',
});

export default function TemplateGridFilters({
  templateSupport,
  orderFilter,
  statusFilter,
  handleOrderFilterChange,
  handleStatusFilterChange,
  debouncedSearch,
}) {
  const intl = useIntl();

  return (
    <SearchPaper elevation={3}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={5}>
          <TextField
            fullWidth
            label={intl.formatMessage({
              id: 'app.templates.search-input',
            })}
            onChange={debouncedSearch}
            disabled={!templateSupport}
            InputProps={{
              startAdornment: (
                <InputAdornment>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} md={3.5}>
          <TextField
            select
            fullWidth
            id="date-select"
            label={intl.formatMessage({ id: 'app.templates.date' })}
            value={orderFilter}
            onChange={handleOrderFilterChange}
            disabled={!templateSupport}
          >
            <MenuItem
              value={false}
              children={intl.formatMessage({
                id: 'app.templates.date.asc',
              })}
            />
            <MenuItem
              value={true}
              children={intl.formatMessage({
                id: 'app.templates.date.des',
              })}
            />
          </TextField>
        </Grid>
        <Grid item xs={12} md={3.5}>
          <TextField
            select
            fullWidth
            label={intl.formatMessage({
              id: 'app.templates.status',
            })}
            value={statusFilter}
            onChange={handleStatusFilterChange}
            disabled={!templateSupport}
          >
            <MenuItem
              value={TEMPLATE_STATUS_ACTIVE}
              children={intl.formatMessage({
                id: 'app.templates.status-in-use',
              })}
            />
            <MenuItem
              value={TEMPLATE_STATUS_DRAFT}
              children={intl.formatMessage({
                id: 'app.templates.status-draft',
              })}
            />
            <MenuItem
              value={''}
              children={intl.formatMessage({
                id: 'app.templates.status-all',
              })}
            />
          </TextField>
        </Grid>
      </Grid>
    </SearchPaper>
  );
}
