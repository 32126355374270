import React, { useReducer } from 'react';

import { STATUS_OK } from '../../constants';

import CountryContext from './CountryContext';
import CountryReducer from './CountryReducer';

import { COUNTRY_GET } from './CountryTypes';

import { getCountryList } from '../../api/country';

const CountryProvider = (props) => {
  const initialState = {
    list: [],
  };

  const [state, dispatch] = useReducer(CountryReducer, initialState);

  const getList = async () => {
    try {
      const response = await getCountryList();

      if (response.status === STATUS_OK) {
        dispatch({ type: COUNTRY_GET, payload: response.data });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <CountryContext.Provider
      value={{
        list: state.list,
        getList,
      }}
    >
      {props.children}
    </CountryContext.Provider>
  );
};

export default CountryProvider;
