import React from 'react';

import MyAccountMenu from '../components/account/MyAccountMenu';
import CountryProvider from '../context/Country/CountryProvider';

import { PrivateLayout } from '../components/PrivateLayout';

import { useIntl } from 'react-intl';

export const MyAccountPage = (props) => {
  const intl = useIntl();

  document.title = intl.formatMessage({ id: 'app.page.my-account' });

  return (
    <CountryProvider>
      <PrivateLayout
        title={intl.formatMessage({ id: 'app.my-account.title' })}
        subtitle={intl.formatMessage({ id: 'app.my-account.subtitle' })}
      >
        <MyAccountMenu
          isChanged={props.isChanged}
          setIsChanged={props.setIsChanged}
        />
      </PrivateLayout>
    </CountryProvider>
  );
};
