import { useParams } from 'react-router-dom';

import CollectionsProvider from '../context/Collections/CollectionsProvider';
import DocumentsProvider from '../context/Documents/DocumentsProvider';
import SharedProvider from '../context/Shared/SharedProvider';

import { Document } from '../components/Document';
import { PrivateLayout } from '../components/PrivateLayout';

import { Box, Stack } from '@mui/material';

import { useIntl } from 'react-intl';

export const DocumentPage = () => {
  const intl = useIntl();
  document.title = intl.formatMessage({ id: 'app.page.document' });
  const { id } = useParams();
  return (
    <>
      <PrivateLayout
        title={intl.formatMessage({ id: 'app.page.document.headertitle' })}
        subtitle={intl.formatMessage({ id: 'app.page.document.subtitle' })}
      >
        <Box component="form" noValidate>
          <Stack spacing={1}>
            <CollectionsProvider>
              <DocumentsProvider>
                <SharedProvider>
                  <Document documentId={id} />
                </SharedProvider>
              </DocumentsProvider>
            </CollectionsProvider>
          </Stack>
        </Box>
      </PrivateLayout>
    </>
  );
};
