import { GET_SHARED, POST_SHARED } from './SharedTypes';

export default function SharedReducer(state, action) {
  const { payload, type } = action;

  switch (type) {
    case GET_SHARED:
      return {
        ...state,
        shared: payload,
      };
    case POST_SHARED:
      return {
        ...state,
        shared: payload,
      };
    default:
      return state;
  }
}
