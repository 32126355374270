import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { validateEmail } from '../utils/validation';
import AuthContext from '../context/Auth/AuthContext';

import { TextField, Alert, Button, Grid } from '@mui/material';
import CustomButton from './CustomButton';

import { styled } from '@mui/material/styles';

import { useIntl } from 'react-intl';

import useForgotPassword from '../hooks/useForgotPassword';
import InlineAlert from './InlineAlert';
import Loader from './Loader';

const CustomGrid = styled(Grid)({
  margin: '1rem',
});

const ButtonContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: '1rem',
  '& button': {
    width: '10rem',
    margin: '0.5rem 0rem',
  },
});

export default function UserForgotPass() {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);

  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const intl = useIntl();

  const forgot = useForgotPassword();

  useEffect(() => {
    if (authContext.loggedIn) {
      navigate(`/stamp`);
    }
  }, [authContext.loggedIn, navigate]);

  const validate = () => {
    let currentError = false;
    setError(currentError);
    let isValid = true;
    if (!validateEmail(email)) {
      currentError = true;
      isValid = false;
    }
    setError(currentError);
    return isValid;
  };

  function handleChange(event) {
    setEmail(event.target.value);
  }

  const handleSubmit = async () => {
    if (validate() && email) {
      await forgot.forgotPassword(email);
    }
    return;
  };

  const handleEnter = (e) => {
    e.key === 'Enter' && handleSubmit();
  };

  return (
    <>
      <Grid container justifyContent="center">
        <CustomGrid item xs={12} md={6}>
          {forgot.ok && (
            <Alert severity="success" sx={{ mb: 2 }}>
              {intl.formatMessage({ id: 'app.forgotpass.success' })}
            </Alert>
          )}
          {forgot.errors && (
            <InlineAlert
              title={intl.formatMessage({
                id: 'app.alerts.inline-error-title',
              })}
              severity="error"
              alertText={forgot.errors}
            />
          )}
          {!forgot.ok && (
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={handleChange}
              onKeyPress={handleEnter}
              value={email}
              error={error}
              helperText={
                error &&
                intl.formatMessage({ id: 'app.forgotpass.email-error' })
              }
            />
          )}
          {forgot.loading && <Loader margin={'6rem'} />}
          <ButtonContainer>
            {!forgot.ok && (
              <CustomButton
                variant="contained"
                onClick={handleSubmit}
                disabled={forgot.loading}
                children={intl.formatMessage({ id: 'app.forgotpass.send' })}
              />
            )}
            <Button
              variant="text"
              onClick={() => {
                navigate(`/login`);
              }}
              children={
                forgot.ok
                  ? intl.formatMessage({ id: 'app.forgotpass.back' })
                  : intl.formatMessage({ id: 'app.forgotpass.cancel' })
              }
            />
          </ButtonContainer>
        </CustomGrid>
      </Grid>
    </>
  );
}
