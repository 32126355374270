import { useContext, useState, useEffect } from 'react';

import { useNavigate, Link } from 'react-router-dom';

import {
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Typography,
  CircularProgress,
} from '@mui/material';
import CustomButton from './CustomButton';

import { styled } from '@mui/material/styles';

import AuthContext from '../context/Auth/AuthContext';
import UserContext from '../context/Users/UserContext';
import CountryContext from '../context/Country/CountryContext';

import { APP_ROUTES, COUNTRY_ARG_ID } from '../constants';
import { validateEmail } from '../utils/validation';

import InlineAlert from './InlineAlert';

import { useIntl } from 'react-intl';

const EmailSentGrid = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '1rem',
});

const InputsGrid = styled(EmailSentGrid)({});

const CountrySelect = styled(Select)({
  textAlign: 'left',
});

const ErrorsContainer = styled('div')({
  textAlign: 'left',
});

const ButtonsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  '& button': {
    margin: '0.5rem 0rem',
  },
});

export const UserRegister = ({ setShowTitle, mobile }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [countryId, setCountryId] = useState(COUNTRY_ARG_ID);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [errors, setErrors] = useState({
    email: false,
    password: false,
    confirmPassword: false,
    firstname: false,
    lastname: false,
  });

  const authContext = useContext(AuthContext);
  const userContext = useContext(UserContext);
  const countryContext = useContext(CountryContext);

  const navigate = useNavigate();
  const intl = useIntl();

  useEffect(() => {
    if (authContext.loggedIn) {
      navigate(APP_ROUTES.STAMP);
    }
  }, [authContext.loggedIn]);

  useEffect(() => {
    countryContext.getList();
  }, []);

  const handleChange = (event) => {
    switch (event.target.name) {
      case 'email':
        setEmail(event.target.value);
        break;
      case 'password':
        setPassword(event.target.value);
        break;
      case 'confirm':
        setConfirmPassword(event.target.value);
        break;
      case 'firstname':
        setFirstname(event.target.value);
        break;
      case 'lastname':
        setLastname(event.target.value);
        break;
      default:
        break;
    }
  };

  const handleChangeCountry = (event) => {
    setCountryId(parseInt(event.target.value));
  };

  const handleSubmit = () => {
    if (validate()) {
      userContext.register({
        first_name: firstname,
        last_name: lastname,
        password: password,
        email: email,
        country_id: countryId,
      });
    }
  };

  const validate = () => {
    const currentErrors = {
      email: false,
      password: false,
      confirmPassword: false,
      firstname: false,
      lastname: false,
    };
    setErrors(currentErrors);
    let isValid = true;
    if (!validateEmail(email)) {
      currentErrors.email = true;
      isValid = false;
    }
    if (password.length < 6) {
      currentErrors.password = true;
      isValid = false;
    } else if (password !== confirmPassword) {
      currentErrors.confirmPassword = true;
      isValid = false;
    }
    if (firstname.trim().length === 0) {
      currentErrors.firstname = true;
      isValid = false;
    }
    if (lastname.trim().length === 0) {
      currentErrors.lastname = true;
      isValid = false;
    }
    setErrors(currentErrors);
    return isValid;
  };

  return (
    <Grid container justifyContent="center">
      {userContext.registerSuccess ? (
        <EmailSentGrid item xs={12}>
          <Typography variant="h4" color="primary" align="center">
            {intl.formatMessage({ id: 'app.register.success' })}
          </Typography>
          <Typography variant="body1" align="center" sx={{ margin: 2 }}>
            {intl.formatMessage({ id: 'app.register.sent1' })}
            <strong>{email}</strong>
            <br />
            {intl.formatMessage({ id: 'app.register.sent2' })}
          </Typography>
          <CustomButton
            variant="contained"
            onClick={() => navigate(APP_ROUTES.LOGIN)}
            children={intl.formatMessage({
              id: 'app.register.login-button',
            })}
          />
        </EmailSentGrid>
      ) : (
        <InputsGrid item xs={12} md={6}>
          {userContext.registerFailed && (
            <ErrorsContainer>
              <InlineAlert
                title={intl.formatMessage({
                  id: 'app.alerts.inline-error-title',
                })}
                severity="error"
                alertText={userContext.errors}
              />
            </ErrorsContainer>
          )}
          <TextField
            margin="normal"
            required
            fullWidth
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={handleChange}
            value={email}
            error={errors.email}
            helperText={
              errors.email &&
              intl.formatMessage({ id: 'app.register.email-error' })
            }
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Contraseña"
            type="password"
            autoComplete="current-password"
            onChange={handleChange}
            value={password}
            error={errors.password}
            helperText={
              errors.password &&
              intl.formatMessage({ id: 'app.register.pass1-error' })
            }
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="confirm"
            label="Confirmar contraseña"
            type="password"
            onChange={handleChange}
            value={confirmPassword}
            error={errors.confirmPassword}
            helperText={
              errors.confirmPassword &&
              intl.formatMessage({ id: 'app.register.pass2-error' })
            }
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="firstname"
            label="Nombre/s"
            autoComplete="firstname"
            onChange={handleChange}
            value={firstname}
            error={errors.firstname}
            helperText={
              errors.firstname &&
              intl.formatMessage({ id: 'app.register.name-error' })
            }
            inputProps={{ maxLength: 255 }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="lastname"
            name="lastname"
            label="Apellido/s"
            autoComplete="lastname"
            onChange={handleChange}
            value={lastname}
            error={errors.lastname}
            helperText={
              errors.lastname &&
              intl.formatMessage({ id: 'app.register.last-name-error' })
            }
            inputProps={{ maxLength: 255 }}
          />
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="country">Pais</InputLabel>
            <CountrySelect
              labelId="country"
              id="country"
              value={countryId}
              label="País"
              onChange={handleChangeCountry}
            >
              {countryContext.list.map((country) => (
                <MenuItem value={country.id}>{country.description}</MenuItem>
              ))}
            </CountrySelect>
          </FormControl>
          {userContext.registerRequested && <CircularProgress sx={{ mt: 2 }} />}
          <div>
            <FormControlLabel
              sx={{ m: 2 }}
              control={
                <Checkbox
                  checked={acceptedTerms}
                  onClick={() => setAcceptedTerms(!acceptedTerms)}
                  color="primary"
                />
              }
              label={
                <span style={{ color: '#737373' }}>
                  {intl.formatMessage({ id: 'app.register.tos1' })}
                  <Link to={'/terms'} target={'_blank'}>
                    {intl.formatMessage({ id: 'app.register.tos2' })}
                  </Link>
                </span>
              }
            />
          </div>
          <ButtonsContainer>
            <CustomButton
              variant="contained"
              onClick={handleSubmit}
              disabled={!acceptedTerms || userContext.registerRequested}
              children={intl.formatMessage({
                id: 'app.register.create-button',
              })}
            />
            <Button
              onClick={() => {
                navigate(APP_ROUTES.LOGIN);
              }}
              children={intl.formatMessage({
                id: 'app.register.back-button',
              })}
            />
          </ButtonsContainer>
        </InputsGrid>
      )}
    </Grid>
  );
};
