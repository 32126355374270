import { get, post, put, doDelete } from '../api';

import {
  GET_TEMPLATE_ID_URL,
  GET_TEMPLATE_SEARCH_URL,
  TEMPLATES_URL,
  TEMPLATE_NEW_URL,
  TEMPLATE_EDIT_URL,
  TEMPLATE_DELETE_URL,
} from '../utils/url';

export const getTemplatesSearch = (
  sortDesc = true,
  filterText = '',
  filterStatus = '',
) => {
  const filters =
    '?sortDesc=' +
    sortDesc +
    '&filterText=' +
    filterText +
    '&filterStatus=' +
    filterStatus;
  return get(GET_TEMPLATE_SEARCH_URL + filters);
};

export const getTemplateById = (id) => {
  return get(GET_TEMPLATE_ID_URL + id);
};

export const getTemplatesAll = () => {
  return get(TEMPLATES_URL);
};

export const postNewTemplate = (template) => {
  return post(TEMPLATE_NEW_URL, template);
};

export const editTemplate = (id, template) => {
  return put(TEMPLATE_EDIT_URL + id, template);
};

export const deleteTemplate = (id) => {
  return doDelete(TEMPLATE_DELETE_URL + id);
};
