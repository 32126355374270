import React from 'react';

import { Grid } from '@mui/material';

import FileUploader from './FileUploader/FileUploader';

import { useIntl } from 'react-intl';

export const ValidatedFile = ({
  textProp0,
  textProp1,
  textValidated,
  textNoValidated,
  onChange,
  getUploadProgress,
}) => {
  const intl = useIntl();

  const styleContainer = { display: 'flex', alignItems: 'center' };
  return (
    <Grid container sx={styleContainer}>
      <Grid item xs={12} align={'center'}>
        <FileUploader
          maxSize={50}
          onChange={onChange}
          getUploadProgress={getUploadProgress}
          isPrivate={false}
          name={'uploader_1'}
          dragDrop={true}
          defaultText={textProp0}
          uploadedFileText={textProp1}
          textValidated={textValidated}
          textNoValidated={textNoValidated}
          cancelText={intl.formatMessage({
            id: 'app.validatedfile.uploader.cancel',
          })}
          clearText={intl.formatMessage({
            id: 'app.validatedfile.uploader.clear',
          })}
        />
      </Grid>
    </Grid>
  );
};
