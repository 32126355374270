import React, { useContext, useState } from 'react';
import FileUploader from './FileUploader/FileUploader';
import {
  ADD_EXISTING_MODES,
  FIELD_TYPE_FILE,
  FIELD_TYPE_TEXT,
  STAMP_MODE_EDIT_DRAFT,
} from '../constants';

import {
  Fade,
  TextField,
  Typography,
  IconButton,
  Paper,
  Tooltip,
  InputLabel,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { styled } from '@mui/material/styles';

import AlertsContext from '../context/Alerts/AlertsContext';
import { shouldReplaceStampCharacter } from '../utils/validation';
import { stampCharacterReplace } from '../helpers';
import { config } from '../config';

import { useIntl } from 'react-intl';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  position: 'relative',
  color: theme.palette.text.secondary,
  boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
  borderRadius: '15px',
  padding: theme.spacing(4),
  paddingLeft: theme.spacing(10),
  paddingRight: theme.spacing(10),
  '@media (max-width: 600px)': {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const Section = styled('div')({
  padding: '0.5rem 0rem',
});

const UploaderLabelContainer = styled('div')({
  display: 'flex',
  direction: 'row',
  alignItems: 'center',
  button: { marginLeft: '0.5rem' },
});

const UploaderErrorText = styled(Typography)({
  color: '#d32f2f',
  fontWeight: '400',
  fontSize: '0.75rem',
  marginLeft: '14px',
});

export default function StampDocumentCard({
  index,
  handleChangeInput,
  handleRemoveFields,
  handleOpenAddExistingModal,
  getUploadProgress,
  setInputFieldIndex,
  onChange,
  inputField,
  documentType,
  deletable,
  stampMode,
}) {
  const [stampReplacementAlerted, setStampReplacementAlerted] = useState(false);
  const [addFileEnabled, setAddFileEnabled] = useState(true);

  const intl = useIntl();
  const alertsContext = useContext(AlertsContext);

  const textValidated = {
    title: intl.formatMessage({
      id: 'app.search.validate',
    }),
  };
  const textNoValidated = {
    title: intl.formatMessage({
      id: 'app.search.novalidate',
    }),
    desc: intl.formatMessage({
      id: 'app.search.novalidate-desc',
    }),
  };

  function textValueHandler(id, e) {
    if (shouldReplaceStampCharacter(e.target.value)) {
      setStampReplacementAlerted(true);
      const text = stampCharacterReplace(e.target.value);
      handleChangeInput(id, e, text);
      if (!stampReplacementAlerted) {
        alertsContext.createWarningAlert(
          intl.formatMessage(
            { id: 'app.stamp.warning-replaced-alert' },
            { replacement: config.doubleQuoteReplacement }
          )
        );
        setStampReplacementAlerted(true);
      }
    } else {
      handleChangeInput(id, e);
    }
  }

  function handleDocumentAutoName(fileName) {
    if (!inputField.title) {
      handleChangeInput(inputField.id, null, fileName);
    }
  }

  return (
    <Fade timeout={1000} in={true}>
      <Item key={inputField.id}>
        {inputField.source_document_id && (
          <Tooltip
            title={intl.formatMessage({
              id: 'app.mydocuments.reused-document',
            })}
            placement="bottom"
            arrow
            enterDelay={600}
          >
            <FileCopyIcon
              sx={{ position: 'absolute', right: '2rem', top: '2rem' }}
              color="primary"
            />
          </Tooltip>
        )}
        <Section>
          <InputLabel
            children={intl.formatMessage({ id: 'app.stamp.title-label' })}
          />
          {inputField.validations ? (
            <div style={{ display: 'flex', padding: '1rem 0rem' }}>
              <Typography
                sx={{ color: 'rgba(0, 0, 0, 0.87)' }}
                children={inputField.title}
              />
              {inputField.validations.required === true && (
                <Typography
                  sx={{ color: 'rgba(57, 105, 232, 1)', marginLeft: '1rem' }}
                  children={intl.formatMessage({
                    id: 'app.templates.field.required',
                  })}
                />
              )}
            </div>
          ) : (
            <TextField
              name="title"
              disabled={inputField.source_document_id}
              placeholder={intl.formatMessage({
                id:
                  documentType === FIELD_TYPE_FILE
                    ? 'app.stamp.file-title-input'
                    : 'app.stamp.text-title-input',
              })}
              value={inputField.title}
              fullWidth
              onChange={(e) => handleChangeInput(inputField.id, e)}
              sx={{ mt: 2 }}
              error={inputField.error.title}
              helperText={
                inputField.error.title &&
                intl.formatMessage({ id: 'app.stamp.error-insert-title' })
              }
              inputProps={{ maxLength: config.maxLengthTitle }}
            />
          )}
        </Section>
        <Section>
          <UploaderLabelContainer>
            <InputLabel
              htmlFor="text_value"
              children={
                documentType === FIELD_TYPE_FILE
                  ? intl.formatMessage({ id: 'app.stamp.file-input' })
                  : intl.formatMessage({ id: 'app.stamp.text-input' })
              }
            />
            {!inputField.source_document_id &&
              addFileEnabled &&
              documentType === FIELD_TYPE_FILE && (
                <Tooltip
                  title={intl.formatMessage({
                    id: 'app.mydocuments.reuse-file',
                  })}
                  placement="right"
                  arrow
                  enterDelay={600}
                  children={
                    <IconButton
                      color="primary"
                      onClick={() => {
                        setInputFieldIndex(index);
                        handleOpenAddExistingModal(ADD_EXISTING_MODES.FILE);
                      }}
                      children={<ManageSearchIcon />}
                    />
                  }
                />
              )}
          </UploaderLabelContainer>
          {documentType === FIELD_TYPE_TEXT ? (
            <TextField
              name="text_value"
              id="text_value"
              disabled={inputField.source_document_id}
              fullWidth
              sx={{ mt: 2 }}
              inputProps={{ maxLength: 280 }}
              multiline
              minRows={4}
              value={inputField.text_value}
              placeholder={intl.formatMessage({
                id: 'app.stamp.text-input-max',
              })}
              error={inputField.error.content}
              helperText={
                inputField.error.content &&
                intl.formatMessage({ id: 'app.stamp.error-insert-content' })
              }
              onChange={(e) => {
                textValueHandler(inputField.id, e);
              }}
            />
          ) : (
            <>
              <FileUploader
                maxSize={50}
                onChange={(e) => onChange(inputField.id, e)}
                handleDocumentAutoName={handleDocumentAutoName}
                setAddFileEnabled={setAddFileEnabled}
                getUploadProgress={getUploadProgress}
                disableDownloadButton={true}
                disableChange={inputField.source_document_id}
                isPrivate={true}
                name="uploader_1"
                value={inputField.uploader_1}
                restoredFile={inputField.restoredFile}
                dragDrop={true}
                textValidated={textValidated}
                textNoValidated={textNoValidated}
                defaultText={intl.formatMessage({
                  id: 'app.stamp.uploader.default',
                })}
                uploadedFileText={intl.formatMessage({
                  id: 'app.stamp.uploader.success',
                })}
                cancelText={intl.formatMessage({
                  id: 'app.stamp.uploader.cancel',
                })}
                clearText={intl.formatMessage({
                  id: 'app.stamp.uploader.clear',
                })}
              />
              {inputField.error.content && (
                <UploaderErrorText
                  children={intl.formatMessage({
                    id: 'app.stamp.error-insert-file',
                  })}
                />
              )}
            </>
          )}
        </Section>
        {documentType !== FIELD_TYPE_TEXT && (
          <Section>
            <InputLabel
              children={intl.formatMessage({ id: 'app.stamp.desc-input' })}
            />
            <TextField
              name="description"
              disabled={inputField.source_document_id}
              placeholder={intl.formatMessage({
                id: 'app.stamp.desc-input-max',
              })}
              variant="outlined"
              fullWidth
              value={inputField.description}
              onChange={(e) => handleChangeInput(inputField.id, e)}
              sx={{ mt: 2 }}
              error={inputField.error.description}
              helperText={
                inputField.error.description &&
                intl.formatMessage({ id: 'app.stamp.error-insert-desc' })
              }
              inputProps={{ maxLength: 280 }}
            />
          </Section>
        )}
        {deletable === true && (
          <IconButton
            sx={{ mt: 2 }}
            onClick={() => handleRemoveFields(inputField.id)}
          >
            <DeleteIcon />
          </IconButton>
        )}
      </Item>
    </Fade>
  );
}
