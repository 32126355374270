import { get, post, patch } from '../api';

import {
  TIERS_GET_ACTIONS_URL,
  TIERS_GET_DETAILS_URL,
  TIERS_CANCEL_URL,
  TIERS_PRICING_URL,
  TIERS_PAYMENT_URL,
  TIERS_GET_PAYMENT_STATUS_URL,
  TIERS_CHANGE_AUTORENEWAL_URL,
} from '../utils/url';

export const getTierActions = () => {
  return get(TIERS_GET_ACTIONS_URL);
};

export const getTiersDetails = () => {
  return get(TIERS_GET_DETAILS_URL);
};

export const getTierPricing = (countryId) => {
  return get(TIERS_PRICING_URL + countryId);
};

export const tierPayment = (tierPriceId) => {
  return post(TIERS_PAYMENT_URL, tierPriceId);
};

export const postChangeUserTier = (tier) => {
  return post(TIERS_CANCEL_URL, tier);
};

export const patchAutoRenewal = (autoRenewal) => {
  return patch(TIERS_CHANGE_AUTORENEWAL_URL, autoRenewal);
};

export const getLastPaymentStatus = () => {
  return get(TIERS_PAYMENT_URL);
};

export const getPaymentStatus = (operationContextId) => {
  return get(TIERS_GET_PAYMENT_STATUS_URL + operationContextId);
};
