import { get, post, put } from '../api';
import { FORGOT_PASSWORD_URL } from '../utils/url';

export const postForgotPassword = (email) => {
  return post(FORGOT_PASSWORD_URL, email, false);
};

export const getForgotPassword = (hash) => {
  return get(FORGOT_PASSWORD_URL + hash, false);
};

export const postResetPassword = (reset) => {
  return put(FORGOT_PASSWORD_URL, reset, false);
};
