const BASE_URL = process.env.REACT_APP_BASE_URL;

export const GET_COLLECTIONS_URL = `${BASE_URL}/collection`;
export const GET_ID_COLLECTIONS_URL = `${BASE_URL}/collection/`;
export const GET_URL_COLLECTIONS_URL = `${BASE_URL}/collection/url/`;
export const POST_COLLECTIONS_URL = `${BASE_URL}/collection`;
export const DELETE_COLLECTION_URL = `${BASE_URL}/collection/`;

//export const GET_DOCUMENTS_URL = `${BASE_URL}/document/list`;
export const GET_ID_DOCUMENTS_URL = `/document/`;
export const GET_URL_DOCUMENTS_URL = `/document/url/`;
export const GET_MD5_DOCUMENTS_URL = `/document/checksum/`;
export const PATCH_REVOKE_DOCUMENT_URL = `/document/revoke/`;
//export const POST_DOCUMENTS_URL = `${BASE_URL}/document/create`;

export const GET_TEMPLATE_ID_URL = `${BASE_URL}/template/`;
export const GET_TEMPLATE_SEARCH_URL = `${BASE_URL}/template/find`;
export const TEMPLATES_URL = `${BASE_URL}/template/`;
export const TEMPLATE_NEW_URL = `${BASE_URL}/template/`;
export const TEMPLATE_EDIT_URL = `${BASE_URL}/template/`;
export const TEMPLATE_DELETE_URL = `${BASE_URL}/template/`;

//export const GET_SHARED_URL = `${BASE_URL}/shared/list`;
export const POST_SHARED_URL = `/shared`;

export const FILE_URL = `${BASE_URL}/file/upload`;
export const GET_FILE_URL = `${BASE_URL}/file/upload/url`;
export const POST_PUBLIC_FILE_URL = `${BASE_URL}/file/public`;
export const POST_PRIVATE_FILE_URL = `${BASE_URL}/file/private`;

export const MEDIA_URL = `${BASE_URL}/file/media`;
export const GET_MEDIA_FILE_URL = `${BASE_URL}/file/media/upload/url`;

export const GET_NOTIFICATIONS = `${BASE_URL}/notification`;
export const PUT_NOTIFICATION_MARK_AS_READ = `${BASE_URL}/notification/`;

export const POST_USERS_URL = `${BASE_URL}/user/create`;
export const USER_REGISTER_URL = `${BASE_URL}/user/register`;
export const USER_ACTIVATE_URL = `/user/activate`;
export const USER_PASSWORD_URL = `${BASE_URL}/user/password`;
export const USER_EDIT_URL = `${BASE_URL}/user/edit`;
export const USER_GET_URL = `${BASE_URL}/user`;
export const USER_POST_BRAND_URL = `${BASE_URL}/user-settings`;

export const TIERS_GET_DETAILS_URL = `${BASE_URL}/tier`;
export const TIERS_GET_ACTIONS_URL = `${BASE_URL}/tier/user-actions`;
export const TIERS_PRICING_URL = `${BASE_URL}/tier-prices/`;
export const TIERS_PAYMENT_URL = `${BASE_URL}/payment-transaction`;
export const TIERS_GET_PAYMENT_STATUS_URL = `${BASE_URL}/payment-transaction/status/`;
export const TIERS_CANCEL_URL = `${BASE_URL}/tier/cancel-tier`;
export const TIERS_CHANGE_AUTORENEWAL_URL = `${BASE_URL}/tier/change-autorenewal`

export const FORGOT_PASSWORD_URL = `${BASE_URL}/forgot-password/`;

export const AUTH_ACCESS_TOKEN_URL = `/auth/access-token`;

export const COUNTRY_LIST_URL = `${BASE_URL}/country/list`;

export const PING_URL = `${BASE_URL}/ping`;