import { getFileUploadUrl, savePrivateFile, savePublicFile } from '../api/file';

const axios = require('axios');
const instance = axios.create({});

const uploadFile = (isPrivate, file, onUploadProgress, source) => {
  return getFileUploadUrl().then((getResponse) => {
    return instance
      .put(getResponse.data.url, file, {
        headers: {
          'Content-Type': 'application/octet-stream',
        },
        cancelToken: source.token,
        onUploadProgress,
      })
      .then(() => {
        const data = {
          storage_name: getResponse.data.storage_name,
          original_name: file.name,
        };
        return isPrivate ? savePrivateFile(data) : savePublicFile(data);
      });
  });
};

const getFile = async (url, params = {}) => {
  return instance.get(url, params);
};

export { uploadFile, getFile };
