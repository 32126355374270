import { config } from '../config';

export const APP_ROUTES = {
  LOGIN: '/login',
  REGISTER: '/register',
  FORGOT: '/forgot',
  RECOVER: '/recover',
  ACTIVATE: '/activate',
  STAMP: {
    INDEX: '/stamp',
    FREE: '/stamp/free',
    TEMPLATE: '/stamp/template',
  },
  MY_DOCUMENTS: '/my-documents',
  FIND: '/find',
  TEMPLATES: '/templates',
  MY_ACCOUNT: {
    INDEX: '/my-account',
    MY_DATA: '/my-account/my-data',
    SECURITY: '/my-account/security',
    CUSTOM_BRAND: '/my-account/custom-brand',
    PLANS: '/my-account/plans',
  },
  LOGOUT: '#',
};

export const NOTIFICATION_CODES = {
  STAMP_LIMIT: 'STAMP_LIMIT',
};

export const STORAGE_USER = 'validita-user-' + config.env;
export const STATUS_OK = 200;
export const STATUS_FORBIDDEN = 403;
export const STATUS_CREATED = 201;
export const COUNTRY_ARG_ID = 11;
export const FULL_DATE_FORMAT = 'DD/MM/YYYY - HH:MM';

export const FREE_PLAN_ID = 1;
export const GLOBAL_CUSTOM_PLAN_ID = 11;

export const FIELD_TYPE_TEXT = 'TEXT';
export const FIELD_TYPE_FILE = 'FILE';

export const TEMPLATE_STATUS_ACTIVE = 'ACTIVE';
export const TEMPLATE_STATUS_INACTIVE = 'INACTIVE';
export const TEMPLATE_STATUS_DRAFT = 'DRAFT';

export const STAMP_MODE_FREE = 'FREE';
export const STAMP_MODE_TEMPLATE = 'TEMPLATE';
export const STAMP_MODE_EDIT_DRAFT = 'EDIT_DRAFT';

export const ADD_EXISTING_MODES = {
  DOCUMENT: 'DOCUMENT',
  FILE: 'FILE',
};

export const COLLECTION_STATUS_PENDING = 'PENDING';
export const COLLECTION_STATUS_PROCESSING = 'PROCESSING';
export const COLLECTION_STATUS_DRAFT = 'DRAFT';
export const COLLECTION_STATUS_INACTIVE = 'INACTIVE';
export const COLLECTION_STATUS_STAMPED = 'STAMPED';

export const REQUEST_SUCCESS = 'REQUEST_SUCCESS';
export const DELETE_SUCCESS = 'DELETE_SUCCESS';
export const REVOKE_SUCCESS = 'REVOKE_SUCCESS';
export const REQUEST_ERROR = 'ERROR';

export const EDITOR_NO_IMAGE = 'NO_IMAGE';

export const PLAN_ONE_PAY = 'ONE_TIME_PAYMENT';
export const PLAN_SUBSCRIPTION = 'SUBSCRIPTION';
export const SENT_REQUEST_CANCEL_TIER = 'SENT_REQUEST_CANCEL_TIER';

export const PLAN_SUBSCRIPTION_INITIAL = 'INITIAL';

export const statusContext = {
  PENDING: `PENDING`,
  STARTED: `STARTED`,
  FINISHED: `FINISHED`,
};

export const statusPayment = {
  REQUESTED: `REQUESTED`,
  PENDING: `PENDING`,
  PAID: `PAID`,
  REFUNDED: `REFUNDED`,
  REVERTED: `REVERTED`,
  EXPIRED: `EXPIRED`,
  REJECTED: `REJECTED`,
};

export const statusSubscription = {
  PENDING: `PENDING`,
  ACCEPTED: `ACCEPTED`,
  CANCELED: `CANCELED`,
  OVERDUE: `OVERDUE`,
  SUSPENDED: `SUSPENDED`,
  EXPIRED: `EXPIRED`,
};

export const extensions = {
  // ppt: 'ppt',
  // pptx: 'pptx',
  // xls: 'xls',
  // doc: 'doc',
  // docx: 'docx',
  // xlsx: 'xlsx',
  pdf: 'pdf',
  csv: 'csv',
  txt: 'txt',
  html: 'html',
  htm: 'htm',
  jpg: 'jpg',
  jpeg: 'jpeg',
  png: 'png',
  tiff: 'tiff',
  bmp: 'bmp',
};

export const MIMETypes = {
  // ppt: 'application/vnd.ms-powerpoint',
  // pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  // xls: 'application/vnd.ms-excel',
  // doc: 'application/msword',
  // docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  // xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  pdf: 'application/pdf',
  csv: 'text/csv',
  txt: 'text/plain',
  html: 'text/html',
  htm: 'text/htm',
  jpg: 'image/jpg',
  jpeg: 'image/jpeg',
  png: 'image/png',
  tiff: 'image/tiff',
  bmp: 'image/bmp',
};

export const pollingConfig = {
  interval: 60000,
  timeout: 15000 
}