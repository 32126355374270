import React, { useState, useEffect, useContext } from 'react';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import InfiniteScroll from 'react-infinite-scroll-component';

import NotificationCard from './NotificationCard';

import { ReactComponent as NotificationsIcon } from '../../assets/NotificationsIcon.svg';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';

import {
  IconButton,
  Badge,
  Popover,
  Slide,
  Typography,
  LinearProgress,
} from '@mui/material';

import { styled } from '@mui/material/styles';

import NotificationsContext from '../../context/Notifications/NotificationsContext';

import { FULL_DATE_FORMAT } from '../../constants';

const CustomPopover = styled(Popover)({
  '.MuiPopover-paper': {
    background: '#ffffff',
    boxShadow: '0px 3.13586px 30px rgba(92, 95, 117, 0.08)',
    borderRadius: '14px',
    width: '576px',
    maxHeight: '470px',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    '::-webkit-scrollbar': {
      display: 'none',
    },
  },
});

const NotificationsIconButton = styled(IconButton)({
  boxShadow: '0px 4px 15px #DED4F9',
  borderRadius: '9.84615px',
  margin: '0rem 2rem',
  '@media (max-width: 900px)': {
    margin: '0rem 0.5rem',
  },
});

const NoNewsPlaceholderContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '0.6rem',
  padding: '1.2rem 0',
  border: '1px dashed #7D50FF',
  borderRadius: '1rem',
  '& svg': {
    marginLeft: 10,
  },
});

const ProgressContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
});

const CustomLinearProgress = styled(LinearProgress)({
  width: '80%',
  height: '8px',
  margin: '8px',
  borderRadius: '1rem',
  backgroundColor: '#3968e86f',
  '.MuiLinearProgress-bar1Indeterminate, .MuiLinearProgress-bar2Indeterminate':
    {
      backgroundColor: '#7D50FF',
    },
});

export default function NotificationsMenu({ searchLimit }) {
  const [open, setOpen] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [notificationsList, setNotificationsList] = useState([]);

  const notificationsContext = useContext(NotificationsContext);

  const intl = useIntl();

  useEffect(() => {
    setNotificationsList(notificationsContext.notifications);
    if (
      notificationsContext.notifications.length < searchLimit &&
      notificationsContext.notifications.length !== 0
    ) {
      setHasMore(false);
    }
  }, [notificationsContext.notifications]);

  function handleClick() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  function closeNotification(index, id) {
    const newList = Array.from(notificationsList);
    newList.splice(index, 1);
    setNotificationsList(newList);
    notificationsContext.setPulled(false);
    notificationsContext.markNotificationAsRead(id);
  }

  async function fetchMoreData() {
    const fetchedNotifications = await notificationsContext.getNotifications(
      notificationsContext.offsetPage,
      searchLimit,
      true,
      null,
      null,
      false,
      true
    );
    if (
      fetchedNotifications.length === 0 ||
      fetchedNotifications.length < searchLimit
    ) {
      setHasMore(false);
    }
    const newNotificationsList = notificationsList.concat(fetchedNotifications);
    setNotificationsList(newNotificationsList);
  }

  const badgeDisplay = () => {
    if (hasMore && notificationsList.length > 0) {
      return notificationsList.length + '+';
    } else {
      return notificationsList.length;
    }
  };

  return (
    <>
      <NotificationsIconButton
        disableTouchRipple
        children={
          <Badge
            badgeContent={badgeDisplay()}
            color="secondary"
            sx={{
              '& .MuiBadge-badge': {
                border: `2px solid #ffffff`,
                padding: '0 4px',
                color: '#ffffff',
              },
            }}
          >
            <NotificationsIcon />
          </Badge>
        }
        onClick={handleClick}
      />
      <CustomPopover
        open={open}
        disableScrollLock
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={{ top: 90, left: 1500 }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        TransitionComponent={Slide}
        TransitionProps={{
          direction: 'left',
          in: open,
          mountOnEnter: true,
          unmountOnExit: true,
        }}
        elevation={0}
        PaperProps={{
          id: 'notification-menu',
        }}
      >
        <InfiniteScroll
          dataLength={notificationsList ? notificationsList.length : 0}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={
            notificationsList?.length !== 0 && (
              <ProgressContainer>
                <CustomLinearProgress variant="indeterminate" />
              </ProgressContainer>
            )
          }
          scrollableTarget={'notification-menu'}
          endMessage={
            <NoNewsPlaceholderContainer>
              <Typography
                variant="body1"
                fontWeight={400}
                children={intl.formatMessage({
                  id: 'app.notifications.no-new-notifications',
                })}
              />
              <SentimentVeryDissatisfiedIcon htmlColor="#7D50FF" />
            </NoNewsPlaceholderContainer>
          }
          style={{
            overflow: 'hidden',
          }}
        >
          {notificationsList?.length !== 0 &&
            notificationsList?.map((notification, i) => {
              return (
                <NotificationCard
                  key={i}
                  index={i}
                  id={notification.id}
                  title={notification.title.es}
                  message={notification.message.es}
                  date={dayjs(notification.created_at).format(FULL_DATE_FORMAT)}
                  code={notification.code}
                  actions={notification.action_url?.actions}
                  closeAction={closeNotification}
                />
              );
            })}
        </InfiniteScroll>
      </CustomPopover>
    </>
  );
}
