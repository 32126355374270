import { config } from '../config';
import { STORAGE_USER } from '../constants';

import Spanish from '../languages/es.json';
import { createIntl, createIntlCache } from 'react-intl';

const locale = 'es-AR';
const messages = Spanish;
const cache = createIntlCache();
const intl = createIntl({ locale, messages }, cache);

const axios = require('axios');

const instance = axios.create({
  baseURL: config.apiBaseUrl,
  headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
  validateStatus: function (status) {
    return (status >= 200 && status < 300) || status === 401 || status === 400;
  },
});

const makeResponse = (status, data) => {
  return { status: status, data: data };
};

const makeRequest = async (
  url,
  method,
  data,
  mustAuthorize,
  mustRefreshToken = true
) => {
  let user = null;
  if (mustAuthorize) {
    const storageUser = localStorage.getItem(STORAGE_USER);
    user = storageUser ? JSON.parse(storageUser) : null;
    if (!user)
      return makeResponse(401, [
        intl.formatMessage({ id: 'app.api.request-auth-error' }),
      ]);
    instance.defaults.headers.common['Authorization'] =
      'Bearer ' + user.access_token;
  }

  const request = {
    url: url,
    method: method === 'download' ? 'get' : method,
    data: data,
  };
  if (method === 'download') request.responseType = 'blob';

  return instance
    .request(request)
    .then((response) => {
      if (response.status === 401) {
        // Try refresh token
        if (mustAuthorize && mustRefreshToken) {
          instance.defaults.headers.common['Authorization'] =
            'Bearer ' + user.refresh_token;
          return makeRequest(
            'auth/refresh-token',
            'get',
            null,
            false,
            false
          ).then((response) => {
            if (response.status === 200) {
              // Store access tokens
              localStorage.setItem(STORAGE_USER, JSON.stringify(response.data));
              // Make original request again
              return makeRequest(url, method, data, mustAuthorize);
            } else {
              localStorage.removeItem(STORAGE_USER);
              return makeResponse(response.status, [
                intl.formatMessage({ id: 'app.api.request-auth-error' }),
              ]);
            }
          });
        } else {
          localStorage.removeItem(STORAGE_USER);
          // Return 401
          return makeResponse(response.status, [
            intl.formatMessage({ id: 'app.api.request-auth-error' }),
          ]);
        }
      } else {
        return makeResponse(response.status, response.data);
      }
    })
    .catch((error) => {
      console.log(error);
      return makeResponse(
        error.response ? error.response.status : 400,
        error.response
          ? error.response.data.message
          : [intl.formatMessage({ id: 'app.api.response-error' })]
      );
    });
};

export const get = (url, mustAuthorize = true) => {
  return makeRequest(url, 'get', null, mustAuthorize);
};

export const post = (url, data, mustAuthorize = true) => {
  return makeRequest(url, 'post', data, mustAuthorize);
};

export const put = (url, data, mustAuthorize = true) => {
  return makeRequest(url, 'put', data, mustAuthorize);
};

export const doDelete = (url, mustAuthorize = true) => {
  return makeRequest(url, 'delete', undefined, mustAuthorize);
};

export const download = (url, mustAuthorize = true) => {
  return makeRequest(url, 'download', null, mustAuthorize);
};

export const patch = (url, data, mustAuthorize = true) => {
  return makeRequest(url, 'patch', data, mustAuthorize);
};

export const getInstance = () => {
  return instance;
};
