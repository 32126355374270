import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import CollectionsContext from '../context/Collections/CollectionsContext';
import SharedContext from '../context/Shared/SharedContext';

import { Grid, Snackbar, Alert, Fade } from '@mui/material';

import CustomButton from './CustomButton';

import { useIntl } from 'react-intl';

export const BackButton = () => {
  const collectionsContext = useContext(CollectionsContext);
  const navigate = useNavigate();

  const intl = useIntl();

  const goBack = () => {
    navigate(`/my-documents`);
  };

  return (
    <Fade timeout={1000} in={true}>
      <Grid>
        {collectionsContext.collection && (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ mt: 4, mb: 2 }}
          >
            <Grid item sx={{ ml: 1 }}>
              <CustomButton
                variant="contained"
                onClick={() => goBack()}
                children={intl.formatMessage({ id: 'app.backbutton.back' })}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Fade>
  );
};
