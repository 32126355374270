import React, { useState } from 'react';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Grid,
  Divider,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import DocumentDetails from './DocumentDetails';
import CustomIcon from '../CustomIcon';
import ShareButton from '../ShareButton';
import CustomButton from '../CustomButton';
import ChipGrid from '../ChipGrid';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ReactComponent as EditIcon } from '../../assets/EditIcon.svg';
import { ReactComponent as SharedIcon } from '../../assets/share.svg';
import editIcon from '../../assets/edit.svg';

import dayjs from 'dayjs';
import { useIntl } from 'react-intl';

import {
  COLLECTION_STATUS_DRAFT,
  COLLECTION_STATUS_STAMPED,
  ADD_EXISTING_MODES
} from '../../constants';

const ItemTitle = styled(Typography)({
  fontWeight: '500',
  color: '333333 !important',
  wordBreak: 'break-all',
});

const SummaryGrid = styled(Grid)({
  padding: '0.5rem 1rem',
});

export default function CollectionAccordion({
  // addExistingDocument,
  addExistingType,
  id,
  name,
  url,
  url_short,
  status,
  created_at,
  documents,
  selectedDocument,
  handleEditDraft,
  handleRefresh,
  handleOpenPreview,
  setSelectedDocument,
}) {
  const [expanded, setExpanded] = useState(false);

  const intl = useIntl();
  const mobile = useMediaQuery('(max-width: 1200px)');

  const handleAccordion = (boolean) => (e, isExpanded) => {
    setExpanded(isExpanded ? boolean : false);
  };

  return (
    <Accordion
      key={id}
      expanded={expanded}
      onChange={handleAccordion(true)}
      sx={{
        marginBottom: '1rem',
        marginLeft: '1px',
        marginRight: '1px',
        border: '0px solid #cfcfcf',
        borderRadius: '4px',
        boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.12)',
        '&:before': {
          display: 'none',
        },
      }}
    >
      <AccordionSummary
        expandIcon={
          <IconButton data-testid="expand-btn">
            <KeyboardArrowDownIcon />
          </IconButton>
        }
      >
        <SummaryGrid container alignItems="center" justifyContent="center">
          <Grid item lg={4} xs={11}>
            <ItemTitle>{name}</ItemTitle>
          </Grid>
          {mobile && (
            <Grid xs={1} display="flex" justifyContent="flex-end">
              {status === COLLECTION_STATUS_DRAFT && (
                <IconButton
                  disableRipple
                  onClick={() => {
                    handleEditDraft(id);
                  }}
                >
                  <CustomIcon src={editIcon} />
                </IconButton>
              )}
            </Grid>
          )}
          <Grid item lg={2} xs={12}>
            <Typography children={dayjs(created_at).format('DD/MM/YYYY')} />
          </Grid>
          <Grid item lg={1.5} xs={6}>
            <Typography>
              {documents.length}{' '}
              {intl.formatMessage({
                id:
                  'app.mydocuments.' +
                  (documents.length === 1 ? 'file' : 'files'),
              })}
            </Typography>
          </Grid>
          <Grid
            display="flex"
            justifyContent={mobile ? 'flex-end' : 'flex-start'}
            item
            lg={2.5}
            xs={6}
          >
            <ChipGrid gridSummary status={status} showIcon={true} />
          </Grid>
          <Grid item lg={2} xs={12} display="flex" justifyContent="flex-end">
            {!mobile &&
              !addExistingType &&
              (status === COLLECTION_STATUS_STAMPED ? (
                <ShareButton
                  shareItem={{
                    id,
                    url,
                    url_short,
                    documents,
                    name,
                  }}
                  buttonIcon={<SharedIcon />}
                  buttonStyle={{
                    maxWidth: '46px',
                    maxHeight: '36px',
                    minWidth: '46px',
                    minHeight: '36px',
                  }}
                />
              ) : status === COLLECTION_STATUS_DRAFT ? (
                <CustomButton
                  icon
                  variant="contained"
                  startIcon={<EditIcon />}
                  onClick={() => {
                    handleEditDraft(id);
                  }}
                  sx={{
                    maxWidth: '46px',
                    maxHeight: '36px',
                    minWidth: '46px',
                    minHeight: '36px',
                  }}
                />
              ) : null)}
          </Grid>
        </SummaryGrid>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 0 }}>
        {documents.map((document, index) => (
          <DocumentDetails
            key={index}
            addExistingType={addExistingType}
            gridDisplay
            expanded={expanded}
            document={document}
            handleOpenPreview={handleOpenPreview}
            handleRefresh={handleRefresh}
            selectedDocument={selectedDocument}
            setSelectedDocument={setSelectedDocument}
            textValidated={{
              title: intl.formatMessage({
                id: 'app.search.validate',
              }),
            }}
            textNoValidated={{
              title: intl.formatMessage({
                id: 'app.search.novalidate',
              }),
              desc: intl.formatMessage({
                id: 'app.search.novalidate-desc',
              }),
            }}
          />
        ))}
        {status === COLLECTION_STATUS_STAMPED && !addExistingType && (
          <>
            <Divider />
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              sx={{ mt: 2, mb: 2 }}
            >
              <ShareButton
                shareItem={{
                  id,
                  url,
                  url_short,
                  documents,
                }}
                buttonIcon={<SharedIcon />}
                buttonText={intl.formatMessage({
                  id: 'app.mydocuments.doc-share',
                })}
              />
            </Grid>
          </>
        )}
      </AccordionDetails>
    </Accordion>
  );
}
