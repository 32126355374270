export const GET_TIER_DATA = 'GET_TIER_DATA';
export const GET_TIERS = 'GET_TIERS';
export const GET_TIERS_PRICES = 'GET_TIERS_PRICES';
export const LOADING_TIERS = 'LOADING_TIERS';
export const LOADING_AUTORENEWAL = 'LOADING_AUTORENEWAL';
export const TIERS_ERROR = 'TIERS_ERROR';
export const CANCEL_TIER_SUBSCRIPTION = 'CANCEL_TIER_SUBSCRIPTION';
export const CANCEL_TIER_ONE_PAY = 'CANCEL_TIER_ONE_PAY';
export const TIER_PAYMENT = 'TIER_PAYMENT';
export const GET_LAST_PAYMENT_STATUS = 'GET_LAST_PAYMENT_STATUS';
export const CHANGE_AUTORENEWAL = 'CHANGE_AUTORENEWAL';
