import { useReducer, useContext } from 'react';

import TemplatesContext from './TemplatesContext';
import TemplatesReducer from './TemplatesReducer';
import AlertsContext from '../Alerts/AlertsContext';

import {
  GET_TEMPLATE_ID,
  GET_TEMPLATES,
  GET_TEMPLATES_SEARCH,
  POST_TEMPLATE,
  EDIT_TEMPLATE,
  DELETE_TEMPLATE,
  LOADING_TEMPLATES,
  ERROR_TEMPLATES,
  STATUS_ERROR,
  STATUS_DISCONNECT_ERROR,
} from './TemplatesTypes';

import {
  getTemplatesSearch,
  getTemplateById as apiGetTemplateById,
  getTemplatesAll,
  postNewTemplate,
  editTemplate as apiEditTemplate,
  deleteTemplate as apiDeleteTemplate,
} from '../../api/templates';

import { STATUS_OK } from '../../constants';

import { useIntl } from 'react-intl';

const TemplatesProvider = (props) => {
  const initialState = {
    loading: false,
    errors: undefined,
    templates: undefined,
    newTemplate: undefined,
    editTemplate: undefined,
    templateId: undefined,
    message: '',
  };

  const intl = useIntl();
  const alertsContext = useContext(AlertsContext);

  const [state, dispatch] = useReducer(TemplatesReducer, initialState);

  const getTemplates = async (sortDesc, filterText, filterStatus) => {
    dispatch({ type: LOADING_TEMPLATES });
    try {
      const response = await getTemplatesSearch(
        sortDesc,
        filterText,
        filterStatus
      );
      if (response.status === STATUS_OK) {
        dispatch({
          type: GET_TEMPLATES_SEARCH,
          payload: response.data,
        });
      } else {
        dispatch({ type: STATUS_ERROR, payload: response.data.errors });
        alertsContext.createErrorAlert(response.data.errors);
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: ERROR_TEMPLATES,
        payload: undefined,
      });
    }
  };

  const getTemplateById = async (id) => {
    dispatch({ type: LOADING_TEMPLATES });
    try {
      const response = await apiGetTemplateById(id);
      if (response.status === STATUS_OK) {
        dispatch({ type: GET_TEMPLATE_ID, payload: response.data });
      } else {
        dispatch({ type: STATUS_ERROR, payload: response.data.errors });
        alertsContext.createWarningAlert(
          intl.formatMessage({ id: 'app.alerts.template-old' })
        );
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: ERROR_TEMPLATES,
        payload: undefined,
      });
    }
  };

  const getTemplatesList = async () => {
    dispatch({ type: LOADING_TEMPLATES });
    try {
      const response = await getTemplatesAll();
      if (response.status === STATUS_OK) {
        dispatch({ type: GET_TEMPLATES, payload: response.data });
      } else {
        dispatch({
          type: STATUS_ERROR,
          payload: response.data.errors,
        });
        alertsContext.createErrorAlert(response.data.errors);
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: ERROR_TEMPLATES,
        payload: undefined,
      });
    }
  };

  const createTemplate = async ({
    title,
    status,
    allow_add_documents,
    template_documents,
  }) => {
    dispatch({ type: LOADING_TEMPLATES });
    try {
      await postNewTemplate({
        title: title,
        status: status,
        allow_add_documents: allow_add_documents,
        template_documents: template_documents,
      })
        .then((response) => {
          if (response.status === STATUS_OK) {
            dispatch({
              type: POST_TEMPLATE,
              payload: response.data,
            });
            alertsContext.createSuccessAlert(
              intl.formatMessage({
                id: 'app.templatesprovider.alert-success-create',
              })
            );
          } else {
            dispatch({ type: STATUS_ERROR, payload: response.data.errors });
            alertsContext.createErrorAlert(response.data.errors);
          }
        })
        .catch((error) => {
          console.log(error);
          dispatch({
            type: ERROR_TEMPLATES,
            payload: error.data,
          });
          alertsContext.createErrorAlert(
            intl.formatMessage({
              id: 'app.templatesprovider.alert-error-api',
            })
          );
        });
    } catch (error) {
      dispatch({
        type: STATUS_DISCONNECT_ERROR,
        payload: error.data,
      });
      alertsContext.createErrorAlert(
        intl.formatMessage({
          id: 'app.templatesprovider.alert-error-create2',
        })
      );
    }
  };

  const putEditTemplate = async (
    id,
    { title, status, allow_add_documents, template_documents }
  ) => {
    dispatch({ type: LOADING_TEMPLATES });
    try {
      await apiEditTemplate(id, {
        title: title,
        status: status,
        allow_add_documents: allow_add_documents,
        template_documents: template_documents,
      })
        .then((response) => {
          if (response.status === STATUS_OK) {
            dispatch({ type: EDIT_TEMPLATE, payload: response.data });
            alertsContext.createSuccessAlert(
              intl.formatMessage({
                id: 'app.templatesprovider.alert-success-edit',
              })
            );
          } else {
            dispatch({ type: STATUS_ERROR, payload: response.data.errors });
            alertsContext.createErrorAlert(response.data.errors);
          }
        })
        .catch((error) => {
          console.log(error);
          dispatch({
            type: ERROR_TEMPLATES,
            payload: error.data,
          });
          alertsContext.createErrorAlert(
            intl.formatMessage({
              id: 'app.templatesprovider.alert-error-api',
            })
          );
        });
    } catch (error) {
      dispatch({
        type: STATUS_DISCONNECT_ERROR,
        payload: error.data,
      });
      alertsContext.createErrorAlert(
        intl.formatMessage({
          id: 'app.templatesprovider.alert-error-edit2',
        })
      );
    }
  };

  const deleteTemplate = async (id) => {
    dispatch({ type: LOADING_TEMPLATES });
    try {
      const response = await apiDeleteTemplate(id);
      if (response.status === STATUS_OK) {
        dispatch({ type: DELETE_TEMPLATE, message: response.data });
        alertsContext.createSuccessAlert(response.data);
      } else {
        dispatch({ type: STATUS_ERROR, payload: response.data.errors });
        alertsContext.createErrorAlert(response.data.errors);
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: ERROR_TEMPLATES,
        payload: error.data,
      });
      alertsContext.createErrorAlert(
        intl.formatMessage({
          id: 'app.templatesprovider.alert-error-delete2',
        })
      );
    }
  };

  return (
    <TemplatesContext.Provider
      value={{
        loading: state.loading,
        errors: state.errors,
        templates: state.templates,
        templatesFilters: state.templatesFilters,
        newTemplate: state.newTemplate,
        editTemplate: state.editTemplate,
        templateId: state.templateId,
        message: state.message,
        getTemplates,
        getTemplateById,
        getTemplatesList,
        createTemplate,
        putEditTemplate,
        deleteTemplate,
      }}
    >
      {props.children}
    </TemplatesContext.Provider>
  );
};

export default TemplatesProvider;
