import Spanish from '../languages/es.json';
import { createIntl, createIntlCache } from 'react-intl';

const locale = 'es-AR';
const messages = Spanish;
const cache = createIntlCache();
const intl = createIntl({ locale, messages }, cache);

const PENDING = `PENDING`;
const PROCESSING = `PROCESSING`;
const STAMPED = `STAMPED`;
const DRAFT = `DRAFT`;

export const statusName = (status) => {
  switch (status) {
    case PROCESSING:
      return intl.formatMessage({ id: 'app.stampstatus.processing' });
    case STAMPED:
      return intl.formatMessage({ id: 'app.stampstatus.stamped' });
    case DRAFT:
      return intl.formatMessage({ id: 'app.stampstatus.draft' });
    default:
      return intl.formatMessage({ id: 'app.stampstatus.pending' });
  }
};
