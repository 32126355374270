import React, { useState, useEffect, useContext, useReducer } from 'react';
import myDataReducer from '../../reducers/my-data/MyDataReducer';
import { useNavigate } from 'react-router-dom';
import { animateScroll } from 'react-scroll';

import { validateEmail } from '../../utils/validation';
import { APP_ROUTES } from '../../constants';
import {
  NAME,
  LAST_NAME,
  EMAIL,
  PHONE,
  COUNTRY,
  BIRTHDAY,
  AVATAR,
  FETCH,
} from '../../reducers/my-data/MyDataReducer';
import ImageEditor from './ImageEditor';
import ConfirmModal from '../ConfirmModal';
import BottomButtons from './BottomButtons';

import AlertsContext from '../../context/Alerts/AlertsContext';
import CountryContext from '../../context/Country/CountryContext';
import SettingsContext from '../../context/Settings/SettingsContext';

import MuiPhoneNumber from 'material-ui-phone-number-2';

import {
  Box,
  Grid,
  Typography,
  MenuItem,
  TextField,
  InputLabel,
  Paper,
  CircularProgress,
} from '@mui/material';

import { styled } from '@mui/material/styles';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import es from 'date-fns/locale/es';

import { useIntl } from 'react-intl';

const PaperCard = styled(Paper)({
  borderRadius: '1rem',
  padding: '2rem',
});

const AvatarEditorGrid = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '1rem 0rem',
  borderRadius: '1rem',
  border: '1px solid rgba(0, 0, 0, 0.2)',
});

const CustomDatePicker = styled(DatePicker)({});

export default function MyData({ isChanged, setIsChanged }) {
  const [userData, dispatch] = useReducer(myDataReducer, {
    name: '',
    lastName: '',
    email: '',
    phone: '',
    country: '',
    birthday: null,
    avatar: null,
  });

  const [emailError, setEmailError] = useState(false);

  const [avatarCropped, setAvatarCropped] = useState(null);
  const [avatarZoom, setAvatarZoom] = useState(1);
  const [avatarRotation, setAvatarRotation] = useState(0);

  const [confirmModal, setConfirmModal] = useState(false);

  const intl = useIntl();
  const navigate = useNavigate();

  const alertsContext = useContext(AlertsContext);
  const countryContext = useContext(CountryContext);
  const settingsContext = useContext(SettingsContext);

  useEffect(() => {
    async function setData() {
      if (settingsContext.user && !settingsContext.loading) {
        const avatarData = JSON.parse(settingsContext.user.avatar_meta);
        const avatarBlob = avatarData?.publicFileURI
          ? await fetch(avatarData.publicFileURI).then((r) => r.blob())
          : null;
        avatarBlob &&
          dispatch({
            type: FETCH,
            value: {
              name: settingsContext.user.first_name,
              lastName: settingsContext.user.last_name,
              email: settingsContext.user.email,
              phone: settingsContext.user.phone,
              country: settingsContext.user.country_id,
              birthday: settingsContext.user.birthday,
              avatar: new File([avatarBlob], 'Avatar.png', {
                type: 'image/png',
              }),
            },
          });
      }
    }
    setData();
  }, [settingsContext.loading, settingsContext.user]);

  const handleChangeZoom = (event) => {
    setAvatarZoom(event.target.value);
  };

  const handleRotateAvatar = (direction) => {
    setAvatarRotation(
      direction === 'right' ? avatarRotation + 90 : avatarRotation - 90
    );
  };

  const handleChangeData = (type, value) => {
    setIsChanged(true);
    dispatch({ type: type, value: value });
  };

  const handleSetAvatar = (value) => {
    handleChangeData(AVATAR, value);
  };

  const handleOpenConfirmModal = () => {
    setConfirmModal(true);
  };

  const handleCloseConfirmModal = () => {
    setConfirmModal(false);
  };

  const validate = () => {
    let isValid = true;
    if (!validateEmail(userData.email)) {
      setEmailError(true);
      isValid = false;
    } else {
      setEmailError(false);
    }
    return isValid;
  };

  async function handleSave(avatarFull, avatarCrop) {
    setIsChanged(false);
    handleCloseConfirmModal();
    await settingsContext.editUserData(
      {
        first_name: userData.name,
        last_name: userData.lastName,
        email: userData.email,
        birthday: userData.birthday,
        country_id: userData.country,
        phone: userData.phone,
      },
      avatarFull,
      avatarCrop
    );
    if (!settingsContext.error) {
      alertsContext.createSuccessAlert(
        intl.formatMessage({ id: 'app.alerts.user-info-updated' })
      );
    } else {
      alertsContext.createErrorAlert(settingsContext.error);
    }
    settingsContext.getUserData();
  }

  return (
    <>
      <ConfirmModal
        open={confirmModal}
        onClose={handleCloseConfirmModal}
        modalTitle={intl.formatMessage({
          id: 'app.my-account.my-data.confirm-modal-title',
        })}
        modalText={intl.formatMessage({
          id: 'app.my-account.my-data.confirm-modal-text',
        })}
        confirmAction={() => {
          handleSave(userData.avatar, avatarCropped);
        }}
        cancelAction={() => {
          handleCloseConfirmModal();
        }}
      />
      <Box sx={{ width: '100%' }}>
        <Box>
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="left"
            marginTop={2}
            marginBottom={2}
          >
            <Grid
              item
              md={12}
              xs={12}
              sx={{
                display: 'flex',
                alignItems: 'center',
                '& span': {
                  marginLeft: '1rem',
                },
              }}
            >
              <Typography
                variant="body2"
                children={intl.formatMessage({
                  id: 'app.my-account.my-data.general-data',
                })}
              />
              {settingsContext.loading && (
                <CircularProgress thickness={6} size="1rem" />
              )}
            </Grid>
          </Grid>
          <PaperCard elevation={6}>
            <Grid
              container
              spacing={2}
              direction="row"
              justifyContent="left"
              alignItems="center"
            >
              <Grid item lg={4} xs={12}>
                <AvatarEditorGrid item xs={12}>
                  <ImageEditor
                    image={userData.avatar}
                    imageZoom={avatarZoom}
                    imageRotation={avatarRotation}
                    loading={settingsContext.loading}
                    handleChangeZoom={handleChangeZoom}
                    handleRotateImage={handleRotateAvatar}
                    setImage={handleSetAvatar}
                    setImageCropped={setAvatarCropped}
                    setIsChanged={setIsChanged}
                    deleteCropMessage={intl.formatMessage({
                      id: 'app.my-account.my-data.delete-crop-alert',
                    })}
                    uploadPrompt={intl.formatMessage({
                      id: 'app.my-account.my-data.avatar.upload-prompt',
                    })}
                  />
                </AvatarEditorGrid>
              </Grid>
              <Grid item lg={8} xs={12}>
                <Grid container spacing={1}>
                  <Grid item lg={6} xs={12} mb={1}>
                    <InputLabel
                      htmlFor="name"
                      children={intl.formatMessage({
                        id: 'app.my-account.my-data.name',
                      })}
                    />
                    <TextField
                      fullWidth
                      id="name"
                      placeholder={
                        settingsContext.loading
                          ? intl.formatMessage({
                              id: 'app.my-account.my-data.name-placeholder',
                            })
                          : intl.formatMessage({
                              id: 'app.my-account.my-data.loading',
                            })
                      }
                      value={userData.name}
                      disabled={settingsContext.loading}
                      onChange={(e) => handleChangeData(NAME, e.target.value)}
                    />
                  </Grid>
                  <Grid item lg={6} xs={12} mb={1}>
                    <InputLabel
                      htmlFor="last-name"
                      children={intl.formatMessage({
                        id: 'app.my-account.my-data.last-name',
                      })}
                    ></InputLabel>
                    <TextField
                      id="last-name"
                      fullWidth
                      placeholder={
                        settingsContext.loading
                          ? intl.formatMessage({
                              id: 'app.my-account.my-data.last-name-placeholder',
                            })
                          : 'Cargando...'
                      }
                      value={userData.lastName}
                      disabled={settingsContext.loading}
                      onChange={(e) =>
                        handleChangeData(LAST_NAME, e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item lg={6} xs={12} mb={1}>
                    <InputLabel
                      htmlFor="email"
                      children={intl.formatMessage({
                        id: 'app.my-account.my-data.email',
                      })}
                    ></InputLabel>
                    <TextField
                      id="email"
                      fullWidth
                      placeholder={
                        settingsContext.loading
                          ? intl.formatMessage({
                              id: 'app.my-account.my-data.email-placeholder',
                            })
                          : 'Cargando...'
                      }
                      value={userData.email}
                      disabled={settingsContext.loading}
                      onChange={(e) => handleChangeData(EMAIL, e.target.value)}
                      onBlur={() => {
                        validate();
                      }}
                      error={emailError}
                      sx={{ '& p': { marginTop: '0px' } }}
                      helperText={
                        emailError && (
                          <Typography
                            sx={{ position: 'absolute' }}
                            variant="body"
                            children={intl.formatMessage({
                              id: 'app.forgotpass.email-error',
                            })}
                          />
                        )
                      }
                    />
                  </Grid>
                  <Grid item lg={6} xs={12} mb={1}>
                    <InputLabel
                      htmlFor="phone"
                      children={intl.formatMessage({
                        id: 'app.my-account.my-data.phone',
                      })}
                    />
                    {settingsContext.loading ? (
                      <TextField fullWidth disabled />
                    ) : (
                      <MuiPhoneNumber
                        id="phone"
                        fullWidth
                        placeholder={
                          settingsContext.loading
                            ? intl.formatMessage({
                                id: 'app.my-account.my-data.phone-placeholder',
                              })
                            : 'Cargando...'
                        }
                        variant="outlined"
                        defaultCountry={'us'}
                        enableareacodes="true"
                        defaultmask={'..(...).......'}
                        enablesearch="true"
                        enableLongNumbers={true}
                        value={userData.phone}
                        disabled={settingsContext.loading}
                        onChange={(e) => handleChangeData(PHONE, e)}
                      />
                    )}
                  </Grid>
                  <Grid item lg={6} xs={12} mb={1}>
                    <InputLabel
                      htmlFor="country"
                      children={intl.formatMessage({
                        id: 'app.my-account.my-data.country',
                      })}
                    />
                    <TextField
                      select
                      fullWidth
                      id="country"
                      value={userData.country}
                      disabled={settingsContext.loading}
                      onChange={(e) =>
                        handleChangeData(COUNTRY, e.target.value)
                      }
                      children={countryContext.list.map((country, index) => (
                        <MenuItem key={index} value={country.id}>
                          {country.description}
                        </MenuItem>
                      ))}
                    />
                  </Grid>
                  <Grid item lg={6} xs={12} mb={1}>
                    <InputLabel
                      htmlFor="birthday"
                      children={intl.formatMessage({
                        id: 'app.my-account.my-data.birthday',
                      })}
                    />
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      locale={es}
                    >
                      <CustomDatePicker
                        disableFuture
                        maxDate={new Date()}
                        disabled={settingsContext.loading}
                        value={userData.birthday}
                        inputFormat="dd/MM/yyyy"
                        renderInput={(props) => (
                          <TextField id="birthday" fullWidth {...props} />
                        )}
                        onChange={(e) => handleChangeData(BIRTHDAY, e)}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </PaperCard>
          <BottomButtons
            disabled={settingsContext.loading}
            confirmText={intl.formatMessage({
              id: 'app.my-account.my-data.button-save',
            })}
            confirmAction={handleOpenConfirmModal}
            cancelText={intl.formatMessage({
              id: 'app.my-account.cancel-button',
            })}
            cancelAction={() => {
              navigate(APP_ROUTES.MY_DOCUMENTS);
              animateScroll.scrollToTop();
            }}
          />
        </Box>
      </Box>
    </>
  );
}
