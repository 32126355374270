import {
  COUNTRY_GET
} from "./CountryTypes";

export default function CountryReducer(state, action) {
  const { payload, type } = action;

  switch (type) {
    case COUNTRY_GET:
      return {
        ...state,
        list: payload,
      };
    default:
      return state;
  }
};
