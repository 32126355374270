export const GET_COLLECTIONS = 'GET_COLLECTIONS';
export const GET_MORE_COLLECTIONS = 'GET_MORE_COLLECTIONS';
export const GET_ID_COLLECTIONS = 'GET_ID_COLLECTIONS';
export const GET_MD5_COLLECTIONS = 'GET_MD5_COLLECTIONS';
export const POST_COLLECTIONS = 'POST_COLLECTIONS';
export const REVOKE_DOCUMENT = 'REVOKE DOCUMENT';
export const DELETE_DRAFT = 'DELETE_DRAFT';
export const AUTOUPDATE_COLLECTIONS = 'AUTOUPDATE_COLLECTIONS';
export const FINISH_AUTOUPDATE = 'FINISH_AUTOUPDATE';
export const STATUS_ERROR = 'STATUS_ERROR';
export const STATUS_DISCONNECT_ERROR = 'STATUS_DISCONNECT_ERROR';
export const LOADING_COLLECTIONS = 'LOADING_COLLECTIONS';
export const ERROR_COLLECTIONS = 'ERROR_COLLECTIONS';
