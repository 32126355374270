import { get, post, put } from '../api';
import {
  USER_REGISTER_URL,
  USER_ACTIVATE_URL,
  USER_PASSWORD_URL,
  USER_EDIT_URL,
  USER_GET_URL,
  USER_POST_BRAND_URL,
} from '../utils/url';

export const registerUser = (user) => {
  return post(USER_REGISTER_URL, user, false);
};

export const activateUser = (hash) => {
  return post(USER_ACTIVATE_URL, { activation_hash: hash }, false);
};

export const changePassword = (newPassword) => {
  return put(USER_PASSWORD_URL, newPassword);
};

export const getUser = () => {
  return get(USER_GET_URL);
};

export const editUser = (newData) => {
  return put(USER_EDIT_URL, newData);
};

export const postCustomBrand = (data) => {
  return post(USER_POST_BRAND_URL, data);
};

export const editCustomBrand = (data) => {
  return put(USER_POST_BRAND_URL, data);
};

export const getBrand = () => {
  return get(USER_POST_BRAND_URL);
};
