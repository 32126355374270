import { PublicLayout } from '../components/PublicLayout';
import { UserLogin } from '../components/UserLogin';

import { useMediaQuery } from '@mui/material';

import { ReactComponent as WelcomeIcon } from '../assets/welcome.svg';

import { useIntl } from 'react-intl';

export const LoginPage = () => {
  const intl = useIntl();
  const mobile = useMediaQuery('(max-width: 900px');

  document.title = intl.formatMessage({ id: 'app.page.login' });

  return (
    <PublicLayout
      iconForm={<WelcomeIcon width={'32px'} height={'37px'} />}
      welcomeTitle={intl.formatMessage({ id: 'app.layout.welcome' })}
      welcomeText={intl.formatMessage({ id: 'app.layout.login-message' })}
      pageTitle={!mobile && intl.formatMessage({ id: 'app.login.title' })}
    >
      <UserLogin />
    </PublicLayout>
  );
};
