import React, { useState, useRef } from 'react';
import Button from '@mui/material/Button';

const UploaderButton = (props) => {
  const {
    disableChange,
    error,
    loading,
    success,
    restored,
    handleReset,
    handleChange,
    validFormats,
    cancelText,
    clearText,
  } = props;

  const [clearButton, setClearButton] = useState(false);
  const fileInputRef = useRef(null);

  return (
    <>
      <Button
        variant={success ? 'text' : 'contained'}
        disabled={disableChange}
        color={
          !error
            ? loading
              ? 'warning'
              : success || restored
              ? 'error'
              : 'primary'
            : 'error'
        }
        onClick={() => {
          success || loading || error || restored
            ? handleReset(fileInputRef)
            : fileInputRef.current.click();
        }}
        size="small"
        sx={{
          textDecoration: success && 'underline',
          ':hover': {
            bgcolor: (success || restored) && 'red',
            color: 'white',
          },
        }}
      >
        {!clearButton
          ? !error
            ? loading
              ? cancelText
              : success || restored
              ? clearText
              : 'Upload'
            : 'Error'
          : clearText}
      </Button>
      <input
        accept={validFormats}
        ref={fileInputRef}
        type="file"
        style={{ display: 'none' }}
        onChange={handleChange}
      />
    </>
  );
};

export default React.memo(UploaderButton);
