import { Grid, Typography, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

// import CustomIcon from './CustomIcon';
// import fileIcon from '../assets/filePDF.svg';

import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

import { FIELD_TYPE_FILE } from '../constants';

import { useIntl } from 'react-intl';

import { config } from '../config';

const BadgePaper = styled(Paper)({
  padding: '0.5rem',
});

const BlockchainText = styled(Typography)(({ theme }) => ({
  fontSize: '0.7rem',
  color: '#000',
  marginTop: '0.3rem',
  wordWrap: 'break-word',
}));

const BlockchainFileText = styled(Typography)(({ theme }) => ({
  color: '#999',
  fontSize: '0.7rem',
  wordWrap: 'break-word',
}));

const CustomGrid = styled(Grid)({
  display: 'flex',
  '@media (max-width: 380px)': {
    width: '100%',
    maxWidth: '3rem',
    height: 'auto',
  },
});

const useStyles = makeStyles({
  anchor: {
    textDecoration: 'none',
  },
});

const Badge = ({ document, showFileHash }) => {
  const intl = useIntl();

  return (
    <BadgePaper>
      <Grid
        container
        spacing={1}
        direction="row"
        sx={{
          display: 'flex',
          flexDiretion: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '3rem',
        }}
      >
        <CustomGrid item xs={2}>
          <InsertDriveFileIcon color="primary" />
        </CustomGrid>
        <Grid item xs={10}>
          {showFileHash ? (
            <>
              <BlockchainText noWrap={true}>
                {`${intl.formatMessage(
                  { id: 'app.mydocuments.doc-hash' },
                  { hashTypeLabel: config.hashTypeLabel }
                )}
                  : ${document.file_hash}`}
              </BlockchainText>
              <BlockchainFileText noWrap={true}>
                {document.file_name}
              </BlockchainFileText>
            </>
          ) : document.document_type === FIELD_TYPE_FILE ? (
            <BlockchainText>{document.file_name}</BlockchainText>
          ) : (
            <BlockchainText>{document.title}</BlockchainText>
          )}
        </Grid>
      </Grid>
    </BadgePaper>
  );
};

function BlockchainBadge({ clickeable, document }) {
  const classes = useStyles();
  return clickeable ? (
    <a className={classes.anchor} href={'#document-' + document.id}>
      <Badge document={document} showFileHash={!clickeable} />
    </a>
  ) : (
    <Badge document={document} showFileHash={!clickeable} />
  );
}

export default BlockchainBadge;
