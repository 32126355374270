import {
  STATUS_ERROR,
  STATUS_DISCONNECT_ERROR,
  GET_COLLECTIONS,
  GET_MORE_COLLECTIONS,
  GET_ID_COLLECTIONS,
  POST_COLLECTIONS,
  REVOKE_DOCUMENT,
  DELETE_DRAFT,
  ERROR_COLLECTIONS,
  LOADING_COLLECTIONS,
  AUTOUPDATE_COLLECTIONS,
  FINISH_AUTOUPDATE,
} from './CollectionsTypes';

export default function CollectionsReducer(state, action) {
  const { payload, status, type } = action;

  switch (type) {
    case GET_COLLECTIONS:
      return {
        ...state,
        loading: false,
        collections: payload,
        errors: undefined,
      };
    case GET_MORE_COLLECTIONS:
      return {
        ...state,
        loading: false,
        collections: [...state.collections, ...payload],
        errors: undefined,
      };
    case GET_ID_COLLECTIONS:
      return {
        ...state,
        loading: false,
        collection: payload,
        errors: undefined,
      };
    case POST_COLLECTIONS:
      return {
        ...state,
        postedCollection: payload,
        status: status,
        errors: undefined,
      };
    case REVOKE_DOCUMENT:
      return {
        ...state,
        revokedDocument: payload,
        loading: false,
        status: status,
      };
    case DELETE_DRAFT:
      return {
        ...state,
        loading: false,
        status: status,
      };
    case AUTOUPDATE_COLLECTIONS:
      return {
        ...state,
        collections: payload,
        loading: false,
        status: status,
      };
    case FINISH_AUTOUPDATE:
      return {
        ...state,
        isAutoUpdating: false,
      };
    case ERROR_COLLECTIONS:
      return {
        ...state,
        loading: false,
        errors: payload,
        status: status,
        collection: undefined,
        collections: undefined,
      };
    case STATUS_ERROR:
      return {
        ...state,
        status: status,
        errors: true,
        loading: false,
      };
    case STATUS_DISCONNECT_ERROR:
      return {
        ...state,
        status: status,
        errors: true,
        loading: false,
      };
    case LOADING_COLLECTIONS:
      return {
        ...state,
        collections: undefined,
        loading: true,
        errors: undefined,
      };
    default:
      return state;
  }
};
