import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Grid, Typography, Box, Modal, Paper } from '@mui/material';
import CustomButton from './CustomButton';
import { styled } from '@mui/material/styles';

import { useIntl } from 'react-intl';
import { IconSvgContainer } from './IconSvgContainer';
import { ReactComponent as GradientLockedIcon } from '../assets/GradientLockedIcon.svg';

const ModalBox = styled(Box)({
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
});

const ModalPaper = styled(Paper)({
  display: 'flex',
  padding: '2rem',
  borderRadius: '15px',
  '@media (max-width: 1200px)': {
    width: '75vw',
  },
});

const CustomGrid = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '1rem',
  '& div': {
    margin: '2rem 0rem',
  },
  '& button': {
    marginTop: '2rem',
  },
});

export default function NoAccessRedirect(props) {
  const navigate = useNavigate();

  const intl = useIntl();

  return (
    <Modal open={true}>
      <ModalBox>
        <ModalPaper>
          <Grid container justifyContent="center">
            <CustomGrid item xs={12}>
              <IconSvgContainer
                backgroundColor="#FFF"
                width={'80px'}
                height={'80px'}
                shadow="true"
                gradient="true"
                children={<GradientLockedIcon />}
              />
              <Typography
                variant="h4"
                color="primary"
                textAlign="center"
                children={props.title}
              />
              <Typography
                variant="h6"
                fontWeight="400"
                textAlign="center"
                children={props.text}
              />
              <CustomButton
                variant="contained"
                children={intl.formatMessage({ id: 'app.noaccess.button' })}
                onClick={() => {
                  navigate('/my-account/plans');
                }}
              />
            </CustomGrid>
          </Grid>
        </ModalPaper>
      </ModalBox>
    </Modal>
  );
}
