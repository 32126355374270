import React, { useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import UploadIcon from '../../../assets/upload.svg';
import ErrorIcon from '@mui/icons-material/Error';

import { Typography } from '@mui/material';

import { ReactComponent as ValidatedIcon } from '../../../assets/ValidatedIcon.svg';

import { styled } from '@mui/material/styles';

const DefaultTextContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  img: {
    margin: '5px',
  },
});

const SuccessTextContainer = styled('div')({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '0.5rem 0rem',
  svg: {
    marginRight: '0.5rem',
  },
});

const Dropzone = (props) => {
  const {
    onChange,
    onError,
    validFormats,
    maxSize,
    error,
    errorMessage,
    loading,
    success,
    restored,
    restoredFile,
    selectedFileInfo,
    uploader,
    defaultText,
    uploadedFileText,
    textValidated,
    textNoValidated,
  } = props;

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    maxSize: maxSize * 1024 * 1024,
    accept: validFormats,
    disabled: restored,

    onDropAccepted: (acceptedFiles, event) => {
      onChange(null, acceptedFiles);
    },

    onDropRejected: (event) => {
      const errorCode = event[0].errors[0].code;

      switch (errorCode) {
        case 'file-too-large':
          onError(`File is larger than ${maxSize} MB`);
          break;
        case 'file-invalid-type':
          onError(`${event[0].errors[0].message}`);
          break;
        default:
          onError(`${errorCode}`);
      }
    },
  });

  const baseStyle = useMemo(
    () => ({
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#3969E8',
      border: !success && '1.5px dashed #3969E8',
      transition: 'border .24s ease-in-out',
      borderRadius: '4px',
      padding: '20px',
      height: '4rem',
    }),
    [success]
  );

  const style = useMemo(
    () => ({
      ...baseStyle,
    }),
    [baseStyle]
  );

  const styleDrop = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  };

  const styleUploaderButton = {
    width: 'auto',
    margin: '4px',
    marginLeft: '10px',
  };

  return (
    <div success={success} {...getRootProps({ style })}>
      <div style={styleDrop}>
        {!success && <input data-testid="uploader" {...getInputProps()} />}
        <div>
          {loading || success ? (
            <Typography variant="body2" children={selectedFileInfo.name} />
          ) : restored ? (
            restoredFile.originalName
          ) : error ? (
            errorMessage
          ) : (
            <DefaultTextContainer>
              <img src={UploadIcon} alt="Upload Icon" />
              <Typography variant="body2" children={defaultText} />{' '}
            </DefaultTextContainer>
          )}
        </div>
        {(success || loading || restored || error) && (
          <div style={styleUploaderButton}>{uploader}</div>
        )}
      </div>
      {success && !loading ? (
        <SuccessTextContainer>
          {uploadedFileText?.title === textValidated.title ? (
            <ValidatedIcon />
          ) : uploadedFileText?.title === textNoValidated.title ? (
            <ErrorIcon color="error" />
          ) : null}
          <div>
            <Typography
              variant="body"
              fontWeight="500"
              children={uploadedFileText?.title}
            />
            <Typography
              variant="body2"
              fontSize="12px"
              children={uploadedFileText?.desc}
            />
          </div>
        </SuccessTextContainer>
      ) : null}
    </div>
  );
};

export default React.memo(Dropzone);
