import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import CollectionsGrid from '../my-documents/CollectionsGrid';
import BottomButtons from '../account/BottomButtons';

import {
  Modal,
  Paper,
  Typography,
  Grid,
  IconButton,
  Divider,
  useMediaQuery,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { styled } from '@mui/material/styles';

import { ADD_EXISTING_MODES } from '../../constants';

const ModalPaper = styled(Paper)({
  position: 'relative',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  background: '#ffffff',
  width: '90vw',
  height: '90vh',
  borderRadius: '18px',
});

const HeaderGrid = styled(Grid)({
  padding: '1rem',
  height: '15%',
});

const CloseButtonContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const CollectionsGridContainer = styled('div')({
  height: '65%',
  overflow: 'auto',
  padding: '1rem 1rem 0rem 1rem',
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  '::-webkit-scrollbar': {
    display: 'none',
  },
});

const BottomButtonsContainer = styled('div')({
  height: '20%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export default function AddExistingDocument({
  open,
  addExistingType,
  inputFieldIndex,
  handleCloseAddExistingModal,
  handleAddExistingDocument,
}) {
  const [selectedDocument, setSelectedDocument] = useState(undefined);

  const intl = useIntl();
  const small = useMediaQuery('(max-width: 600px)');

  const handleAdd = (type) => {
    handleAddExistingDocument(selectedDocument, inputFieldIndex, type);
    setSelectedDocument(undefined);
  };

  const handleClose = () => {
    setSelectedDocument(undefined);
    handleCloseAddExistingModal();
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        handleClose();
      }}
    >
      <ModalPaper>
        <HeaderGrid container>
          <Grid item xs={9} display="flex" alignItems="center">
            <Typography
              variant="h5"
              fontSize={!small ? '25px' : '4vw'}
              noWrap
              fontWeight="400"
              color="primary"
              children={intl.formatMessage({
                id:
                  addExistingType === ADD_EXISTING_MODES.DOCUMENT
                    ? 'app.stamp.add-existing-doc-title'
                    : 'app.stamp.add-existing-file-title',
              })}
            />
          </Grid>
          <Grid
            item
            xs={3}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
          >
            <CloseButtonContainer>
              <IconButton
                size="small"
                onClick={() => {
                  handleClose();
                }}
                children={<CloseIcon htmlColor="#7D50FF" />}
              />
            </CloseButtonContainer>
          </Grid>
        </HeaderGrid>
        <Divider />
        <CollectionsGridContainer id="add-doc-scroll">
          <CollectionsGrid
            addExistingType={addExistingType}
            selectedDocument={selectedDocument}
            setSelectedDocument={setSelectedDocument}
            // selectedFile={selectedFile}
            // setSelectedFile={setSelectedFile}
          />
        </CollectionsGridContainer>
        <Divider />
        <BottomButtonsContainer>
          <BottomButtons
            mt={0}
            mb={0}
            disabled={!selectedDocument}
            confirmText={intl.formatMessage({
              id: 'app.stamp.add-existing-doc',
            })}
            confirmAction={() => {
              handleAdd(addExistingType);
            }}
            cancelText={intl.formatMessage({
              id: 'app.stamp.cancel-existing-doc',
            })}
            cancelAction={() => {
              handleClose();
            }}
          />
        </BottomButtonsContainer>
      </ModalPaper>
    </Modal>
  );
}
