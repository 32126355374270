import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';

import AuthContext from '../context/Auth/AuthContext';

import { styled } from '@mui/material/styles';

import {
  SwipeableDrawer,
  IconButton,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
import LogoutSharpIcon from '@mui/icons-material/LogoutSharp';
import { ReactComponent as UserIcon } from '../assets/AvatarPlaceholderIcon.svg';
import { ReactComponent as StampIcon } from '../assets/StampIcon.svg';
import { ReactComponent as MyDocumentsIcon } from '../assets/MyDocumentsIcon.svg';
import { ReactComponent as SearchIcon } from '../assets/SearchIcon.svg';
import { ReactComponent as TemplatesIcon } from '../assets/template-side-menu.svg';

import { APP_ROUTES } from '../constants';

const DrawerIconButton = styled(IconButton)({
  margin: '0rem 0.5rem',
});

const CustomListItemIcon = styled(ListItemIcon)({
  minWidth: '1.5rem',
  svg: {
    fill: '#3969E8',
    padding: '0rem',
    maxWidth: '1rem',
  },
});

const PullerContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  padding: '0rem 0rem 0.6rem 0rem',
});

const Puller = styled(Box)({
  width: 30,
  height: 6,
  backgroundColor: '#858585',
  borderRadius: 3,
  bottom: 8,
  left: 'calc(50%)',
});

export default function TopDrawerMenu(props) {
  const [open, setOpen] = useState(false);

  const authContext = useContext(AuthContext);
  const intl = useIntl();
  const navigate = useNavigate();

  const menuOptions = [
    {
      name: intl.formatMessage({ id: 'app.sidemenu.profile' }),
      route: APP_ROUTES.MY_ACCOUNT.INDEX,
      icon: <UserIcon />,
    },
    {
      name: intl.formatMessage({ id: 'app.sidemenu.stamp' }),
      route: APP_ROUTES.STAMP.INDEX,
      icon: <StampIcon />,
    },
    {
      name: intl.formatMessage({ id: 'app.sidemenu.my-documents' }),
      route: APP_ROUTES.MY_DOCUMENTS,
      icon: <MyDocumentsIcon />,
    },
    {
      name: intl.formatMessage({ id: 'app.sidemenu.search' }),
      route: APP_ROUTES.FIND,
      icon: <SearchIcon />,
    },
    {
      name: intl.formatMessage({ id: 'app.sidemenu.templates' }),
      route: APP_ROUTES.TEMPLATES,
      icon: <TemplatesIcon />,
    },
    {
      name: intl.formatMessage({ id: 'app.sidemenu.logout' }),
      route: APP_ROUTES.LOGOUT,
      icon: <LogoutSharpIcon />,
    },
  ];

  const toggleDrawer = (boolean) => () => {
    setOpen(boolean);
  };

  function handleCloseDrawer(location) {
    if (location) {
      if (location === APP_ROUTES.LOGOUT) {
        authContext.logout();
      }
      location && navigate(location);
    }
    setOpen(false);
  }

  return (
    <>
      <DrawerIconButton size="medium" onClick={toggleDrawer(true)}>
        <MenuIcon htmlColor="#7D50FF" fontSize="medium" />
      </DrawerIconButton>
      <SwipeableDrawer
        open={open}
        anchor="top"
        onOpen={toggleDrawer(true)}
        onClose={toggleDrawer(false)}
        transitionDuration={110}
        disableSwipeToOpen={true}
      >
        <nav>
          <List>
            {menuOptions.map((item, index) => (
              <ListItem disablePadding divider key={index}>
                <ListItemButton
                  onClick={() => {
                    handleCloseDrawer(item.route);
                  }}
                >
                  <CustomListItemIcon children={item.icon} />
                  <ListItemText
                    primaryTypographyProps={{ noWrap: false }}
                    primary={item.name}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </nav>
        <PullerContainer>
          <Puller />
        </PullerContainer>
      </SwipeableDrawer>
    </>
  );
}
