import React, { useReducer, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  PLAN_ONE_PAY,
  PLAN_SUBSCRIPTION_INITIAL,
  STATUS_OK,
  SENT_REQUEST_CANCEL_TIER,
} from '../../constants';

import TiersContext from './TiersContext';
import TiersReducer from './TiersReducer';
import AlertsContext from '../Alerts/AlertsContext';

import {
  GET_TIER_DATA,
  GET_TIERS,
  GET_TIERS_PRICES,
  LOADING_TIERS,
  TIERS_ERROR,
  CANCEL_TIER_SUBSCRIPTION,
  CANCEL_TIER_ONE_PAY,
  TIER_PAYMENT,
  GET_LAST_PAYMENT_STATUS,
  CHANGE_AUTORENEWAL,
  LOADING_AUTORENEWAL,
} from './TiersTypes';

import {
  getTierActions,
  getTiersDetails,
  getTierPricing,
  postChangeUserTier,
  tierPayment,
  getLastPaymentStatus as apiGetLastPaymentStatus,
  getPaymentStatus,
  patchAutoRenewal,
} from '../../api/tiers';
import { useIntl } from 'react-intl';

export default function TiersProvider(props) {
  const initialState = {
    tiersData: {
      tiers: [],
      prices: [],
    },
    userTier: {
      tierId: undefined,
      documentStamp: undefined,
      collectionStamp: undefined,
      whiteLineSupport: undefined,
      sdkSupport: undefined,
      templateSupport: undefined,
      paymentType: undefined,
    },
    paymentData: {
      lastOperationStatus: undefined,
      lastOperationUrl: undefined,
      lastOperationNumber: undefined,
      lastTransactionStatus: undefined,
    },
    autoRenewal: undefined,
    loading: false,
    loadingAutoRenewal: false,
    errors: undefined,
  };

  const navigate = useNavigate();

  const [state, dispatch] = useReducer(TiersReducer, initialState);

  const alertsContext = useContext(AlertsContext);
  const intl = useIntl();

  async function getTierState() {
    dispatch({ type: LOADING_TIERS });
    try {
      const response = await getTierActions();
      if (response.status === STATUS_OK) {
        dispatch({ type: GET_TIER_DATA, payload: response.data });
      } else {
        dispatch({
          type: TIERS_ERROR,
          payload: response.data,
        });
        alertsContext.createErrorAlert(
          intl.formatMessage({ id: 'app.alerts.no-user-tier' })
        );
      }
    } catch (error) {
      dispatch({
        type: TIERS_ERROR,
      });
      alertsContext.createErrorAlert(error);
    }
  }

  async function getTiers() {
    dispatch({ type: LOADING_TIERS });
    try {
      const response = await getTiersDetails();
      if (response.status === STATUS_OK) {
        dispatch({ type: GET_TIERS, payload: response.data });
      } else {
        dispatch({
          type: TIERS_ERROR,
          payload: response.data,
        });
        alertsContext.createErrorAlert(
          intl.formatMessage({ id: 'app.alerts.no-user-tier' })
        );
      }
    } catch (error) {
      dispatch({
        type: TIERS_ERROR,
      });
      alertsContext.createErrorAlert(error);
    }
  }

  async function getPrices(id) {
    dispatch({ type: LOADING_TIERS });
    try {
      const response = await getTierPricing(id);
      if (response.status === STATUS_OK) {
        dispatch({ type: GET_TIERS_PRICES, payload: response.data });
      } else {
        dispatch({
          type: TIERS_ERROR,
          payload: response.data,
        });
        alertsContext.createErrorAlert(
          intl.formatMessage({ id: 'app.alerts.no-user-tier' })
        );
      }
    } catch (error) {
      dispatch({
        type: TIERS_ERROR,
      });
      alertsContext.createErrorAlert(error);
    }
  }

  async function changeFreeTier(tier) {
    dispatch({ type: LOADING_TIERS });
    try {
      const response = await postChangeUserTier(tier);
      if (response.status === STATUS_OK) {
        if (response.data.code === SENT_REQUEST_CANCEL_TIER) {
          dispatch({ type: CANCEL_TIER_ONE_PAY });
          alertsContext.createSuccessAlert(response.data.message);
        } else {
          dispatch({ type: CANCEL_TIER_SUBSCRIPTION, payload: response.data });
          alertsContext.createSuccessAlert(
            intl.formatMessage({
              id: 'app.my-account.plans.cancel-success-alert',
            })
          );
        }
      } else {
        dispatch({
          type: TIERS_ERROR,
          payload: response.data,
        });
        alertsContext.createErrorAlert(
          response.data.errors
            ? response.data.errors
            : intl.formatMessage({ id: 'app.api.response-error' })
        );
      }
    } catch (error) {
      dispatch({
        type: TIERS_ERROR,
      });
      alertsContext.createErrorAlert(error);
    }
  }

  async function changeAutoRenewal(autoRenewal) {
    dispatch({ type: LOADING_AUTORENEWAL });
    try {
      const response = await patchAutoRenewal(autoRenewal);
      if (response.status === STATUS_OK) {
        dispatch({
          type: CHANGE_AUTORENEWAL,
          payload: response.data,
        });
      }
    } catch (error) {
      dispatch({
        type: TIERS_ERROR,
      });
      alertsContext.createErrorAlert(error);
    }
  }

  async function paymentTransaction(tierPriceId) {
    dispatch({ type: LOADING_TIERS });
    try {
      const response = await tierPayment(tierPriceId);
      if (response.status === STATUS_OK) {
        dispatch({
          type: TIER_PAYMENT,
          // payload: response.data,
        });
      }
      alertsContext.createSuccessAlert(
        intl.formatMessage({ id: 'app.my-account.plans.alert-tier-email-sent' })
      );
      navigate('/');
      // return response.data.url_to_redirect;
    } catch (error) {
      dispatch({
        type: TIERS_ERROR,
      });
      alertsContext.createErrorAlert(error);
    }
  }

  async function getLastPaymentStatus(autoUpdate) {
    if (!autoUpdate) {
      dispatch({ type: LOADING_TIERS });
    }
    let payload = {};
    try {
      const statusResponse = await apiGetLastPaymentStatus();
      if (statusResponse.status === STATUS_OK) {
        const paymentsResponse = await getPaymentStatus(
          statusResponse.data.operation_context_id
        );
        const payment = paymentsResponse.data.payments?.find(
          (p) => p.source === PLAN_ONE_PAY || PLAN_SUBSCRIPTION_INITIAL
        );
        payload['operation_context_id'] =
          statusResponse.data.operation_context_id;
        payload['status'] = payment
          ? payment.status
          : statusResponse.data.status;
        payload['url_to_continue_flow'] =
          paymentsResponse.data.url_to_continue_flow;
        payload['transaction_status'] = statusResponse.data.status;
        dispatch({ type: GET_LAST_PAYMENT_STATUS, payload: payload });
      } else {
        dispatch({ type: TIERS_ERROR, payload: statusResponse.data });
      }
    } catch (error) {
      dispatch({
        type: TIERS_ERROR,
      });
      alertsContext.createErrorAlert(error);
    }
  }

  return (
    <TiersContext.Provider
      value={{
        tiersData: state.tiersData,
        tierId: state.tierId,
        userTier: state.userTier,
        paymentData: state.paymentData,
        autoRenewal: state.autoRenewal,
        loading: state.loading,
        loadingAutoRenewal: state.loadingAutoRenewal,
        errors: state.errors,
        getTierState,
        getTiers,
        getPrices,
        changeFreeTier,
        changeAutoRenewal,
        paymentTransaction,
        getLastPaymentStatus,
      }}
    >
      {props.children}
    </TiersContext.Provider>
  );
}
