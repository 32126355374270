import { useState } from "react";
import { get } from "../api";

const useDocument = () => {

  const [downloadUrl, setDownloadUrl] = useState(undefined);

  async function fetchDownloadUrl(url) {
    const response = await get(url, false);
    setDownloadUrl(response.data.url);
  }

  return {
    downloadUrl,
    fetchDownloadUrl,
  }

}

export default useDocument;
