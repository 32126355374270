import React from 'react';

const CustomIcon = (props) => {
  return (
    <img
      src={props.src}
      alt={props.alt}
      height={props.height}
      width={props.width}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: !props.noMargin && '8px',
      }}
    />
  );
};

export default CustomIcon;
