import { get, post, doDelete, patch } from '../api';

import {
  GET_COLLECTIONS_URL,
  POST_COLLECTIONS_URL,
  DELETE_COLLECTION_URL,
  GET_ID_COLLECTIONS_URL,
  GET_URL_COLLECTIONS_URL,
  PATCH_REVOKE_DOCUMENT_URL,
} from '../utils/url';

export const getCollectionList = (
  offset = 0,
  limit = 1000,
  sortDesc = true,
  filterText = '',
  filterTemplateId = '',
  filterStatus = ''
) => {
  const filters =
    '?offset=' +
    offset +
    '&limit=' +
    limit +
    '&sortDesc=' +
    sortDesc +
    (filterText && '&filterText=' + filterText) +
    (filterTemplateId && '&filterTemplateId=' + filterTemplateId) +
    (filterStatus && '&filterStatus=' + filterStatus);
  return get(GET_COLLECTIONS_URL + filters);
};

export const createCollection = (collection) => {
  return post(POST_COLLECTIONS_URL, collection);
};

export const getCollectionById = (id) => {
  return get(GET_ID_COLLECTIONS_URL + id);
};

export const getCollectionByUrl = (hash) => {
  return get(GET_URL_COLLECTIONS_URL + hash, false);
};

export const patchRevokeDocument = (id) => {
  return patch(PATCH_REVOKE_DOCUMENT_URL + id);
};

export const deleteDraftCollection = (id) => {
  return doDelete(DELETE_COLLECTION_URL + id);
};
