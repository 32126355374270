import { useState, useContext } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { styled, alpha } from '@mui/material/styles';
import ContentCopy from '@mui/icons-material/ContentCopy';
import Link from '@mui/icons-material/Link';
import QrCode from '@mui/icons-material/QrCode';
import CustomButton from './CustomButton';
import SharedContext from '../context/Shared/SharedContext';

import {
  Typography,
  Alert,
  Divider,
  Menu,
  MenuItem,
  Snackbar,
} from '@mui/material';

import { SharedQR } from './SharedQR';

import { useIntl } from 'react-intl';

import { sanitizeSpecialCharacters } from '../helpers';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const ButtonText = styled(Typography)({
  margin: '0rem 1rem',
});

export default function ShareButton({
  shareItem,
  buttonIcon,
  buttonText = '',
  buttonStyle = null,
  customColor,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [showClipboardSnack, setShowClipboardSnack] = useState(false);

  const sharedContext = useContext(SharedContext);

  const intl = useIntl();

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };
  const handleClose = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setShowClipboardSnack(false);
    setAnchorEl(null);
    setShowClipboardSnack(false);
  };

  const handleShare = async (e, shareItem) => {
    e.preventDefault();
    e.stopPropagation();
    if (shareItem.documents) {
      sharedContext.postShared(shareItem.id, 'Collections');
    } else {
      sharedContext.postShared(shareItem.id, 'Documents');
    }
    handleClose(e);
    setShowClipboardSnack(true);
  };

  const handleLink = (e, url) => {
    e.preventDefault();
    e.stopPropagation();
    window.open(url, '_blank', 'noopener');
    handleClose(e);
  };

  const downloadQR = () => {
    let filenameQR;
    if (shareItem.documents) {
      filenameQR =
        shareItem.documents.length > 1
          ? sanitizeSpecialCharacters(`${shareItem.name}`)
          : sanitizeSpecialCharacters(`${shareItem.documents[0].title}`);
    } else {
      filenameQR = shareItem.title;
    }
    let canvas = document.getElementById(shareItem.url_short || shareItem.url);
    let pngUrl = canvas
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream');
    let downloadLink = document.createElement('a');
    downloadLink.href = pngUrl;
    downloadLink.download = `${filenameQR}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const handleQr = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    downloadQR();
    handleClose(e);
  };

  return (
    <>
      <CustomButton
        variant="contained"
        backgroundcolor={customColor}
        sx={buttonStyle && buttonStyle}
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        startIcon={buttonIcon}
        children={buttonText}
      />
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <CopyToClipboard text={shareItem.url_short || shareItem.url}>
          <MenuItem onClick={(e) => handleShare(e, shareItem)} disableRipple>
            <ContentCopy />
            {intl.formatMessage({ id: 'app.share-button.copy' })}
          </MenuItem>
        </CopyToClipboard>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem
          onClick={(e) => handleLink(e, shareItem.url_short || shareItem.url)}
          disableRipple
        >
          <Link />
          {intl.formatMessage({ id: 'app.share-button.open' })}
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem onClick={(e) => handleQr(e)} disableRipple>
          <QrCode />
          {intl.formatMessage({ id: 'app.share-button.qr' })}
        </MenuItem>
      </StyledMenu>
      <Snackbar
        open={showClipboardSnack}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          {intl.formatMessage({ id: 'app.share-button.success-copy' })}
        </Alert>
      </Snackbar>
      <SharedQR
        id={shareItem.url_short || shareItem.url}
        link={shareItem.url_short || shareItem.url}
        name={shareItem.url_short || shareItem.url}
        srcLogo="logoValidita_fondoPositivo.png"
      />
    </>
  );
}
