import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import {
  Paper,
  TextField,
  Typography,
  Checkbox,
  IconButton,
  Grid,
  FormGroup,
  FormControl,
  FormControlLabel,
  InputLabel,
  InputAdornment,
  Select,
  MenuItem,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import RefreshIcon from '@mui/icons-material/Refresh';

import TemplatesContext from '../../context/Templates/TemplatesContext';

import {
  COLLECTION_STATUS_PROCESSING,
  COLLECTION_STATUS_DRAFT,
  COLLECTION_STATUS_STAMPED,
  ADD_EXISTING_MODES,
} from '../../constants';

import { SORT, TEMPLATE, STATUS, GROUP, REFRESH } from '../../reducers/my-documents/FiltersReducer';

const FiltersPaper = styled(Paper)({
  flexDirection: 'row',
  textAlign: 'center',
  padding: '1rem 1rem',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'theme.palette.text.secondary',
  borderRadius: '18px',
  boxShadow: 'box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1)',
  '@media (max-width: 900px)': {
    flexDirection: 'column',
  },
});

const CustomSelect = styled(Select)({
  textAlign: 'left',
});

const GroupGrid = styled(Grid)({
  display: 'flex',
  justifyContent: 'flex-end',
  margin: '0.5rem 0rem',
  '@media (max-width: 900px)': {
    justifyContent: 'center',
  },
});

export default function CollectionsGridFilters({
  addExistingType,
  sortDesc,
  filterTemplateId,
  filterStatus,
  groupByCollections,
  debouncedSearch,
  handleChangeFilters,
}) {
  const intl = useIntl();
  const templatesContext = useContext(TemplatesContext);

  return (
    <>
      {!templatesContext.loading && (
        <>
          <FiltersPaper elevation={1}>
            <Grid container spacing={2} alignItems="center">
              <Grid item md={addExistingType ? 5.5 : 2.75} xs={12}>
                <TextField
                  fullWidth
                  label={intl.formatMessage({
                    id: 'app.mydocuments.search-input',
                  })}
                  onChange={debouncedSearch}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              {!addExistingType && (
                <>
                  <Grid item md={2.75} xs={12}>
                    <FormControl fullWidth>
                      <InputLabel
                        children={intl.formatMessage({
                          id: 'app.mydocuments.date-select',
                        })}
                      />
                      <CustomSelect
                        fullWidth
                        label={intl.formatMessage({
                          id: 'app.mydocuments.date-select',
                        })}
                        onChange={(e) =>
                          handleChangeFilters(SORT, e.target.value === 0)
                        }
                        IconComponent={KeyboardArrowDownIcon}
                        value={sortDesc ? 0 : 1}
                      >
                        <MenuItem value={1}>
                          {intl.formatMessage({
                            id: 'app.mydocuments.date-asc',
                          })}
                        </MenuItem>
                        <MenuItem value={0}>
                          {intl.formatMessage({
                            id: 'app.mydocuments.date-des',
                          })}
                        </MenuItem>
                      </CustomSelect>
                    </FormControl>
                  </Grid>
                  <Grid item md={2.75} xs={12}>
                    <FormControl fullWidth>
                      <InputLabel
                        children={intl.formatMessage({
                          id: 'app.mydocuments.template-select',
                        })}
                      />
                      <CustomSelect
                        fullWidth
                        label={intl.formatMessage({
                          id: 'app.mydocuments.template-select',
                        })}
                        onChange={(e) =>
                          handleChangeFilters(TEMPLATE, e.target.value)
                        }
                        IconComponent={KeyboardArrowDownIcon}
                        value={filterTemplateId}
                      >
                        <MenuItem value="">
                          {intl.formatMessage({
                            id: 'app.mydocuments.template-select-all',
                          })}
                        </MenuItem>
                        {templatesContext.templates?.map((template, index) => (
                          <MenuItem
                            value={template.id}
                            key={index}
                            index={index}
                          >
                            {template.title}
                          </MenuItem>
                        ))}
                      </CustomSelect>
                    </FormControl>
                  </Grid>
                </>
              )}
              <Grid item md={addExistingType ? 5.5 : 2.75} xs={12}>
                <FormControl fullWidth>
                  <InputLabel
                    children={intl.formatMessage({
                      id: 'app.mydocuments.status-select',
                    })}
                  />
                  <CustomSelect
                    fullWidth
                    label={intl.formatMessage({
                      id: 'app.mydocuments.status-select',
                    })}
                    onChange={(e) =>
                      handleChangeFilters(STATUS, e.target.value)
                    }
                    IconComponent={KeyboardArrowDownIcon}
                    value={filterStatus}
                  >
                    <MenuItem
                      value=""
                      children={intl.formatMessage({
                        id: 'app.mydocuments.status-select-all',
                      })}
                    />
                    <MenuItem
                      value={COLLECTION_STATUS_DRAFT}
                      children={intl.formatMessage({
                        id: 'app.mydocuments.draft-chip',
                      })}
                    />
                    <MenuItem
                      value={COLLECTION_STATUS_PROCESSING}
                      children={intl.formatMessage({
                        id: 'app.mydocuments.pending',
                      })}
                    />
                    <MenuItem
                      value={COLLECTION_STATUS_STAMPED}
                      children={intl.formatMessage({
                        id: 'app.mydocuments.stamped',
                      })}
                    />
                  </CustomSelect>
                </FormControl>
              </Grid>
              <Grid item md={1} xs={12}>
                <IconButton
                  size="large"
                  variant="contained"
                  color="primary"
                  children={<RefreshIcon />}
                  onClick={() => handleChangeFilters(REFRESH)}
                />
              </Grid>
            </Grid>
          </FiltersPaper>
          <Grid container>
            <GroupGrid item xs={12}>
              {addExistingType !== ADD_EXISTING_MODES.FILE && (
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox />}
                    label={
                      <Typography
                        variant="body2"
                        children={intl.formatMessage({
                          id: 'app.mydocuments.group',
                        })}
                      />
                    }
                    checked={groupByCollections}
                    onChange={(e) =>
                      handleChangeFilters(GROUP, e.target.checked)
                    }
                  />
                </FormGroup>
              )}
            </GroupGrid>
          </Grid>
        </>
      )}
    </>
  );
}
