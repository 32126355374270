import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { styled } from '@mui/material/styles';

import { Grid, Fade } from '@mui/material';
import CustomButton from './CustomButton';

import DocumentsContext from '../context/Documents/DocumentsContext';
import DocumentCard from '../components/kontroli/DocumentCard';
import InlineAlert from './InlineAlert';
import Loader from './Loader';

import { useIntl } from 'react-intl';

const DocumentContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: 'auto',
  paddingBottom: '1rem',
});

export const Document = ({ documentId }) => {
  const documentsContext = useContext(DocumentsContext);

  const intl = useIntl();

  const navigate = useNavigate();

  useEffect(() => {
    async function fetchDocument() {
      await documentsContext.getDocumentById(documentId);
    }
    fetchDocument();
  }, []);

  const goBack = () => {
    navigate('/my-documents');
  };

  if (documentsContext.loading) {
    return <Loader margin={'6rem'} />;
  }

  return (
    <Fade timeout={500} in={true}>
      <DocumentContainer>
        {documentsContext.errors && (
          <InlineAlert
            title={intl.formatMessage({ id: 'app.alerts.inline-error-title' })}
            severity="error"
            alertText={documentsContext.errors}
          />
        )}
        {documentsContext.document && (
          <DocumentCard
            document={documentsContext.document}
            validateDialog={false}
            isShareable={true}
            textValidated={{
              title: intl.formatMessage({
                id: 'app.search.validate',
              }),
            }}
            textNoValidated={{
              title: intl.formatMessage({
                id: 'app.search.novalidate',
              }),
              desc: intl.formatMessage({
                id: 'app.search.novalidate-desc',
              }),
            }}
          />
        )}
        {(documentsContext.errors || documentsContext.document) && (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ mt: 2 }}
          >
            <CustomButton
              variant="contained"
              onClick={() => goBack()}
              children={intl.formatMessage({
                id: 'app.collection.back-button',
              })}
            />
          </Grid>
        )}
      </DocumentContainer>
    </Fade>
  );
};
