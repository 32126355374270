import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useCallbackPrompt } from '../hooks/useCallbackPrompt';
import TemplatesNewFieldModal from './TemplatesNewFieldModal';
import TemplateCreatedField from './TemplateCreatedField';
import Loader from './Loader';
import ConfirmModal from './ConfirmModal';
import BottomButtons from './account/BottomButtons';
import ChipGrid from './ChipGrid';

import {
  APP_ROUTES,
  TEMPLATE_STATUS_ACTIVE,
  TEMPLATE_STATUS_DRAFT,
} from '../constants';

import TemplatesContext from '../context/Templates/TemplatesContext';

import { styled } from '@mui/material/styles';

import { useIntl } from 'react-intl';

import {
  Box,
  Button,
  Grid,
  TextField,
  Paper,
  InputLabel,
  Fade,
  Typography,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Checkbox,
  Divider,
} from '@mui/material';

import AddCircleIcon from '@mui/icons-material/AddCircle';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { config } from '../config';

const TemplateContainer = styled(Paper)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  borderRadius: '18px',
  padding: '2rem 2rem',
});

const TitleGridContainer = styled(Grid)({
  padding: '0.5rem',
});

const AddFieldButtonGrid = styled(Grid)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const OptionsGrid1 = styled(Grid)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  '@media (max-width: 900px)': {
    justifyContent: 'center',
    flexDirection: 'column',
  },
});

const OptionsGrid2 = styled(OptionsGrid1)({
  justifyContent: 'flex-end',
});

const FieldButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#FFFFFF',
  margin: '1rem 0rem',
  boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.2)',
  p: {
    color: theme.palette.primary.main,
  },
  '&:hover': {
    backgroundColor: '#f0f0f0',
  },
}));

const AddIcon = styled(AddCircleIcon)({
  fontSize: '30px',
  marginRight: '0.5rem',
  color: 'rgba(125,80,255,1)',
});

const PATH_TEMPLATES = 'templates';

export default function TemplatesEditor(props) {
  const [isChanged, setIsChanged] = useState(false);
  const [fieldModal, setFieldModal] = useState(false);
  const [editField, setEditField] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const [title, setTitle] = useState('');
  const [fields, setFields] = useState([]);
  const [status, setStatus] = useState(TEMPLATE_STATUS_ACTIVE);
  const [allowAddDocuments, setAllowAddDocuments] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const [loading, setLoading] = useState(false);

  const templatesContext = useContext(TemplatesContext);
  const intl = useIntl();
  const navigate = useNavigate();
  const { id } = useParams();

  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(isChanged);

  useEffect(() => {
    if (props.edit) {
      templatesContext.getTemplateById(id);
    }
  }, [id, props.edit]);

  useEffect(() => {
    if (templatesContext.editTemplate) {
      setTitle(templatesContext.editTemplate.title);
      setFields(templatesContext.editTemplate.template_documents);
      setStatus(templatesContext.editTemplate.status);
      setAllowAddDocuments(templatesContext.editTemplate.allow_add_documents);
    }
  }, [templatesContext.editTemplate]);

  function reorderFields(fields, startIndex, endIndex) {
    const result = Array.from(fields);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    result.forEach((field, index) => {
      field.order_index = index + 1;
    });
    return result;
  }

  function onDragEnd(result) {
    setIsChanged(true);
    if (!result.destination) {
      return;
    }
    const items = reorderFields(
      fields,
      result.source.index,
      result.destination.index
    );
    setFields(items);
  }

  function handleOpenFieldModal() {
    setFieldModal(true);
  }

  function handleCloseFieldModal() {
    setEditField(false);
    setFieldModal(false);
  }

  function handleOpenEditModal(index) {
    setEditField(true);
    setEditIndex(index);
    setFieldModal(true);
  }

  function handleOpenConfirmModal() {
    title !== '' ? setConfirmModal(true) : setTitleError(true);
  }

  function handleCloseConfirmModal() {
    setConfirmModal(false);
  }

  function handleChangeTitle(event) {
    setIsChanged(true);
    setTitleError(false);
    setTitle(event.target.value);
  }

  function handleCreateField(newField) {
    setIsChanged(true);
    fields.push(newField);
  }

  function handleEditField(index, newField) {
    setIsChanged(true);
    fields.splice(index, 1, newField);
  }

  async function handleDeleteField(prevFields, index) {
    const newFields = Array.from(prevFields);
    newFields.splice(index, 1);
    await setFields(newFields);
  }

  function handleCheckAllowAddDocuments(event) {
    setIsChanged(true);
    setAllowAddDocuments(event.target.checked);
  }

  function handleCheckSetStatus(event) {
    setIsChanged(true);
    setStatus(event.target.value);
  }

  async function handleSubmit() {
    setIsChanged(false);
    handleCloseConfirmModal();
    setLoading(true);
    if (title !== '') {
      if (props.edit) {
        await templatesContext.putEditTemplate(id, {
          title: title,
          status: status,
          allow_add_documents: allowAddDocuments,
          template_documents: fields,
        });
      } else {
        await templatesContext.createTemplate({
          title: title,
          status: status,
          allow_add_documents: allowAddDocuments,
          template_documents: fields,
        });
      }
      navigate(`/${PATH_TEMPLATES}`);
    }
    setTitleError(true);
    handleCloseConfirmModal();
  }

  return (
    <>
      <ConfirmModal
        open={showPrompt}
        warningTitle={intl.formatMessage({
          id: 'app.confirm-modal.warning-title',
        })}
        warningText={intl.formatMessage({
          id: 'app.alerts.not-saved-changes.text',
        })}
        confirmAction={() => {
          setFields([]);
          confirmNavigation();
        }}
        cancelAction={cancelNavigation}
      />
      <ConfirmModal
        open={confirmModal}
        onClose={handleCloseConfirmModal}
        modalTitle={intl.formatMessage({
          id: props.edit
            ? 'app.templates.new.modal-title-edit'
            : 'app.templates.new.modal-title-new',
        })}
        modalText={
          props.edit
            ? intl.formatMessage({
                id: 'app.templates.edit.modal-confirm',
              })
            : intl.formatMessage({
                id:
                  status === TEMPLATE_STATUS_ACTIVE
                    ? 'app.templates.new.modal-text-in-use'
                    : 'app.templates.new.modal-text-draft',
              })
        }
        confirmAction={() => {
          handleSubmit();
        }}
        cancelAction={handleCloseConfirmModal}
      />
      <TemplatesNewFieldModal
        fields={fields}
        editIndex={editIndex}
        handleCreateField={handleCreateField}
        handleEditField={handleEditField}
        handleCloseFieldModal={handleCloseFieldModal}
        fieldModal={fieldModal}
        editField={editField}
      />
      <div style={{ filter: props.templateSupport === false && 'blur(3px)' }}>
        <Fade timeout={250} in={true}>
          <Box component="form" noValidate>
            <Grid container>
              <Grid item xs={12}>
                <TemplateContainer elevation={3}>
                  {(props.edit && !templatesContext.editTemplate) || loading ? (
                    <Loader />
                  ) : (
                    <>
                      {props.edit && (
                        <Grid container>
                          <Grid item xs={0} md={11} />
                          <Grid
                            item
                            xs={12}
                            md={1}
                            sx={{ display: 'flex', justifyContent: 'center' }}
                          >
                            <ChipGrid
                              status={templatesContext.editTemplate.status}
                              showIcon={true}
                              templateBadge
                              stampedCollections={
                                templatesContext.editTemplate
                                  .stamped_collections
                              }
                            />
                          </Grid>
                        </Grid>
                      )}
                      <TitleGridContainer
                        container
                        justifyContent="center"
                        sx={{
                          mb: 4,
                        }}
                      >
                        <Grid item xs={12} md={8}>
                          <InputLabel htmlFor="title" sx={{ mb: 1 }}>
                            {intl.formatMessage({
                              id: 'app.templates.new.title',
                            })}
                          </InputLabel>
                          <TextField
                            id="title"
                            error={titleError}
                            helperText={
                              titleError &&
                              intl.formatMessage({
                                id: 'app.templates.new.title-error',
                              })
                            }
                            value={title}
                            onChange={handleChangeTitle}
                            fullWidth
                            placeholder={intl.formatMessage({
                              id: 'app.templates.new.title-placeholder',
                            })}
                            inputProps={{ maxLength: config.maxLengthTitle }}
                            disabled={!props.templateSupport}
                          />
                        </Grid>
                      </TitleGridContainer>
                      <DragDropContext onDragEnd={onDragEnd}>
                        <Grid container justifyContent="center">
                          <Droppable droppableId="fields">
                            {(provided) => (
                              <div
                                style={{
                                  width: '100%',
                                }}
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                              >
                                {fields?.map((field, index) => (
                                  <Draggable
                                    key={field.title}
                                    draggableId={field.title}
                                    index={index}
                                  >
                                    {(provided) => (
                                      <Grid
                                        container
                                        justifyContent="center"
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                      >
                                        <TemplateCreatedField
                                          dragProps={provided}
                                          key={index}
                                          index={index}
                                          fields={fields}
                                          fieldTitle={field.title}
                                          fieldType={field.field_type}
                                          required={field.validations.required}
                                          handleDeleteField={handleDeleteField}
                                          handleOpenEditModal={
                                            handleOpenEditModal
                                          }
                                        />
                                      </Grid>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </Grid>
                      </DragDropContext>
                      <AddFieldButtonGrid item>
                        <FieldButton
                          variant="contained"
                          onClick={handleOpenFieldModal}
                          children={
                            <>
                              <AddIcon />
                              <p>
                                {intl.formatMessage({
                                  id: 'app.templates.new.add-field-button',
                                })}
                              </p>
                            </>
                          }
                          disabled={!props.templateSupport}
                        />
                      </AddFieldButtonGrid>
                      {fields.length !== 0 && (
                        <>
                          <Divider
                            sx={{ width: '100%', marginBottom: '2rem' }}
                          />
                          <Grid container justifyContent="space-around">
                            <OptionsGrid1 item md={4} xs={12}>
                              <FormControlLabel
                                control={<Checkbox />}
                                label={
                                  <Typography
                                    variant="body2"
                                    noWrap
                                    children={intl.formatMessage({
                                      id: 'app.templates.allow-add-documents',
                                    })}
                                  />
                                }
                                checked={allowAddDocuments}
                                onChange={handleCheckAllowAddDocuments}
                              />
                            </OptionsGrid1>
                            <OptionsGrid2 item md={4} xs={12}>
                              <FormLabel
                                id="template-state"
                                children={
                                  <Typography
                                    noWrap
                                    variant="body2"
                                    children={intl.formatMessage({
                                      id: 'app.templates.status-edit',
                                    })}
                                  />
                                }
                                sx={{ paddingRight: '1rem' }}
                              />
                              <RadioGroup
                                row
                                value={status}
                                onChange={handleCheckSetStatus}
                                sx={{ flexWrap: 'nowrap' }}
                              >
                                <FormControlLabel
                                  value={TEMPLATE_STATUS_ACTIVE}
                                  control={<Radio />}
                                  label={
                                    <Typography
                                      variant="body2"
                                      children={intl.formatMessage({
                                        id: 'app.templates.status-active-check',
                                      })}
                                    />
                                  }
                                />
                                <FormControlLabel
                                  value={TEMPLATE_STATUS_DRAFT}
                                  control={<Radio />}
                                  label={
                                    <Typography
                                      variant="body2"
                                      children={intl.formatMessage({
                                        id: 'app.templates.status-draft-check',
                                      })}
                                    />
                                  }
                                />
                              </RadioGroup>
                            </OptionsGrid2>
                          </Grid>
                        </>
                      )}
                    </>
                  )}
                </TemplateContainer>
              </Grid>
            </Grid>
          </Box>
        </Fade>
        <BottomButtons
          disabled={fields?.length === 0}
          confirmText={intl.formatMessage({
            id: props.edit
              ? 'app.templates.edit.button'
              : 'app.templates.new.create',
          })}
          confirmAction={() => {
            handleOpenConfirmModal();
          }}
          cancelText={intl.formatMessage({
            id: 'app.templates.new.back',
          })}
          cancelAction={() => {
            navigate(APP_ROUTES.TEMPLATES);
          }}
        />
      </div>
    </>
  );
}
