import UserProvider from '../context/Users/UserProvider';

import { UserActivate } from '../components/UserActivate';
import { PublicLayout } from '../components/PublicLayout';

import { useIntl } from 'react-intl';

import { useMediaQuery } from '@mui/material';

import { ReactComponent as CheckedIcon } from '../assets/checked.svg';

export const ActivatePage = () => {
  const intl = useIntl();
  const mobile = useMediaQuery('(max-width: 900px)');

  document.title = useIntl().formatMessage({ id: 'app.page.activate' });

  return (
    <PublicLayout
      iconForm={<CheckedIcon width={'60px'} height={'60px'} />}
      welcomeTitle={intl.formatMessage({ id: 'app.layout.activate' })}
      welcomeText={intl.formatMessage({ id: 'app.layout.activate-message' })}
      pageTitle={!mobile && intl.formatMessage({ id: 'app.activate.title' })}
    >
      <UserProvider>
        <UserActivate />
      </UserProvider>
    </PublicLayout>
  );
};
