import { useContext, useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import {
  TextField,
  InputLabel,
  Typography,
  Button,
  Grid,
  Alert,
  CircularProgress,
  Divider,
  Box,
} from '@mui/material';

import CustomButton from './CustomButton';
import originoLogo from '../assets/origino-logo.svg';

import { styled } from '@mui/material/styles';

import { validateEmail } from '../utils/validation';

import AuthContext from '../context/Auth/AuthContext';

import { useIntl } from 'react-intl';

import { APP_ROUTES } from '../constants';

const InputsGrid = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  margin: '1rem',
  '& label': {
    marginTop: '1rem',
  },
});

const ButtonsGrid = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: '1rem',
  '& button': {
    width: '10rem',
    margin: '0.5rem 0rem',
  },
});

const OriginoGrid = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '1rem',
  '& *': {
    margin: '0.5rem 0rem',
  },
  '& button': {
    width: '10rem',
  },
});

const CustomLogo = styled('img')((props) => ({
  width: '6rem',
  height: 'auto',
  marginLeft: '0.5rem',
}));

const CreateAccountContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  a: {
    marginLeft: '0.5rem',
  },
});

export const UserLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({
    email: false,
    password: false,
  });

  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const intl = useIntl();

  useEffect(() => {
    if (authContext.loggedIn) {
      navigate(`/stamp`);
    }
  }, [authContext.loggedIn]);

  const handleChange = (event) => {
    switch (event.target.name) {
      case 'email':
        setEmail(event.target.value);
        break;
      case 'password':
        setPassword(event.target.value);
        break;
      default:
        break;
    }
  };

  const validate = () => {
    const currentErrors = {
      email: false,
      password: false,
    };
    setErrors(currentErrors);
    let isValid = true;
    if (!validateEmail(email)) {
      currentErrors.email = true;
      isValid = false;
    }
    if (!password) {
      currentErrors.password = true;
      isValid = false;
    }
    setErrors(currentErrors);
    return isValid;
  };

  const handleSubmit = () => {
    if (validate()) {
      authContext.login(email, password);
    }
  };

  const handleEnter = (e) => e.key === 'Enter' && handleSubmit();

  return (
    <Grid container>
      <Grid container justifyContent="center">
        <InputsGrid item xs={12} md={6}>
          <Box component="form" noValidate>
            {authContext.loginFailed && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {intl.formatMessage({ id: 'app.login.invalid' })}
              </Alert>
            )}
            <InputLabel
              htmlFor="email"
              shrink
              children={intl.formatMessage({ id: 'app.login.email' })}
            />
            <TextField
              margin="dense"
              required
              fullWidth
              name="email"
              type="email"
              id="email"
              autoComplete="email"
              autoFocus
              onChange={handleChange}
              onKeyPress={handleEnter}
              value={email}
              error={errors.email}
              helperText={
                errors.email &&
                intl.formatMessage({ id: 'app.login.user-error' })
              }
            />
            <InputLabel
              htmlFor="password"
              shrink
              children={intl.formatMessage({ id: 'app.login.pass' })}
            />
            <TextField
              margin="dense"
              required
              fullWidth
              name="password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={handleChange}
              onKeyPress={handleEnter}
              value={password}
              error={errors.password}
              helperText={
                errors.password &&
                intl.formatMessage({ id: 'app.login.pass-error' })
              }
            />
          </Box>
          <Link to={!authContext.loginRequested && APP_ROUTES.FORGOT}>
            <Typography
              variant="body2"
              children={intl.formatMessage({ id: 'app.login.forgot-pass' })}
            />
          </Link>
        </InputsGrid>
      </Grid>
      <Grid container justifyContent="center">
        <ButtonsGrid item xs={12} md={6}>
          <CustomButton
            variant="contained"
            fullWidth
            onClick={!authContext.loginRequested ? handleSubmit : undefined}
            disabled={authContext.loginRequested}
            children={
              !authContext.loginRequested ? (
                intl.formatMessage({ id: 'app.login.login-button' })
              ) : (
                <CircularProgress
                  size="1.5rem"
                  thickness={5}
                  sx={{ color: '#fff' }}
                />
              )
            }
          />
          <CreateAccountContainer>
            <Typography
              color="#737373"
              children={intl.formatMessage({ id: 'app.login.new-acc-1' })}
              noWrap
            />
            <Link to={APP_ROUTES.REGISTER}>
              <Typography
                noWrap
                children={intl.formatMessage({ id: 'app.login.new-acc-2' })}
              />
            </Link>
          </CreateAccountContainer>
        </ButtonsGrid>
      </Grid>
      <Grid container justifyContent="center">
        <OriginoGrid item xs={12} md={6}>
          <Divider sx={{ width: '100%', mb: 4 }} />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Typography
              color="#737373"
              children={intl.formatMessage({ id: 'app.login.origino' })}
              noWrap
            />
            <CustomLogo src={originoLogo} />
          </div>
          <Button
            variant="outlined"
            children={intl.formatMessage({ id: 'app.login.login-button' })}
          />
        </OriginoGrid>
      </Grid>
    </Grid>
  );
};
