import React, { useState, useContext } from 'react';
import { Grid, Fade } from '@mui/material';
import DocumentsContext from '../../context/Documents/DocumentsContext';
import DocumentCard from './DocumentCard';
import FilePreviewer from '../FilePreviewer';
import InlineAlert from '../InlineAlert';
import SharedProvider from '../../context/Shared/SharedProvider';

import { useIntl } from 'react-intl';

export default function DocumentShared(props) {
  const documentsContext = useContext(DocumentsContext);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewDocument, setPreviewDocument] = useState(undefined);

  const intl = useIntl();

  function handleOpenPreview(document) {
    setPreviewDocument(document);
    setPreviewOpen(true);
  }

  function handleClosePreview() {
    setPreviewOpen(false);
  }

  return (
    <>
      <FilePreviewer
        open={previewOpen}
        onClose={handleClosePreview}
        document={previewDocument}
      />
      <Fade timeout={1000} in={true}>
        <Grid>
          <SharedProvider>
            {documentsContext.errors && (
              <InlineAlert
                title={intl.formatMessage({
                  id: 'app.alerts.inline-error-title',
                })}
                severity="error"
                alertText={documentsContext.errors}
              />
            )}
            {documentsContext.document && (
              <DocumentCard
                document={documentsContext.document}
                isShareable={true}
                customColor={props.customColor}
                handleOpenPreview={handleOpenPreview}
                textValidated={{
                  title: intl.formatMessage({
                    id: 'app.search.validate',
                  }),
                }}
                textNoValidated={{
                  title: intl.formatMessage({
                    id: 'app.search.novalidate',
                  }),
                  desc: intl.formatMessage({
                    id: 'app.search.novalidate-desc',
                  }),
                }}
              />
            )}
          </SharedProvider>
        </Grid>
      </Fade>
    </>
  );
}
