import {
  GET_TEMPLATE_ID,
  GET_TEMPLATES,
  GET_TEMPLATES_SEARCH,
  POST_TEMPLATE,
  EDIT_TEMPLATE,
  DELETE_TEMPLATE,
  LOADING_TEMPLATES,
  ERROR_TEMPLATES,
  STATUS_ERROR,
  STATUS_DISCONNECT_ERROR,
} from './TemplatesTypes';

export default function TemplatesReducer(state, action) {
  const { payload, status, message, type } = action;

  switch (type) {
    case GET_TEMPLATES:
      return {
        ...state,
        loading: false,
        errors: false,
        templates: payload,
        status: status,
        message: message,
      };
    case GET_TEMPLATES_SEARCH:
      return {
        ...state,
        loading: false,
        errors: false,
        templates: payload,
        status: status,
        message: message,
      };
    case GET_TEMPLATE_ID:
      return {
        ...state,
        loading: false,
        errors: false,
        editTemplate: payload,
        templateId: payload.id,
        status: status,
        message: message,
      };
    case EDIT_TEMPLATE:
      return {
        ...state,
        loading: false,
        errors: false,
        editTemplate: payload,
        status: status,
        message: message,
      };
    case POST_TEMPLATE:
      return {
        ...state,
        loading: false,
        errors: false,
        newTemplate: payload,
        status: status,
        message: message,
      };
    case DELETE_TEMPLATE:
      return {
        ...state,
        loading: false,
        errors: false,
        templates: payload,
        status: status,
        message: message,
      };
    case LOADING_TEMPLATES:
      return {
        ...state,
        loading: true,
        errors: false,
        templates: undefined,
        status: status,
        message: message,
      };
    case ERROR_TEMPLATES:
      return {
        ...state,
        loading: false,
        errors: payload,
        templates: undefined,
        status: status,
        message: message,
      };
    case STATUS_ERROR:
      return {
        ...state,
        loading: false,
        errors: payload,
        templates: undefined,
        status: status,
        message: message,
      };
    case STATUS_DISCONNECT_ERROR:
      return {
        ...state,
        loading: false,
        errors: payload,
        templates: undefined,
        status: status,
        message: message,
      };
    default:
      break;
  }
};
