import { useParams } from 'react-router-dom';

import CollectionsProvider from '../context/Collections/CollectionsProvider';
import SharedProvider from '../context/Shared/SharedProvider';

import { PrivateLayout } from '../components/PrivateLayout';
import { Collection } from '../components/Collection';
import { DocumentBadges } from '../components/DocumentBadges';

import {
  Box,
  Typography,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';

import { styled } from '@mui/material/styles';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useIntl } from 'react-intl';

export const AccordionContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
});

export const CustomAccordion = styled(Accordion)({
  borderRadius: '12px',
  width: '30rem',
  '@media (min-width: 900px)': {
    display: 'none',
  },
});

export const CollectionPage = () => {
  const { id } = useParams();

  const intl = useIntl();

  document.title = intl.formatMessage({ id: 'app.page.collection' });

  return (
    <>
      <PrivateLayout
        title={intl.formatMessage({ id: 'app.page.collection.headertitle' })}
        subtitle={intl.formatMessage({ id: 'app.page.collection.subtitle' })}
      >
        <Box component="form" noValidate>
          <CollectionsProvider>
            <AccordionContainer>
              <CustomAccordion square={true}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>
                    {intl.formatMessage({ id: 'app.collection.header' })}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <DocumentBadges />
                </AccordionDetails>
              </CustomAccordion>
            </AccordionContainer>
            <Stack spacing={2}>
              <SharedProvider>
                <Collection collectionId={id} />
              </SharedProvider>
            </Stack>
          </CollectionsProvider>
        </Box>
      </PrivateLayout>
    </>
  );
};
