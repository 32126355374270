import React, { useState, useEffect, useContext } from 'react';
import CountryProvider from '../context/Country/CountryProvider';

import { useMediaQuery } from '@mui/material';

import { PublicLayout } from '../components/PublicLayout';
import { UserRegister } from '../components/UserRegister';

import { ReactComponent as UserIcon } from '../assets/user.svg';

import { useIntl } from 'react-intl';

import UserContext from '../context/Users/UserContext';

export const RegisterPage = () => {
  const [showTitle, setShowTitle] = useState(true);

  const intl = useIntl();
  const userContext = useContext(UserContext);
  const mobile = useMediaQuery('(max-width: 900px');

  useEffect(() => {
    if (userContext.registerSuccess && mobile) {
      setShowTitle(false);
    } else {
      setShowTitle(true);
    }
  }, [userContext.registerSuccess, mobile]);

  document.title = intl.formatMessage({ id: 'app.page.register' });

  return (
    <PublicLayout
      iconForm={<UserIcon width={'32px'} height={'37px'} />}
      welcomeTitle={
        showTitle && intl.formatMessage({ id: 'app.layout.register' })
      }
      welcomeText={
        showTitle && intl.formatMessage({ id: 'app.layout.register-message' })
      }
      pageTitle={!mobile && intl.formatMessage({ id: 'app.register.title' })}
    >
      <CountryProvider>
        <UserRegister mobile={mobile} setShowTitle={setShowTitle} />
      </CountryProvider>
    </PublicLayout>
  );
};
