import React, { useState } from 'react';
import ChipGrid from './ChipGrid';
import { TEMPLATE_STATUS_ACTIVE, TEMPLATE_STATUS_DRAFT } from '../constants';

import dayjs from 'dayjs';

import { useNavigate } from 'react-router-dom';

import { styled } from '@mui/material/styles';

import { useIntl } from 'react-intl';

import {
  Grid,
  Paper,
  Typography,
  IconButton,
  Popover,
  Button,
  useMediaQuery,
} from '@mui/material';

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const CardPaper = styled(Paper)({
  display: 'flex',
  flexDirection: 'row',
  padding: '0.5rem',
  borderRadius: '4px',
  marginBottom: '1rem',
  boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.12)',
});

const NameGrid = styled(Grid)({
  display: 'flex',
  flexDirection: 'row',
  paddingLeft: '0.5rem',
  '& svg': {
    marginRight: '0.5rem',
  },
  '@media (max-width: 600px)': {
    paddingLeft: '0rem',
  },
});

const MobileActiveIconGrid = styled(Grid)({
  display: 'flex',
  justifyContent: 'center',
  height: '100%',
  '& svg': {
    marginTop: '0.3rem',
  },
});

const CustomGrid = styled(Grid)({
  display: 'flex',
  '@media (min-width: 900px)': {
    margin: '0.5rem 0rem',
    justifyContent: 'center',
  },
});

const MenuPopover = styled(Popover)({
  display: 'flex',
  flexDirection: 'column',
  '& div': {
    padding: '0.2rem',
    '& button': {
      color: '#3969E8',
    },
  },
});

function colorSwitcher(element, status) {
  switch (element) {
    case 'chip':
      if (status === TEMPLATE_STATUS_ACTIVE) {
        return '#DEFFF3';
      } else if (status === TEMPLATE_STATUS_DRAFT) {
        return '#F3F3F3';
      }
      break;
    case 'text':
      if (status === TEMPLATE_STATUS_ACTIVE) {
        return '#2CAB6E';
      } else if (status === TEMPLATE_STATUS_DRAFT) {
        return '#5572BE';
      }
      break;
    case 'dot':
      if (status === TEMPLATE_STATUS_ACTIVE) {
        return '#4BCDB6';
      } else if (status === TEMPLATE_STATUS_DRAFT) {
        return '#A8AEAD';
      }
      break;
    case 'name':
      if (status === TEMPLATE_STATUS_ACTIVE) {
        return 'rgba(51, 51, 51, 1)';
      } else if (status === TEMPLATE_STATUS_DRAFT) {
        return 'rgba(153, 153, 153, 1)';
      }
      break;
    default:
      break;
  }
}

export default function TemplateCard(props) {
  const [anchor, setAnchor] = useState(null);

  const mobile = useMediaQuery('(max-width: 600px)');

  const open = Boolean(anchor);
  const id = open ? 'menu' : undefined;
  const intl = useIntl();
  const navigate = useNavigate();

  const handleMenuOpen = (event) => {
    setAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchor(null);
  };

  async function handleEditTemplate(id) {
    handleMenuClose();
    navigate(`/edit-template/${id}`);
  }

  return (
    <>
      <CardPaper>
        <Grid container direction="row" alignItems="center">
          {mobile && (
            <MobileActiveIconGrid item xs={1}>
              <FiberManualRecordIcon
                sx={{ color: colorSwitcher('dot', props.status) }}
              />
            </MobileActiveIconGrid>
          )}
          <Grid
            item
            xs={11}
            sm={12}
            display="flex"
            direction={mobile ? 'column' : 'row'}
          >
            <NameGrid item xs={12} sm={3}>
              <Grid item xs={6} sm={12} display="flex" alignItems="center">
                {!mobile && (
                  <FiberManualRecordIcon
                    sx={{ color: colorSwitcher('dot', props.status) }}
                  />
                )}
                <Typography
                  sx={{
                    color: colorSwitcher('name', props.status),
                    fontWeight: '500',
                  }}
                  variant="body2"
                  children={props.title}
                />
              </Grid>
              {mobile && (
                <Grid item xs={6} display="flex" justifyContent="flex-end">
                  <IconButton
                    disableRipple
                    sx={{ padding: '0rem' }}
                    children={<MoreVertIcon fontSize="large" />}
                    onClick={handleMenuOpen}
                  />
                </Grid>
              )}
            </NameGrid>
            <CustomGrid
              item
              xs={12}
              sm={3}
              justifyContent={!mobile ? 'center' : 'left'}
              alignItems="center"
            >
              <Typography
                variant="body2"
                color="#666666"
                children={`${intl.formatMessage({
                  id: 'app.templates.card.fields',
                })}${props.fieldCount}`}
              />
            </CustomGrid>
            <CustomGrid item xs={12} sm={2} alignItems="center">
              <Grid xs={6} sm={12}>
                <Typography
                  variant="body2"
                  color="#666666"
                  noWrap
                  align={!mobile ? 'center' : 'left'}
                  children={dayjs(props.createdAt).format('DD/MM/YYYY')}
                />
              </Grid>
              {mobile && (
                <Grid xs={6} display="flex" justifyContent="right">
                  <ChipGrid
                    status={props.status}
                    showIcon={true}
                    templateBadge
                    stampedCollections={props.stampedCollections}
                  />
                </Grid>
              )}
            </CustomGrid>
            {!mobile && (
              <CustomGrid item xs={12} sm={3} justifyContent="center">
                <ChipGrid
                  status={props.status}
                  showIcon={true}
                  templateBadge
                  stampedCollections={props.stampedCollections}
                />
              </CustomGrid>
            )}
            {!mobile && (
              <CustomGrid item xs={1} sm={1} justifyContent="flex-end">
                <IconButton
                  children={<MoreVertIcon />}
                  onClick={handleMenuOpen}
                />
              </CustomGrid>
            )}
            <MenuPopover
              id={id}
              open={open}
              anchorEl={anchor}
              onClose={handleMenuClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <div>
                <Button
                  startIcon={<EditIcon />}
                  variant="text"
                  onClick={() => {
                    handleEditTemplate(props.id);
                  }}
                >
                  {intl.formatMessage({ id: 'app.templates.card.edit-button' })}
                </Button>
              </div>
              <div>
                <Button
                  startIcon={<DeleteIcon />}
                  variant="text"
                  disabled={props.stampedCollections > 0 ? true : false}
                  onClick={() => {
                    props.handleOpenConfirmModal(props.id);
                    handleMenuClose();
                  }}
                >
                  {intl.formatMessage({
                    id: 'app.templates.card.delete-button',
                  })}
                </Button>
              </div>
            </MenuPopover>
          </Grid>
        </Grid>
      </CardPaper>
    </>
  );
}
