import {
  GET_TIER_DATA,
  GET_TIERS,
  GET_TIERS_PRICES,
  LOADING_TIERS,
  LOADING_AUTORENEWAL,
  TIERS_ERROR,
  CANCEL_TIER_SUBSCRIPTION,
  CANCEL_TIER_ONE_PAY,
  TIER_PAYMENT,
  GET_LAST_PAYMENT_STATUS,
  CHANGE_AUTORENEWAL,
} from './TiersTypes';

export default function TiersReducer(state, action) {
  const { payload, type } = action;

  switch (type) {
    case GET_TIER_DATA:
      return {
        ...state,
        userTier: {
          tierId: payload.tier_id,
          documentStamp: payload.meta_json.stamp_documents,
          collectionStamp: payload.meta_json.stamp_collections,
          whiteLineSupport: payload.meta_json.white_line_support,
          sdkSupport: payload.meta_json.sdk_support,
          templateSupport: payload.meta_json.template_editor_support,
          paymentType: payload.meta_json.payment_type,
        },
        autoRenewal: payload.auto_renewal,
        loading: false,
        errors: false,
      };
    case GET_TIERS:
      return {
        ...state,
        tiersData: {
          ...state.tiersData,
          tiers: payload.sort((a, b) =>
            a.tier_detail_json.order < b.tier_detail_json.order ? -1 : 1
          ),
        },
        loading: false,
        errors: false,
      };
    case GET_TIERS_PRICES:
      return {
        ...state,
        tiersData: { ...state.tiersData, prices: payload },
        loading: false,
        errors: false,
      };
    case CANCEL_TIER_SUBSCRIPTION:
      return {
        ...state,
        userTier: {
          tierId: payload.tier_id,
          documentStamp: payload.meta_json.stamp_documents,
          collectionStamp: payload.meta_json.stamp_collections,
          whiteLineSupport: payload.meta_json.white_line_support,
          sdkSupport: payload.meta_json.sdk_support,
          templateSupport: payload.meta_json.template_editor_support,
          paymentType: payload.meta_json.payment_type,
        },
        autoRenewal: payload.auto_renewal,
        loading: false,
        errors: false,
      };
    case CANCEL_TIER_ONE_PAY:
      return {
        ...state,
        loading: false,
        errors: false,
      };
    case CHANGE_AUTORENEWAL:
      return {
        ...state,
        autoRenewal: payload.auto_renewal,
        loading: false,
        loadingAutoRenewal: false,
        errors: false,
      };
    case TIER_PAYMENT:
      return {
        ...state,
        // paymentData: {
        //   lastOperationNumber: payload.operation_context_id,
        //   lastOperationUrl: payload.url_to_redirect,
        // },
        loading: false,
        errors: false,
      };
    case GET_LAST_PAYMENT_STATUS:
      return {
        ...state,
        paymentData: {
          lastOperationNumber: payload.operation_context_id,
          lastOperationStatus: payload.status,
          lastOperationUrl: payload.url_to_continue_flow,
          lastTransactionStatus: payload.transaction_status,
        },
        loading: false,
        errors: false,
      };
    case LOADING_TIERS:
      return {
        ...state,
        loading: true,
        errors: false,
      };
    case LOADING_AUTORENEWAL:
      return {
        ...state,
        loadingAutoRenewal: true,
        errors: false,
      };
    case TIERS_ERROR:
      return {
        ...state,
        loading: false,
        loadingAutoRenewal: false,
        errors: payload,
      };
    default:
      return state;
  }
}
