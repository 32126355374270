import React, { useContext } from 'react';

import { Box, Grid, Typography, CircularProgress } from '@mui/material';

import { useIntl } from 'react-intl';

import UserProvider from '../../context/Users/UserProvider';
import SettingsContext from '../../context/Settings/SettingsContext';

import SecurityChangePassword from './SecurityChangePassword';

export default function Security(props) {
  const intl = useIntl();
  const settingsContext = useContext(SettingsContext);
  return (
    <Box sx={{ width: '100%' }}>
      <Grid container marginTop={4}>
        <Grid
          item
          md={12}
          xs={12}
          sx={{
            display: 'flex',
            alignItems: 'center',
            '& span': {
              marginLeft: '1rem',
            },
          }}
        >
          <Typography variant="body2">
            {intl.formatMessage({
              id: 'app.my-account.security.title-change-password',
            })}
          </Typography>
          {settingsContext.loading && (
            <CircularProgress thickness={6} size={'1rem'} />
          )}
        </Grid>
      </Grid>
      <UserProvider>
        <SecurityChangePassword
          isChanged={props.isChanged}
          setIsChanged={props.setIsChanged}
        />
      </UserProvider>
    </Box>
  );
}
