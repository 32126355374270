import { Box, Container, Paper, Stack, Grid } from '@mui/material';

import { makeStyles } from '@mui/styles';

import { styled } from '@mui/material/styles';

import logo from '../assets/logoWhite.svg';

import DocumentsProvider from '../context/Documents/DocumentsProvider';
import { Find } from '../components/Find';

import HeaderIdenteco from '../components/HeaderIdenteco';

import { useIntl } from 'react-intl';

export const FindPage = () => {
  const intl = useIntl();

  document.title = intl.formatMessage({ id: 'app.page.find' });

  return (
    <>
      <Grid container spacing={0}>
        <HeaderIdenteco
          title={intl.formatMessage({ id: 'app.collection.shared.title' })}
        />
        <Grid item xs={12} sx={{ marginTop: '6rem' }}>
          <Container>
            <Box component="form" noValidate>
              <Stack spacing={2}>
                <DocumentsProvider>
                  <Find />
                </DocumentsProvider>
              </Stack>
            </Box>
          </Container>
        </Grid>
      </Grid>
    </>
  );
};
