import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { animateScroll } from 'react-scroll';

import ImageEditor from './ImageEditor';
import { HexColorPicker, HexColorInput } from 'react-colorful';
import ColorPickerButtons from './ColorPickerButtons';
import BottomButtons from './BottomButtons';
import ConfirmModal from '../ConfirmModal';
import NoAccessRedirect from '../NoAccessRedirect';
import Loader from '../Loader';
import { ReactComponent as PreviewWireframe } from '../../assets/wireframe.svg';

import AlertsContext from '../../context/Alerts/AlertsContext';
import TiersContext from '../../context/Tiers/TiersContext';
import SettingsContext from '../../context/Settings/SettingsContext';

import { uploadMedia } from '../../services/mediaUpload';
import { imageDataParser } from '../../helpers';

import {
  Grid,
  Box,
  InputLabel,
  TextField,
  Switch,
  FormControlLabel,
  Typography,
  CircularProgress,
  Avatar,
  useMediaQuery,
} from '@mui/material';

import { styled } from '@mui/material/styles';

import { useIntl } from 'react-intl';
import {
  STATUS_CREATED,
  EDITOR_NO_IMAGE,
  statusPayment,
  statusContext,
  APP_ROUTES,
} from '../../constants';

const Section1Grid = styled(Grid)({
  marginBottom: '2rem',
});

const Section2Grid = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  padding: '1rem',
  marginBottom: '2rem',
  borderRadius: '1rem',
  border: '1px solid rgba(0, 0, 0, 0.2)',
});

const CustomGridItem = styled(Grid)({
  padding: '1rem',
});

const ColorsGridContainer = styled(Grid)({
  display: 'flex',
});

const ColorPickerGridContainer = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '14rem',
  input: {
    padding: '16.5px 14px',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    borderRadius: '4px',
    fontSize: '1rem',
    textTransform: 'uppercase',
    width: '5rem',
  },
  '.react-colorful': {
    margin: '1rem 0rem',
    width: '100%',
  },
  '.react-colorful__saturation-pointer, .react-colorful__hue-pointer': {
    zIndex: '1',
  },
});

const LogoEditorGrid = styled(CustomGridItem)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

const WireframeGrid = styled(Grid)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const WireframeLogoPreview = styled(Avatar)((props) => ({
  position: 'absolute',
  transform: 'translate(-170px, -145px)',
  boxShadow: `0px 0px 0px 3px ${props.color ? props.color : 'grey'}`,
  borderRadius: '0.3rem',
  height: '2rem',
  width: '6rem',
  background: '#fff',
  '@media (max-width: 600px)': {
    transform: 'translate(-22vw, -22vw)',
  },
}));

const CustomPreviewWireframe = styled(PreviewWireframe)((props) => ({
  padding: '1rem',
  height: `20rem`,
  width: `28rem`,
  margin: '1rem',
  borderRadius: '0.3rem',
  boxShadow: props.fill ? `0px 0px 0px 3px ${props.fill}` : null,
  '@media (max-width: 600px)': {
    height: '48vw',
  },
}));

const FieldLabel = styled(InputLabel)({
  marginBottom: '1rem',
});

const colors = [
  '#f44336',
  '#e91e63',
  '#9c27b0',
  '#673ab7',
  '#3f51b5',
  '#2196f3',
  '#03a9f4',
  '#00bcd4',
  '#009688',
  '#4caf50',
  '#8bc34a',
  '#cddc39',
  '#ffeb3b',
  '#ffc107',
  '#ff9800',
  '#ff784e',
];

export default function CustomBrand(props) {
  const [loading, setLoading] = useState(false);
  const [isActivated, setIsActivated] = useState(false);
  const [isNew, setIsNew] = useState(true);

  const [confirmModal, setConfirmModal] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);

  const [name, setName] = useState('');
  const [color, setColor] = useState('');
  const [logo, setLogo] = useState();
  const [logoCropped, setLogoCropped] = useState();
  const [logoZoom, setLogoZoom] = useState(1);
  const [logoRotation, setLogoRotation] = useState(0);

  const [logoThumbURL, setLogoThumbURL] = useState(null);

  const alertsContext = useContext(AlertsContext);
  const tiersContext = useContext(TiersContext);
  const settingsContext = useContext(SettingsContext);
  const intl = useIntl();
  const navigate = useNavigate();
  const mobile = useMediaQuery('(max-width: 900px)');

  const logoHeight = 120;

  useEffect(() => {
    tiersContext.getLastPaymentStatus();
    async function defaultFetch() {
      await tiersContext.getTierState();
      await settingsContext.getSettings();
    }
    defaultFetch();
  }, []);

  useEffect(() => {
    async function setData() {
      setLoading(true);
      if (settingsContext.settings) {
        const logoData = JSON.parse(settingsContext.settings.logo_meta);
        const logoBlob = logoData?.publicFileURI
          ? await fetch(logoData.publicFileURI).then((r) => r.blob())
          : null;
        setName(settingsContext.settings.name);
        setColor(settingsContext.settings.color);
        const logoThumbURI = imageDataParser(
          settingsContext.settings,
          'logo_thumb_meta'
        );
        setLogoThumbURL(logoThumbURI);
        setLogo(
          logoBlob &&
            new File([logoBlob], 'Logo.png', {
              type: 'image/png',
            })
        );
        setIsActivated(settingsContext.settings.whiteline_active);
        setIsNew(false);
        setLoading(false);
      } else if (!settingsContext.settings && settingsContext.error) {
        setIsActivated(false);
        setLoading(false);
      }
    }
    setData();
  }, [settingsContext.error, settingsContext.settings]);

  useEffect(() => {
    if (
      tiersContext.paymentData.lastOperationStatus === statusPayment.PAID &&
      tiersContext.paymentData.lastTransactionStatus === statusContext.PENDING &&
      tiersContext.userTier.whiteLineSupport.enabled !== false
    ) {
      setPaymentModal(true);
    }
  }, [tiersContext.paymentData.lastOperationStatus]);

  function handleChangeZoom(event) {
    setLogoZoom(event.target.value);
  }

  function handleRotateLogo(direction) {
    props.setIsChanged(true);
    setLogoRotation(
      direction === 'right' ? logoRotation + 90 : logoRotation - 90
    );
  }

  function handleChangeBrandName(event) {
    props.setIsChanged(true);
    setName(event.target.value);
  }

  function handleChangeBrandColor(hex) {
    props.setIsChanged(true);
    setColor(hex);
  }

  function handleChangeActivated() {
    props.setIsChanged(true);
    setIsActivated(!isActivated);
  }

  function handleOpenConfirmModal() {
    setConfirmModal(true);
  }

  function handleCloseConfirmModal() {
    setConfirmModal(false);
  }

  async function handleSave(logoFull, logoCrop) {
    handleCloseConfirmModal();
    setLoading(true);
    const logoRes1 = await uploadMedia(logoFull);
    const logoRes2 = await uploadMedia(logoCrop);
    if (
      (logoRes1?.status === STATUS_CREATED &&
        logoRes2?.status === STATUS_CREATED) ||
      (logoRes1 === EDITOR_NO_IMAGE && logoRes2 === EDITOR_NO_IMAGE)
    ) {
      await settingsContext.editSettings(isNew, {
        name: name,
        color: color,
        whiteline_active: isActivated,
        logo_meta:
          logoRes1 !== EDITOR_NO_IMAGE && JSON.stringify(logoRes1.data),
        logo_thumb_meta:
          logoRes2 !== EDITOR_NO_IMAGE && JSON.stringify(logoRes2.data),
      });
    } else {
      alertsContext.createErrorAlert(
        logoRes1.data.errors,
        logoRes2.data.errors
      );
    }
    props.setIsChanged(false);
    setLoading(false);
  }

  return (
    <>
      <ConfirmModal
        open={confirmModal}
        close={handleCloseConfirmModal}
        modalTitle={intl.formatMessage({
          id: 'app.my-account.custom-brand.confirm-modal-title',
        })}
        modalText={intl.formatMessage({
          id: 'app.my-account.custom-brand.confirm-modal-text',
        })}
        warningTitle={
          !isActivated &&
          intl.formatMessage({
            id: 'app.confirm-modal.warning-title',
          })
        }
        warningText={
          !isActivated &&
          intl.formatMessage({
            id: 'app.my-account.custom-brand.confirm-modal-warning-text',
          })
        }
        confirmAction={() => {
          handleSave(logo, logoCropped);
        }}
        cancelAction={() => {
          handleCloseConfirmModal();
        }}
      />
      <ConfirmModal
        open={paymentModal}
        modalText={intl.formatMessage({
          id: 'app.my-account.plans.processing-modal-paid',
        })}
        confirmAction={() => {
          setPaymentModal(false);
        }}
      />
      <Box
        sx={{
          filter:
            tiersContext.userTier.whiteLineSupport?.enabled === false && 'blur(3px)',
        }}
      >
        <Grid marginTop={3} marginBottom={2} container>
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              '@media (max-width: 600px)': {
                flexDirection: 'column',
                alignItems: 'flex-start',
              },
            }}
          >
            <div style={{ display: 'flex' }}>
              <Typography
                variant="body2"
                children={intl.formatMessage({
                  id: 'app.my-account.custom-brand',
                })}
              />
              {loading && (
                <CircularProgress
                  thickness={6}
                  size={'1rem'}
                  sx={{ marginLeft: '1rem' }}
                />
              )}
            </div>
            {tiersContext.userTier.whiteLineSupport?.enabled && (
              <FormControlLabel
                sx={{
                  '@media (max-width: 600px)': {
                    alignSelf: 'center',
                  },
                }}
                control={
                  <Switch
                    disabled={
                      loading ||
                      tiersContext.userTier.whiteLineSupport?.enabled === false
                    }
                    size="medium"
                    checked={
                      tiersContext.userTier.whiteLineSupport?.enabled && isActivated
                    }
                    onChange={() => {
                      handleChangeActivated();
                    }}
                  />
                }
                label={
                  <InputLabel
                    children={
                      <Typography
                        variant="body2"
                        children={intl.formatMessage({
                          id: 'app.my-account.custom-brand.switch-label',
                        })}
                      />
                    }
                  />
                }
              />
            )}
          </Grid>
        </Grid>
        <>
          <Section1Grid container>
            <CustomGridItem item xs={12} lg={6}>
              <FieldLabel
                htmlFor="name"
                disabled={loading}
                children={intl.formatMessage({
                  id: 'app.my-account.custom-brand.brand-name',
                })}
              />
              <TextField
                id="name"
                disabled={loading}
                fullWidth
                placeholder={intl.formatMessage({
                  id: 'app.my-account.custom-brand.brand-name-placeholder',
                })}
                onChange={handleChangeBrandName}
                value={name}
              />
            </CustomGridItem>
            <CustomGridItem
              item
              xs={12}
              lg={6}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {!loading && tiersContext.userTier.whiteLineSupport?.enabled ? (
                <ColorsGridContainer container spacing={2}>
                  <ColorPickerGridContainer item md={6} xs={12}>
                    <HexColorPicker
                      color={color}
                      onChange={(hex) => {
                        handleChangeBrandColor(hex);
                      }}
                    />
                    <HexColorInput
                      color={color}
                      prefixed
                      onChange={(hex) => {
                        handleChangeBrandColor(hex);
                      }}
                    />
                  </ColorPickerGridContainer>
                  <ColorPickerButtons
                    mobile={mobile}
                    colors={colors}
                    handleChangeBrandColor={handleChangeBrandColor}
                  />
                </ColorsGridContainer>
              ) : (
                <Loader />
              )}
            </CustomGridItem>
          </Section1Grid>
          <Section2Grid container>
            <LogoEditorGrid item lg={6} xs={12}>
              <InputLabel
                children={intl.formatMessage({
                  id: 'app.my-account.custom-brand.brand-logo',
                })}
                sx={{ marginBottom: '1rem' }}
              />
              <ImageEditor
                disableRotation
                logoEditor={logoHeight}
                image={logo}
                imageZoom={logoZoom}
                imageRotation={logoRotation}
                loading={loading}
                handleChangeZoom={handleChangeZoom}
                handleRotateImage={handleRotateLogo}
                setImage={setLogo}
                setImageThumbURL={setLogoThumbURL}
                setImageCropped={setLogoCropped}
                setIsChanged={props.setIsChanged}
                deleteCropMessage={intl.formatMessage({
                  id: 'app.my-account.my-data.delete-crop-alert',
                })}
                uploadPrompt={intl.formatMessage({
                  id: 'app.my-account.custom-brand.logo.upload-prompt',
                })}
              />
            </LogoEditorGrid>
            <WireframeGrid color={color} item lg={6} xs={12}>
              <WireframeLogoPreview
                srcSet={logoThumbURL}
                variant="square"
                alt="Logo Preview"
                color={color}
              />
              <CustomPreviewWireframe fill={color ? color : 'grey'} />
            </WireframeGrid>
          </Section2Grid>
          <BottomButtons
            disabled={!name || !color || loading}
            confirmText={intl.formatMessage({
              id: 'app.my-account.custom-brand.save-button',
            })}
            confirmAction={handleOpenConfirmModal}
            cancelText={intl.formatMessage({
              id: 'app.my-account.cancel-button',
            })}
            cancelAction={() => {
              navigate(APP_ROUTES.MY_DOCUMENTS);
              animateScroll.scrollToTop();
            }}
          />
        </>
        {tiersContext.userTier.whiteLineSupport?.enabled === false && (
          <NoAccessRedirect
            title={intl.formatMessage({ id: 'app.noaccess.title' })}
            text={intl.formatMessage({ id: 'app.noaccess.text' })}
          />
        )}
      </Box>
    </>
  );
}
