import React, { useState, useEffect, useContext } from 'react';

import TemplatesProvider from '../context/Templates/TemplatesProvider';
import TiersContext from '../context/Tiers/TiersContext';

import { PrivateLayout } from '../components/PrivateLayout';
import TemplatesGrid from '../components/TemplatesGrid';
import NoAccessRedirect from '../components/NoAccessRedirect';
import ConfirmModal from '../components/ConfirmModal';

import { useIntl } from 'react-intl';

import { statusPayment, statusContext } from '../constants';

export default function TemplatesPage() {
  const intl = useIntl();
  const tiersContext = useContext(TiersContext);
  const [paymentModal, setPaymentModal] = useState(false);

  useEffect(() => {
    if (
      tiersContext.paymentData.lastOperationStatus === statusPayment.PAID &&
      tiersContext.paymentData.lastTransactionStatus === statusContext.PENDING
    ) {
      setPaymentModal(true);
    }
  }, [tiersContext.paymentData.lastOperationStatus]);

  document.title = intl.formatMessage({ id: 'app.templates.title' });

  return (
    <PrivateLayout
      title={intl.formatMessage({ id: 'app.templates.title' })}
      subtitle={intl.formatMessage({ id: 'app.templates.subtitle' })}
    >
      <ConfirmModal
        open={paymentModal}
        modalText={intl.formatMessage({
          id: 'app.my-account.plans.processing-modal-paid',
        })}
        confirmButtonText={intl.formatMessage({
          id: 'app.my-account.plans.processing-modal-confirm-button',
        })}
        confirmAction={() => {
          setPaymentModal(false);
        }}
      />
      <TemplatesProvider>
        <TemplatesGrid
          templateSupport={tiersContext.userTier.templateSupport?.enabled}
        />
        {tiersContext.userTier.templateSupport?.enabled === false && (
          <NoAccessRedirect
            title={intl.formatMessage({ id: 'app.noaccess.title' })}
            text={intl.formatMessage({ id: 'app.noaccess.text' })}
          />
        )}
      </TemplatesProvider>
    </PrivateLayout>
  );
}
