import React from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Header } from './PrivateLayout';

import { useIntl } from 'react-intl';

import validitaLogo from '../assets/ValiditaLogoClear.svg';
import validitaLogoPowered from '../assets/ValiditaLogoPowered.svg';

const Info1Container = styled('div')({
  display: 'flex',
  alignItems: 'center',
  '@media (max-width: 600px)': {
    width: '100%',
    justifyContent: 'space-between',
  },
});

const Info2Container = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  marginRight: '2rem',
  '@media (max-width: 600px)': {
    display: 'none',
  },
});

const BrandTitle = styled(Typography)((props) => ({
  marginLeft: '1rem',
  color: props.color ? props.color : '#fff',
  '@media (max-width: 350px)': {
    fontSize: '7vw',
  },
}));

const CustomLogo = styled('img')((props) => ({
  width: props.logo ? '11rem' : '6rem',
  height: 'auto',
  marginLeft: '1rem',
  '@media (max-width: 600px)': {
    maxWidth: '10rem',
  },
}));

const PoweredByValiditaLogo = styled(CustomLogo)({
  width: '8rem',
  '@media (max-width: 600px)': {
    display: 'none',
  },
});

function HeaderIdenteco({ title, customColor, logoUrl, brandName }) {
  const intl = useIntl();

  return (
    <>
      <Header>
        <Info1Container>
          <CustomLogo
            src={!logoUrl ? validitaLogo : logoUrl}
            alt="Custom Logo"
            logo={logoUrl}
          />
          {brandName && (
            <BrandTitle
              variant="h5"
              color={customColor ? customColor : 'primary'}
              children={brandName}
            />
          )}
        </Info1Container>
        <Info2Container>
          {customColor || brandName || logoUrl ? (
            <>
              <Typography
                variant="body1"
                fontWeight="700"
                color={customColor ? customColor : 'primary'}
                whiteSpace="nowrap"
                children={intl.formatMessage({
                  id: 'app.collection.shared.powered',
                })}
              />
              <PoweredByValiditaLogo
                src={validitaLogoPowered}
                alt="Validita Logo"
              />
            </>
          ) : (
            <Typography
              variant="h5"
              fontWeight="500"
              color="primary"
              children={title}
            />
          )}
        </Info2Container>
      </Header>
    </>
  );
}

export default HeaderIdenteco;
