import * as React from 'react';
import { useState, useContext, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { imageDataParser } from '../helpers';

import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Avatar,
} from '@mui/material';
import { ReactComponent as StampIcon } from '../assets/StampIcon.svg';
import { ReactComponent as MyDocumentsIcon } from '../assets/MyDocumentsIcon.svg';
import { ReactComponent as SearchIcon } from '../assets/SearchIcon.svg';
import { ReactComponent as TemplatesIcon } from '../assets/template-side-menu.svg';
import AvatarPlaceholderIcon from '../assets/AvatarPlaceholderIcon.svg';
import LogoutSharpIcon from '@mui/icons-material/LogoutSharp';

import AuthContext from '../context/Auth/AuthContext';
import SettingsContext from '../context/Settings/SettingsContext';

import { styled } from '@mui/material/styles';

import { useIntl } from 'react-intl';

import { APP_ROUTES } from '../constants';

const styleTextDefault = {
  paddingLeft: '0.5rem',
  color: 'rgba(0, 0, 0, 0.6)',
  width: '100%',
  '@media (max-width: 1200px)': {
    display: 'none',
  },
};

const styleTextSelected = {
  paddingLeft: '0.5rem',
  color: '#3969E8',
  width: '100%',
  '@media (max-width: 1200px)': {
    display: 'none',
  },
};

const styleIconDefault = {
  fill: 'rgba(0, 0, 0, 0.54)',
  padding: '0rem',
};

const styleIconSelected = {
  fill: '#3969E8',
  padding: '0rem',
};

const styleButton = {
  display: 'flex',
  padding: '0.5rem 1rem',
  '@media (max-width: 1200px)': {
    justifyContent: 'center',
  },
};

const CustomNavMenu = styled('nav')({
  paddingTop: '76px',
});

const CustomList = styled(List)({
  display: 'flex',
  height: '100%',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

const CustomLink = styled(Link)({
  display: 'flex',
  textDecoration: 'none',
  width: '100%',
});

const ProfileListItem = styled(ListItem)({
  '@media (max-width: 1200px)': {
    justifyContent: 'center',
  },
});

const ProfileLink = styled(CustomLink)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'fixed',
  width: '12vw',
  bottom: '0.5rem',
});

const AvatarContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '3rem',
  width: '3rem',
  padding: '0.6rem',
  '@media (max-width: 1200px)': {
    height: '2rem',
    width: '2rem',
  },
});

const CustomAvatar = styled(Avatar)({
  height: '3rem',
  width: '3rem',
});

const AvatarPlaceholder = styled('img')({
  height: '2rem',
  width: '2rem',
});

const ProfileLinkText = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0.6rem',
  '@media (max-width: 1200px)': {
    display: 'none',
  },
});

const CustomListItemIcon = styled(ListItemIcon)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minWidth: '1.5rem',
});

function SideMenu() {
  const [avatar, setAvatar] = useState(null);
  const [userName, setUserName] = useState(undefined);

  const settingsContext = useContext(SettingsContext);
  const authContext = useContext(AuthContext);

  const location = useLocation();
  const intl = useIntl();

  useEffect(() => {
    settingsContext.getUserData();
  }, []);

  useEffect(() => {
    async function defaultFetch() {
      if (settingsContext.user?.avatar_thumb_meta) {
        const avatarThumbURI = imageDataParser(
          settingsContext.user,
          'avatar_thumb_meta'
        );
        setAvatar(avatarThumbURI);
      }
      settingsContext.user &&
        setUserName(
          settingsContext.user.first_name + ' ' + settingsContext.user.last_name
        );
    }
    defaultFetch();
  }, [settingsContext.user]);

  const selectStyleText = (pathItem) => {
    return location.pathname === pathItem
      ? styleTextSelected
      : styleTextDefault;
  };

  const selectStyleIcon = (pathItem) => {
    return location.pathname === pathItem
      ? styleIconSelected
      : styleIconDefault;
  };

  return (
    <CustomNavMenu>
      <CustomList disablePadding>
        <div>
          <ListItem disablePadding>
            <CustomLink to={`${APP_ROUTES.STAMP.INDEX}`}>
              <ListItemButton sx={styleButton}>
                <CustomListItemIcon>
                  <StampIcon style={selectStyleIcon(APP_ROUTES.STAMP.INDEX)} />
                </CustomListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ noWrap: false }}
                  sx={selectStyleText(APP_ROUTES.STAMP.INDEX)}
                  primary={intl.formatMessage({ id: 'app.sidemenu.stamp' })}
                />
              </ListItemButton>
            </CustomLink>
          </ListItem>
          <ListItem disablePadding>
            <CustomLink to={`${APP_ROUTES.MY_DOCUMENTS}`}>
              <ListItemButton sx={styleButton}>
                <CustomListItemIcon>
                  <MyDocumentsIcon
                    style={selectStyleIcon(APP_ROUTES.MY_DOCUMENTS)}
                  />
                </CustomListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ noWrap: false }}
                  sx={selectStyleText(APP_ROUTES.MY_DOCUMENTS)}
                  primary={intl.formatMessage({
                    id: 'app.sidemenu.my-documents',
                  })}
                />
              </ListItemButton>
            </CustomLink>
          </ListItem>
          <ListItem disablePadding>
            <CustomLink to={`${APP_ROUTES.FIND}`} target={'_blank'}>
              <ListItemButton sx={styleButton}>
                <CustomListItemIcon>
                  <SearchIcon style={styleIconDefault} />
                </CustomListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ noWrap: false }}
                  sx={styleTextDefault}
                  primary={intl.formatMessage({ id: 'app.sidemenu.search' })}
                />
              </ListItemButton>
            </CustomLink>
          </ListItem>
          <ListItem disablePadding>
            <CustomLink to={`${APP_ROUTES.TEMPLATES}`}>
              <ListItemButton sx={styleButton}>
                <CustomListItemIcon>
                  <TemplatesIcon
                    style={selectStyleIcon(APP_ROUTES.TEMPLATES)}
                    width={'21px'}
                    height={'18px'}
                  />
                </CustomListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ noWrap: false }}
                  sx={selectStyleText(APP_ROUTES.TEMPLATES)}
                  primary={intl.formatMessage({ id: 'app.sidemenu.templates' })}
                />
              </ListItemButton>
            </CustomLink>
          </ListItem>
          <ListItem disablePadding>
            <CustomLink
              to={`${APP_ROUTES.LOGOUT}`}
              onClick={authContext.logout}
            >
              <ListItemButton sx={styleButton}>
                <CustomListItemIcon>
                  <LogoutSharpIcon style={styleIconDefault} />
                </CustomListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ noWrap: false }}
                  sx={styleTextDefault}
                  primary={intl.formatMessage({ id: 'app.sidemenu.logout' })}
                />
              </ListItemButton>
            </CustomLink>
          </ListItem>
        </div>
        <div>
          <ProfileListItem disablePadding>
            <ProfileLink to={`${APP_ROUTES.MY_ACCOUNT.MY_DATA}`}>
              <AvatarContainer>
                {avatar ? (
                  <CustomAvatar src={avatar} />
                ) : (
                  <AvatarPlaceholder src={AvatarPlaceholderIcon} />
                )}
              </AvatarContainer>
              <ProfileLinkText>
                <Typography
                  variant="body1"
                  color="primary"
                  noWrap={false}
                  sx={
                    location.pathname.includes('/my-account')
                      ? styleTextSelected
                      : styleTextDefault
                  }
                  children={intl.formatMessage({
                    id: 'app.sidemenu.my-account',
                  })}
                />
                <Typography
                  variant="body2"
                  noWrap={false}
                  sx={styleTextDefault}
                  children={
                    userName

                    // settingsContext.user?.first_name !== undefined
                    //   ? settingsContext.user.first_name +
                    //     ' ' +
                    //     settingsContext.user.last_name
                    //   : ' '
                  }
                />
              </ProfileLinkText>
            </ProfileLink>
          </ProfileListItem>
        </div>
      </CustomList>
    </CustomNavMenu>
  );
}

export default SideMenu;
