import React, { useState, useEffect, useContext } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useIntl } from 'react-intl';
import { Document, Page } from 'react-pdf';
import useDocument from '../../hooks/useDocument';
import { config } from '../../config';
import Loader from '../Loader';

import { Typography, Grid, IconButton } from '@mui/material';

import OpenInFullRoundedIcon from '@mui/icons-material/OpenInFullRounded';
// import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ImageIcon from '@mui/icons-material/Image';
// import DescriptionIcon from '@mui/icons-material/Description';
// import ListAltIcon from '@mui/icons-material/ListAlt';
// import CollectionsIcon from '@mui/icons-material/Collections';

import { styled } from '@mui/material/styles';

import { FIELD_TYPE_FILE, FIELD_TYPE_TEXT, extensions } from '../../constants';
import {
  filePreviewCheck,
  filePreviewMIMECheck,
  hashShortener,
} from '../../helpers';

import AlertsContext from '../../context/Alerts/AlertsContext';

const DocumentPreviewContainer = styled('div')(({ height, width }) => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: height ? height : '220px',
  width: width ? width : '100%',
  background: '#F5F5F5',
  borderRadius: '8px',
}));

const FileThumbnailContainer = styled('div')({
  pointerEvents: 'none',
  userSelect: 'none',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '75%',
  width: '75%',
  background: '#e5e5e5',
  borderRadius: '8px',
});

const TxtCsvThumbnailContainer = styled('div')({
  pointerEvents: 'none',
  userSelect: 'none',
  background: '#FFFF',
  height: '100%',
  width: '100%',
  borderRadius: '8px',
  border: '1px solid rgba(0, 0, 0, 0.2)',
});

const TextThumbnailContainer = styled(FileThumbnailContainer)({
  background: '#ffffff',
  alignItems: 'flex-start',
  border: '1px solid rgba(0, 0, 0, 0.2)',
  '& p': {
    fontSize: '8px',
    margin: '1rem',
  },
});

const OfficeThumbnailContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: '2rem',
});

const ImageThumbnailContainer = styled('img')({
  maxHeight: '100%',
  maxWidth: '100%',
  height: 'auto',
  width: 'auto',
});

const NoPreviewContainer = styled('div')({
  height: '75%',
  width: '75%',
  background: '#C2C4D1',
  borderRadius: '8px',
  '& div': {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '-2rem',
  },
});

const CustomImageIcon = styled(ImageIcon)({
  height: '40%',
  width: '40%',
  color: '#FFFFFF',
});

const FileInfoContainer = styled('div')({
  position: 'absolute',
  bottom: 0,
  zIndex: 1,
  heigh: '78px',
  width: '100%',
  boxShadow: '0px 0px 14.1026px rgba(221, 221, 221, 0.25)',
  borderRadius: '8px',
});

const InfoContainer = styled('div')(({ footer }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: footer && '#FFFFFF',
  height: '100%',
  width: '100%',
}));

const PreviewButton = styled(IconButton)({
  position: 'absolute',
  top: 5,
  right: 5,
  zIndex: 1,
});

const FileInfoGrid = styled(Grid)({
  padding: '0.4rem',
  width: '100%',
});

const PDFThumbnailDocument = styled(Document)({
  display: 'flex',
  justifyContent: 'center',
  zoom: 0.25,
  pointerEvents: 'none',
  userSelect: 'none',
  height: '90%',
  width: '90%',
  overflow: 'auto',
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  '::-webkit-scrollbar': {
    display: 'none',
  },
});

export const FileInfoDisplay = ({ name, hash, footer = true, copy }) => {
  return (
    <InfoContainer footer={footer}>
      <FileInfoGrid item xs={12} display="flex" flexDirection="row">
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            width: '10%',
          }}
        >
          <CopyToClipboard text={hash}>
            <IconButton
              p={0}
              disableRipple
              children={<ContentCopyIcon fontSize="small" color="primary" />}
              onClick={(e) => copy(e)}
            ></IconButton>
          </CopyToClipboard>
        </div>
        <div style={{ display: 'flex', width: '90%' }}>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                noWrap
                overflow="auto"
                // fontSize="0.5rem"
                variant="caption"
                children={config.hashTypeLabel + ': ' + hashShortener(hash)}
              />
            </Grid>
            {/* <Grid item sx={{ width: '100%' }}>
              <Typography
                sx={{ wordWrap: 'break-word' }}
                variant="caption"
                children={hash}
              />
            </Grid> */}
            <Grid item xs={12}>
              <Typography
                color="#999999"
                noWrap
                variant="caption"
                children={name}
              />
            </Grid>
          </Grid>
        </div>
      </FileInfoGrid>
    </InfoContainer>
  );
};

export default function DocumentPreview({
  document,
  expanded = true,
  height,
  width,
  handleOpenPreview,
}) {
  const [previewEnabled, setPreviewEnabled] = useState();
  const [thumbnail, setThumbnail] = useState([]);
  const [textThumbnail, setTextThumbnail] = useState();
  const [txtCsvThumbnail, setTxtCsvThumbnail] = useState();

  const intl = useIntl();
  const doc = useDocument();

  const alertsContext = useContext(AlertsContext);

  useEffect(() => {
    if (document) {
      if (document.document_type === FIELD_TYPE_FILE) {
        setPreviewEnabled(filePreviewCheck(document.file_name));
        if (expanded && thumbnail.length === 0) {
          doc.fetchDownloadUrl(document.file);
        }
      } else {
        setPreviewEnabled(true);
      }
    }
  }, [document]);

  useEffect(() => {
    if (
      expanded &&
      previewEnabled &&
      document.document_type === FIELD_TYPE_FILE &&
      thumbnail.length === 0
    ) {
      doc.fetchDownloadUrl(document.file);
    }
  }, [expanded]);

  useEffect(() => {
    async function thumbnailSet() {
      if (doc.downloadUrl && document.document_type === FIELD_TYPE_FILE) {
        const fileInfo = filePreviewMIMECheck(doc.downloadUrl);
        if (
          fileInfo.extension === extensions.txt ||
          fileInfo.extension === extensions.csv
        ) {
          const response = await fetch(doc.downloadUrl);
          const txt = await response.text();
          setTxtCsvThumbnail(txt);
        } else {
          setThumbnail([
            {
              uri: doc.downloadUrl,
              fileType: fileInfo.typeMatched,
            },
          ]);
        }
      } else if (document.document_type === FIELD_TYPE_TEXT) {
        setTextThumbnail(document.text_value);
      }
    }
    thumbnailSet();
  }, [doc.downloadUrl]);

  // const OfficeThumbnail = ({ name, icon }) => {
  //   return (
  //     <OfficeThumbnailContainer>
  //       <Typography variant="body2" children={name} />
  //       {icon}
  //     </OfficeThumbnailContainer>
  //   );
  // };

  const FileThumbnail = (fileUrl, txtCsvFile) => {
    const fileInfo = filePreviewMIMECheck(fileUrl);

    if (txtCsvFile) {
      return (
        <TxtCsvThumbnailContainer>
          <p style={{ zoom: 0.25, margin: '4rem' }}>{txtCsvFile}</p>
        </TxtCsvThumbnailContainer>
      );
    }

    switch (fileInfo.extension) {
      case extensions.pdf:
        return (
          <PDFThumbnailDocument file={fileUrl}>
            <Page pageNumber={1} />
          </PDFThumbnailDocument>
        );
      // case extensions.doc:
      // case extensions.docx:
      //   return (
      //     <OfficeThumbnail
      //       name={intl.formatMessage({
      //         id: 'app.mydocuments.file-preview-word',
      //       })}
      //       icon={
      //         <DescriptionIcon sx={{ fontSize: '2.5rem', color: '#00a2ed' }} />
      //       }
      //     />
      //   );
      // case extensions.xls:
      // case extensions.xlsx:
      //   return (
      //     <OfficeThumbnail
      //       name={intl.formatMessage({
      //         id: 'app.mydocuments.file-preview-excel',
      //       })}
      //       icon={<ListAltIcon sx={{ fontSize: '2.5rem', color: '		#1D6F42' }} />}
      //     />
      //   );
      // case extensions.ppt:
      // case extensions.pptx:
      //   return (
      //     <OfficeThumbnail
      //       name={intl.formatMessage({
      //         id: 'app.mydocuments.file-preview-powerpoint',
      //       })}
      //       icon={
      //         <CollectionsIcon sx={{ fontSize: '2.5rem', color: '#D35230' }} />
      //       }
      //     />
      //   );
      case extensions.jpg:
      case extensions.jpeg:
      case extensions.png:
        return <ImageThumbnailContainer src={fileUrl} alt="thumbnail" />;
      default:
        return <Loader />;
    }
  };

  const handleCopy = (e) => {
    e.preventDefault();
    e.stopPropagation();
    alertsContext.createSuccessAlert(
      intl.formatMessage(
        { id: 'app.share-button.success-copy-hash' },
        { hashType: config.hashTypeLabel }
      )
    );
  };

  return (
    <DocumentPreviewContainer height={height} width={width}>
      {previewEnabled && (
        <PreviewButton
          disableRipple
          children={<OpenInFullRoundedIcon fontSize="small" color="primary" />}
          onClick={() => {
            handleOpenPreview(document);
          }}
        />
      )}
      {previewEnabled && thumbnail && !textThumbnail ? (
        <FileThumbnailContainer>
          {FileThumbnail(thumbnail[0]?.uri, txtCsvThumbnail)}
        </FileThumbnailContainer>
      ) : textThumbnail ? (
        <TextThumbnailContainer>
          <p>{textThumbnail}</p>
        </TextThumbnailContainer>
      ) : (
        <NoPreviewContainer>
          <div>
            <CustomImageIcon />
            <Typography
              variant="body2"
              color="white"
              textAlign="center"
              children={intl.formatMessage({
                id: 'app.mydocuments.no-preview',
              })}
            />
          </div>
        </NoPreviewContainer>
      )}
      <FileInfoContainer>
        <FileInfoDisplay
          name={
            document.file_name ||
            intl.formatMessage({
              id: 'app.mydocuments.file-preview-info-doc-text-type',
            })
          }
          hash={document.file_hash}
          copy={handleCopy}
        />
      </FileInfoContainer>
    </DocumentPreviewContainer>
  );
}
