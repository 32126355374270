import React, { useState, useContext } from 'react';

import { Paper, Typography, Fade, Grid } from '@mui/material';

import { styled } from '@mui/material/styles';

import CollectionsContext from '../../context/Collections/CollectionsContext';
import SharedProvider from '../../context/Shared/SharedProvider';

import DocumentCard from './DocumentCard';
import FilePreviewer from '../FilePreviewer';

import ShareCollectionsButton from './ShareCollectionsButton';

import InlineAlert from '../InlineAlert';

import { useIntl } from 'react-intl';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  paddingLeft: theme.spacing(16),
  boxShadow: 'none',
  backgroundColor: '#FCFCFC',
  '@media (max-width: 600px)': {
    padding: 0,
  },
}));

const ContentBlock = styled('div')({
  paddingTop: '20px',
  clear: 'both',
});

const ShareBlockContainer = styled('div')({
  float: 'right',
  paddingTop: '10px',
  '@media (max-width: 900px)': {
    display: 'flex',
    padding: '1rem 0rem',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  '@media (max-width: 600px)': {
    display: 'none',
  },
});

const ShareBlockBottomContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  paddingTop: '10px',
  '& button': {
    margin: '1rem',
  },
  '@media (min-width: 600px)': {
    display: 'none',
  },
});

export default function CollectionShared(props) {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewDocument, setPreviewDocument] = useState(undefined);
  const collectionsContext = useContext(CollectionsContext);

  const intl = useIntl();

  function handleOpenPreview(document) {
    setPreviewDocument(document);
    setPreviewOpen(true);
  }

  function handleClosePreview() {
    setPreviewOpen(false);
  }

  return (
    <>
      <FilePreviewer
        open={previewOpen}
        onClose={handleClosePreview}
        document={previewDocument}
      />
      <Fade timeout={1000} in={true}>
        <Grid>
          {collectionsContext.errors && (
            <InlineAlert
              title={intl.formatMessage({
                id: 'app.alerts.inline-error-title',
              })}
              severity="error"
              alertText={collectionsContext.errors}
            />
          )}
          {collectionsContext.collection && (
            <SharedProvider>
              <Item>
                <div
                  style={{
                    float: 'left',
                    '@media (max-width: 900px)': {
                      display: 'flex',
                      padding: '2rem 0rem 1rem 0rem',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                    },
                  }}
                >
                  <div>
                    <Typography
                      htmlColor={props.customColor}
                      variant="subtitle1"
                      component="h5"
                    >
                      {intl.formatMessage({ id: 'app.collection.name' })}
                    </Typography>
                    <Typography
                      variant="subtitle"
                      component="h2"
                      color="primary"
                    >
                      {collectionsContext.collection.name}
                    </Typography>
                  </div>
                </div>
                <ShareBlockContainer>
                  <SharedProvider>
                    <ShareCollectionsButton
                      collectionId={collectionsContext.collection.id}
                      customColor={props.customColor}
                    />
                  </SharedProvider>
                </ShareBlockContainer>
              </Item>
              <ContentBlock>
                {collectionsContext.collection.documents.map(
                  (document, index) => (
                    <DocumentCard
                      key={index}
                      document={document}
                      isShareable={true}
                      expanded
                      customColor={props.customColor}
                      handleOpenPreview={handleOpenPreview}
                      textValidated={{
                        title: intl.formatMessage({
                          id: 'app.search.validate',
                        }),
                      }}
                      textNoValidated={{
                        title: intl.formatMessage({
                          id: 'app.search.novalidate',
                        }),
                        desc: intl.formatMessage({
                          id: 'app.search.novalidate-desc',
                        }),
                      }}
                    />
                  )
                )}
              </ContentBlock>
              <ShareBlockBottomContainer>
                <ShareCollectionsButton
                  collectionId={collectionsContext.collection.id}
                />
              </ShareBlockBottomContainer>
            </SharedProvider>
          )}
        </Grid>
      </Fade>
    </>
  );
}
