import React, { useEffect, useContext } from 'react';

import TemplatesProvider from '../context/Templates/TemplatesProvider';
import TiersContext from '../context/Tiers/TiersContext';

import { PrivateLayout } from '../components/PrivateLayout';
import TemplatesEditor from '../components/TemplatesEditor';
import NoAccessRedirect from '../components/NoAccessRedirect';

import { useIntl } from 'react-intl';

export default function NewTemplatePage() {
  const intl = useIntl();
  const tiersContext = useContext(TiersContext);
  
  document.title = intl.formatMessage({ id: 'app.templates.title' });

  return (
    <PrivateLayout
      title={intl.formatMessage({ id: 'app.templates.title' })}
      subtitle={intl.formatMessage({ id: 'app.templates.subtitle' })}
    >
      <TemplatesProvider>
        <TemplatesEditor
          templateSupport={tiersContext.userTier.templateSupport?.enabled}
        />
        {tiersContext.userTier.templateSupport?.enabled === false && (
          <NoAccessRedirect
            title={intl.formatMessage({ id: 'app.noaccess.title' })}
            text={intl.formatMessage({ id: 'app.noaccess.text' })}
          />
        )}
      </TemplatesProvider>
    </PrivateLayout>
  );
}
