export const GET_TEMPLATE_ID = "GET_TEMPLATE_ID";
export const GET_TEMPLATES = "GET_TEMPLATES";
export const GET_TEMPLATES_SEARCH = "GET_TEMPLATES_SEARCH";
export const POST_TEMPLATE = "POST_TEMPLATE";
export const EDIT_TEMPLATE = "EDIT_TEMPLATE";
export const DELETE_TEMPLATE = "DELETE_TEMPLATE";
export const LOADING_TEMPLATES = "LOADING_TEMPLATES";
export const ERROR_TEMPLATES = "ERROR_TEMPLATES";
export const STATUS_ERROR = "STATUS_ERROR";
export const STATUS_DISCONNECT_ERROR = "STATUS_DISCONNECT_ERROR";
