import React, { useReducer, useContext } from 'react';
import { STATUS_OK, REQUEST_ERROR } from '../../constants';

import AlertsContext from '../Alerts/AlertsContext';

import NotificationsContext from './NotificationsContext';
import NotificationsReducer from './NotificationsReducer';

import {
  PUT_MARK_NOTIFICATION_AS_READ,
  GET_NOTIFICATIONS,
  GET_SCROLL_NOTIFICATIONS,
  NOTIFICATIONS_ERROR,
  SET_PULLED,
  NOTIFICATIONS_LOADING,
} from './NotificationsTypes';

import {
  getNotificationsList,
  putMarkNotificationAsRead,
} from '../../api/notification';

export default function NotificationsProvider(props) {
  const initialState = {
    notifications: [],
    offsetPage: 1,
    pulled: false,
    loading: false,
    error: false,
  };
  const [state, dispatch] = useReducer(NotificationsReducer, initialState);

  const alertsContext = useContext(AlertsContext);

  async function getNotifications(
    offset = 0,
    limit = 1000,
    sortDesc,
    context,
    code,
    isRead,
    infiniteScroll
  ) {
    if (!infiniteScroll) {
      dispatch({ type: NOTIFICATIONS_LOADING });
    }
    try {
      const response = await getNotificationsList(
        offset,
        limit,
        sortDesc,
        context,
        code,
        isRead
      );
      if (response.status === STATUS_OK) {
        if (!infiniteScroll) {
          dispatch({
            type: GET_NOTIFICATIONS,
            payload: response.data.rows,
          });
        } else {
          dispatch({
            type: GET_SCROLL_NOTIFICATIONS,
            payload: response.data.rows,
          });
          return response.data.rows;
        }
        setPulled(true);
      } else {
        dispatch({
          type: NOTIFICATIONS_ERROR,
          payload: response.data.errors,
          status: REQUEST_ERROR,
        });
        alertsContext.createErrorAlert(response.data.errors);
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: NOTIFICATIONS_ERROR,
        payload: error,
      });
    }
  }

  async function markNotificationAsRead(notificationId) {
    try {
      const response = await putMarkNotificationAsRead(notificationId);
      if (response.status === STATUS_OK) {
        dispatch({
          type: PUT_MARK_NOTIFICATION_AS_READ,
        });
        const markedNotification = response.data;
        state.notifications.forEach((notification, i) => {
          if (notification.id === markedNotification.id) {
            const newNotifications = Array.from(state.notifications);
            newNotifications.splice(i, 1);
            dispatch({ type: GET_NOTIFICATIONS, payload: newNotifications });
          }
        });
      } else {
        dispatch({
          type: NOTIFICATIONS_ERROR,
          payload: response.data.errors,
        });
        alertsContext.createErrorAlert(response.data.errors);
      }
    } catch (error) {
      dispatch({
        type: NOTIFICATIONS_ERROR,
        payload: error.data,
      });
    }
  }

  function setPulled(value) {
    dispatch({ type: SET_PULLED, payload: value });
  }

  return (
    <NotificationsContext.Provider
      value={{
        notifications: state.notifications,
        offsetPage: state.offsetPage,
        pulled: state.pulled,
        loading: state.loading,
        error: state.error,
        getNotifications,
        markNotificationAsRead,
        setPulled,
      }}
    >
      {props.children}
    </NotificationsContext.Provider>
  );
}
