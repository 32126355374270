import React, { useState } from 'react';
import { FIELD_TYPE_FILE, FIELD_TYPE_TEXT } from '../constants';
import ConfirmModal from './ConfirmModal';

import { styled } from '@mui/material/styles';

import { useIntl } from 'react-intl';

import {
  Grid,
  Paper,
  Typography,
  IconButton,
} from '@mui/material';

import { ReactComponent as DragFieldIcon } from '../assets/DragFieldIcon.svg';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const FieldPaper = styled(Paper)({
  display: 'flex',
  flexDirection: 'row',
  padding: '0.5rem',
  borderRadius: '15px',
  marginBottom: '1rem',
});

const FieldGrid = styled(Grid)({
  padding: '1rem 0rem',
});

const TitleContainer = styled('div')({
  maxWidth: '100%',
  display: 'flex',
  flexDirection: 'row',
  '@media (max-width: 900px)': {
    flexDirection: 'column',
    alignItems: 'center',
  },
});

const TitleGrid = styled(Grid)({
  '@media (max-width: 900px)': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
});

const ButtonsGrid = styled(Grid)({
  display: 'flex',
  justifyContent: 'center',
});

export default function TemplateCreatedField(props) {
  const [deleteModal, setDeleteModal] = useState(false);

  const intl = useIntl();

  function HandleOpenDeleteModal() {
    setDeleteModal(true);
  }

  function handleCloseDeleteModal() {
    setDeleteModal(false);
  }

  function fieldTypeName(type) {
    switch (type) {
      case FIELD_TYPE_TEXT:
        return intl.formatMessage({ id: 'app.templates.new.field-text' });
      case FIELD_TYPE_FILE:
        return intl.formatMessage({ id: 'app.templates.new.field-file' });
      default:
        break;
    }
  }

  return (
    <>
      <ConfirmModal
        open={deleteModal}
        onClose={handleCloseDeleteModal}
        modalTitle={intl.formatMessage({
          id: 'app.templates.new.delete-field-title',
        })}
        modalText={intl.formatMessage({
          id: 'app.templates.new.delete-field',
        })}
        confirmAction={() => {
          props.handleDeleteField(props.fields, props.index);
        }}
        cancelAction={handleCloseDeleteModal}
      />
      <Grid item md={8} xs={12}>
        <FieldPaper elevation={2}>
          <FieldGrid container alignItems="center">
            <Grid
              item
              xs={1}
              sx={{ '@media (max-width: 900px)': { position: 'absolute' } }}
            >
              <IconButton
                {...props.dragProps.dragHandleProps}
                size="small"
                disableRipple
                children={<DragFieldIcon sx={{ color: '#C4C4C4' }} />}
              />
            </Grid>
            <TitleGrid item xs={12} md={9}>
              <TitleContainer>
                <Typography
                  variant="body2"
                  children={props.fieldTitle}
                  sx={{
                    wordWrap: 'break-word',
                    '@media (max-width: 420px)': {
                      fontSize: '3vw',
                    },
                  }}
                />
                {props.required && (
                  <Typography
                    variant="body2"
                    children={intl.formatMessage({
                      id: 'app.templates.field.required',
                    })}
                    sx={{
                      color: '#3969E8',
                      marginLeft: '1rem',
                      '@media (max-width: 900px)': {
                        marginLeft: 0,
                      },
                      '@media (max-width: 420px)': {
                        fontSize: '3vw',
                      },
                    }}
                  />
                )}
              </TitleContainer>
              <Typography
                sx={{
                  color: '#999999',
                  '@media (max-width: 420px)': {
                    fontSize: '3vw',
                  },
                }}
                variant="body1"
                children={fieldTypeName(props.fieldType)}
              />
            </TitleGrid>
            <ButtonsGrid item xs={12} md={2}>
              <IconButton
                size="small"
                onClick={() => {
                  props.handleOpenEditModal(props.index);
                }}
                children={
                  <EditIcon fontSize="medium" sx={{ color: '#3969E8' }} />
                }
              />
              <IconButton
                size="small"
                onClick={() => {
                  HandleOpenDeleteModal();
                }}
                sx={{ marginLeft: '0.5rem' }}
                children={
                  <DeleteOutlineIcon
                    fontSize="medium"
                    sx={{ color: '#EB5757' }}
                  />
                }
              />
            </ButtonsGrid>
          </FieldGrid>
        </FieldPaper>
      </Grid>
    </>
  );
}
