import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { animateScroll } from 'react-scroll';

import { styled } from '@mui/material/styles';

import { useIntl } from 'react-intl';

import { Grid, TextField, InputLabel } from '@mui/material';

import Loader from '../Loader';
import BottomButtons from './BottomButtons';

import UserContext from '../../context/Users/UserContext';
import SettingsContext from '../../context/Settings/SettingsContext';
import { APP_ROUTES } from '../../constants';

const InputsContainerGrid = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '& input': {
    width: '30rem',
  },
});

export default function SecurityChangePassword(props) {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [error, setError] = useState(false);

  const intl = useIntl();
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const settingsContext = useContext(SettingsContext);

  function handleChangeOldPassword(event) {
    props.setIsChanged(true);
    setOldPassword(event.target.value);
  }

  function handleChangeNewPassword(event) {
    props.setIsChanged(true);
    setNewPassword(event.target.value);
  }

  function handleChangeConfirmNewPassword(event) {
    props.setIsChanged(true);
    setConfirmNewPassword(event.target.value);
  }

  function handleReset() {
    setOldPassword('');
    setNewPassword('');
    setConfirmNewPassword('');
    setError(false);
  }

  function validatePassword() {
    setError(false);
    if (newPassword === confirmNewPassword) {
      return true;
    }
    setError(true);
    return false;
  }

  function handleSubmit() {
    if (validatePassword()) {
      userContext.changePasswordManually(oldPassword, confirmNewPassword);
    }
  }

  useEffect(() => {
    if (userContext.passwordSuccess && !userContext.passwordFailed) {
      handleReset();
    }
  }, [userContext.passwordSuccess]);

  return (
    <>
      <InputsContainerGrid container mt={2} spacing={2}>
        <Grid item sm={6} xs={12}>
          <InputLabel htmlFor="old-pass" sx={{ mb: 1 }}>
            {intl.formatMessage({
              id: 'app.my-account.security.input-old-password',
            })}
          </InputLabel>
          <TextField
            id="old-pass"
            required={true}
            type="password"
            fullWidth
            value={oldPassword}
            disabled={settingsContext.loading}
            onChange={handleChangeOldPassword}
            placeholder={intl.formatMessage({
              id: 'app.my-account.security.input-old-password-placeholder',
            })}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <InputLabel htmlFor="new-pass" sx={{ mb: 1 }}>
            {intl.formatMessage({
              id: 'app.my-account.security.input-new-password',
            })}
          </InputLabel>
          <TextField
            id="new-pass"
            required={true}
            type="password"
            fullWidth
            value={newPassword}
            disabled={settingsContext.loading}
            onChange={handleChangeNewPassword}
            onBlur={() => {
              confirmNewPassword && validatePassword();
            }}
            error={error}
            helperText={
              error &&
              intl.formatMessage({
                id: 'app.my-account.security.passwords-must-be-same',
              })
            }
            placeholder={intl.formatMessage({
              id: 'app.my-account.security.input-new-password-placeholder',
            })}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <InputLabel htmlFor="confirm-new-pass" sx={{ mb: 1 }}>
            {intl.formatMessage({
              id: 'app.my-account.security.input-repeat-password',
            })}
          </InputLabel>
          <TextField
            id="confirm-new-pass"
            required={true}
            type="password"
            fullWidth
            value={confirmNewPassword}
            disabled={settingsContext.loading}
            onChange={handleChangeConfirmNewPassword}
            onBlur={validatePassword}
            error={error}
            helperText={
              error &&
              intl.formatMessage({
                id: 'app.my-account.security.passwords-must-be-same',
              })
            }
            placeholder={intl.formatMessage({
              id: 'app.my-account.security.input-repeat-password-placeholder',
            })}
          />
        </Grid>
        <Grid item justifyContent="center">
          {userContext.passwordRequested && <Loader margin={'6rem'} />}
        </Grid>
      </InputsContainerGrid>
      {props.isChanged && (
        <BottomButtons
          disabled={
            !confirmNewPassword ||
            confirmNewPassword.length < 6 ||
            oldPassword.length < 6 ||
            userContext.passwordRequested ||
            settingsContext.loading
          }
          confirmText={intl.formatMessage({
            id: 'app.my-account.security.button-change',
          })}
          confirmAction={handleSubmit}
          cancelText={intl.formatMessage({
            id: 'app.my-account.cancel-button',
          })}
          cancelAction={() => {
            navigate(APP_ROUTES.MY_DOCUMENTS);
            animateScroll.scrollToTop();
          }}
        />
      )}
    </>
  );
}
