import {
  REGISTER_REQUEST,
  REGISTER_FAILURE,
  REGISTER_SUCCESS,
  ACTIVATE_FAILURE,
  ACTIVATE_SUCCESS,
  PASSWORD_REQUEST,
  PASSWORD_SUCCESS,
  PASSWORD_FAILURE,
} from './UserTypes';

export default function UserReducer(state, action) {
  const { payload, type } = action;

  switch (type) {
    case REGISTER_REQUEST:
      return {
        ...state,
        registerRequested: true,
        registerFailed: false,
        registerSuccess: false,
        errors: undefined,
      };
    case REGISTER_FAILURE:
      return {
        ...state,
        registerRequested: false,
        registerFailed: true,
        registerSuccess: false,
        errors: payload,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        registerRequested: false,
        registerFailed: false,
        registerSuccess: true,
      };
    case ACTIVATE_SUCCESS:
      return {
        ...state,
        activateFailed: false,
        activateSuccess: true,
        errors: undefined,
      };
    case ACTIVATE_FAILURE:
      return {
        ...state,
        activateFailed: true,
        activateSuccess: false,
        errors: payload,
      };
    case PASSWORD_REQUEST:
      return {
        ...state,
        passwordRequested: true,
        passwordFailed: false,
        passwordSuccess: false,
        errors: undefined,
      };
    case PASSWORD_FAILURE:
      return {
        ...state,
        passwordRequested: false,
        passwordFailed: true,
        passwordSuccess: false,
        errors: payload,
      };
    case PASSWORD_SUCCESS:
      return {
        ...state,
        passwordRequested: false,
        passwordFailed: false,
        passwordSuccess: true,
        errors: undefined,
      };
    default:
      return state;
  }
}
