const sanitize = require('sanitize-filename');
const { config } = require('../config/index');

const {
  FIELD_TYPE_FILE,
  FIELD_TYPE_TEXT,
  MIMETypes,
  extensions,
} = require('../constants/index');

const sanitizeSpecialCharacters = (str) => {
  return sanitize(str);
};

const appendToFilename = (filename, str) => {
  const dotIndex = filename.lastIndexOf('.');
  if (dotIndex == -1) {
    return filename + str;
  } else {
    return filename.substring(0, dotIndex) + str + filename.substring(dotIndex);
  }
};

const renameFilesDuplicatesZip = (documents) => {
  let temp = {};
  for (let i in documents) {
    for (let k in documents[i]) {
      if (temp[documents[i][k]]) {
        temp[documents[i][k]] += 1;
        documents[i][k] = appendToFilename(
          documents[i][k],
          `(${temp[documents[i][k]] - 1})`
        );
      } else {
        temp[documents[i][k]] = 1;
      }
    }
  }
  return documents;
};

const fileTypeName = (type) => {
  switch (type) {
    case FIELD_TYPE_FILE:
      return 'app.mydocuments.doc-file-type';
    case FIELD_TYPE_TEXT:
      return 'app.mydocuments.doc-text-type';
    default:
      break;
  }
};

const stampCharacterReplace = (text) => {
  const replacedText = text.replace(/"/g, `${config.doubleQuoteReplacement}`);
  return replacedText;
};

const imageDataParser = (entity, metaKey) => {
  if (entity && entity[metaKey]) {
    try {
      const meta = JSON.parse(entity[metaKey]);
      return meta.publicFileURI;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  } else {
    return undefined;
  }
};

const filePreviewMIMECheck = (documentText) => {
  let fileInfo = {};
  const exts = Object.values(extensions).map((ext) => ext.toUpperCase());
  if (documentText) {
    const docExtension = documentText.split('.').pop().trim().toUpperCase();
    if (exts.includes(docExtension)) {
      fileInfo['extension'] = docExtension.toLowerCase();
      fileInfo['typeMatched'] = MIMETypes[docExtension.toLowerCase()];
    } else {
      fileInfo = false;
    }
  }
  return fileInfo;
};

const filePreviewCheck = (fileName) => {
  const mimeType = filePreviewMIMECheck(fileName);
  const msOfficeMimeTypes = [
    MIMETypes.doc,
    MIMETypes.docx,
    MIMETypes.xls,
    MIMETypes.xlsx,
    MIMETypes.ppt,
    MIMETypes.pptx,
  ];
  if (mimeType) {
    if (msOfficeMimeTypes.includes(mimeType)) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
};

const hashShortener = (hash) => {
  return hash.slice(0, 8) + '...' + hash.slice(-8);
};

module.exports = {
  sanitizeSpecialCharacters,
  appendToFilename,
  renameFilesDuplicatesZip,
  fileTypeName,
  stampCharacterReplace,
  imageDataParser,
  filePreviewMIMECheck,
  filePreviewCheck,
  hashShortener,
};
