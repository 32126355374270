import { getMediaUploadUrl, saveMediaFile } from '../api/file';

import { EDITOR_NO_IMAGE } from '../constants';

const axios = require('axios');
const instance = axios.create({});

const uploadMedia = (file) => {
  if (file) {
    return getMediaUploadUrl().then((getResponse) => {
      return instance
        .put(getResponse.data.url, file, {
          headers: {
            'Content-Type': 'image/png',
          },
        })
        .then(() => {
          const data = {
            storage_name: getResponse.data.storage_name,
            original_name: file.name,
          };
          return saveMediaFile(data);
        });
    });
  } else {
    return EDITOR_NO_IMAGE;
  }
};

export { uploadMedia };
