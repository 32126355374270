import React, { useRef, useContext } from 'react';

import AlertsContext from '../../context/Alerts/AlertsContext';
import AvatarEditor from 'react-avatar-editor';
import Dropzone from 'react-dropzone';

import {
  Typography,
  Slider,
  IconButton,
  CircularProgress,
  useMediaQuery,
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Rotate90DegreesCcwIcon from '@mui/icons-material/Rotate90DegreesCcw';
import Rotate90DegreesCwIcon from '@mui/icons-material/Rotate90DegreesCw';
import UploadIcon from '@mui/icons-material/Upload';
import ClearIcon from '@mui/icons-material/Clear';

import { styled } from '@mui/material/styles';

const ImageEditorContainer = styled('div')((props) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const ImageUploaderContainer = styled('div')(({ size }) => ({
  height: size ? `${size}px` : '210px',
  width: size ? `${size * 3}px` : '210px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '1rem',
  borderRadius: '1rem',
  border: '1px solid rgba(0, 0, 0, 0.2)',
  '& svg': {
    fontSize: '3rem',
  },
  '@media (max-width: 600px)': {
    height: size ? `${size / 6}vw` : '50vw',
    width: size ? `${size / 2}vw` : '50vw',
    '& svg': {
      fontSize: '8vw',
    },
  },
}));

const EditorOptionsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: '1rem 0rem',
});

const SliderContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  '& svg': {
    margin: '0.5rem',
  },
});

const RotateButtonsContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  width: '100%',
});

const CustomSlider = styled(Slider)({
  color: '#3969E8',
  '.MuiSlider-thumb': {
    height: '1rem',
    width: '1rem',
  },
  '@media (max-width: 600px)': {
    width: '45vw',
  },
});

const CustomButton = styled(IconButton)({
  background: '#3969E8',
  color: 'white',
});

export default function ImageEditor({
  image,
  imageZoom,
  imageRotation,
  disableRotation,
  logoEditor,
  loading,
  disabled,
  handleChangeZoom,
  handleRotateImage,
  setImage,
  setImageCropped,
  setImageThumbURL,
  setIsChanged,
  deleteCropMessage,
  uploadPrompt,
}) {
  const imageRef = useRef(null);
  const alertsContext = useContext(AlertsContext);
  const mobile = useMediaQuery('(max-width: 600px)');

  function croppedImageCreator(imageName) {
    const imageCanvas = imageRef.current.getImageScaledToCanvas();
    imageCanvas.toBlob((blob) => {
      logoEditor && setImageThumbURL(URL.createObjectURL(blob));
      const imageFile = new File([blob], imageName, {
        type: 'image/png',
      });
      setImageCropped(imageFile);
    });
  }

  function handleDeleteImage() {
    setIsChanged(true);
    setImage(null);
    setImageCropped(null);
    logoEditor && setImageThumbURL(null);
    alertsContext.createSuccessAlert(deleteCropMessage);
  }

  return (
    <ImageEditorContainer size={logoEditor + 30}>
      {image && !loading ? (
        <AvatarEditor
          image={image}
          height={logoEditor ? logoEditor : 150}
          width={logoEditor ? logoEditor * 3 : 150}
          border={30}
          color={[0, 0, 0, 0.6]}
          scale={imageZoom}
          borderRadius={logoEditor ? 10 : 1000}
          rotate={imageRotation}
          ref={imageRef}
          disableBoundaryChecks={Boolean(logoEditor)}
          onImageReady={() => {
            croppedImageCreator(image.name);
          }}
          onMouseUp={() => {
            croppedImageCreator(image.name);
            setIsChanged(true);
          }}
          style={
            mobile && logoEditor
              ? {
                  height: `auto`,
                  width: `80vw`,
                }
              : null
          }
        />
      ) : (
        <Dropzone
          onDrop={(drop) => setImage(drop[0])}
          noClick={loading}
          noKeyboard
          disabled={disabled}
        >
          {({ getRootProps, getInputProps }) => {
            return (
              <div>
                <ImageUploaderContainer
                  size={logoEditor + 30}
                  {...getRootProps()}
                >
                  {!loading ? (
                    <>
                      <UploadIcon
                        htmlColor={!disabled ? '#3969E8' : '#a0a0a0'}
                        fontSize="large"
                      />
                      <Typography variant="body2" children={uploadPrompt} />
                    </>
                  ) : (
                    <CircularProgress />
                  )}
                </ImageUploaderContainer>
                <input
                  {...getInputProps()}
                  data-testid="uploader"
                  accept="image/png, image/jpeg, image/jpg"
                />
              </div>
            );
          }}
        </Dropzone>
      )}
      <EditorOptionsContainer>
        <SliderContainer>
          <RemoveIcon />
          <CustomSlider
            value={imageZoom}
            min={logoEditor ? 0.3 : 1}
            max={3}
            step={0.05}
            disabled={loading || !image}
            onChange={handleChangeZoom}
            onMouseUp={() => {
              croppedImageCreator(image.name);
              setIsChanged(true);
            }}
          />
          <AddIcon />
        </SliderContainer>
        <RotateButtonsContainer>
          {!disableRotation && (
            <>
              <IconButton
                disabled={!image || loading}
                onClick={async () => {
                  await handleRotateImage('left');
                  croppedImageCreator(image.name);
                }}
                children={<Rotate90DegreesCcwIcon />}
              />
              <IconButton
                disabled={!image || loading}
                onClick={async () => {
                  await handleRotateImage('right');
                  croppedImageCreator(image.name);
                }}
                children={<Rotate90DegreesCwIcon />}
              />
            </>
          )}
          <CustomButton
            disabled={!image || loading}
            onClick={() => {
              handleDeleteImage();
            }}
            children={<ClearIcon />}
          />
        </RotateButtonsContainer>
      </EditorOptionsContainer>
    </ImageEditorContainer>
  );
}
