export const config = {
  apiBaseUrl: process.env.REACT_APP_BASE_URL,
  env: process.env.REACT_APP_ENV,
  templateDefaultId: parseInt(process.env.REACT_APP_TEMPLATE_DEFAULT_ID),
  hashTypeLabel: process.env.REACT_APP_HASH_TYPE_LABEL,
  maxLengthTitle:
    parseInt(process.env.REACT_APP_MAX_LENGTH_TITLE) > 255
      ? 255
      : parseInt(process.env.REACT_APP_MAX_LENGTH_TITLE),
  doubleQuoteReplacement:
    process.env.REACT_APP_STAMP_DOUBLE_QUOTE_REPLACEMENT.toString(),
  stampAutoUpdateDelayMs: process.env.REACT_APP_STAMP_AUTOUPDATE_DELAY_MS,
  testsUser: process.env.REACT_APP_TESTS_USER.toString(),
  testsPassword: process.env.REACT_APP_TESTS_PASSWORD.toString(),
};
