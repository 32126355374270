import React, { useReducer, useContext } from 'react';
import { useIntl } from 'react-intl';
import { STATUS_OK, STATUS_CREATED, EDITOR_NO_IMAGE } from '../../constants';

import { uploadMedia } from '../../services/mediaUpload';

import SettingsContext from './SettingsContext';
import SettingsReducer from './SettingsReducer';

import AlertsContext from '../Alerts/AlertsContext';

import {
  GET_USER,
  GET_SETTINGS,
  EDIT_USER,
  EDIT_SETTINGS,
  LOADING_SETTINGS,
  SETTINGS_ERROR,
} from './SettingsTypes';

import {
  getBrand,
  getUser,
  editUser,
  postCustomBrand,
  editCustomBrand,
} from '../../api/user';

export default function SettingsProvider(props) {
  const initialState = {
    user: undefined,
    settings: undefined,
    loading: false,
    error: false,
  };

  const [state, dispatch] = useReducer(SettingsReducer, initialState);

  const alertsContext = useContext(AlertsContext);
  const intl = useIntl();

  async function getUserData() {
    dispatch({ type: LOADING_SETTINGS });
    try {
      const response = await getUser();
      if (response.status === STATUS_OK) {
        dispatch({ type: GET_USER, payload: response.data });
      } else {
        dispatch({
          type: SETTINGS_ERROR,
          payload: response.data.errors,
        });
      }
    } catch (error) {
      dispatch({
        type: SETTINGS_ERROR,
        payload: error.data,
      });
    }
  }

  async function getSettings() {
    dispatch({ type: LOADING_SETTINGS });
    try {
      const response = await getBrand();
      if (response.status === STATUS_OK) {
        dispatch({ type: GET_SETTINGS, payload: response.data });
      } else {
        dispatch({
          type: SETTINGS_ERROR,
          payload: response.data.errors,
        });
      }
    } catch (error) {
      dispatch({
        type: SETTINGS_ERROR,
        payload: error.data,
      });
    }
  }

  async function editUserData(data, avatarFull, avatarCrop) {
    dispatch({ type: LOADING_SETTINGS });
    const avatar1Res = await uploadMedia(avatarFull);
    const avatar2Res = await uploadMedia(avatarCrop);
    if (
      (avatar1Res.status === STATUS_CREATED &&
        avatar2Res.status === STATUS_CREATED) ||
      (avatar1Res === EDITOR_NO_IMAGE && avatar2Res === EDITOR_NO_IMAGE)
    ) {
      const userData = {
        ...data,
        avatar_meta:
          avatar1Res !== EDITOR_NO_IMAGE && JSON.stringify(avatar1Res.data),
        avatar_thumb_meta:
          avatar2Res !== EDITOR_NO_IMAGE && JSON.stringify(avatar2Res.data),
      };
      try {
        const response = await editUser(userData);
        if (response.status === STATUS_OK) {
          dispatch({ type: EDIT_USER, payload: response.data });
        } else {
          dispatch({
            type: SETTINGS_ERROR,
            payload: response.data.errors,
          });
        }
      } catch (error) {
        dispatch({
          type: SETTINGS_ERROR,
          payload: error.data,
        });
      }
    } else {
      alertsContext.createErrorAlert(avatar1Res.errors, avatar2Res.errors);
    }
  }

  async function editSettings(isNew, data) {
    dispatch({ type: LOADING_SETTINGS });
    try {
      const response = isNew
        ? await postCustomBrand(data)
        : await editCustomBrand(data);
      if (response.status === STATUS_OK) {
        dispatch({ type: EDIT_SETTINGS, payload: response.data });
        alertsContext.createSuccessAlert(
          intl.formatMessage({ id: 'app.alerts.user-info-updated' })
        );
      } else {
        dispatch({
          type: SETTINGS_ERROR,
          payload: response.data.errors,
        });
        alertsContext.createErrorAlert(response.data.errors);
      }
    } catch (error) {
      dispatch({
        type: SETTINGS_ERROR,
        payload: error.data,
      });
    }
  }

  return (
    <SettingsContext.Provider
      value={{
        user: state.user,
        settings: state.settings,
        loading: state.loading,
        error: state.error,
        getUserData,
        editUserData,
        getSettings,
        editSettings,
      }}
    >
      {props.children}
    </SettingsContext.Provider>
  );
}
