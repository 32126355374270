import { useReducer } from 'react';

import { STORAGE_USER, STATUS_OK } from '../../constants';

import AuthContext from './AuthContext';
import AuthReducer from './AuthReducer';

import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
} from './AuthTypes';

import { accessToken } from '../../api/auth';

const AuthProvider = (props) => {
  const user = localStorage.getItem(STORAGE_USER)
    ? JSON.parse(localStorage.getItem(STORAGE_USER))
    : null;

  const initialState = {
    user: user,
    loginRequested: false,
    loginFailed: false,
    loggedIn: user ? true : false,
  };

  const [state, dispatch] = useReducer(AuthReducer, initialState);

  const login = async (email, password) => {
    try {
      dispatch({ type: LOGIN_REQUEST });

      const response = await accessToken(email, password);

      if (response.status === STATUS_OK) {
        localStorage.setItem(STORAGE_USER, JSON.stringify(response.data));
        dispatch({ type: LOGIN_SUCCESS, payload: response.data });
      } else {
        dispatch({ type: LOGIN_FAILURE });
      }
    } catch (error) {
      console.error(error);
      dispatch({ type: LOGIN_FAILURE });
    }
  };

  const logout = async () => {
    localStorage.removeItem(STORAGE_USER);
    dispatch({ type: LOGOUT });
  };

  return (
    <AuthContext.Provider
      value={{
        loginRequested: state.loginRequested,
        loginFailed: state.loginFailed,
        loggedIn: state.loggedIn,
        login,
        logout,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
