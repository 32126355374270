import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { Typography, Grid } from '@mui/material';
import CustomButton from '../CustomButton';

import { styled } from '@mui/material/styles';

import checked from '../../assets/checked.svg';

import InlineAlert from '../InlineAlert';

import { useIntl } from 'react-intl';
import { statusPayment } from '../../constants';
import TiersContext from '../../context/Tiers/TiersContext';

const ContentGrid = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

export const TierPayment = (props) => {
  const tiersContext = useContext(TiersContext);
  const navigate = useNavigate();
  const intl = useIntl();

  return (
    <Grid container justifyContent="center">
      <ContentGrid item xs={12}>
        {tiersContext.paymentData.lastOperationStatus === statusPayment.REJECTED ? (
          <InlineAlert
            title={intl.formatMessage({ id: 'app.alerts.inline-error-title' })}
            severity="error"
            alertText={intl.formatMessage({
              id: 'app.tier-payment.inline-error',
            })}
          />
        ) : (
          tiersContext.paymentData.lastOperationStatus === statusPayment.PAID && (
            <>
              <img src={checked} alt="create" />
              <Typography
                variant="h4"
                sx={{ mt: 2 }}
                children={intl.formatMessage({
                  id: 'app.tier-payment.success-title',
                })}
              />
              <Typography
                variant="body1"
                sx={{ mt: 2 }}
                children={intl.formatMessage({
                  id: 'app.tier-payment.success-body',
                })}
              />
            </>
          )
        )}
        {!tiersContext.loading && (
          <CustomButton
            variant="contained"
            onClick={() => navigate(`/`)}
            sx={{ mt: 3, mb: 2 }}
            children={intl.formatMessage({ id: 'app.backbutton.back' })}
          />
        )}
      </ContentGrid>
    </Grid>
  );
};
