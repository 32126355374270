import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';

import PrivateRoute from '../components/PrivateRoute';

import { LoginPage } from '../pages/LoginPage';
import { ForgotPassPage } from '../pages/ForgotPassPage';
import RecoverPassPage from '../pages/RecoverPassPage';
import { RegisterPage } from '../pages/RegisterPage';
import { ActivatePage } from '../pages/ActivatePage';
import { StampPage } from '../pages/StampPage';
import { MyDocumentsPage } from '../pages/MyDocumentsPage';
import { FindPage } from '../pages/FindPage';
import { MyAccountPage } from '../pages/MyAccountPage';
import TemplatesPage from '../pages/TemplatesPage';
import NewTemplatePage from '../pages/NewTemplatePage';
import EditTemplatePage from '../pages/EditTemplatePage';
import EditDraftStampPage from '../pages/EditDraftStampPage';
import TierPaymentPage from '../pages/TierPaymentPage';

import MyData from '../components/account/MyData';
import Security from '../components/account/Security';
import CustomBrand from '../components/account/CustomBrand';
import Plans from '../components/account/Plans';
import Stamp from '../components/Stamp';

import { CollectionPage } from '../pages/CollectionPage';
import { DocumentPage } from '../pages/DocumentPage';

import DocumentSharedPage from '../pages/DocumentSharedPage';
import { CollectionSharedPage } from '../pages/CollectionSharedPage';

import AuthProvider from '../context/Auth/AuthProvider';
import UserProvider from '../context/Users/UserProvider';
import SettingsProvider from '../context/Settings/SettingsProvider';
import CollectionsProvider from '../context/Collections/CollectionsProvider';
import DocumentsProvider from '../context/Documents/DocumentsProvider';
import TiersProvider from '../context/Tiers/TiersProvider';

import { STAMP_MODE_FREE, STAMP_MODE_TEMPLATE } from '../constants';

export const AppRouter = () => {
  const [isChanged, setIsChanged] = useState(false);

  return (
    <AuthProvider>
      <SettingsProvider>
        <TiersProvider>
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/forgot" element={<ForgotPassPage />} />
            <Route path="/recover/:hash" element={<RecoverPassPage />} />
            <Route
              path="/register"
              element={
                <UserProvider>
                  <RegisterPage />
                </UserProvider>
              }
            />
            <Route path="/activate/:hash" element={<ActivatePage />} />

            <Route path="/stamp" element={<PrivateRoute />}>
              <Route path="/stamp" element={<StampPage />}>
                <Route
                  path="free"
                  element={<Stamp stampMode={STAMP_MODE_FREE} />}
                />
                <Route
                  path="template"
                  element={<Stamp stampMode={STAMP_MODE_TEMPLATE} />}
                />
              </Route>
            </Route>

            <Route path="/stamp/:id" element={<PrivateRoute />}>
              <Route path="/stamp/:id" element={<EditDraftStampPage />} />
            </Route>

            <Route path="/my-documents" element={<PrivateRoute />}>
              <Route path="/my-documents" element={<MyDocumentsPage />} />
            </Route>

            <Route path="/collections/:id" element={<PrivateRoute />}>
              <Route path="/collections/:id" element={<CollectionPage />} />
            </Route>

            <Route path="/documents/:id" element={<PrivateRoute />}>
              <Route path="/documents/:id" element={<DocumentPage />} />
            </Route>

            <Route path="/my-account" element={<PrivateRoute />}>
              <Route
                path="/my-account"
                element={
                  <MyAccountPage
                    isChanged={isChanged}
                    setIsChanged={setIsChanged}
                  />
                }
              >
                <Route
                  path="*"
                  element={
                    <MyData isChanged={isChanged} setIsChanged={setIsChanged} />
                  }
                />
                <Route
                  index
                  element={
                    <MyData isChanged={isChanged} setIsChanged={setIsChanged} />
                  }
                />
                <Route
                  path="my-data"
                  element={
                    <MyData isChanged={isChanged} setIsChanged={setIsChanged} />
                  }
                />
                <Route
                  path="security"
                  element={
                    <Security
                      isChanged={isChanged}
                      setIsChanged={setIsChanged}
                    />
                  }
                />
                <Route
                  path="custom-brand"
                  element={
                    <CustomBrand
                      isChanged={isChanged}
                      setIsChanged={setIsChanged}
                    />
                  }
                />
                <Route
                  path="plans"
                  element={
                    <Plans isChanged={isChanged} setIsChanged={setIsChanged} />
                  }
                />
              </Route>
            </Route>

            <Route path="/templates" element={<PrivateRoute />}>
              <Route path="/templates" element={<TemplatesPage />} />
            </Route>

            <Route path="/new-template" element={<PrivateRoute />}>
              <Route path="/new-template" element={<NewTemplatePage />} />
            </Route>

            <Route path="/edit-template/:id" element={<PrivateRoute />}>
              <Route path="/edit-template/:id" element={<EditTemplatePage />} />
            </Route>

            {/* <Route path="/tier-payment" element={<PrivateRoute />}>
              <Route path="/tier-payment" element={<TierPaymentPage />} />
            </Route> */}

            {/* Validita (public) */}
            <Route path="/find" element={<FindPage />} />
            <Route
              path="/document/:hash"
              element={
                <CollectionsProvider>
                  <DocumentsProvider>
                    <DocumentSharedPage />
                  </DocumentsProvider>
                </CollectionsProvider>
              }
            />
            <Route
              path="/collection/:hash"
              element={
                <CollectionsProvider>
                  <DocumentsProvider>
                    <CollectionSharedPage />
                  </DocumentsProvider>
                </CollectionsProvider>
              }
            />
          </Routes>
        </TiersProvider>
      </SettingsProvider>
    </AuthProvider>
  );
};
