import { get } from '../api';
import {
  GET_ID_DOCUMENTS_URL,
  GET_URL_DOCUMENTS_URL,
  GET_MD5_DOCUMENTS_URL,
} from '../utils/url';

export const getDocumentById = (id) => {
  return get(GET_ID_DOCUMENTS_URL + id);
};

export const getDocumentByUrl = (hash) => {
  return get(GET_URL_DOCUMENTS_URL + hash, false);
};

export const getDocumentByMd5 = (md5, limit, offset) => {
  return get(
    GET_MD5_DOCUMENTS_URL + md5 + '?limit=' + limit + '&offset=' + offset,
    false
  );
};
