import { Alert, AlertTitle } from '@mui/material';

const InlineAlert = ({ title, severity, alertText }) => {
  return (
    <Alert severity={severity}>
      <AlertTitle>{title}</AlertTitle>
      {Array.isArray(alertText) ? (
        <ul>
          {alertText.map((error) => (
            <li>{error}</li>
          ))}
        </ul>
      ) : (
        <div>{alertText}</div>
      )}
    </Alert>
  );
};

export default InlineAlert;
