import React, { useContext } from 'react';

import CustomButton from './CustomButton';
import { IconSvgContainer } from './IconSvgContainer';
import { ReactComponent as FreeStampIcon } from '../assets/StampFreeIcon.svg';
import { ReactComponent as TemplateStampIcon } from '../assets/StampTemplateIcon.svg';
import { ReactComponent as LockedIcon } from '../assets/LockedIcon.svg';

import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { styled } from '@mui/material/styles';

import {
  Box,
  Paper,
  Grid,
  Typography,
  Avatar,
  Fade,
  useMediaQuery,
} from '@mui/material';

import TiersContext from '../context/Tiers/TiersContext';

const CustomBox = styled(Box)({});

const CustomGrid = styled(Grid)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const TitleGrid = styled(CustomGrid)(({ mobile }) => ({
  flexDirection: 'column',
  padding: mobile ? '2rem 0rem' : '4rem 0rem',
}));

const ButtonsGrid = styled(Grid)({
  display: 'flex',
  justifyContent: 'center',
  '@media (max-width: 900px)': {
    flexDirection: 'column',
  },
});

const StampModeCardPaper = styled(Paper)(({ mobile }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-around',
  padding: mobile ? '1rem' : '2rem',
  margin: mobile ? '1rem' : '2rem',
  boxShadow: '0px 0px 20px 2px rgba(0, 0, 0, 0.1)',
  borderRadius: '0.8rem',
  height: '292px',
  minWidth: '260px',
  '& button': {
    marginTop: '1rem',
  },
}));

const BottomButtonContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

export default function StampSelect(props) {
  const intl = useIntl();
  const mobile = useMediaQuery('(max-width: 900px)');
  const navigate = useNavigate();
  const tiersContext = useContext(TiersContext);

  return (
    <Fade timeout={250} in={true}>
      <CustomBox
        sx={{
          filter:
            !tiersContext.userTier.documentStamp?.enabled ||
            !tiersContext.userTier.collectionStamp?.enabled
              ? 'blur(3px)'
              : null,
        }}
      >
        <CustomGrid container>
          <TitleGrid mobile={mobile} item md={12}>
            <Typography
              align="center"
              variant="h5"
              children={intl.formatMessage({ id: 'app.stamp.select-text1' })}
            />
            <Typography
              align="center"
              variant="h5"
              color="primary"
              children={intl.formatMessage({ id: 'app.stamp.select-text2' })}
            />
          </TitleGrid>
          <ButtonsGrid item md={12}>
            <StampModeCardPaper mobile={mobile}>
              <IconSvgContainer
                backgroundColor="#FFF"
                width={'80px'}
                height={'80px'}
                shadow="true"
                gradient="true"
                children={<FreeStampIcon />}
              />
              <BottomButtonContainer>
                <div>
                  <Typography
                    fontWeight="500"
                    textAlign="center"
                    children={intl.formatMessage({
                      id: 'app.stamp.select-alt1',
                    })}
                  />
                  <Typography
                    fontWeight="400"
                    textAlign="center"
                    noWrap
                    children={intl.formatMessage({
                      id: 'app.stamp.select-alt1-desc',
                    })}
                  />
                </div>
                <CustomButton
                  variant="contained"
                  children={intl.formatMessage({
                    id: 'app.stamp.select-button1',
                  })}
                  onClick={() => {
                    navigate(props.locations.FREE);
                  }}
                  disabled={
                    !tiersContext.userTier.documentStamp?.enabled ||
                    !tiersContext.userTier.collectionStamp?.enabled
                  }
                />
              </BottomButtonContainer>
            </StampModeCardPaper>
            <StampModeCardPaper mobile={mobile}>
              <div style={{ position: 'relative' }}>
                {!tiersContext.userTier.templateSupport?.enabled && (
                  <Avatar
                    sx={{
                      position: 'absolute',
                      zIndex: '2',
                      right: '-1rem',
                      top: '-1rem',
                      background:
                        'linear-gradient(44.77deg, #7D50FF 32.9%, #3969E8 96.1%)',
                    }}
                    children={<LockedIcon style={{ height: 20, width: 20 }} />}
                  />
                )}
                <IconSvgContainer
                  backgroundColor="#FFF"
                  width={'80px'}
                  height={'80px'}
                  shadow="true"
                  gradient="true"
                  children={<TemplateStampIcon />}
                />
              </div>
              <BottomButtonContainer>
                <div>
                  <Typography
                    fontWeight="500"
                    textAlign="center"
                    children={intl.formatMessage({
                      id: 'app.stamp.select-alt2',
                    })}
                  />
                  <Typography
                    fontWeight="400"
                    textAlign="center"
                    noWrap
                    children={intl.formatMessage({
                      id: tiersContext.userTier.templateSupport?.enabled
                        ? 'app.stamp.select-alt2-desc'
                        : 'app.stamp.select-alt2-desc-forbidden',
                    })}
                  />
                </div>
                <CustomButton
                  variant="contained"
                  children={intl.formatMessage({
                    id: tiersContext.userTier.templateSupport?.enabled
                      ? 'app.stamp.select-button1'
                      : 'app.stamp.select-button2',
                  })}
                  onClick={() => {
                    tiersContext.userTier.templateSupport?.enabled
                      ? navigate(props.locations.TEMPLATE)
                      : navigate('/my-account/plans');
                  }}
                  disabled={
                    !tiersContext.userTier.documentStamp?.enabled ||
                    !tiersContext.userTier.collectionStamp?.enabled
                  }
                />
              </BottomButtonContainer>
            </StampModeCardPaper>
          </ButtonsGrid>
        </CustomGrid>
      </CustomBox>
    </Fade>
  );
}
