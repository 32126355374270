import React, { useReducer } from "react";
import axios from "axios";

import SharedContext from "./SharedContext";
import SharedReducer from "./SharedReducer";

import { POST_SHARED } from "./SharedTypes";

import { COLLECTION_STATUS_STAMPED } from "../../constants";

import { createShared } from "../../api/shared";

const SharedProvider = (props) => {
  const initialState = {
    shared: undefined,
  };

  const [state, dispatch] = useReducer(SharedReducer, initialState);

  /*
  const getShared = async () => {
    try {
      const res = await axios.get(GET_SHARED_URL);
      const data = res.data.data;
      dispatch({ type: GET_SHARED, payload: data });
    } catch (error) {
      console.error(error);
    }
  };
  */

  const postShared = async (id, type) => {
    try {
      const res = await createShared({
        document_type: type,
        document_id: id,
        status: COLLECTION_STATUS_STAMPED,
      });
      const data = res.data;
      dispatch({ type: POST_SHARED, payload: data });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <SharedContext.Provider
      value={{
        shared: state.shared,
        postShared,
      }}
    >
      {props.children}
    </SharedContext.Provider>
  );
};

export default SharedProvider;
