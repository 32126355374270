import { Box, Grid, Typography, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

import gradient1 from '../assets/Gradient1.svg';
import gradient2 from '../assets/Gradient2.svg';

import { ReactComponent as ValiditaLogo } from '../assets/validita-logo.svg';

import { IconSvgContainer } from './IconSvgContainer';

const WelcomeGrid = styled(Grid)({
  background: '#F8F8F8',
  '@media (max-width: 900px)': {
    background: 'linear-gradient(180deg, #eef1f6 0%, rgba(255,255,255,0) 100%)',
  },
});

const ValiditaLogoContainer = styled('div')({
  margin: '1rem 0rem',
  '@media (min-width: 900px)': {
    position: 'absolute',
    top: '3rem',
  },
});

const PageInfoContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const TextInfoContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '@media (max-width: 900px)': {
    marginTop: '2rem'
  },
});

const WelcomeText = styled(Typography)({
  color: '#3969E8',
});

const InputBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
  flexDirection: 'column',
  '@media (max-width: 900px)': {
    minHeight: 'auto',
    paddingTop: '15',
  },
});

const WelcomeBox = styled(InputBox)({
  flexDirection: 'row',
  '@media (max-width: 900px)': {
    minHeight: 'auto',
    padding: '2rem',
  },
});

const Gradient = styled('img')((props) => ({
  position: 'fixed',
  top: props.top,
  bottom: props.bottom,
  left: props.left,
  right: props.right,
  '@media (max-width: 900px)': {
    right: '0px',
  },
}));

const PageGrid = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

export const PublicLayout = (props) => {
  const mobile = useMediaQuery('(max-width: 900px)');

  return (
    <Grid container spacing={0}>
      <WelcomeGrid item xs={12} md={6}>
        <WelcomeBox>
          {!mobile && (
            <>
              <Gradient bottom="0px" left="0px" src={gradient1} />
              <Gradient top="0px" right="50%" src={gradient2} />
            </>
          )}
          <Stack
            spacing={mobile ? 0 : 8}
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <ValiditaLogoContainer>
              <IconSvgContainer width={'150px'} height={'35px'}>
                <ValiditaLogo />
              </IconSvgContainer>
            </ValiditaLogoContainer>
            <PageInfoContainer>
              {!mobile && (
                <IconSvgContainer
                  backgroundColor="#fff"
                  width={'70px'}
                  height={'70px'}
                >
                  {props.iconForm}
                </IconSvgContainer>
              )}
              <TextInfoContainer>
                <WelcomeText
                  variant="h4"
                  fontSize="30px"
                  align='center'
                  children={props.welcomeTitle}
                />
                <Typography
                  variant="body1"
                  fontSize="18px"
                  align='center'
                  children={props.welcomeText}
                />
              </TextInfoContainer>
            </PageInfoContainer>
          </Stack>
        </WelcomeBox>
      </WelcomeGrid>
      <PageGrid item xs={12} md={6}>
        {props.pageTitle && (
          <Typography
            variant="h5"
            color="primary"
            children={props.pageTitle}
          />
        )}
        {props.children}
      </PageGrid>
    </Grid>
  );
};
