import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
} from "./AuthTypes";

export default function AuthReducer(state, action) {
  const { payload, type } = action;

  switch (type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        user: null,
        loginRequested: true,
        loginFailed: false,
        loggedIn: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        user: payload,
        loginRequested: false,
        loginFailed: false,
        loggedIn: true,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        user: null,
        loginRequested: false,
        loginFailed: true,
        loggedIn: false,
      };
    case LOGOUT:
      return {
        ...state,
        user: null,
        loginRequested: false,
        loginFailed: false,
        loggedIn: false,
      };
    default:
      return state;
  }
};
