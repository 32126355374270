import {
  PUT_MARK_NOTIFICATION_AS_READ,
  GET_NOTIFICATIONS,
  GET_SCROLL_NOTIFICATIONS,
  NOTIFICATIONS_LOADING,
  NOTIFICATIONS_ERROR,
  SET_PULLED,
} from './NotificationsTypes';

export default function NotificationsReducer(state, action) {
  const { payload, type } = action;

  switch (type) {
    case PUT_MARK_NOTIFICATION_AS_READ:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case GET_NOTIFICATIONS:
      return {
        ...state,
        notifications: payload,
        loading: false,
        error: false,
      };
    case GET_SCROLL_NOTIFICATIONS:
      return {
        ...state,
        notifications: [...state.notifications, ...payload],
        offsetPage: state.offsetPage + 1,
        loading: false,
        error: false,
      };
    case NOTIFICATIONS_LOADING:
      return {
        ...state,
        notifications: [],
        loading: true,
        error: false,
      };
    case NOTIFICATIONS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case SET_PULLED:
      return {
        ...state,
        pulled: payload,
        loading: false,
        error: false,
      };
    default:
      return state;
  }
}
