import {
  ALERT_ERROR,
  ALERT_WARNING,
  ALERT_INFO,
  ALERT_SUCCESS,
} from './AlertsTypes';

export default function AlertsReducer (state, action) {
  const { status, message, type } = action;

  switch (type) {
    case ALERT_ERROR:
      return {
        status: status,
        message: message,
        type: type,
      };
      case ALERT_WARNING:
      return {
        status: status,
        message: message,
        type: type,
      };
    case ALERT_INFO:
      return {
        status: status,
        message: message,
        type: type,
      };
    case ALERT_SUCCESS:
      return {
        status: status,
        message: message,
        type: type,
      };
    default:
      return {
        status: status,
      };
  }
};
