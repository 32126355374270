import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ReactComponent as IconBackGradient } from '../assets/IconBackGradient.svg';

const ContainerIcon = styled(Box)((props) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: props.width,
  height: props.height,
  borderRadius: '13px',
  boxShadow: props.shadow && '0px 0px 20px 2px rgba(0, 0, 0, 0.1)',
  background: props.backgroundColor && props.backgroundColor,
}));

const BackGradient = styled(IconBackGradient)({
  position: 'absolute',
  zIndex: '-1',
});

export const IconSvgContainer = (props) => {
  return (
    <div style={{ position: 'relative', zIndex: '1' }}>
      <ContainerIcon
        backgroundColor={props.backgroundColor}
        width={props.width}
        height={props.height}
        shadow={props.shadow}
      >
        {props.gradient && <BackGradient />}
        {props.children}
      </ContainerIcon>
    </div>
  );
};
