import React, { useState, useEffect, useContext } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { animateScroll } from 'react-scroll';

import {
  Box,
  Grid,
  Typography,
  Divider,
  Select,
  MenuItem,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import TierCard from './TierCard';
import PaymentCardsModal from './PaymentCardsModal';
import BottomButtons from './BottomButtons';
import ConfirmModal from '../ConfirmModal';
import Loader from '../Loader';
import InlineAlert from '../InlineAlert';
import {
  FREE_PLAN_ID,
  PLAN_ONE_PAY,
  statusContext,
  statusPayment,
  PLAN_SUBSCRIPTION,
  APP_ROUTES,
} from '../../constants';

import TiersContext from '../../context/Tiers/TiersContext';
import SettingsContext from '../../context/Settings/SettingsContext';
import CountryContext from '../../context/Country/CountryContext';

const InfoGrid = styled(Grid)({
  margin: '2rem 0rem',
});

const CountrySelectGrid = styled(Grid)({
  '@media (max-width: 900px)': {
    marginTop: '2rem',
    justifyContent: 'center',
  },
});

const CountrySelect = styled(Select)({
  width: '12rem',
});

const CustomDivider = styled(Divider)({
  margin: '2rem 0rem',
});

export default function Plans() {
  const tiersContext = useContext(TiersContext);
  const settingsContext = useContext(SettingsContext);
  const countryContext = useContext(CountryContext);

  const [request, setRequest] = useState(0);

  const [paymentCardsModal, setPaymentCardsModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [processingModal, setProcessingModal] = useState(false);

  const [userCountryId, setUserCountryId] = useState(undefined);

  const [planNumber, setPlanNumber] = useState(undefined);
  const [tierPriceId, setTierPriceId] = useState(undefined);

  const intl = useIntl();
  const navigate = useNavigate();

  useEffect(() => {
    setUserCountryId(settingsContext.user?.country_id);
  }, [settingsContext.user]);

  useEffect(() => {
    if (userCountryId) {
      async function tiersFetch() {
        await tiersContext.getTiers();
        await tiersContext.getPrices(userCountryId);
      }
      tiersFetch();
    }
  }, [userCountryId]);

  useEffect(() => {
    async function openProcessingModal() {
      if (
        (tiersContext.paymentData.lastOperationStatus === statusPayment.PAID ||
          tiersContext.paymentData.lastOperationStatus === statusPayment.PENDING) &&
        tiersContext.paymentData.lastTransactionStatus === statusContext.PENDING &&
        !tiersContext.loading &&
        request === 0
      ) {
        setProcessingModal(true);
      }
    }
    openProcessingModal();
  }, [tiersContext]);

  // useEffect(() => {
  //   if (
  //     tiersContext.paymentData.lastOperationStatus === statusPayment.PAID &&
  //     tiersContext.paymentData.lastTransactionStatus === statusContext.FINISHED &&
  //     !loading &&
  //     request > 0
  //   ) {
  //     navigate('/');
  //     alertsContext.createSuccessAlert(
  //       intl.formatMessage({ id: 'app.my-account.plans.alert-tier-email-sent' })
  //     );
  //   }
  // }, [
  //   request,
  //   tiersContext.paymentData.lastOperationStatus,
  //   tiersContext.paymentData.lastTransactionStatus,
  // ]);

  async function handleChangePlan(tierPriceId, planId) {
    setRequest(1);
    handleClosePaymentModal();
    handleCloseConfirmModal();
    handleCloseCancelModal();
    if (planId !== FREE_PLAN_ID && tierPriceId) {
      // const paymentUrl =
      await tiersContext.paymentTransaction(tierPriceId);
      // if (paymentUrl) {
      //   window.location.replace(paymentUrl);
      // }
    } else {
      await tiersContext.changeFreeTier(
        {
          tier_id: FREE_PLAN_ID,
        },
        tiersContext.userTier.paymentType
      );
    }
  }

  function handleOpenPaymentModal(planNumber) {
    setPlanNumber(planNumber);
    setPaymentCardsModal(true);
  }

  function handleClosePaymentModal() {
    setPlanNumber(undefined);
    setPaymentCardsModal(false);
  }

  const handleOpenConfirmModal = (id) => {
    setTierPriceId(id);
    setConfirmModal(true);
  };

  const handleCloseConfirmModal = () => {
    setConfirmModal(false);
  };

  const handleOpenCancelModal = () => {
    setCancelModal(true);
  };

  const handleCloseCancelModal = () => {
    setCancelModal(false);
  };

  const handleChangeCountryId = (e) => {
    setUserCountryId(e.target.value);
  };

  const paymentCardInfo = (prices, tierId, paymentType) => {
    let data = {};
    if (prices?.length !== 0) {
      prices?.forEach((price) => {
        if (price.tier_id === tierId && price.payment_type === paymentType) {
          data['priceData'] = {
            id: price.id,
            price: price.usd_price,
            months: price.payment_months,
          };
          data['features'] = price.meta_json.features;
        }
      });
      return data;
    } else {
      return null;
    }
  };

  return (
    <>
      <PaymentCardsModal
        open={paymentCardsModal}
        onClose={handleClosePaymentModal}
        planNumber={planNumber}
        types={{ onePay: PLAN_ONE_PAY, subscription: PLAN_SUBSCRIPTION }}
        prices={tiersContext.tiersData.prices}
        activePaymentType={tiersContext.userTier.paymentType}
        paymentCardInfo={paymentCardInfo}
        handleOpenConfirmModal={handleOpenConfirmModal}
      />
      <ConfirmModal
        open={confirmModal}
        onClose={handleCloseConfirmModal}
        modalTitle={intl.formatMessage({
          id: 'app.my-account.plans.confirm-modal-title',
        })}
        modalText={intl.formatMessage({
          id: 'app.my-account.plans.confirm-modal-text',
        })}
        confirmAction={() => {
          handleChangePlan(
            {
              tier_price_id: tierPriceId,
            },
            planNumber
          );
        }}
        cancelAction={handleCloseConfirmModal}
      />
      <ConfirmModal
        open={cancelModal}
        onClose={handleCloseCancelModal}
        modalTitle={intl.formatMessage({
          id: 'app.my-account.plans.cancel-modal-title',
        })}
        modalText={
          tiersContext.userTier.paymentType === PLAN_ONE_PAY
            ? intl.formatMessage({
                id: 'app.my-account.plans.cancel-modal-text-annual',
              })
            : intl.formatMessage({
                id: 'app.my-account.plans.cancel-modal-text',
              })
        }
        confirmAction={() => {
          handleChangePlan(null, planNumber);
        }}
        cancelAction={handleCloseCancelModal}
      />
      <ConfirmModal
        open={processingModal}
        modalText={
          tiersContext.paymentData.lastOperationStatus !== statusPayment.PAID
            ? intl.formatMessage({
                id: 'app.my-account.plans.processing-modal',
              })
            : intl.formatMessage({
                id: 'app.my-account.plans.processing-modal-paid',
              })
        }
        confirmAction={() => {
          if (tiersContext.paymentData.lastOperationUrl) {
            window.open(tiersContext.paymentData.lastOperationUrl, '_blank', 'noopener');
          } else {
            setProcessingModal(false);
          }
        }}
      />
      {tiersContext.loading ? (
        <Loader margin={'6rem'} />
      ) : (
        <Box>
          <InfoGrid container>
            <Grid item xs={12} md={4}>
              <Typography
                variant="body2"
                children={
                  tiersContext.userTier.tierId
                    ? intl.formatMessage({
                        id: 'app.my-account.plans.header2',
                      })
                    : intl.formatMessage({
                        id: 'app.my-account.plans.header1',
                      })
                }
              />
            </Grid>
            <Grid item xs={12} md={4}>
              {tiersContext.paymentData.lastOperationStatus === statusPayment.REJECTED && (
                <InlineAlert
                  severity="error"
                  title={intl.formatMessage({
                    id: 'app.alerts.inline-error-title',
                  })}
                  alertText={intl.formatMessage({
                    id: 'app.my-account.plans.inline-error',
                  })}
                />
              )}
            </Grid>
            <CountrySelectGrid
              item
              xs={12}
              md={4}
              display="flex"
              justifyContent="flex-end"
            >
              <div>
                <Typography
                  variant="body2"
                  children={intl.formatMessage({
                    id: 'app.my-account.plans.selected-country',
                  })}
                />
                <CountrySelect
                  size="small"
                  margin="dense"
                  value={userCountryId}
                  disabled={tiersContext.loading}
                  onChange={handleChangeCountryId}
                >
                  {countryContext.list?.map((country, index) => (
                    <MenuItem key={index} value={country.id}>
                      {country.description}
                    </MenuItem>
                  ))}
                </CountrySelect>
              </div>
            </CountrySelectGrid>
          </InfoGrid>
          <Grid container>
            {tiersContext.tiersData.tiers.map((tier, index) => {
              const priceData = {};
              tiersContext.tiersData.prices.filter((p) =>
                p.tier_id === tier.id
                  ? (priceData[p.payment_type] = p.usd_price)
                  : null
              );
              return (
                !tier.custom_user_id && (
                  <TierCard
                    key={index}
                    tier={tier}
                    priceData={priceData}
                    setPlanNumber={setPlanNumber}
                    handleOpenPaymentModal={handleOpenPaymentModal}
                    handleOpenCancelModal={handleOpenCancelModal}
                    handleOpenConfirmModal={handleOpenConfirmModal}
                  />
                )
              );
            })}
          </Grid>
          <CustomDivider />
          {tiersContext.tiersData.tiers.map((tier, index) => {
            return (
              tier.custom_user_id && (
                <React.Fragment key={index}>
                  <InfoGrid container>
                    <Grid item xs={12}>
                      <Typography
                        variant="body2"
                        children={intl.formatMessage({
                          id: 'app.my-account.plans.ons',
                        })}
                      />
                    </Grid>
                  </InfoGrid>
                  <Grid container>
                    <TierCard
                      key={index}
                      customPlan
                      tier={tier}
                      handleOpenPaymentModal={handleOpenPaymentModal}
                      handleOpenCancelModal={handleOpenCancelModal}
                      handleOpenConfirmModal={handleOpenConfirmModal}
                    />
                  </Grid>
                </React.Fragment>
              )
            );
          })}
          <BottomButtons
            cancelText={intl.formatMessage({
              id: 'app.my-account.cancel-button',
            })}
            cancelAction={() => {
              navigate(APP_ROUTES.MY_DOCUMENTS);
              animateScroll.scrollToTop();
            }}
          />
        </Box>
      )}
    </>
  );
}
