import React, { useState, useContext, useEffect } from 'react';
import { Tabs, Tab, Box, Divider } from '@mui/material';

import { Link, Outlet, useLocation } from 'react-router-dom';
import { useCallbackPrompt } from '../../hooks/useCallbackPrompt';

import { styled } from '@mui/material/styles';

import { useIntl } from 'react-intl';

import ConfirmModal from '../ConfirmModal';

import TiersContext from '../../context/Tiers/TiersContext';
import SettingsContext from '../../context/Settings/SettingsContext';
import CountryContext from '../../context/Country/CountryContext';

import { APP_ROUTES } from '../../constants';

const CustomTabs = styled(Tabs)({
  'div:first-of-type': {
    display: 'flex',
    '@media (min-width: 700px)': {
      justifyContent: 'center',
    },
  },
});

const CustomTab = styled(Tab)({
  textTransform: 'none',
  padding: '8px',
  height: '0.5rem',
});

export default function MyAccountMenu({ isChanged, setIsChanged }) {
  const [step, setStep] = useState(0);

  const intl = useIntl();
  const location = useLocation();

  const tiersContext = useContext(TiersContext);
  const settingsContext = useContext(SettingsContext);
  const countryContext = useContext(CountryContext);

  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(isChanged);

  useEffect(() => {
    location.pathname === APP_ROUTES.MY_ACCOUNT.MY_DATA &&
      settingsContext.getUserData();
    countryContext.getList();
    stepSetter(location.pathname);
  }, [location]);

  const handleChange = (e, index) => {
    setStep(index);
  };

  function stepSetter(loc) {
    switch (loc) {
      case APP_ROUTES.MY_ACCOUNT.MY_DATA:
        setStep(0);
        break;
      case APP_ROUTES.MY_ACCOUNT.SECURITY:
        setStep(1);
        break;
      case APP_ROUTES.MY_ACCOUNT.CUSTOM_BRAND:
        setStep(2);
        break;
      case APP_ROUTES.MY_ACCOUNT.PLANS:
        setStep(3);
        break;
      default:
        break;
    }
  }

  return (
    <Box sx={{ width: '100%' }}>
      <ConfirmModal
        open={showPrompt}
        warningTitle={intl.formatMessage({
          id: 'app.confirm-modal.warning-title',
        })}
        warningText={intl.formatMessage({
          id: 'app.alerts.not-saved-changes.text',
        })}
        confirmAction={() => {
          setIsChanged(false);
          confirmNavigation();
        }}
        cancelAction={() => {
          cancelNavigation();
          stepSetter(location.pathname);
        }}
      />
      <Box>
        <CustomTabs
          value={step}
          allowScrollButtonsMobile={true}
          variant="scrollable"
          scrollButtons="auto"
          onChange={handleChange}
        >
          <CustomTab
            component={Link}
            disabled={
              tiersContext.loading ||
              settingsContext.loading ||
              location.pathname === APP_ROUTES.MY_ACCOUNT.MY_DATA
            }
            disableRipple
            to={APP_ROUTES.MY_ACCOUNT.MY_DATA}
            label={intl.formatMessage({ id: 'app.my-account.my-data' })}
          />
          <CustomTab
            component={Link}
            disabled={
              tiersContext.loading ||
              settingsContext.loading ||
              location.pathname === APP_ROUTES.MY_ACCOUNT.SECURITY
            }
            disableRipple
            to={APP_ROUTES.MY_ACCOUNT.SECURITY}
            label={intl.formatMessage({ id: 'app.my-account.security' })}
          />
          <CustomTab
            component={Link}
            disabled={
              tiersContext.loading ||
              settingsContext.loading ||
              location.pathname === APP_ROUTES.MY_ACCOUNT.CUSTOM_BRAND
            }
            disableRipple
            to={APP_ROUTES.MY_ACCOUNT.CUSTOM_BRAND}
            label={intl.formatMessage({ id: 'app.my-account.custom-brand' })}
          />
          <CustomTab
            component={Link}
            disabled={
              tiersContext.loading ||
              settingsContext.loading ||
              location.pathname === APP_ROUTES.MY_ACCOUNT.PLANS
            }
            disableRipple
            to={APP_ROUTES.MY_ACCOUNT.PLANS}
            label={intl.formatMessage({ id: 'app.my-account.plans' })}
          />
        </CustomTabs>
        <Divider />
      </Box>
      <Outlet />
    </Box>
  );
}
