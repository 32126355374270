import React, { useContext } from 'react';

import { Snackbar, Alert, IconButton } from '@mui/material';
import AlertsContext from '../context/Alerts/AlertsContext';

import CloseIcon from '@mui/icons-material/Close';

import {
  ALERT_ERROR,
  ALERT_WARNING,
  ALERT_INFO,
  ALERT_SUCCESS,
} from '../context/Alerts/AlertsTypes';

export default function GlobalAlert() {
  const context = useContext(AlertsContext);

  function severitySwitch(type) {
    switch (type) {
      case ALERT_ERROR:
        return 'error';
      case ALERT_WARNING:
        return 'warning';
      case ALERT_INFO:
        return 'info';
      case ALERT_SUCCESS:
        return 'success';
      default:
        break;
    }
  }

  return (
    <Snackbar
      open={context.status}
      autoHideDuration={6000}
      onClose={() => {
        context.closeAlert();
      }}
    >
      <Alert
        severity={severitySwitch(context.type)}
        children={
          Array.isArray(context.message)
            ? context.message?.map((m, index) => {
                return (
                  <div key={index}>
                    <div
                      style={
                        context.message.length > 1 &&
                        index + 1 !== context.message.length
                          ? { marginBottom: '0.5rem' }
                          : null
                      }
                    >
                      {m}
                    </div>
                  </div>
                );
              })
            : context.message
        }
        action={
          <IconButton size="small" onClick={context.closeAlert}>
            <CloseIcon />
          </IconButton>
        }
      ></Alert>
    </Snackbar>
  );
}
