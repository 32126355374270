import { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Container, Stack, Grid } from '@mui/material';
import DocumentShared from '../components/kontroli/DocumentShared';
import HeaderIdenteco from '../components/HeaderIdenteco';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { imageDataParser } from '../helpers';

import { useIntl } from 'react-intl';
import DocumentsContext from '../context/Documents/DocumentsContext';
import Loader from '../components/Loader';

export default function DocumentSharedPage() {
  const [customColor, setCustomColor] = useState();
  const [logoUrl, setLogoUrl] = useState();
  const [brandName, setBrandName] = useState();

  const intl = useIntl();
  const { hash } = useParams();
  const documentsContext = useContext(DocumentsContext);

  document.title = intl.formatMessage({ id: 'app.page.document-shared' });

  useEffect(() => {
    async function defaultFetch() {
      await documentsContext.getDocumentByUrl(hash);
    }
    defaultFetch();
  }, []);

  useEffect(() => {
    if (documentsContext.document &&
      documentsContext.document.user.user_settings) {
      if (documentsContext.document.user.user_settings.whiteline_active) {
        setCustomColor(documentsContext.document.user.user_settings.color);
        setBrandName(documentsContext.document.user.user_settings.name);
        const logoThumbURL = imageDataParser(
          documentsContext.document.user.user_settings,
          'logo_thumb_meta'
        );
        setLogoUrl(logoThumbURL);
      }
    }
  }, [documentsContext.document]);

  const CustomTheme = createTheme({
    typography: {
      fontFamily: `"Rubik", "Ubuntu", "Roboto", "Helvetica", "Arial", sans-serif`,
    },
    palette: {
      primary: {
        main: customColor ? customColor : '#3969E8',
      },
      secondary: {
        main: '#FF6969',
      },
    },
  });

  return (
    <ThemeProvider theme={CustomTheme}>
      {!documentsContext.loading ? (
        <>
          <HeaderIdenteco
            title={intl.formatMessage({ id: 'app.collection.shared.title' })}
            customColor={customColor}
            logoUrl={logoUrl}
            brandName={brandName} />
          <Grid container spacing={0}>
            <Grid item xs={12} sx={{ margin: '6rem 0rem' }}>
              <Container>
                <Box component="form" noValidate>
                  <Stack spacing={1}>
                    <DocumentShared customColor={customColor} />
                  </Stack>
                </Box>
              </Container>
            </Grid>
          </Grid>
        </>
      ) : (
        <Loader margin={'8rem'} />
      )}
    </ThemeProvider>
  );
}
