import { PrivateLayout } from '../components/PrivateLayout';

import CollectionsProvider from '../context/Collections/CollectionsProvider';
import SharedProvider from '../context/Shared/SharedProvider';
import TemplatesProvider from '../context/Templates/TemplatesProvider';
import CollectionsGrid from '../components/my-documents/CollectionsGrid';

import { useIntl } from 'react-intl';

export const MyDocumentsPage = () => {
  const intl = useIntl();

  document.title = intl.formatMessage({ id: 'app.page.mydocuments' });

  return (
    <>
      <PrivateLayout
        title={intl.formatMessage({ id: 'app.mydocuments.title' })}
        subtitle={intl.formatMessage({ id: 'app.mydocuments.subtitle' })}
      >
        <CollectionsProvider>
          <SharedProvider>
            <TemplatesProvider>
              <CollectionsGrid />
            </TemplatesProvider>
          </SharedProvider>
        </CollectionsProvider>
      </PrivateLayout>
    </>
  );
};
