import React, { useState, useEffect } from 'react';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import useDocument from '../hooks/useDocument';

import { FileInfoDisplay } from './my-documents/DocumentPreview';

import { filePreviewMIMECheck } from '../helpers';

import {
  Paper,
  Grid,
  IconButton,
  Modal,
  Divider,
  useMediaQuery,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
// import ZoomInIcon from '@mui/icons-material/ZoomIn';
// import ZoomOutIcon from '@mui/icons-material/ZoomOut';
// import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
// import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';

import { styled } from '@mui/material/styles';

import { FIELD_TYPE_FILE, FIELD_TYPE_TEXT } from '../constants';

const ModalPaper = styled(Paper)({
  position: 'relative',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  background: '#ffffff',
  width: '38rem',
  height: '90vh',
  borderRadius: '18px',
  '@media (max-width: 900px)': {
    width: '90vw',
  },
});

const CloseButtonContainer = styled('div')(({ smallHeight }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: !smallHeight && '1rem',
}));

// const PreviewControlsContainer = styled(Grid)({
//   position: 'absolute',
//   left: '30%',
//   bottom: '6%',
//   width: '12rem',
//   minHeight: '36px',
//   background: '#7D50FF',
//   zIndex: 1,
//   borderRadius: '4px',
// });

const CustomDocViewer = styled(DocViewer)({
  border: '1px solid rgba(0, 0, 0, 0.2)',
  borderRadius: '4px',
  height: '100%',
  width: '100%',
  zIndex: 0,
  '#pdf-controls': {
    boxShadow: '0px 0px 14.1026px rgba(0, 0, 0, 0.262)',
    position: 'absolute',
    top: '88%',
    left: '43%',
    background: '#FFFFFF',
    width: 'auto',
    height: '30px',
    borderRadius: '4px',
  },
  '#pdf-zoom-in, #pdf-zoom-out': {
    width: '30px',
    height: '30px',
    background: 'none',
    boxShadow: 'none',
    '& svg': {
      fill: '#FFFFFF',
    },
  },
  '#pdf-download, #pdf-zoom-reset': {
    display: 'none',
  },
  '#header-bar': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

// const PageButtonsContainer = styled('div')({
//   position: 'absolute',
//   pointerEvents: 'none',
//   bottom: '50%',
//   right: '-25%',
//   display: 'flex',
//   justifyContent: 'space-between',
//   width: '150%',
//   '& button': {
//     pointerEvents: 'auto',
//   },
// });

export default function FilePreviewer({ open, onClose, document }) {
  const smallHeight = useMediaQuery('(max-height: 500px)');
  const [previewDoc, setPreviewDoc] = useState([]);
  const [textPreview, setTextPreview] = useState(null);

  const doc = useDocument();

  useEffect(() => {
    if (document && open) {
      if (
        document.document_type === FIELD_TYPE_FILE &&
        previewDoc.length === 0
      ) {
        doc.fetchDownloadUrl(document.file);
      } else if (document.document_type === FIELD_TYPE_TEXT) {
        setTextPreview(document.text_value);
      }
    }
  }, [document, open, previewDoc]);

  useEffect(() => {
    if (doc.downloadUrl) {
      setPreviewDoc([
        {
          uri: doc.downloadUrl,
          fileType: filePreviewMIMECheck(doc.downloadUrl).typeMatched,
        },
      ]);
    }
  }, [doc.downloadUrl]);

  function handleClosePreview() {
    setTextPreview(null);
    setPreviewDoc([]);
    onClose();
  }

  // const PreviewControls = (state, previousDocument, nextDocument) => {
  //   if (state.documentLoading) {
  //     return null;
  //   }
  //   return (
  //     <PreviewControlsContainer container>
  //       <Grid
  //         item
  //         xs={5.5}
  //         display="flex"
  //         justifyContent="center"
  //         alignItems="center"
  //       >
  //         <Typography variant="body2" color={'#FFFFFF'} children={'1/4'} />
  //       </Grid>
  //       <Grid item xs={1} display="flex">
  //         <Divider
  //           orientation="vertical"
  //           flexItem
  //           sx={{ background: '#A28CE1', margin: '0.4rem 0' }}
  //         />
  //       </Grid>
  //       <Grid item xs={5.5} display="flex">
  //         <IconButton
  //           disableRipple
  //           onClick={previousDocument}
  //           children={<ZoomInIcon htmlColor={'#FFFFFF'} />}
  //         />
  //         <IconButton
  //           disableRipple
  //           onClick={nextDocument}
  //           children={<ZoomOutIcon htmlColor={'#FFFFFF'} />}
  //         />
  //       </Grid>
  //     </PreviewControlsContainer>
  //   );
  // };

  return (
    <Modal open={open} onClose={handleClosePreview}>
      <ModalPaper>
        {!smallHeight && (
          <Grid container minHeight="10%">
            <Grid item xs={9}>
              <FileInfoDisplay
                name={document?.title}
                hash={document?.file_hash}
                footer={false}
              />
            </Grid>
            <Grid item xs={3} display="flex" justifyContent="flex-end">
              <CloseButtonContainer>
                <IconButton
                  size="small"
                  onClick={handleClosePreview}
                  children={<CloseIcon htmlColor="#7D50FF" />}
                />
              </CloseButtonContainer>
            </Grid>
          </Grid>
        )}
        {!smallHeight ? (
          <Divider />
        ) : (
          <Grid item xs={12} display="flex" justifyContent="flex-end">
            <CloseButtonContainer smallHeight={smallHeight}>
              <IconButton
                size="small"
                onClick={handleClosePreview}
                children={<CloseIcon htmlColor="#7D50FF" />}
              />
            </CloseButtonContainer>
          </Grid>
        )}
        <Grid
          container
          display="flex"
          justifyContent="center"
          alignItems={!textPreview && 'center'}
          padding="2rem"
          height="90%"
        >
          {!textPreview ? (
            <CustomDocViewer
              pluginRenderers={DocViewerRenderers}
              documents={previewDoc}
              config={{
                header: {
                  disableHeader: true,
                  disableFileName: true,
                  retainURLParams: false,
                  // overrideComponent: PreviewControls,
                },
                csvDelimiter: ',',
                pdfZoom: {
                  defaultZoom: 1,
                  zoomJump: 0.2,
                },
              }}
            />
          ) : (
            <p>{textPreview}</p>
          )}
        </Grid>
        {/* <PageButtonsContainer>
          <IconButton
            disableRipple
            onClick={() => {
              // Prev. Page
            }}
            children={<ArrowCircleLeftIcon htmlColor={'#FFFFFF'} />}
          />
          <IconButton
            disableRipple
            onClick={() => {
              // Next Page
            }}
            children={<ArrowCircleRightIcon htmlColor={'#FFFFFF'} />}
          />
        </PageButtonsContainer> */}
      </ModalPaper>
    </Modal>
  );
}
