import React from 'react';
import { Offline } from 'react-detect-offline';
import CustomButton from './CustomButton';
import { Modal, Box, Paper, Alert } from '@mui/material';
import { useIntl } from 'react-intl';

import { styled } from '@mui/material/styles';

import { PING_URL } from '../utils/url';
import { pollingConfig } from '../constants';

const ModalBox = styled(Box)({
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
});

const ModalPaper = styled(Paper)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '30rem',
  height: 'auto',
  padding: '2rem',
  '@media (max-width: 600px)': {
    width: '75vw',
  },
});

const InfoPaper = styled(Paper)({
  display: 'flex',
  marginBottom: '2rem',
  width: '30rem',
  height: 'auto',
  '@media (max-width: 600px)': {
    width: '75vw',
  },
});

const CustomAlert = styled(Alert)({
  width: '100%',
  margin: '1rem',
});

export default function DisconnectModal(props) {
  const intl = useIntl();
  return (
    <Offline 
      polling={{ 
        url: PING_URL,
        interval: pollingConfig.interval,
        timeout: pollingConfig.timeout
      }} 
      onChange={props.onChange}>
      <Modal open={true}>
        <ModalBox>
          <ModalPaper elevation={2}>
            <InfoPaper variant="outlined">
              <CustomAlert severity="warning">{props.alertText}</CustomAlert>
            </InfoPaper>
            <CustomButton
              variant="contained"
              onClick={props.buttonAction}
              children={props.buttonText}
            />
          </ModalPaper>
        </ModalBox>
      </Modal>
    </Offline>
  );
}
