import React from 'react';
import { useIntl } from 'react-intl';
import PaymentCard from './PaymentCard';
import InlineAlert from '../InlineAlert';

import { Modal, Button, Paper, Grid } from '@mui/material';

import { styled } from '@mui/material/styles';

const ModalPaper = styled(Paper)({
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '16px',
  padding: '1rem 1rem 0rem 1rem',
  '@media (max-width: 900px)': {
    padding: '0rem',
    transform: 'translate(-50%, -32%)',
    width: '95vw',
  },
});

const PricesContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  '@media (max-width: 900px)': {
    flexDirection: 'column',
  },
});

export default function PaymentCardsModal({
  open,
  onClose,
  planNumber,
  types,
  prices,
  activePaymentType,
  paymentCardInfo,
  handleOpenConfirmModal,
}) {
  const intl = useIntl();

  return (
    <Modal open={open} onClose={onClose} sx={{ overflow: 'scroll' }}>
      <ModalPaper>
        {paymentCardInfo(prices, planNumber, types.onePay) &&
          paymentCardInfo(prices, planNumber, types.subscription) && (
            <InlineAlert
              title={intl.formatMessage({ id: 'app.alerts.inline-info-title' })}
              severity="info"
              alertText={intl.formatMessage({
                id: 'app.my-account.plans.alert-payment-info',
              })}
            />
          )}
        <PricesContainer>
          <PaymentCard
            planLength={intl.formatMessage({
              id: 'app.my-account.plans.monthly-plan',
            })}
            planType={intl.formatMessage({
              id: 'app.my-account.plans.monthly-plan-desc',
            })}
            buttonText={intl.formatMessage({
              id: 'app.my-account.plans.modal.button',
            })}
            priceHeaderText={intl.formatMessage({
              id: 'app.my-account.plans.card.price',
            })}
            planNumber={planNumber}
            type={types.subscription}
            activePaymentType={activePaymentType}
            cardData={paymentCardInfo(prices, planNumber, types.subscription)}
            handleOpenConfirmModal={handleOpenConfirmModal}
          />
          <PaymentCard
            planLength={intl.formatMessage({
              id: 'app.my-account.plans.yearly-plan',
            })}
            planType={intl.formatMessage({
              id: 'app.my-account.plans.yearly-plan-desc',
            })}
            buttonText={intl.formatMessage({
              id: 'app.my-account.plans.modal.button',
            })}
            priceHeaderText={intl.formatMessage({
              id: 'app.my-account.plans.card.price',
            })}
            planNumber={planNumber}
            type={types.onePay}
            activePaymentType={activePaymentType}
            cardData={paymentCardInfo(prices, planNumber, types.onePay)}
            handleOpenConfirmModal={handleOpenConfirmModal}
          />
          {!paymentCardInfo(prices, planNumber, types.onePay) &&
            !paymentCardInfo(prices, planNumber, types.subscription) && (
              <InlineAlert
                title={intl.formatMessage({
                  id: 'app.alerts.inline-error-title',
                })}
                severity="error"
                alertText={intl.formatMessage({
                  id: 'app.my-account.plans.modal.plan-not-available',
                })}
              />
            )}
        </PricesContainer>
        <Grid container sx={{ padding: '1rem 0rem' }}>
          <Grid item xs={12} display="flex" justifyContent="center">
            <Button
              variant="outlined"
              children={intl.formatMessage({
                id: 'app.my-account.plans.cancel-button',
              })}
              onClick={onClose}
            />
          </Grid>
        </Grid>
      </ModalPaper>
    </Modal>
  );
}
