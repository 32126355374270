import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useIntl } from 'react-intl';

import { Typography, Box, Fade, Alert } from '@mui/material';
import { styled } from '@mui/material/styles';

import DocumentCard from '../components/kontroli/DocumentCard';
import FilePreviewer from './FilePreviewer';
import InlineAlert from './InlineAlert';
import Loader from './Loader';
import { ValidatedFile } from './ValidatedFile';

import { getDocumentByMd5 } from '../api/document';

import { STATUS_OK } from '../constants';

const SearchContainer = styled('div')(({ theme }) => ({
  color: theme.palette.text.secondary,
  padding: theme.spacing(2),
}));

export const Find = () => {
  const [searchHashKey, setSearchHashKey] = useState('');
  const [noResults, setNoResults] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(undefined);
  const [textProp1, setTextProp1] = useState(undefined);
  const [uploadInProgress, setUploadInProgress] = useState(false);

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewDocument, setPreviewDocument] = useState(undefined);

  const intl = useIntl();

  function handleOpenPreview(document) {
    setPreviewDocument(document);
    setPreviewOpen(true);
  }

  function handleClosePreview() {
    setPreviewOpen(false);
  }

  const textValidated = {
    title: intl.formatMessage({
      id: 'app.search.validate',
    }),
  };
  const textNoValidated = {
    title: intl.formatMessage({
      id: 'app.search.novalidate',
    }),
    desc: intl.formatMessage({
      id: 'app.search.novalidate-desc',
    }),
  };

  const SEARCH_LIMIT = 5;

  const getUploadProgress = (progressInfo) => {
    setUploadInProgress(progressInfo?.status.loading);
    if (uploadInProgress) {
      setTextProp1(undefined);
    }
  };

  const onChange = async (statusInfo) => {
    if (statusInfo.reset) {
      setTextProp1(undefined);
    }
    if (!uploadInProgress) {
      if (statusInfo.uploadedFileInfo[0]?.checksum) {
        setSearchHashKey(statusInfo.uploadedFileInfo[0]?.checksum);
      } else {
        setSearchHashKey('');
      }
    }
  };

  // Found items list
  const [items, setItems] = useState([]);
  // Infinite scroll flags
  const [hasMore, sethasMore] = useState(true);
  const [page, setpage] = useState(1);

  // TODO maybe move within a dedicated "findHook" or such
  const fetchDataByHash = async (searchHashKey, page, limit = SEARCH_LIMIT) => {
    if (page === 0) {
      setLoading(true);
    }
    setErrors(undefined);

    const res = await getDocumentByMd5(searchHashKey, limit, page);

    if (page === 0) {
      setLoading(false);
    }

    if (res.status === STATUS_OK) {
      const data = res.data;
      if (data.count == 1) {
        sethasMore(false);
      }
      return data.rows;
    } else {
      setErrors(res.data);
      sethasMore(false);
      return [];
    }
  };

  // When a new file is uploaded for search fetch the API with the hash of the file to match coincidences
  useEffect(() => {
    async function wrapFetch() {
      const data = await fetchDataByHash(searchHashKey, 0);
      setItems(data);
      setNoResults(data.length === 0);
      data.length === 0
        ? setTextProp1(textNoValidated)
        : setTextProp1(textValidated);
    }
    // Only search if hash has length
    if (searchHashKey.length > 0) {
      wrapFetch();
    }
  }, [searchHashKey]);

  const fetchMoreData = async () => {
    const DocumentsFromServer = await fetchDataByHash(searchHashKey, page);
    //Add new page documents to the local current list
    setItems([...items, ...DocumentsFromServer]);
    if (
      DocumentsFromServer.length === 0 ||
      DocumentsFromServer.length < SEARCH_LIMIT
    ) {
      sethasMore(false);
    }
    setpage(page + 1);
  };

  return (
    <>
      <FilePreviewer
        open={previewOpen}
        onClose={handleClosePreview}
        document={previewDocument}
      />
      <SearchContainer>
        <Typography
          variant="h4"
          gutterBottom
          component="h5"
          style={{ textAlign: 'center' }}
        >
          {intl.formatMessage({ id: 'app.search.title' })}
        </Typography>
        <Box>
          <ValidatedFile
            textProp0={intl.formatMessage({ id: 'app.search.default' })}
            textProp1={textProp1}
            textValidated={textValidated}
            textNoValidated={textNoValidated}
            onChange={onChange}
            getUploadProgress={getUploadProgress}
          />
        </Box>
      </SearchContainer>

      {/*noResults && !errors && <NoRecordsToShow />*/}

      {loading && <Loader />}
      {errors && (
        <InlineAlert
          title={intl.formatMessage({ id: 'app.alerts.inline-error-title' })}
          severity="error"
          alertText={errors}
        />
      )}

      {items.length > 0 && textProp1?.title.length > 0 && (
        <InfiniteScroll
          dataLength={items.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={<Loader margin={'6rem'} />}
          endMessage={
            <Alert severity="info" sx={{ mb: 2 }}>
              {intl.formatMessage({ id: 'app.mydocuments.no-records' })}
            </Alert>
          }
          style={{ overflow: 'hidden' }}
        >
          <Fade timeout={1000} in={true}>
            <div className="container">
              <div className="row m-2">
                {items.map((item) => (
                  <DocumentCard
                    document={item}
                    isShareable={false}
                    validateDialog={false}
                    find={true}
                    handleOpenPreview={handleOpenPreview}
                    textValidated={textValidated}
                    textNoValidated={textNoValidated}
                  />
                ) )}
              </div>
            </div>
          </Fade>
        </InfiniteScroll>
      )}
    </>
  );
};
