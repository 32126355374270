import React from 'react';

import { Button, Modal, Typography, Paper, Box } from '@mui/material';
import CustomButton from './CustomButton';
import InlineAlert from './InlineAlert';
import { styled } from '@mui/material/styles';

import { useIntl } from 'react-intl';

const ModalBox = styled(Box)({
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
});

const ModalPaper = styled(Paper)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '30rem',
  height: 'auto',
  borderRadius: '18px',
  '@media (max-width: 600px)': {
    width: '95vw',
  },
});

const ModalTitleContainer = styled('div')({
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  borderBottom: '1px solid rgba(33, 33, 33, 0.08)',
  padding: '2rem 0rem',
});

const ModalDescriptionContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '2rem 2rem 1rem 2rem',
});

const ConfirmModalButtonsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: ' center',
  padding: '1rem 2rem 2rem 2rem',
  '& button': {
    width: '8rem',
    margin: '0.5rem',
  },
});

export default function ConfirmModal({
  open,
  onClose,
  modalTitle,
  modalText,
  warningTitle,
  warningText,
  confirmButtonText,
  cancelButtonText,
  confirmAction,
  cancelAction,
}) {
  const intl = useIntl();

  return (
    <Modal open={open} onClose={onClose}>
      <ModalBox>
        <ModalPaper>
          {modalTitle && (
            <ModalTitleContainer>
              <Typography
                variant="h5"
                align="center"
                children={modalTitle}
              ></Typography>
            </ModalTitleContainer>
          )}
          <ModalDescriptionContainer>
            <Typography variant="body1" align="center" children={modalText} />
            <br />
            {warningTitle && warningText ? (
              <div style={{ width: '100%' }}>
                <InlineAlert
                  title={warningTitle}
                  severity="warning"
                  alertText={warningText}
                />
              </div>
            ) : null}
          </ModalDescriptionContainer>
          {(confirmAction || cancelAction) && (
            <ConfirmModalButtonsContainer>
              {confirmAction && (
                <CustomButton
                  variant="contained"
                  children={
                    confirmButtonText
                      ? confirmButtonText
                      : intl.formatMessage({
                          id: 'app.confirm-modal.confirm',
                        })
                  }
                  onClick={confirmAction}
                />
              )}
              {cancelAction && (
                <Button
                  variant="outlined"
                  children={
                    cancelButtonText
                      ? cancelButtonText
                      : intl.formatMessage({
                          id: 'app.confirm-modal.cancel',
                        })
                  }
                  onClick={cancelAction}
                />
              )}
            </ConfirmModalButtonsContainer>
          )}
        </ModalPaper>
      </ModalBox>
    </Modal>
  );
}
