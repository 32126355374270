import { GET_ID_DOCUMENTS, LOADING_DOCUMENTS, ERROR_DOCUMENTS } from "./DocumentsTypes";

export default function DocumentsReducer(state, action) {
  const { payload, type } = action;

  switch (type) {
    case LOADING_DOCUMENTS:
      return {
        ...state,
        loading: true,
        errors: undefined,
        document: undefined,
      };
    case GET_ID_DOCUMENTS:
      return {
        ...state,
        document: payload,
        loading: false,
        errors: undefined,
      };
    case ERROR_DOCUMENTS:
      return {
        ...state,
        errors: payload,
        document: undefined,
        loading: false,
      };
    default:
      return state;
  }
};
