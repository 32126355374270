import React from 'react';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../CustomButton';

import { Grid, Typography, Divider, IconButton } from '@mui/material';

import { styled } from '@mui/material/styles';

import CircleIcon from '@mui/icons-material/Circle';
import CloseIcon from '@mui/icons-material/Close';

const NotificationGrid = styled(Grid)({
  padding: '0.8rem',
});

const ActionButtonsContainer = styled('div')({
  '& button': {
    margin: '0.2rem 0.4rem',
  },
});

export default function NotificationCard({
  index,
  id,
  title,
  message,
  date,
  code,
  actions,
  closeAction,
}) {
  const navigate = useNavigate();

  return (
    <>
      <NotificationGrid container>
        <Grid item xs={0.4}>
          <CircleIcon
            sx={{
              fontSize: '9px',
              paddingTop: '12px',
              color: '#7D50FF',
            }}
          />
        </Grid>
        <Grid item xs={10.9}>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                fontWeight={500}
                fontSize={18}
                children={title}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body2"
                fontWeight={400}
                fontSize={14}
                gutterBottom
                children={message}
              />
            </Grid>
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                variant="caption"
                color={'#A4A7C1'}
                fontWeight={400}
                fontSize={10}
                children={date}
              />
              <ActionButtonsContainer>
                {actions?.map((action) => (
                  <CustomButton
                    variant="contained"
                    onClick={() => {
                      navigate(
                        action.route ? action.route : action.external_url,
                        { replace: true }
                      );
                    }}
                    children={
                      <Typography
                        variant="caption"
                        children={action.title.es}
                      />
                    }
                  />
                ))}
              </ActionButtonsContainer>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={0.7}>
          <IconButton
            disableRipple
            onClick={() => {
              closeAction(index, id);
            }}
            children={<CloseIcon fontSize="small" htmlColor="#7D50FF" />}
          />
        </Grid>
      </NotificationGrid>
      <Divider />
    </>
  );
}
